export const weightsAndZones = {
  weights_TNT_ECO: [
    10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 500, 510, 520,
  ],

  zones_TNT: [0, 1, 2, 3, 4, 5, 6],
  weights_FEDEX_ENV: [0.25],
  weights_FEDEX_PAK: [0.5, 1, 1.5, 2, 2.5],
  weights_FEDEX_DOX: [0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 10, 11],
  weights_FEDEX_BOX: [
    0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5,
    10, 10.5, 71, 72,
  ],
  zones_FEDEX: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L'],
};
