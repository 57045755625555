<script lang="ts">
  import HantaInputMultiSelectLean from './input/hanta-input-multi-select-lean.svelte';
  import * as Avatar from '$lib/components/ui/avatar';
  import { getItems } from '$lib/api/queries';
  import { appStore, popupStore } from '$lib/stores/app-store';
  import { Button } from '$lib/components/ui/button';
  import { goto } from '$app/navigation';
  import Icon from '@iconify/svelte';

  export let module = 'invoices';
  export let label = '';
  export let hideLabel = false;
  export let value = undefined;
  export let onChange = undefined;
  export let withOpenButton = false;
  export let placeholder = 'Select...';
  export let multiple = false;

  const loadOptions = async (keyword: string): Promise<any> => {
    let select = 'id,name';
    if (module === 'contacts') {
      select += ',photo';
    } else if (module === 'accounts') {
      select +=
        ',photo:logo,creditRating,debtReason,currentBalance,maxDebt,currentCreditLimit,baseTariff:tariffs!baseTariff(id, name),customerTariff:tariffs!customerTariff(id, name),supplierTariff:tariffs!supplierTariff(id, name)';
    } else if (module === 'orders') {
      select += ',shipment:shipments!inner(*,rate:rates(*))';
    }

    const queryKey = ['crm-object-selector', module, keyword];
    const cached = $appStore.queryClient.getQueryData(queryKey);

    if (cached) {
      return cached;
    }

    const result = await getItems({
      collection: module,
      from: 0,
      to: 25,
      search: keyword,
      select: select,
    });

    $appStore.queryClient.setQueryData(queryKey, result.data, {
      staleTime: 1000 * 60 * 5, // 5 minutes
    });

    return result.data;
  };

  function openInPopup(id: string, e: Event) {
    popupStore.openPopup('crmObject', {
      module,
      id: id,
    });
    e.stopPropagation();
  }

  $: withPhoto = module === 'contacts' || module === 'accounts';
</script>

<HantaInputMultiSelectLean
  class="w-full"
  bind:value
  label={hideLabel ? '' : label}
  {loadOptions}
  {multiple}
  onSelectChanged={value => onChange && onChange(value)}
  {placeholder}
  {withOpenButton}
>
  <div class="flex items-center w-full h-full" let:index let:item slot="item">
    {#if withPhoto && item?.photo}
      <Avatar.Root class="rounded-md size-6">
        <Avatar.Image
          class="flex overflow-hidden relative shrink-0"
          src={item.photo}
        />
        <Avatar.Fallback>-</Avatar.Fallback>
      </Avatar.Root>
    {/if}
    <div class="ml-2 text-sm truncate">{item?.name}</div>
  </div>
  <div
    class="flex overflow-hidden items-center w-full h-full gap-1"
    let:selection
    slot="selection"
  >
    {#if multiple && Array.isArray(selection)}
      {#each selection as item}
        <div
          class="flex items-center gap-1 bg-muted px-2 py-0.5 rounded-md group"
        >
          {#if withPhoto && item?.photo}
            <Avatar.Root class="rounded-md size-4">
              <Avatar.Image
                class="flex overflow-hidden relative shrink-0"
                src={item.photo}
              />
              <Avatar.Fallback>-</Avatar.Fallback>
            </Avatar.Root>
          {/if}
          <span class="text-sm truncate">{item?.name}</span>
          {#if item.id && multiple}
            <Button
              variant="ghost"
              size="icon"
              class="size-4 opacity-0 group-hover:opacity-100 -mr-1"
              on:click={e => openInPopup(item.id, e)}
            >
              <Icon icon="mdi:external-link" />
            </Button>
          {/if}
        </div>
      {/each}
    {:else}
      <div class="flex items-center w-full group">
        {#if withPhoto && selection?.photo}
          <Avatar.Root class="rounded-md size-6">
            <Avatar.Image
              class="flex overflow-hidden relative shrink-0"
              src={selection.photo}
            />
            <Avatar.Fallback>-</Avatar.Fallback>
          </Avatar.Root>
        {/if}
        <div class="ml-2 w-full text-sm truncate">
          {selection?.name}
        </div>
        {#if selection?.id && multiple}
          <Button
            variant="ghost"
            size="icon"
            class="size-6 opacity-0 group-hover:opacity-100"
            on:click={e => openInPopup(selection.id, e)}
          >
            <Icon icon="mdi:external-link" />
          </Button>
        {/if}
      </div>
    {/if}
  </div>
</HantaInputMultiSelectLean>
