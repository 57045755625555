import { weightsAndZones } from '$lib/config';
const {
  weights_TNT_ECO,
  weights_FEDEX_ENV,
  weights_FEDEX_PAK,
  weights_FEDEX_BOX,
  weights_FEDEX_DOX,
  zones_TNT,
  zones_FEDEX,
} = weightsAndZones;
import { PriceServiceFactory } from '../leads_orders/prices/PriceServiceFactory';

const priceService = PriceServiceFactory.create();

const getDiff = (weight, count) => {
  return +weight == +520 || +weight == +120
    ? 10
    : +weight == +11 || +weight == +72
      ? 1
      : (+weight == +5.5 && count < 7) || +weight == +10.5
        ? 0.5
        : 0;
};

const createPriceObject = (weights, zones, options) => {
  return weights.map(weight => ({
    weight,
    weightLabel:
      getDiff(weight, weights?.length) > 0
        ? `+ ${getDiff(weight, weights?.length)}kg`
        : weight,
    prices: zones.reduce((zoneAcc, zone) => {
      zoneAcc[zone] = {
        ...options,
        id: zone,
        zone,
        fuelFee: true,
        securityFee: true,
        weight,
        diff: getDiff(weight, weights?.length),
        country: {
          id: zone,
          name: 'Zone ' + zone,
          zone,
          zoneEco: zone,
          zoneFedEx: zone,
        },
        price: null, // This will be filled by getPrices
      };
      return zoneAcc;
    }, {}),
  }));
};

export const prices = {
  FEDEX_ENV: createPriceObject(weights_FEDEX_ENV, zones_FEDEX, {
    operator: 'FedEx',
    direction: 'EXPORT',
    paymentSide: 'SENDER',
    extraService: '',
    packageType: 'PL - FedEx Envelope',
    product: 'DOX',
  }),
  FEDEX_DOX: createPriceObject(weights_FEDEX_PAK, zones_FEDEX, {
    operator: 'FedEx',
    direction: 'EXPORT',
    paymentSide: 'SENDER',
    extraService: '',
    packageType: 'PP - FedEx Pak',
    product: 'DOX',
  }),
  FEDEX_XPS: createPriceObject(weights_FEDEX_DOX, zones_FEDEX, {
    operator: 'FedEx',
    direction: 'EXPORT',
    paymentSide: 'SENDER',
    extraService: '',
    packageType: 'PO - Other packaging material',
    product: 'XPS',
  }),
  FEDEX_IMPORT_IP_PL_ENV: createPriceObject(weights_FEDEX_ENV, zones_FEDEX, {
    operator: 'FedEx',
    direction: 'IMPORT',
    paymentSide: 'RECEIVER',
    extraService: '',
    packageType: 'PL - FedEx Envelope',
    product: 'DOX',
  }),
  FEDEX_IMPORT_IP_PO: createPriceObject(weights_FEDEX_PAK, zones_FEDEX, {
    operator: 'FedEx',
    direction: 'IMPORT',
    paymentSide: 'RECEIVER',
    extraService: '',
    packageType: 'PP - FedEx Pak',
    product: 'DOX',
  }),
  FEDEX_IMPORT_IP_PP_PAK: createPriceObject(weights_FEDEX_BOX, zones_FEDEX, {
    operator: 'FedEx',
    direction: 'IMPORT',
    paymentSide: 'RECEIVER',
    extraService: '',
    packageType: 'PO - Other packaging material',
    product: 'XPS',
  }),
  TNT_ECO: createPriceObject(weights_TNT_ECO, zones_TNT, {
    operator: 'TNT',
    direction: 'EXPORT',
    paymentSide: 'SENDER',
    extraService: '',
    packageType: 'PO - Other packaging material',
    product: 'ECO',
  }),
};

export const getPrices = async (tariffs: any) => {
  const calculatedPrices = {};

  for (const [productKey, productPrices] of Object.entries(prices)) {
    calculatedPrices[productKey] = await Promise.all(
      productPrices.map(async item => {
        const calculatedZonePrices = {};
        for (const [zone, priceObj] of Object.entries(item.prices)) {
          const result = await priceService.calculatePrices(
            {
              ...priceObj,
              fuelFee: false,
              securityFee: false,
            },
            tariffs,
            [],
            false,
          );
          if (priceObj.diff > 0) {
            const resultPreviousStep = await priceService.calculatePrices(
              {
                ...priceObj,
                fuelFee: false,
                securityFee: false,
                weight: priceObj.weight - priceObj.diff,
              },
              tariffs,
              [],
              false,
            );
            result.totalEUR = result.totalEUR - resultPreviousStep.totalEUR;
          }

          calculatedZonePrices[zone] = {
            ...priceObj,
            price: parseFloat(result.totalEUR.toFixed(2)),
          };
        }
        return {
          weight: item.weight,
          weightLabel: item.weightLabel,
          prices: calculatedZonePrices,
        };
      }),
    );
  }

  return calculatedPrices;
};

// Usage example:
// const tariffs = /* your tariff data */;
// const calculatedPrices = await getPrices(tariffs);
// console.debug(calculatedPrices.TNT_DOX[0].prices['0'].price); // Access price for TNT_DOX, first weight, zone 0
