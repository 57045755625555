import { supabase } from './supabase';
import { authStore } from '$lib/stores/auth-store';
import { get } from 'svelte/store';

export interface InvoiceRow {
  invoiceNumber: string;
  invoiceDate: string;
  dueDate: string;
  currency: string;
  amountDue: string;
  customerNumber: string;
  shipmentNumber: string;
  serviceType: string;
  packageCount: string;
  weight: string;
  weightUnit: string;
  senderCompany: string;
  senderName: string;
  senderAddress1: string;
  senderAddress2: string;
  senderCity: string;
  senderPostal: string;
  senderCountry: string;
  recipientCompany: string;
  recipientName: string;
  recipientAddress1: string;
  recipientAddress2: string;
  recipientCity: string;
  recipientPostal: string;
  recipientCountry: string;
  deliveryDate: string;
  shipDate: string;
  total: string;
}

export interface InvoiceHeader {
  key: string;
  title: string;
}

export interface ParsedInvoiceResponse {
  headers: InvoiceHeader[];
  data: InvoiceRow[];
}

export async function parseBalance(file: File): Promise<ParsedInvoiceResponse> {
  try {
    const formData = new FormData();
    formData.append('file', file);

    const { data, error } = await supabase(
      get(authStore)?.token,
      false,
    ).functions.invoke('parse-balance', {
      body: formData,
    });

    if (error) {
      console.error('Supabase function error:', error);
      throw new Error(`Failed to parse invoice: ${error.message}`);
    }

    if (!data || typeof data !== 'object' || !data.headers || !data.data) {
      throw new Error('Invalid data received from parse-invoice function');
    }

    // Validate the data structure matches our interface
    const validatedData = data.data.map((row: any) => {
      // Ensure all required fields are present, even if empty
      const validatedRow: InvoiceRow = {
        invoiceNumber: row.invoiceNumber?.toString() || '',
        invoiceDate: row.invoiceDate || '',
        dueDate: row.dueDate || '',
        currency: row.currency || '',
        amountDue: row.amountDue?.toString() || '',
        customerNumber: row.customerNumber?.toString() || '',
        shipmentNumber: row.shipmentNumber?.toString() || '',
        serviceType: row.serviceType || '',
        packageCount: row.packageCount?.toString() || '',
        weight: row.weight?.toString() || '',
        weightUnit: row.weightUnit || '',
        senderCompany: row.senderCompany || '',
        senderName: row.senderName || '',
        senderAddress1: row.senderAddress1 || '',
        senderAddress2: row.senderAddress2 || '',
        senderCity: row.senderCity || '',
        senderPostal: row.senderPostal?.toString() || '',
        senderCountry: row.senderCountry || '',
        recipientCompany: row.recipientCompany || '',
        recipientName: row.recipientName || '',
        recipientAddress1: row.recipientAddress1 || '',
        recipientAddress2: row.recipientAddress2 || '',
        recipientCity: row.recipientCity || '',
        recipientPostal: row.recipientPostal?.toString() || '',
        recipientCountry: row.recipientCountry || '',
        deliveryDate: row.deliveryDate || '',
        shipDate: row.shipDate || '',
        total: row.total?.toString() || '',
      };
      return validatedRow;
    });

    return {
      headers: data.headers as InvoiceHeader[],
      data: validatedData,
    };
  } catch (error) {
    console.error('Error parsing invoice:', error);
    throw error;
  }
}

export const saveImportedBalance = async (balances: InvoiceRow[]) => {
  if (balances?.length === 0) return;

  // First, fetch all orders with their shipments and rates that match our shipment numbers
  const shipmentNumbers = balances.map(b => b.shipmentNumber);
  const { data: orders, error: ordersError } = await supabase(
    get(authStore)?.token,
    false,
  )
    .from('orders')
    .select(
      `
      id,
      name,
      shipments (
        id,
        weight,
        totalEUR,        
        rates (
          id,          
          supplierTotalEur,
          services
        )
      )
    `,
    )
    .in('name', shipmentNumbers)
    .order('createdAt', { ascending: false });

  if (ordersError) throw ordersError;

  // Create a map for quick lookup
  const orderMap = new Map(orders?.map(order => [order.name, order]) || []);
  console.log(orderMap);

  const newBalance = {
    name: balances[0].invoiceNumber,
    refId: balances[0].invoiceNumber,
    invoiceNumber: balances[0].invoiceNumber,
    invoiceDate: balances[0].invoiceDate,
    dueDate: balances[0].dueDate,
    currency: balances[0].currency,
    amount: balances[0].amountDue,
    difference: 0,
    differenceWeight: 0,
    balance: 0,
    tags: ['imported'],
    status: 'NEW',
    positions: balances.map((position, idx) => {
      const order = orderMap.get(position.shipmentNumber);
      const shipment = order?.shipments;
      const rate = shipment?.rates;

      const balanceTotal = parseFloat(position.total);
      const balanceWeight = parseFloat(position.weight);

      const insurancePrice =
        rate?.services?.find(s => s.product.includes('Страховка'))
          ?.supplierEur ?? 0;

      const crmTotal = (rate?.supplierTotalEur || 0) - insurancePrice;

      return {
        id: crypto.randomUUID(),
        order,
        position: idx + 1,
        comments: position.shipmentNumber,
        crmTotal: crmTotal,
        crmWeight: order?.shipments?.weight || 0,
        balanceTotal,
        balanceWeight,
        diffTotal: +(crmTotal - balanceTotal).toFixed(2),
        diffWeight: +(order?.shipments?.weight - balanceWeight).toFixed(2),
      };
    }),
  };

  const amountTotal = newBalance.positions.reduce(
    (sum, p) => sum + (p.balanceTotal ?? 0),
    0,
  );

  const crmTotal = newBalance.positions.reduce(
    (sum, p) => sum + (p.crmTotal ?? 0),
    0,
  );
  const balanceTotal = newBalance.positions.reduce(
    (sum, p) => sum + (p.balanceTotal ?? 0),
    0,
  );
  newBalance.balance = crmTotal;
  newBalance.difference = crmTotal - balanceTotal;

  const crmWeight = newBalance.positions.reduce(
    (sum, p) => sum + (p.crmWeight ?? 0),
    0,
  );
  const balanceWeight = newBalance.positions.reduce(
    (sum, p) => sum + (p.balanceWeight ?? 0),
    0,
  );
  newBalance.differenceWeight = crmWeight - balanceWeight;

  if (!newBalance.amount) {
    newBalance.amount = amountTotal;
  }
  if (!newBalance.currency) {
    newBalance.currency = 'EUR';
  }

  if (!newBalance.dueDate) {
    newBalance.dueDate = new Date(
      Date.now() + 30 * 24 * 60 * 60 * 1000,
    ).toISOString(); // + 30days
  }

  const { data, error } = await supabase(get(authStore)?.token, false)
    .from('balances')
    .insert(newBalance)
    .select();

  if (error) throw error;
  return data;
};
