<script lang="ts">
  import { type ActivityWithRefs, activityWithRefsSchema } from '$db/schema';
  import { superForm } from 'sveltekit-superforms';
  import { zodClient } from 'sveltekit-superforms/adapters';
  import { saveActivity } from '$lib/api/mutations';
  import { appStore } from '$lib/stores/app-store';
  import { alertStore } from '$lib/stores/alert-store';
  import HantaInputText from '$lib/widgets/input/hanta-input-text.svelte';
  import HantaInputDate from '$lib/widgets/input/hanta-input-date.svelte';
  import HantaInputCheckbox from '$lib/widgets/input/hanta-input-checkbox.svelte';
  import HantaInputTextareaRichtext from '$lib/widgets/input/hanta-input-textarea-richtext/hanta-input-textarea-richtext.svelte';
  import * as Form from '$lib/components/ui/form';
  import { cn } from '$lib/utils/hanta-utils';

  import HantaInputMultiSelect from '$lib/widgets/input/hanta-input-multi-select.svelte';
  import { getItems } from '$lib/api/queries';
  import { formatRoleName, isCandidate } from '$lib/app/utils.js';
  import { onMount, tick } from 'svelte';
  import CrmAvatar from '$lib/components/hanta/crm-avatar.svelte';
  import { Badge } from '$lib/components/ui/badge';
  import File from '$lib/components/hanta/files/file.svelte';
  import { getIconForActivityType } from '$lib/config/crm-config';
  import ActivityTypeSwitcher from './activity-type-switcher.svelte';
  import Button from '$lib/components/ui/button/button.svelte';
  import UsersSelector from '$lib/widgets/users/users-selector.svelte';
  import ActivityRefsPopover from './activity-refs-popover.svelte';
  import CrmDeleteObject from '$lib/ui/crm-delete-object.svelte';
  import HantaInputLovSelect from '$lib/widgets/input/hanta-input-lov-select.svelte';

  export let activity: ActivityWithRefs;
  export let form: any;
  export let onClose: () => void = () => {};
  export let showButtons = true;

  const { form: formData, errors } = form;

  const loadOptionsContacts = async (keyword: string): Promise<any> => {
    console.log(activity);
    if (!keyword) {
      const result = await getItems({
        collection: 'contacts',
        from: 0,
        to: 25,
        select: 'id,name,photo,role',
        filters: [
          {
            field: 'customer',
            operator: 'eq',
            value: '',
            active: true,
          },
        ],
      });
      return result.data;
    }

    const result = await getItems({
      collection: 'contacts',
      from: 0,
      to: 25,
      search: keyword,
      select: 'id,name,photo,role',
    });

    return result.data;
  };

  const onContactsChanged = value => {
    const candidates = [];
    const contacts = [];
    value.forEach(v => {
      if (isCandidate(v.role)) {
        candidates.push(v);
      } else {
        contacts.push(v);
      }
    });

    formData.update(data => ({
      ...data,
      candidates: candidates,
      contacts: contacts,
    }));
  };

  onMount(() => {
    formData.update(data => ({
      ...data,
      allContacts: [...(data.candidates || []), ...(data.contacts || [])],
    }));
  });

  const refsChanged = async value => {
    const allContacts = [
      ...new Map(
        [...$formData.contacts, ...$formData.candidates].map(item => [
          item.id,
          item,
        ]),
      ).values(),
    ];

    await tick();

    formData.update(data => ({
      ...data,
      allContacts,
    }));
  };

  let showDeleteDialog = false;
</script>

<div class="flex flex-col py-0 mt-4 h-full overflow-scroll">
  <ActivityTypeSwitcher bind:selectedType={$formData.type} />
  <div class="flex flex-row gap-2 items-center w-full my-2 mx-6">
    <HantaInputText
      class={cn(
        'text-xl border-b w-full ',
        $formData.done ? 'line-through' : '',
      )}
      {form}
      name="name"
      placeholder={`Enter title for ${$formData.type?.toLowerCase()} here...`}
      variant="ghost"
      data-testid="activity-name-input"
    />
  </div>

  <div class="flex flex-col px-6 w-full md:gap-6 md:flex-row items-center">
    <HantaInputDate
      {form}
      label="Start Date"
      name="startDate"
      withTime={true}
      data-testid="activity-start-date-input"
    />
    <div class="text-2xl">-</div>
    <HantaInputDate
      {form}
      label="Due Date"
      name="dueDate"
      withTime={true}
      data-testid="activity-due-date-input"
    />
  </div>

  <div class="w-full px-6 grid grid-cols-3 gap-2">
    <UsersSelector
      bind:value={$formData.consultantObj}
      name="consultantObj"
      label="Assigned to"
      multiple={false}
      data-testid="activity-consultant-select"
    />

    <HantaInputLovSelect
      {form}
      name="tags"
      bind:value={$formData.priority}
      type="Activity > Priority"
      label="Priority"
      placeholder="Select a priority"
      multiple={false}
      addable={false}
      icon="mdi:priority-high"
    />
    <HantaInputLovSelect
      {form}
      name="tags"
      bind:value={$formData.tags}
      type="Activity > Tags"
      label="Tags"
      placeholder="Select a tag"
      multiple={true}
      addable={true}
      icon="mdi:tags"
    />
  </div>
  <div class="flex px-6 min-w-0 max-w-full mt-2">
    <HantaInputMultiSelect
      class="w-full min-w-0"
      {form}
      label="Contacted"
      loadOptions={loadOptionsContacts}
      multiple={true}
      name="allContacts"
      onSelectChanged={onContactsChanged}
      data-testid="activity-contacted-multi-select"
    >
      <div
        class="flex gap-1.5 items-center w-full h-full content-stretch"
        let:index
        let:item
        slot="item"
      >
        <div class="w-full">
          <CrmAvatar
            id={item.id}
            module="contacts"
            name={item.name}
            asDiv={true}
          />
        </div>
        <Badge class="text-xs font-light" variant="secondary"
          >{formatRoleName(item.role)}</Badge
        >
      </div>
      <div
        class="flex overflow-hidden gap-1.5 items-center w-full h-full"
        let:selection
        slot="selection"
      >
        <CrmAvatar id={selection.id} module="contacts" name={selection.name} />
        <span class="text-xs font-light text-muted-foreground"
          >{formatRoleName(selection.role)}</span
        >
      </div>
    </HantaInputMultiSelect>
  </div>

  <div class="w-full px-8">
    <ActivityRefsPopover
      {form}
      withPopover={true}
      data-testid="activity-refs-popover"
    />
  </div>
  <div>
    {#if activity.hasAttachment}
      <div class="grid grid-cols-2 gap-2 mx-8 my-4">
        {#each activity.attachments ?? [] as attachment}
          <File
            file={attachment}
            folder={`activities/${activity?.id}`}
            compact={true}
            data-testid="activity-file"
          />
        {/each}
      </div>
    {/if}
    <div class="pl-6 px-4">
      <HantaInputTextareaRichtext
        class="w-full h-full"
        {form}
        name="description"
        placeholder="Notes..."
        resizable={false}
        variant="ghost"
        data-testid="activity-description-input"
      />
    </div>
  </div>

  {#if showButtons}
    <div class="flex justify-end space-x-2 w-full items-center">
      {#if activity?.id}
        <CrmDeleteObject
          bind:open={showDeleteDialog}
          module="activities"
          id={activity?.id}
          onDeleteFn={onClose}
          data-testid="activity-delete-object"
        />

        <Button
          variant="ghost"
          class="absolute left-4 text-red-500"
          on:click={() => (showDeleteDialog = true)}
          data-testid="activity-remove-button">Remove activity</Button
        >
      {/if}

      <div class="mt-2 mr-4">
        <HantaInputCheckbox
          {form}
          name="done"
          label="Mark as done"
          data-testid="activity-done-checkbox"
        />
      </div>

      <Button
        on:click={() => onClose()}
        variant="outline"
        data-testid="activity-cancel-button"
        >Cancel
      </Button>

      <Form.Button variant="default" data-testid="activity-save-button"
        >{$formData?.id ? 'Update' : 'Create'} activity</Form.Button
      >
    </div>
  {/if}
</div>
