<script lang="ts">
  import HantaDateRange from '$lib/components/hanta/date/hanta-date-range.svelte';
  import InvoicesStatisticsOverall from './invoices-statistics-overall.svelte';
  import InvoicesStatisticsOverdue from './invoices-statistics-overdue.svelte';

  import type { DateRange } from 'bits-ui';

  let dateRange: DateRange;
</script>

<div class="mt-48 p-6 bg-card hidden md:block">
  <div class="flex justify-between items-center mb-4">
    <h1 class="text-2xl font-bold">Invoices</h1>
  </div>
  <div class="grid grid-cols-1 gap-4 2xl:grid-cols-2">
    <InvoicesStatisticsOverdue />
    <div class="space-y-4">
      <HantaDateRange bind:value={dateRange} />
      <InvoicesStatisticsOverall
        start={dateRange?.start}
        end={dateRange?.end}
      />
    </div>
    <!--div class="col-span-2">
      <InvoicesToIssue />
    </div-->
  </div>
</div>
