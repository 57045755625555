<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  import { Button } from '$lib/components/ui/button';
  import { Input } from '$lib/components/ui/input';
  import * as Resizable from '$lib/components/ui/resizable';
  import Icon from '@iconify/svelte';
  import { writable } from 'svelte/store';
  import { toast } from 'svelte-sonner';

  const dispatch = createEventDispatcher();
  export let dimensions: any[] = [];

  let selectedDimension = writable(null);
  let isEditing = writable(false);
  let searchTerm = '';

  const typeOptions = ['Single Value', 'Range'];
  const fieldOptions = [
    { key: 'this.week', label: 'Week of the Year' },

    { key: 'this.operator', label: 'Operator' },
    { key: 'this.product', label: 'Product' },
    { key: 'this.weight', label: 'Weight' },

    { key: 'this.direction', label: 'Direction' },
    { key: 'this.paymentSide', label: 'Payment Side' },
    { key: 'this.packageType', label: 'Package Type' },
    { key: 'this.extraService', label: 'Extra Service' },

    { key: 'this.paymentMethod', label: 'Payment Method' },

    { key: 'this.country.zone', label: 'Zone TNT' },
    { key: 'this.country.zoneEco', label: 'Zone TNT Eco' },
    { key: 'this.country.zoneFedEx', label: 'Zone FedEx' },

    {
      key: 'this.operator === "FedEx" ? this.country.zoneFedEx : this.product === "ECO" ? this.country.zoneEco : this.country.zone',
      label: 'Zone (FedEx/ECO/TNT)',
    },
  ];

  function generateUniqueName() {
    const base = 'Dimension';
    let counter = dimensions.length + 1;
    let name = `${base} ${counter}`;

    // Keep incrementing counter until we find a unique name
    while (dimensions.some(d => d.name === name)) {
      counter++;
      name = `${base} ${counter}`;
    }

    return name;
  }

  function handleEdit(dimension) {
    $selectedDimension = {
      id: dimension.id,
      name: dimension.name || '',
      type: dimension.type || 'Single Value',
      field: dimension.field || fieldOptions[0].key,
      values: [...(dimension.values || [])],
      originalName: dimension.name,
    };
    $isEditing = true;
  }

  function handleUpdate() {
    if (!$selectedDimension) return;

    // Check if the new name is unique (excluding the current dimension)
    const nameExists = dimensions.some(
      d =>
        d.name === $selectedDimension.name &&
        d.name !== $selectedDimension.originalName,
    );

    if (nameExists) {
      alert(
        'A dimension with this name already exists. Please choose a different name.',
      );
      return;
    }

    const index = dimensions.findIndex(
      d => d.name === $selectedDimension.originalName,
    );
    const updatedDimension = {
      id: $selectedDimension.id,
      name: $selectedDimension.name,
      type: $selectedDimension.type,
      field: $selectedDimension.field,
      values: [...$selectedDimension.values],
    };

    if (index !== -1) {
      dimensions = [
        ...dimensions.slice(0, index),
        updatedDimension,
        ...dimensions.slice(index + 1),
      ];
    } else {
      dimensions = [...dimensions, updatedDimension];
    }

    // Force update the parent component
    dimensions = [...dimensions];

    // Keep the dimension selected after update
    $selectedDimension = {
      ...updatedDimension,
      originalName: updatedDimension.name,
    };

    toast.success('Dimension updated successfully');
  }

  function handleCancel() {
    const index = dimensions.findIndex(
      d => d.name === $selectedDimension?.originalName,
    );

    // If this was a new dimension that was cancelled, remove it
    if (index !== -1 && dimensions[index].values.length === 0) {
      dimensions = dimensions.filter((_, i) => i !== index);
    }

    $isEditing = false;
    $selectedDimension = null;
  }

  function handleAdd() {
    const newDimension = {
      id: crypto.randomUUID(),
      name: generateUniqueName(),
      type: 'Single Value',
      field: fieldOptions[0].key,
      values: [],
    };
    // First add to dimensions array
    dimensions = [...dimensions, newDimension];
    // Then select it for editing
    handleEdit(newDimension);
  }

  function handleRemove(dimension) {
    if (
      confirm(
        `Are you sure you want to remove the dimension "${dimension.name}"?`,
      )
    ) {
      dimensions = dimensions.filter(d => d.name !== dimension.name);
      if (
        $selectedDimension &&
        $selectedDimension.originalName === dimension.name
      ) {
        handleCancel();
      }
    }
  }

  function addValue() {
    if (!$selectedDimension) return;

    if (!$selectedDimension.values) {
      $selectedDimension.values = [];
    }

    const newValue =
      $selectedDimension.type === 'Range' ? { from: '', to: '' } : { name: '' };

    $selectedDimension = {
      ...$selectedDimension,
      values: [...$selectedDimension.values, newValue],
    };
  }

  function removeValue(index) {
    if (!$selectedDimension || !$selectedDimension.values) return;

    $selectedDimension = {
      ...$selectedDimension,
      values: $selectedDimension.values.filter((_, i) => i !== index),
    };
  }

  function handleCartesianProduct() {
    // Validate dimensions first
    if (!dimensions || dimensions.length === 0) {
      console.warn('No dimensions available for grid generation');
      return;
    }

    // Extract and validate values from each dimension
    const dimensionValues = dimensions.map(dim => {
      if (!dim.values || dim.values.length === 0) {
        console.warn(`No values found for dimension: ${dim.name}`);
        return [];
      }

      return dim.values.map(v =>
        dim.type === 'Range' ? `${v.from} - ${v.to}` : v.name,
      );
    });

    // Validate that all dimensions have values
    if (dimensionValues.some(values => values.length === 0)) {
      console.warn('Some dimensions have no valid values');
      return;
    }

    // Generate Cartesian product
    const grid = cartesianProduct(...dimensionValues);

    // Create price entries for each combination
    const priceEntries = grid.map(combination => {
      const entry = {
        price: 0,
        minPrice: 0,
        unitPrice: 0,
        unit: '',
        percent: 0,
      };
      dimensions.forEach((dim, index) => {
        entry[dim.name] = combination[index];
      });
      return entry;
    });

    // Dispatch event with generated price grid
    dispatch('generatePriceGrid', { priceEntries });
  }

  // Function to generate Cartesian product of arrays
  function cartesianProduct(...arrays) {
    if (arrays.length === 0) return [[]];
    if (arrays.some(arr => !Array.isArray(arr) || arr.length === 0)) return [];

    return arrays.reduce(
      (acc, curr) => acc.flatMap(x => curr.map(y => [...x, y])),
      [[]],
    );
  }

  $: filteredDimensions = dimensions.filter(
    d =>
      d.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      d.type.toLowerCase().includes(searchTerm.toLowerCase()) ||
      d.field.toLowerCase().includes(searchTerm.toLowerCase()),
  );
</script>

<div class="p-4">
  <div class="flex justify-between items-center mb-4">
    <h1 class="text-2xl font-bold">Dimensions Manager</h1>
    <div class="flex gap-2">
      <Button on:click={handleCartesianProduct} variant="outline">
        <Icon icon="mdi:table-refresh" class="size-4 mr-2" />
        Generate Price Grid
      </Button>
      <Button on:click={handleAdd}>
        <Icon icon="mdi:plus" class="size-4" />
        Add Dimension
      </Button>
    </div>
  </div>

  <div class="mb-4">
    <Input
      type="text"
      placeholder="Search dimensions..."
      bind:value={searchTerm}
    >
      <Icon icon="mdi:magnify" class="text-muted-foreground size-4" />
    </Input>
  </div>

  <Resizable.PaneGroup
    direction="horizontal"
    class="h-[600px] max-w-4xl rounded-lg border"
  >
    <Resizable.Pane defaultSize={40} minSize={30}>
      <div class="overflow-y-auto p-4 h-full">
        <h2 class="mb-4 text-xl font-bold">Dimensions</h2>
        {#if filteredDimensions.length === 0}
          <p class="text-gray-500">No dimensions found</p>
        {/if}
        {#each filteredDimensions as dimension}
          <div
            class="flex items-center justify-between p-2 rounded transition-colors duration-200 ease-in-out
                      {$selectedDimension &&
            $selectedDimension.originalName === dimension.name
              ? 'bg-blue-100 hover:bg-blue-200'
              : 'hover:bg-gray-100'}"
          >
            <span>{dimension.name}</span>
            <div>
              <Button
                variant="ghost"
                size="sm"
                on:click={() => handleEdit(dimension)}
              >
                <Icon icon="mdi:pencil" class="size-4" />
              </Button>
              <Button
                variant="ghost"
                size="sm"
                on:click={() => handleRemove(dimension)}
              >
                <Icon icon="mdi:trash" class="size-4" />
              </Button>
            </div>
          </div>
        {/each}
      </div>
    </Resizable.Pane>
    <Resizable.Handle withHandle />
    <Resizable.Pane defaultSize={60} minSize={40}>
      <div class="overflow-y-auto p-4 h-full">
        {#if $isEditing}
          <div class="flex justify-between items-center mb-4">
            <h2 class="text-xl font-bold">Edit Dimension</h2>
            <Button variant="ghost" size="sm" on:click={handleCancel}>
              <Icon icon="mdi:close" class="size-4" />
            </Button>
          </div>
          <div class="space-y-4">
            <div>
              <label for="name" class="block text-sm font-medium text-gray-700"
                >Name</label
              >
              <Input
                type="text"
                bind:value={$selectedDimension.name}
                id="name"
              />
            </div>
            <div>
              <label for="type" class="block text-sm font-medium text-gray-700"
                >Type</label
              >

              <select
                bind:value={$selectedDimension.type}
                id="type"
                class="block mt-1 w-full rounded-md border border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                {#each typeOptions as option}
                  <option value={option}>{option}</option>
                {/each}
              </select>
            </div>
            <div>
              <label for="field" class="block text-sm font-medium text-gray-700"
                >Field</label
              >
              <select
                bind:value={$selectedDimension.field}
                id="type"
                class="block mt-1 w-full rounded-md border border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                {#each fieldOptions as option}
                  <option value={option.key}>{option.label}</option>
                {/each}
              </select>
            </div>
            <div class="my-12">
              <Button variant="outline" size="sm" on:click={addValue}>
                <Icon icon="mdi:plus" class="size-4" />
                Add Value
              </Button>
              <div class="block text-sm font-medium text-gray-700 my-2">
                Values
              </div>
              {#each $selectedDimension.values as value, i}
                <div class="flex mb-2 space-x-2">
                  {#if $selectedDimension.type === 'Single Value'}
                    <Input
                      type="text"
                      placeholder="Name"
                      bind:value={value.name}
                    />
                  {/if}

                  {#if $selectedDimension.type === 'Range'}
                    <Input
                      type="text"
                      placeholder="From"
                      bind:value={value.from}
                    />
                    <Input type="text" placeholder="To" bind:value={value.to} />
                  {/if}
                  <Button
                    variant="outline"
                    size="sm"
                    on:click={() => removeValue(i)}
                  >
                    <Icon icon="mdi:trash" class="size-4" />
                  </Button>
                </div>
              {/each}
            </div>
            <Button on:click={handleUpdate}>Update Dimension</Button>
          </div>
        {:else}
          <div class="flex justify-center items-center h-full text-gray-500">
            Select a dimension to edit
          </div>
        {/if}
      </div>
    </Resizable.Pane>
  </Resizable.PaneGroup>
</div>
