<script lang="ts">
  import { superForm } from 'sveltekit-superforms';
  import * as Avatar from '$lib/components/ui/avatar';
  import { type Contact, insertContactsSchema } from '$db/schema';

  import { createMutation, useQueryClient } from '@tanstack/svelte-query';
  import { saveContact } from '$lib/api/mutations';
  import { zodClient } from 'sveltekit-superforms/adapters';
  import HantaInputText from '$lib/widgets/input/hanta-input-text.svelte';
  import HantaInputAddress from '$lib/widgets/input/hanta-input-address.svelte';
  import HantaInputCommunication from '$lib/widgets/input/hanta-input-communication.svelte';
  import HantaInputTextarea from '$lib/widgets/input/hanta-input-textarea.svelte';
  import HantaInputDate from '$lib/widgets/input/hanta-input-date.svelte';
  import HantaInputMultiSelect from '$lib/widgets/input/hanta-input-multi-select.svelte';
  import HantaInputLovSelect from '$lib/widgets/input/hanta-input-lov-select.svelte';
  import { getItems } from '$lib/api/queries';

  import Icon from '@iconify/svelte';
  import FormActions from '$lib/app/forms/form-actions.svelte';
  import { alertStore } from '$lib/stores/alert-store';
  import HantaInputCheckbox from '../input/hanta-input-checkbox.svelte';

  const moduleName = 'contacts';
  export let crmContact: Contact;

  const client = useQueryClient();

  const loadOptionsAccounts = async (keyword: string): Promise<any> => {
    const result = await getItems({
      collection: 'accounts',
      from: 0,
      to: 10,
      search: keyword,
      select: 'id,name,logo',
    });

    return result.data;
  };
  const addMutation = createMutation({
    mutationFn: (variables): Promise<Contact> =>
      Promise.resolve(saveContact(variables)),
    onSuccess: () => client.invalidateQueries({ queryKey: [moduleName] }),
    onMutate: async newItem => {
      await client.cancelQueries({ queryKey: [moduleName, crmContact.id] });
      const previousItems = client.getQueryData<Contact[]>([
        moduleName,
        crmContact.id,
      ]);

      client.setQueryData([moduleName, crmContact.id], {
        ...crmContact,
        ...newItem,
      });

      return { previousItems };
    },
    onError: (err: any, variables: any, context: any) => {
      if (context?.previousItems) {
        client.setQueryData([moduleName], context.previousItems);
      }
      alertStore.show(
        'Error Saving Contact',
        err.message || 'Failed to save contact information',
        undefined,
        () => {
          // Optional: Add retry logic
          $addMutation.mutate(variables);
        },
      );
    },
    onSettled: () => {
      client.invalidateQueries({ queryKey: [moduleName, crmContact.id] });
    },
  });

  const formSchema = insertContactsSchema.partial();
  let initContact: Contact = {
    ...crmContact,
    addressPrivate: crmContact?.addressPrivate ?? {},
    addressWork: crmContact?.addressWork ?? {},
    communicationPrivate: crmContact?.communicationPrivate ?? {},
    communicationWork: crmContact?.communicationWork ?? {},
  };
  const form = superForm<Contact>(initContact, {
    resetForm: false,
    SPA: true,
    dataType: 'json',
    validators: zodClient(formSchema),
    onSubmit: async () => {
      if (Object.keys($errors).length > 0) {
        alertStore.show(
          'Validation Error',
          'Please check the form for errors',
          undefined,
          () => {
            // Optional: Add form resubmission logic
          },
        );
        console.error('Validation errors', $errors, { form: $formData });
        $errors = {};
        return;
      }

      console.debug('Form data', $formData);

      let parse = insertContactsSchema.parse($formData);
      $addMutation.mutate(parse, {
        onError(error) {
          console.error('Error', error);
          alertStore.show(
            'Error Saving Contact',
            error.message || 'Failed to save contact information',
            undefined,
            () => {
              // Optional: Add retry logic
              $addMutation.mutate(parse);
            },
          );
        },
        onSuccess: () => {},
      });
    },
  });

  let { form: formData, enhance, errors } = form;
</script>

<form class="w-full" method="POST" use:enhance>
  <!-- Personal info -->
  <div>
    <h2
      class="sticky top-0 z-10 px-6 py-1 font-semibold border-b border-solid border-b-primary-200/20 bg-primary-50"
    >
      Personal Info
    </h2>
    <div class="flex flex-col px-4 m-2 rounded gap-2">
      <HantaInputText {form} name="refId" label="Id" readOnly={true} />
      <HantaInputMultiSelect
        asArray={false}
        class="w-full"
        {form}
        label="Account"
        loadOptions={loadOptionsAccounts}
        multiple={false}
        name="customer"
        placeholder="Select..."
      >
        <svelte:fragment slot="icon">
          <Icon icon="mdi:building" class="w-3 h-3" />
        </svelte:fragment>

        <div
          class="flex items-center w-full h-full"
          let:index
          let:item
          slot="item"
        >
          <Avatar.Root class="size-8">
            <Avatar.Image
              class="object-scale-down rounded-none"
              src={item?.logo}
            />
            <Avatar.Fallback>-</Avatar.Fallback>
          </Avatar.Root>
          <div class="ml-2 text-sm truncate">{item?.name}</div>
        </div>
        <div
          class="flex overflow-hidden items-center w-full h-full"
          let:selection
          slot="selection"
        >
          <Avatar.Root class="size-8">
            <Avatar.Image
              class="object-scale-down rounded-none"
              src={selection?.logo}
            />
            <Avatar.Fallback>-</Avatar.Fallback>
          </Avatar.Root>
          <div class="ml-2 w-full text-sm truncate">
            {selection?.name}
          </div>
        </div>
      </HantaInputMultiSelect>
      <HantaInputText {form} name="title" label="Title" />
      <HantaInputLovSelect
        {form}
        bind:value={$formData.salutation}
        type="Salutation"
        label="Salutation"
        multiple={false}
      />
      <HantaInputText {form} name="firstname" label="First name" />
      <HantaInputText {form} name="lastname" label="Last name" />
      <HantaInputText {form} name="position" label="Position" />
      <HantaInputCheckbox {form} name="mainContact" label="Main contact" />

      <HantaInputText {form} name="jobTitle" label="Job title" />
      <!--
      <HantaInputText
        {form}
        name="positionInCompany"
        label="Position in company"
      /> -->
      <HantaInputLovSelect
        {form}
        bind:value={$formData.positionInCompany}
        type="Position"
        label="Position in company"
        addable={true}
        multiple={false}
      />

      <!--
      <HantaInputText {form} name="department" label="Department" />
      -->
      <HantaInputLovSelect
        {form}
        bind:value={$formData.department}
        type="Department"
        label="Department"
        addable={true}
        multiple={false}
      />

      <!-- languageSkills -->
      <HantaInputLovSelect
        {form}
        bind:value={$formData.languageSkills}
        type="Language"
        label="Languages"
        addable={true}
      />

      <HantaInputTextarea {form} name="impression" label="Impression" />
      <HantaInputTextarea {form} name="other" label="Additional information" />

      <HantaInputDate
        {form}
        name="birthday"
        label="Date of Birth"
        type="htmlDate"
      />

      <HantaInputText {form} name="nationality" label="Nationality" />
    </div>
  </div>

  <!-- Contact -->
  <div class="mb-20">
    <h2
      class="sticky top-0 z-10 px-6 py-1 font-semibold border-b border-solid border-b-primary-200/20 bg-primary-50"
    >
      Contact
    </h2>
    <div class="flex flex-col px-4 m-2 rounded">
      <HantaInputText {form} name="linkedin" label="LinkedIn" />
      <HantaInputCommunication
        {form}
        name="communicationWork"
        label="Contact"
      />

      <!--
      <HantaInputText {form} name="xing" label="Xing" />
      -->
    </div>
  </div>

  <!-- Address -->
  <div class="mb-20">
    <h2
      class="sticky top-0 z-10 px-6 py-1 font-semibold border-b border-solid border-b-primary-200/20 bg-primary-50"
    >
      Address
    </h2>
    <div class="flex flex-col px-4 m-2 rounded">
      <HantaInputAddress {form} name="addressWork" label="Address" />
    </div>
  </div>
  <FormActions {form} />
</form>
