<script lang="ts">
  import Orders from '../pages/orders/orders.svelte';
  import Leads from '../pages/leads/leads.svelte';
  import GenericItems from '../pages/_generic/generic-items.svelte';
  import Invoices from '$lib/pages/invoices/invoices.svelte';
  import Balances from '$lib/pages/balances/balances.svelte';
  import Deals from '../pages/deals/deals.svelte';
  import Accounts from '../pages/accounts/accounts.svelte';
  import Contacts from '../pages/contacts/contacts.svelte';
  import Issues from '../pages/issues/issues.svelte';
  import Contracts from '$lib/pages/contracts/contracts.svelte';
  import Tariffs from '$lib/pages/tariffs/tariffs.svelte';
  import Pipelines from '$lib/pages/pipelines/pipelines.svelte';
  import Expenses from '$lib/pages/expenses/expenses-page.svelte';
  import ActivityPage from '$lib/pages/activities/activity-page.svelte';
  import PipelineItemPage from '$lib/pages/pipeline-item/pipeline-item-page.svelte';
  import Manifests from '$lib/pages/manifests/manifests.svelte';
  import Payments from '$lib/pages/payments/payments.svelte';
  import Credits from '$lib/pages/credits/credits.svelte';

  export let module: string;
  export let item: any;
  export let currentTab: string | undefined = undefined;
</script>

{#if module === 'pipelineItems'}
  <PipelineItemPage {item} />
{:else if module === 'deals'}
  <Deals deal={item} {currentTab} />
{:else if module === 'activities'}
  <ActivityPage activity={item} type={currentTab} />
{:else if module === 'accounts'}
  <Accounts crmAccount={item} />
{:else if module === 'contacts'}
  <Contacts crmContact={item} />
{:else if module === 'invoices'}
  <Invoices {item} />
{:else if module === 'orders'}
  <Orders {item} />
{:else if module === 'leads'}
  <Leads {item} />
{:else if module === 'issues'}
  <Issues {item} />
{:else if module === 'contracts'}
  <Contracts {item} />
{:else if module === 'pipelines'}
  <Pipelines selectedPipelineId={item.id} />
{:else if module === 'manifests'}
  <Manifests {item} />
{:else if module === 'tariffs'}
  <Tariffs {item} />
{:else if module === 'balances'}
  <Balances {item} />
{:else if module === 'credits'}
  <Credits {item} />
{:else if module === 'payments'}
  <Payments {item} />
{:else if module === 'expenses'}
  <Expenses {item} />
{:else}
  <GenericItems moduleName={module} {item} />
{/if}
