import { supabase } from './supabase';
import { get } from 'svelte/store';
import { authStore } from '$lib/stores/auth-store';

interface ReportParams {
  startDate: string;
  endDate: string;
  compareWithPeriod?: 'previous_period' | 'previous_year' | 'custom';
  compareStartDate?: string;
  compareEndDate?: string;
}

interface FinancialMetric {
  date: string;
  value: number;
}

interface ComparisonMetrics {
  current: number;
  previous: number;
  change: number;
  changePercentage: number;
}

interface KPIs {
  revenueTotal: ComparisonMetrics;
  totalExpenses: ComparisonMetrics;
  netProfit: ComparisonMetrics;
  cashBalance: ComparisonMetrics;
  profitMargin: ComparisonMetrics;
  revenueGrowth: ComparisonMetrics;
  operatingExpenseRatio: ComparisonMetrics;
}

interface FinancialReport {
  revenue: FinancialMetric[];
  expenses: FinancialMetric[];
  profit: FinancialMetric[];
  cashFlow: FinancialMetric[];
  kpis: KPIs;
}

interface CustomerActivityReport {
  customer: string;
  current: {
    invoiceCount: number;
    totalSpent: number;
    avgPurchase: number;
    firstPurchase: string;
    lastPurchase: string;
  };
  previous: {
    invoiceCount: number;
    totalSpent: number;
    revenueTotal: number;
    avgPurchase: number;
    firstPurchase: string;
    lastPurchase: string;
  };
  changes: {
    invoiceCount: number;
    totalSpent: number;
    avgPurchase: number;
    growthRate: number | null;
  };
}

interface SalesReportInvoice {
  id: string;
  number: string;
  date: string;
  paidOn: string;
  customer: {
    id: string;
    name: string;
    isNew: boolean;
    newClientMonth?: number; // 1-4 indicating which month of the "new client" period
  };
  salesRep: {
    id: string;
    name: string;
  };
  net: number;
  gross: number;
  revenueTotal: number;
  supplierTotal: number;
  revenueNational: number;
  type: 'export' | 'import';
  status: string;
}

interface SalesBySalesRep {
  salesRep: {
    id: string;
    name: string;
  };
  invoiceCount: number;
  totalNet: number;
  totalGross: number;
  revenueTotal: number;
  revenueNational: number;
  supplierTotal: number;
  customers: {
    id: string;
    name: string;
    invoiceCount: number;
    totalSpent: number;
    revenueTotal: number;
    revenueNational: number;
    supplierTotal: number;
    gross: number;
  }[];
}

interface SalesReport {
  invoices: SalesReportInvoice[];
  salesBySalesRep: SalesBySalesRep[];
  totalInvoices: number;
  totalNet: number;
  totalGross: number;
  revenueTotal: number;
  supplierTotal: number;
  revenueNational: number;
}

export async function getFinancialReport(
  params: ReportParams,
): Promise<FinancialReport> {
  const { data, error } = await supabase(get(authStore)?.token).rpc(
    'get_financial_report',
    {
      start_date: params.startDate,
      end_date: params.endDate,
      compare_with_period: params.compareWithPeriod || 'previous_period',
      compare_start_date: params.compareStartDate,
      compare_end_date: params.compareEndDate,
    },
  );

  if (error) throw error;
  return data;
}

export async function getCustomerActivityReport(
  params: Pick<ReportParams, 'startDate' | 'endDate'>,
): Promise<CustomerActivityReport[]> {
  const { data, error } = await supabase(get(authStore)?.token).rpc(
    'get_customer_activity',
    {
      start_date: params.startDate,
      end_date: params.endDate,
      compare_with_period: 'previous_period',
    },
  );

  if (error) throw error;
  return data || [];
}

export async function getSalesReport(
  params: Pick<ReportParams, 'startDate' | 'endDate'>,
): Promise<SalesReport> {
  // Initialize an array to hold all invoice data
  let allInvoicesData: any[] = [];
  let hasMoreData = true;
  let currentPage = 0;
  const pageSize = 1000;

  // Fetch data in batches of 1000 records
  while (hasMoreData) {
    const { data: invoicesData, error: invoicesError } = await supabase(
      get(authStore)?.token,
    )
      .from('invoices')
      .select(
        `*, customer:accounts(id, name, salesRep:users(id, name))
        `,
      )
      .gte('invoiceDate', params.startDate)
      .lte('invoiceDate', params.endDate)
      .in('status', ['Paid', 'Overdue', 'Open'])
      .eq('deleted', false)
      .range(currentPage * pageSize, (currentPage + 1) * pageSize - 1);

    if (invoicesError) throw invoicesError;

    if (invoicesData && invoicesData.length > 0) {
      allInvoicesData = [...allInvoicesData, ...invoicesData];
      currentPage++;

      // If we got fewer records than the page size, we've reached the end
      if (invoicesData.length < pageSize) {
        hasMoreData = false;
      }
    } else {
      hasMoreData = false;
    }
  }

  // Transform the data to the required format
  const invoices: SalesReportInvoice[] = allInvoicesData.map(invoice => {
    console.log('Invoice tags:', {
      id: invoice.id,
      invoiceId: invoice.invoiceId,
      tags: invoice.tags,
      isArray: Array.isArray(invoice.tags),
      includesExport:
        Array.isArray(invoice.tags) && invoice.tags.includes('EXPORT'),
    });
    const revenueTotal = Number(invoice.revenueTotal) || 0;
    const gross = Number(invoice.gross) || 0;
    const grossNational = Number(invoice.grossNational || 0);
    const supplierTotal = Number(invoice.supplierTotal) || 0;

    // Calculate revenueNational safely
    let revenueNational = 0;
    if (gross > 0) {
      revenueNational = revenueTotal * (grossNational / gross);
    }

    return {
      id: invoice.id,
      number: invoice.invoiceId,
      date: invoice.invoiceDate,
      paidOn: invoice.paidOn,
      customer: {
        id: invoice.customer.id,
        name: invoice.customer.name || 'Unknown Customer',
      },
      salesRep: {
        id: invoice.customer.salesRep?.id || '',
        name: invoice.customer.salesRep?.name || 'Unassigned',
      },
      revenueTotal: Number(invoice.revenueTotal) || 0,
      net: Number(invoice.net) || 0,
      gross: Number(invoice.gross) || 0,
      supplierTotal: Number(invoice.supplierTotal) || 0,
      revenueNational: Number(invoice.revenueNational) || 0,
      type:
        (Array.isArray(invoice.tags) && invoice.tags.includes('EXPORT')) ||
        (typeof invoice.tags === 'string' && invoice.tags.includes('EXPORT'))
          ? 'export'
          : 'import',
      status: invoice.status || 'unknown',
    };
  });

  // Group data by sales rep
  const salesRepMap = new Map<string, SalesBySalesRep>();
  const customerMap = new Map<
    string,
    Map<
      string,
      {
        id: string;
        name: string;
        invoiceCount: number;
        totalSpent: number;
        revenueTotal: number;
        revenueNational: number;
        supplierTotal: number;
      }
    >
  >();

  invoices.forEach(invoice => {
    const salesRepId = invoice.salesRep?.id || 'unassigned';

    // Initialize sales rep data if not exists
    if (!salesRepMap.has(salesRepId)) {
      salesRepMap.set(salesRepId, {
        salesRep: invoice.salesRep,
        invoiceCount: 0,
        totalNet: 0,
        totalGross: 0,
        revenueTotal: 0,
        revenueNational: 0,
        supplierTotal: 0,
        customers: [],
      });
    }

    // Initialize customer map for this sales rep if not exists
    if (!customerMap.has(salesRepId)) {
      customerMap.set(salesRepId, new Map());
    }

    const salesRepData = salesRepMap.get(salesRepId)!;
    const customersForSalesRep = customerMap.get(salesRepId)!;

    // Update sales rep totals
    salesRepData.invoiceCount += 1;
    salesRepData.totalNet += invoice.net;
    salesRepData.totalGross += invoice.gross;
    salesRepData.revenueTotal += invoice.revenueTotal;
    salesRepData.revenueNational += invoice.revenueNational;
    salesRepData.supplierTotal += invoice.supplierTotal;

    // Update customer data
    const customerId = invoice.customer.id;
    if (customerId) {
      if (!customersForSalesRep.has(customerId)) {
        customersForSalesRep.set(customerId, {
          id: customerId,
          name: invoice.customer.name,
          invoiceCount: 0,
          totalSpent: 0,
          revenueTotal: 0,
          revenueNational: 0,
          supplierTotal: 0,
        });
      }

      const customerData = customersForSalesRep.get(customerId)!;
      customerData.invoiceCount += 1;
      customerData.totalSpent += invoice.revenueTotal;
      customerData.revenueTotal += invoice.revenueTotal;
      customerData.revenueNational += invoice.revenueNational;
      customerData.supplierTotal += invoice.supplierTotal;
    }
  });

  // Convert maps to arrays for the final output
  const salesBySalesRep: SalesBySalesRep[] = [];

  salesRepMap.forEach((salesRepData, salesRepId) => {
    const customersMap = customerMap.get(salesRepId);
    const customers = customersMap ? Array.from(customersMap.values()) : [];

    salesBySalesRep.push({
      ...salesRepData,
      customers,
    });
  });

  // Sort sales reps by total revenue
  salesBySalesRep.sort((a, b) => b.revenueTotal - a.revenueTotal);

  // Calculate overall totals
  const totalInvoices = invoices.length;
  const totalNet = invoices.reduce((sum, invoice) => sum + invoice.net, 0);
  const totalGross = invoices.reduce((sum, invoice) => sum + invoice.gross, 0);
  const revenueTotal = invoices.reduce(
    (sum, invoice) => sum + invoice.revenueTotal,
    0,
  );
  const supplierTotal = invoices.reduce(
    (sum, invoice) => sum + invoice.supplierTotal,
    0,
  );
  const revenueNational = invoices.reduce(
    (sum, invoice) => sum + invoice.revenueNational,
    0,
  );

  return {
    invoices,
    salesBySalesRep,
    totalInvoices,
    totalNet,
    totalGross,
    revenueTotal,
    supplierTotal,
    revenueNational,
  };
}

export async function getSalesBonusReport(
  params: Pick<ReportParams, 'startDate' | 'endDate'>,
): Promise<SalesReport> {
  // Initialize an array to hold all invoice data
  let allInvoicesData: any[] = [];
  let hasMoreData = true;
  let currentPage = 0;
  const pageSize = 1000;

  // Fetch Import invoices (tag contains "Import", status "Paid", paidOn between dates)
  while (hasMoreData) {
    const { data: importInvoicesData, error: importInvoicesError } =
      await supabase(get(authStore)?.token)
        .from('invoices')
        .select(
          `*, customer:accounts(id, name, customerSince, salesRep:users(id, name))
        `,
        )
        .gte('paidOn', params.startDate)
        .lte('paidOn', params.endDate)
        .eq('status', 'Paid')
        .contains('tags', ['IMPORT'])
        .eq('deleted', false)
        .range(currentPage * pageSize, (currentPage + 1) * pageSize - 1);

    if (importInvoicesError) throw importInvoicesError;

    if (importInvoicesData && importInvoicesData.length > 0) {
      allInvoicesData = [...allInvoicesData, ...importInvoicesData];
      currentPage++;

      // If we got fewer records than the page size, we've reached the end
      if (importInvoicesData.length < pageSize) {
        hasMoreData = false;
      }
    } else {
      hasMoreData = false;
    }
  }

  // Reset for next query
  hasMoreData = true;
  currentPage = 0;

  // Fetch Export invoices (tag "Export", status "Paid"/"Overdue"/"Open", invoiceDate between dates)
  while (hasMoreData) {
    const { data: exportInvoicesData, error: exportInvoicesError } =
      await supabase(get(authStore)?.token)
        .from('invoices')
        .select(
          `*, customer:accounts(id, name, customerSince, salesRep:users(id, name))
        `,
        )
        .gte('invoiceDate', params.startDate)
        .lte('invoiceDate', params.endDate)
        .in('status', ['Paid', 'Overdue', 'Open'])
        .contains('tags', ['EXPORT'])
        .eq('deleted', false)
        .range(currentPage * pageSize, (currentPage + 1) * pageSize - 1);

    if (exportInvoicesError) throw exportInvoicesError;

    if (exportInvoicesData && exportInvoicesData.length > 0) {
      allInvoicesData = [...allInvoicesData, ...exportInvoicesData];
      currentPage++;

      // If we got fewer records than the page size, we've reached the end
      if (exportInvoicesData.length < pageSize) {
        hasMoreData = false;
      }
    } else {
      hasMoreData = false;
    }
  }

  // Transform the data to the required format
  const invoices: SalesReportInvoice[] = allInvoicesData.map(invoice => {
    const revenueTotal = Number(invoice.revenueTotal) || 0;
    const gross = Number(invoice.gross) || 0;
    const grossNational = Number(invoice.grossNational || 0);

    // Calculate revenueNational safely
    let revenueNational = 0;
    if (gross > 0) {
      revenueNational = revenueTotal * (grossNational / gross);
    }

    const invoiceDate = new Date(invoice.invoiceDate);
    const customerId = invoice.customer?.id;

    // Determine if customer is "new" based on customerSince field
    let isNewCustomer = false;
    let newClientMonth: number | undefined = undefined;

    if (customerId && invoice.customer?.customerSince) {
      const customerSinceDate = new Date(invoice.customer.customerSince);

      // Calculate the date 12 months before the invoice date
      const twelveMonthsBeforeInvoice = new Date(invoiceDate);
      twelveMonthsBeforeInvoice.setMonth(
        twelveMonthsBeforeInvoice.getMonth() - 12,
      );

      // A customer is considered "new" if their customerSince date is less than 12 months before the invoice
      isNewCustomer = customerSinceDate >= twelveMonthsBeforeInvoice;

      if (isNewCustomer) {
        // Calculate which month of the new client period this invoice falls in (1-4)
        const monthsSinceCustomerSince = getMonthDifference(
          customerSinceDate,
          invoiceDate,
        );

        // The first month is month 1, and we track up to month 4
        newClientMonth = monthsSinceCustomerSince + 1;

        // Only consider it a new client if it's within the 4-month period
        if (newClientMonth > 4) {
          isNewCustomer = false;
          newClientMonth = undefined;
        }

        console.log(
          `Customer ${customerId} (${invoice.customer.name}) is a new client in month ${newClientMonth}. Customer since: ${customerSinceDate.toISOString().split('T')[0]}, Invoice date: ${invoiceDate.toISOString().split('T')[0]}`,
        );
      }
    }

    return {
      id: invoice.id,
      number: invoice.invoiceId,
      date: invoice.invoiceDate,
      paidOn: invoice.paidOn,
      customer: {
        id: invoice.customer.id,
        name: invoice.customer.name || 'Unknown Customer',
        isNew: isNewCustomer,
        newClientMonth: newClientMonth,
      },
      salesRep: {
        id: invoice.customer.salesRep?.id || '',
        name: invoice.customer.salesRep?.name || 'Unassigned',
      },
      revenueTotal: revenueTotal,
      net: Number(invoice.net) || 0,
      gross: gross,
      supplierTotal: Number(invoice.supplierTotal) || 0,
      revenueNational: revenueNational,
      type:
        (Array.isArray(invoice.tags) && invoice.tags.includes('EXPORT')) ||
        (typeof invoice.tags === 'string' && invoice.tags.includes('EXPORT'))
          ? 'export'
          : 'import',
      status: invoice.status || 'unknown',
    };
  });

  // Group data by sales rep
  const salesRepMap = new Map<string, SalesBySalesRep>();
  const customerMap = new Map<
    string,
    Map<
      string,
      {
        id: string;
        name: string;
        invoiceCount: number;
        totalSpent: number;
        revenueTotal: number;
        revenueNational: number;
        supplierTotal: number;
      }
    >
  >();

  invoices.forEach(invoice => {
    const salesRepId = invoice.salesRep?.id || 'unassigned';

    // Initialize sales rep data if not exists
    if (!salesRepMap.has(salesRepId)) {
      salesRepMap.set(salesRepId, {
        salesRep: invoice.salesRep,
        invoiceCount: 0,
        totalNet: 0,
        totalGross: 0,
        revenueTotal: 0,
        revenueNational: 0,
        supplierTotal: 0,
        customers: [],
        gross: 0,
      });
    }

    // Initialize customer map for this sales rep if not exists
    if (!customerMap.has(salesRepId)) {
      customerMap.set(salesRepId, new Map());
    }

    const salesRepData = salesRepMap.get(salesRepId)!;
    const customersForSalesRep = customerMap.get(salesRepId)!;

    // Update sales rep totals
    salesRepData.invoiceCount += 1;
    salesRepData.totalNet += invoice.net;
    salesRepData.totalGross += invoice.gross;
    salesRepData.revenueTotal += invoice.revenueTotal;
    salesRepData.revenueNational += invoice.revenueNational;
    salesRepData.supplierTotal += invoice.supplierTotal;

    // Update customer data
    const customerId = invoice.customer.id;
    if (customerId) {
      if (!customersForSalesRep.has(customerId)) {
        customersForSalesRep.set(customerId, {
          id: customerId,
          name: invoice.customer.name,
          invoiceCount: 0,
          totalSpent: 0,
          revenueTotal: 0,
          revenueNational: 0,
          supplierTotal: 0,
          gross: 0,
        });
      }

      const customerData = customersForSalesRep.get(customerId)!;
      customerData.invoiceCount += 1;
      customerData.totalSpent += invoice.revenueTotal;
      customerData.revenueTotal += invoice.revenueTotal;
      customerData.revenueNational += invoice.revenueNational;
      customerData.supplierTotal += invoice.supplierTotal;
      customerData.gross += invoice.gross;
    }
  });

  // Convert maps to arrays for the final output
  const salesBySalesRep: SalesBySalesRep[] = [];

  salesRepMap.forEach((salesRepData, salesRepId) => {
    const customersMap = customerMap.get(salesRepId);
    const customers = customersMap ? Array.from(customersMap.values()) : [];

    salesBySalesRep.push({
      ...salesRepData,
      customers,
    });
  });

  // Sort sales reps by total revenue
  salesBySalesRep.sort((a, b) => b.revenueTotal - a.revenueTotal);

  // Calculate overall totals
  const totalInvoices = invoices.length;
  const totalNet = invoices.reduce(
    (sum, invoice) => sum + (invoice.net || 0),
    0,
  );
  const totalGross = invoices.reduce(
    (sum, invoice) => sum + (invoice.gross || 0),
    0,
  );
  const revenueTotal = invoices.reduce(
    (sum, invoice) => sum + invoice.revenueTotal,
    0,
  );
  const supplierTotal = invoices.reduce(
    (sum, invoice) => sum + (invoice.supplierTotal || 0),
    0,
  );
  const revenueNational = invoices.reduce(
    (sum, invoice) => sum + (invoice.revenueNational || 0),
    0,
  );

  return {
    invoices,
    salesBySalesRep,
    totalInvoices,
    totalNet,
    totalGross,
    revenueTotal,
    supplierTotal,
    revenueNational,
  };
}

export async function getFedexReport(
  params: Pick<ReportParams, 'startDate' | 'endDate'>,
): Promise<any> {
  // Get all FedEx report data using the combined stored procedure
  const { data: reportData, error: reportError } = await supabase(
    get(authStore)?.token,
  ).rpc('get_fedex_report_data', {
    start_date: params.startDate,
    end_date: params.endDate,
  });

  if (reportError) {
    console.error('Error fetching FedEx report data:', reportError);
    return { error: reportError };
  }

  // Extract data from the JSON response
  const totalActivities = reportData.total_activities || 0;

  // Format type stats
  const typeStats =
    reportData.activity_types && Array.isArray(reportData.activity_types)
      ? reportData.activity_types.map((typeStat: any) => ({
          type: typeStat.activity_type,
          count: typeStat.activity_count,
        }))
      : [];

  // Process sales rep data
  const salesRepStats =
    reportData.sales_reps && Array.isArray(reportData.sales_reps)
      ? reportData.sales_reps.map((rep: any) => ({
          salesRep: {
            id: rep.sales_rep_id,
            name: rep.sales_rep_name,
            position: rep.position || 'Unknown Position',
          },
          count: rep.total_activities,
          visitMeetingCount: rep.visit_meeting_count,
          phoneCallCount: rep.phone_call_count,
        }))
      : [];

  // Calculate visit/meeting and phone call counts from activity type stats
  const visitMeetingCount = salesRepStats.reduce(
    (sum, rep) => sum + rep.visitMeetingCount,
    0,
  );

  const phoneCallCount = salesRepStats.reduce(
    (sum, rep) => sum + rep.phoneCallCount,
    0,
  );

  // Group sales reps by position to create positionStats
  const positionMap = salesRepStats.reduce((map: any, rep: any) => {
    const position = rep.salesRep.position;
    if (!map[position]) {
      map[position] = {
        position,
        salesReps: [],
        totalActivities: 0,
        totalVisitMeetings: 0,
        totalPhoneCalls: 0,
      };
    }
    map[position].salesReps.push(rep);
    map[position].totalActivities += rep.count;
    map[position].totalVisitMeetings += rep.visitMeetingCount;
    map[position].totalPhoneCalls += rep.phoneCallCount;
    return map;
  }, {});

  const positionStats = Object.values(positionMap);

  return {
    totalActivities,
    typeStats,
    visitMeetingCount,
    phoneCallCount,
    salesRepStats,
    positionStats,
    order_stats: reportData.order_stats || {},
    prev_year_order_stats: reportData.prev_year_order_stats || {},
    regional_order_stats: reportData.regional_order_stats || [],
    prev_year_regional_order_stats: reportData.prev_year_regional_order_stats || []
  };
}

// Helper function to calculate the difference in months between two dates
function getMonthDifference(startDate: Date, endDate: Date): number {
  // Calculate the difference in months
  const yearDiff = endDate.getFullYear() - startDate.getFullYear();
  const monthDiff = endDate.getMonth() - startDate.getMonth();

  // Basic month difference
  let diff = yearDiff * 12 + monthDiff;

  // Adjust for day of month
  // If the end date's day is earlier in the month than the start date's day,
  // we haven't completed a full month
  if (endDate.getDate() < startDate.getDate()) {
    diff -= 1;
  }

  // Ensure we never return a negative value
  return Math.max(0, diff);
}

// Original helper function kept for backward compatibility
function monthDifference(date1: Date, date2: Date): number {
  const yearDiff = date1.getFullYear() - date2.getFullYear();
  const monthDiff = date1.getMonth() - date2.getMonth();
  return yearDiff * 12 + monthDiff;
}
