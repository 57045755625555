export interface Holiday {
  isHoliday: boolean;
  name?: string;
  englishName?: string;
  global?: boolean;
  state?: string;
}

export interface Country {
  countryCode: string;
  name: string;
}

export interface County {
  countryCode: string;
  countyCode: string;
  name: string;
}

const HOLIDAY_API_URL = 'https://date.nager.at/api/v3/PublicHolidays';

// Add cache object
const holidayCache: Record<string, any[]> = {};

export async function checkHoliday(
  date: Date,
  country: string = 'DE',
  state: string = 'DE-HH',
): Promise<Holiday> {
  try {
    const year = date.getFullYear();
    const cacheKey = `${year}_${country}`;

    // Check cache first
    let holidays = holidayCache[cacheKey];

    if (!holidays) {
      const response = await fetch(`${HOLIDAY_API_URL}/${year}/${country}`);
      if (!response.ok) return { isHoliday: false };

      holidays = await response.json();

      // Store in cache
      holidayCache[cacheKey] = holidays;
    }

    const formattedDate = date.toLocaleDateString('sv').substring(0, 10);

    const holiday = holidays.find((h: any) => {
      const isDateMatch = h.date === formattedDate;
      const isGlobal = h.global;
      const isStateHoliday = h.counties?.includes(state);

      return isDateMatch && (isGlobal || isStateHoliday);
    });

    return holiday
      ? {
          isHoliday: true,
          name: holiday.localName,
          englishName: holiday.name,
          global: holiday.global,
        }
      : {
          isHoliday: false,
        };
  } catch (error) {
    console.error('Failed to fetch holidays:', error);
    return { isHoliday: false };
  }
}

export async function getAvailableCountries(): Promise<Country[]> {
  try {
    const response = await fetch(
      'https://date.nager.at/api/v3/AvailableCountries',
    );
    if (!response.ok) {
      throw new Error('Failed to fetch countries');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching countries:', error);
    return [];
  }
}

export async function getCountyCodesForCountry(
  countryCode: string,
): Promise<County[]> {
  try {
    const response = await fetch(
      `https://date.nager.at/api/v3/CountryRegions/${countryCode}`,
    );
    if (!response.ok) {
      throw new Error('Failed to fetch counties');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching counties:', error);
    return [];
  }
}

/**
 * Get the number of holidays within a date range for a specific country
 * @param startDate Start date of the range
 * @param endDate End date of the range
 * @param country Country code (default: 'MD')
 * @returns Promise with the number of holidays in the range
 */
export async function getHolidaysInPeriod(
  startDate: Date,
  endDate: Date,
  country: string = 'MD',
): Promise<number> {
  try {
    // Ensure dates are Date objects
    const start = new Date(startDate);
    const end = new Date(endDate);
    
    // Get all years in the range
    const startYear = start.getFullYear();
    const endYear = end.getFullYear();
    
    let allHolidays: any[] = [];
    
    // Fetch holidays for each year in the range
    for (let year = startYear; year <= endYear; year++) {
      const cacheKey = `${year}_${country}`;
      
      // Check cache first
      let yearHolidays = holidayCache[cacheKey];
      
      if (!yearHolidays) {
        const response = await fetch(`${HOLIDAY_API_URL}/${year}/${country}`);
        if (!response.ok) continue;
        
        yearHolidays = await response.json();
        
        // Store in cache
        holidayCache[cacheKey] = yearHolidays;
      }
      
      allHolidays = [...allHolidays, ...yearHolidays];
    }
    
    // Filter holidays that fall within the date range
    const holidaysInRange = allHolidays.filter((holiday) => {
      const holidayDate = new Date(holiday.date);
      return holidayDate >= start && holidayDate <= end;
    });
    
    return holidaysInRange.length;
  } catch (error) {
    console.error('Failed to fetch holidays in period:', error);
    return 0;
  }
}
