<script lang="ts">
  import { Card } from '$lib/components/ui/card';
  import { getItems } from '$lib/api/queries';

  import { onMount } from 'svelte';
  import { cn } from '$lib/utils/utils';
  import CrmAvatar from '$lib/components/hanta/crm-avatar.svelte';
  import { formatCurrency } from '$lib/utils/hanta-utils';
  import Button from '$lib/components/ui/button/button.svelte';

  let selectedAccountId = '';
  let credits = [];

  onMount(() => {
    loadCredits();
  });

  function loadCredits() {
    getItems({
      collection: 'credits',
      from: 0,
      to: 1000,
      filters: [
        {
          field: 'status',
          operator: 'eq',
          value: 'Open',
        },
      ],
    }).then(result => {
      credits = result.data || [];
    });
  }

  $: accounts = credits
    ? Object.values(
        credits.reduce((acc, credit) => {
          if (!credit.accountId || credit.remainingAmount <= 0) {
            return acc;
          }

          const accountId = credit.accountId;
          if (!acc[accountId]) {
            acc[accountId] = {
              id: accountId,
              credits: [],
              totalRemaining: 0,
            };
          }

          acc[accountId].credits.push(credit);
          acc[accountId].totalRemaining += credit.remainingAmount || 0;

          return acc;
        }, {}),
      )
        .filter(account => account.totalRemaining > 0)
        .sort((a, b) => b.totalRemaining - a.totalRemaining)
    : [];

  function handleAccountClick(accountId) {
    if (selectedAccountId === accountId) {
      selectedAccountId = '';
    } else {
      selectedAccountId = accountId;
    }
  }
</script>

<div class="mt-24 w-full space-y-8 max-w-[1200px]">
  <div class="flex justify-between items-center">
    <div>
      <h2 class="text-3xl font-bold tracking-tight">
        Available Credits by Customer
      </h2>
      {#if accounts.length > 0}
        <p class="text-muted-foreground text-xl">
          Total available: <span class="font-medium"
            >{formatCurrency(
              accounts.reduce(
                (sum, account) => sum + account.totalRemaining,
                0,
              ),
              'MDL',
            )}</span
          >
        </p>
      {/if}
    </div>
    <Button variant="outline" on:click={loadCredits}>Refresh</Button>
  </div>

  <div class="grid gap-4 lg:grid-cols-2 lg:grid-cols-2 max-w-lg lg:max-w-full">
    {#each accounts as account}
      <Card
        class={cn(
          'p-4 cursor-pointer hover:shadow-md transition-shadow',
          selectedAccountId === account.id ? 'ring-2 ring-primary' : '',
        )}
        on:click={() => handleAccountClick(account.id)}
      >
        <div class="flex justify-between items-start">
          <div class="flex items-center gap-3 max-w-[450px] overflow-hidden">
            <CrmAvatar
              module="accounts"
              id={account.id}
              name={account.name}
              size="size-10"
              withBorder={false}
            />
            <div>
              <p class="text-sm text-muted-foreground">
                {account.credits.length} credit{account.credits.length !== 1
                  ? 's'
                  : ''}
              </p>
            </div>
          </div>
          <div class="text-xl font-bold">
            {formatCurrency(account.totalRemaining, 'MDL')}
          </div>
        </div>

        {#if selectedAccountId === account.id}
          <div class="mt-4 space-y-2">
            {#each account.credits as credit}
              <div
                class="flex justify-between items-center p-2 bg-muted rounded"
              >
                <span class="truncate max-w-[200px]" title={credit.name}
                  >{credit.name}</span
                >
                <span class="font-medium"
                  >{formatCurrency(credit.remainingAmount, 'MDL')}</span
                >
              </div>
            {/each}
          </div>
        {/if}
      </Card>
    {/each}
  </div>

  {#if credits.length === 0}
    <div class="text-center p-8">
      <p class="text-muted-foreground">No credits found</p>
    </div>
  {:else if accounts.length === 0}
    <div class="text-center p-8">
      <p class="text-muted-foreground">No accounts with available credits</p>
    </div>
  {/if}
</div>
