import { authStore } from '$lib/stores/auth-store';
import { writable } from 'svelte/store';
import { supabase } from './supabase';
import { get } from 'svelte/store';
import { ActivityType, ContactRoleType, PriorityType } from '$db/schema';
import { isValidUUID } from '$lib/app/utils';
import { currentUserStore } from '$lib/stores/current-user-store';

// Cache for countries data
let countriesCache: any = null;
let countriesCacheTimestamp: number | null = null;
const CACHE_DURATION = 24 * 1000 * 60 * 60; // 1 hour in milliseconds

export type FilterType = {
  field: string;
  operator: string;
  value?: any;
  active: boolean;
  meta?: any;
};

export type QueryStore = {
  collection: string;
  from?: number;
  to?: number;
  items?: any[];
  count?: number;
  filters?: FilterType[];
  search?: string;
  select?: string;
  sort?: { column: string; order: 'asc' | 'desc' };
  status?: 'preparing' | 'ready';
  fulltextsearch?: boolean;
  asCsv?: boolean;
};

export const getFieldValues = async (module: string, fieldName: string) => {
  const { data, error } = await supabase(get(authStore)?.token)
    .from('lovs')
    .select('values')
    .eq('name', fieldName);
  if (error) {
    throw Error(error.message);
  } else if (data.length > 0) {
    return data[0].values;
  } else {
    return [];
  }
};

export const getCountries = async () => {
  const now = Date.now();

  // Return cached data if it exists and hasn't expired
  if (
    countriesCache &&
    countriesCacheTimestamp &&
    now - countriesCacheTimestamp < CACHE_DURATION
  ) {
    return countriesCache;
  }

  const { data, error } = await supabase(get(authStore)?.token)
    .from('lovs')
    .select('*')
    .eq('name', 'Countries');

  if (error) {
    throw Error(error.message);
  } else {
    // Update cache
    countriesCache = data[0].values;
    countriesCacheTimestamp = now;
    return countriesCache;
  }
};

export const getCountryZones = async (country: string) => {
  if (country.startsWith('Zone ')) {
    const zone = country.split('Zone ')[1];
    return {
      id: country,
      name: country,
      zone: zone,
      zoneEco: zone,
      zoneFedEx: zone,
    };
  }

  const countries = await getCountries();
  return countries.find(item => item.name === country) ?? null;
};

export const findAccountOrders = async (accountId: string) => {
  const { data, error } = await supabase(get(authStore)?.token)
    .from('orders')
    .select('*')
    .eq('customer', accountId);
  return data;
};

export const findOpenInvoices = async (customerId: string, tag: string) => {
  const { data, error } = await supabase(get(authStore)?.token, false)
    .from('invoices')
    .select('*')
    .contains('tags', [tag])
    .eq('customer', customerId)
    .eq('deleted', false)
    .eq('status', 'Draft');
  if (error) {
    throw Error(error.message);
  } else {
    return data;
  }
};

export const getOrCreateItem = async (collection: string, id: any) => {
  if (id) {
    return getItemById(collection, id);
  } else {
    const initValues = {};
    if (collection === 'accounts') {
      initValues['communication'] = {};
    } else if (collection === 'deals') {
      initValues['communicationPrivate'] = {};
    }
    return Promise.resolve({
      ...initValues,
      id: self.crypto.randomUUID(),
    });
  }
};

export const getExchangeRates = async (refDate: string) => {
  const date = new Date(refDate)
    .toLocaleDateString('de-DE', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    })
    .replace(/\./g, '.');

  const { data, error } = await supabase(get(authStore)?.token)
    .from('generic')
    .select('*')
    .eq('type', 'day')
    .eq('name', date);

  if (error) {
    throw Error(error.message);
  } else {
    if (data && data.length > 0 && data[0].payload) {
      return data[0].payload?.exchanges || data[0].payload;
    }
    return null;
  }
};

export const getInvoicesToIssueGroupedByCustomer = async () => {
  const invoices = await getInvoicesToIssue();
  const groupedInvoices = invoices.reduce((acc, invoice) => {
    const customerId = invoice.account.id;
    if (!acc[customerId]) {
      acc[customerId] = {
        customer: invoice.account,
        orders: [],
      };
    }
    acc[customerId].orders.push(invoice);
    return acc;
  }, {});

  return Object.values(groupedInvoices);
};

export const getInvoicesToIssue = async (customerId?: string) => {
  let query = supabase(get(authStore)?.token)
    .from('orders')
    .select('*, account:accounts(id, name, logo)')
    .is('invoiceDate', null);
  //TODO: .eq('status', 'Archived')
  if (customerId) {
    query.eq('customer', customerId);
  }
  const { data, error } = await query;
  return data;
};

export const getCurrentUser = async () => {
  const fullName = get(authStore)?.user?.fullName;

  if (!fullName) {
    return null;
  }

  const { data, error } = await supabase(get(authStore)?.token)
    .from('users')
    .select('*')
    .eq('name', fullName);

  if (data && data.length > 0) {
    return data[0];
  } else {
    // insert new user
    const {
      fullName,
      primaryEmailAddress: { emailAddress },
    } = get(authStore)?.user;
    return await supabase(get(authStore)?.token)
      .from('users')
      .insert({
        name: fullName,
        username: emailAddress,
        email: emailAddress,
      })
      .select()
      .single();
  }
};

export const getPayments = async (invoiceId: string) => {
  const { data, error } = await supabase(get(authStore)?.token)
    .from('payments')
    .select('*')
    .eq('invoiceId', invoiceId);
  return data;
};

export const getTariff = async (id: string) => {
  const result = await getItemById('tariffs', id);
  if (!result) {
    return {};
  }
  return result;
};

export const getItemById = async (
  collection: string,
  id: string,
  withRelated: boolean = true,
) => {
  let select = '*';
  if (withRelated) {
    if (collection === 'orders') {
      select = `*,
        account:accounts(id, name,
        logo,
        creditRating,
        debtReason,
        currentBalance,
        maxDebt,
        currentCreditLimit,
        baseTariff:tariffs!baseTariff(id, name),
        customerTariff:tariffs!customerTariff(id, name),
        supplierTariff:tariffs!supplierTariff(id, name)
        ),
        user:users(id, name, photo),
        invoice:invoices(id, name),
        contactObj:contacts(id, name, photo),
        shipment:shipments!inner(*,rate:rates(*))`;
    } else if (collection === 'leads') {
      select = `*,
        account:accounts(id, name,
        logo,
        creditRating,
        debtReason,
        currentBalance,
        maxDebt,
        currentCreditLimit,
        baseTariff:tariffs!baseTariff(id, name),
        customerTariff:tariffs!customerTariff(id, name),
        supplierTariff:tariffs!supplierTariff(id, name)
        ),
        user:users(id, name, photo),
        contactObj:contacts(id, name, photo),
        shipment:shipments!inner(*,rate:rates(*))`; // inner!
    } else if (collection === 'accounts') {
      //  contacts:contacts!accountsToContacts(id, name),
      // contracts:contracts!accounts_contractId_contracts_id_fk(id, name),
      select = `*,
        deals:deals(id, name, position, start, consultant, state, totalFee),
        sales:users(id, name, photo),
        baseTariffObj:tariffs!baseTariff(id, name),
        customerTariffObj:tariffs!customerTariff(id, name),
        supplierTariffObj:tariffs!supplierTariff(id, name),
        contracts:contracts!contracts_accountId_accounts_id_fk(*),
        contacts:contacts(id, salutation, title, name, firstname, lastname, photo, position, addressWork, communicationWork, linkedin, languageSkills)`;
    } else if (collection === 'deals') {
      select = `*,
        contacts:contacts(id, salutation, title, name, photo, position, addressWork, communicationWork, linkedin),        
        customerObj:accounts(id, name, logo )`;
    } else if (collection === 'credits') {
      select = `*,account:accounts(id, name)`;
    } else if (collection === 'payments') {
      select = `*,invoice:invoices(id, name), account:accounts!payments_accountId_accounts_id_fk(id, name)`;
    } else if (collection === 'contacts' || collection === 'candidates') {
      select = `*,
          accounts:accounts(id, name, logo ),
          customer:accounts(id, name, logo, address ),
          deals:deals(id, name, position, start, consultant, state, totalFee)          
        `;
    } else if (collection === 'invoices') {
      select = `*,
          customerObj:accounts(id, name, logo, address, officialName, officialAddress, communication),
          recipientObj:contacts(id, name, photo ),
          ordersRefs:orders(*, shipment:shipments(*,rate:rates(*))),
          paymentsRefs:payments(*)
        `;
    } else if (collection === 'issues') {
      select = `*,
        account:accounts(id, name, logo),        
        responsible:users!issues_responsibleId_users_id_fk(id, name, photo),
        reporter:users!issues_reporterId_users_id_fk(id, name, photo),
        invoice:invoices(id, name),
        order:orders(id, name),
        comments:comments(*)          
        `;
    } else if (collection === 'contracts') {
      select = `*,
        account:accounts!contracts_accountId_accounts_id_fk(*),
        contact:contacts(id, name, position),
        baseTariffObj:tariffs!contracts_baseTariffId_tariffs_id_fk(*),
        customerTariffObj:tariffs!contracts_customerTariffId_tariffs_id_fk(*),
        sales:users(id, name, photo)
        `;
    } else if (collection === 'activities') {
      select = `*,
        consultantObj:users(id, name, photo)     
        `;
    }
  }
  const { data, error } = await supabase(get(authStore)?.token)
    .from(collection === 'candidates' ? 'contacts' : collection)
    .select(select)
    .eq('id', id);
  if (error) {
    throw Error(error.message);
  } else {
    return data?.[0];
  }
};

export const getAvatar = async (module, id) => {
  if (!module || !id) {
    return null;
  }

  let collection = module === 'candidates' ? 'contacts' : module;
  let selection = `id,name,${collection === 'contacts' || collection === 'users' ? 'photo' : 'logo'}`;
  if (module === 'deals') {
    selection = 'id,name,customerObj:accounts(id, name, logo)';
  }
  const { data, error } = await supabase(get(authStore)?.token)
    .from(collection)
    .select(selection)
    .eq('id', id);
  if (error) {
    throw Error(error.message);
  }
  return data?.[0] || null;
};

export const createFilters = (
  filters: FilterType[],
  rpcCall: boolean = false,
) => {
  const result = [];

  (filters ?? [])
    .filter(el => el?.active || el?.active === undefined)
    .forEach(filter => {
      let value;
      const field = filter.field;

      // Handle null values with IS NULL syntax
      if (filter.value === null) {
        result.push({
          field,
          operator: 'is',
          value: null,
        });
        return;
      }

      // Handle date range by creating separate gte and lt conditions
      if (
        filter.operator === 'within' &&
        Array.isArray(filter.value) &&
        filter.value.length === 2
      ) {
        const [startDate, endDate] = filter.value;
        if (startDate) {
          result.push({
            field,
            operator: 'gte',
            value: startDate,
          });
        }

        if (endDate) {
          result.push({
            field,
            operator: 'lt',
            value: endDate,
          });
        }

        return;
      }

      const valueStr = Array.isArray(filter.value)
        ? filter.value.map(item => {
            // Handle null values in arrays
            if (item === null || item?.key === null) {
              return 'null';
            }
            if (item?.key === '') {
              return 'EMPTY';
            }
            return rpcCall &&
              typeof item?.key === 'string' &&
              filter.operator !== 'ov'
              ? `'${item?.key}'${isValidUUID(item?.key) ? '::uuid' : ''}`
              : `"${item.key ? item.key : item}"`;
          })
        : filter.value;

      if (valueStr?.length === 0) {
        return;
      }

      // Special handling for arrays containing null values
      if (
        Array.isArray(valueStr) &&
        (valueStr.includes('null') || valueStr.includes('EMPTY')) &&
        filter.operator === 'in'
      ) {
        // Split into null and non-null parts
        const nullValues = valueStr.filter(v => v === 'null');
        const emptyValues = valueStr.filter(v => v === 'EMPTY');

        const nonNullValues = valueStr.filter(
          v => v !== 'null' && v !== 'EMPTY',
        );

        if (nonNullValues.length > 0) {
          result.push({
            field,
            operator: 'in',
            value: `(${nonNullValues.join(',')})`,
          });
        }

        if (nullValues.length > 0) {
          result.push({
            field,
            operator: 'is',
            value: null,
          });
        }

        if (emptyValues.length > 0) {
          result.push({
            field,
            operator: 'eq',
            value: '',
          });
        }

        return;
      }

      if (filter.operator === 'in') {
        value = `(${valueStr.join(',')})`;
      } else if (filter.operator === 'ov') {
        value = `{${valueStr.join(',')}}`;
      } else if (['cs', 'cd'].indexOf(filter.operator) >= 0) {
        value = `{${valueStr.join(',')}}`;
      } else {
        value = filter.value?.key ? filter.value.key : filter.value;
      }
      result.push({ field, operator: filter.operator, value });
    });
  return result;
};

// Helper function to convert operator codes to SQL operators
export const getOperator = (operator: string): string => {
  const operatorMap = {
    eq: '=',
    neq: '!=',
    gt: '>',
    gte: '>=',
    lt: '<',
    lte: '<=',
    in: 'IN',
    nin: 'NOT IN',
    is: 'IS',
    isnot: 'IS NOT',
    like: 'LIKE',
    ilike: 'ILIKE',
    cs: '@>',
    cd: '<@',
    ov: '&&',
  };

  return operatorMap[operator] || '=';
};

export const getSearchString = (search: string) => {
  return (
    search
      .split(' ')
      .filter(e => e && e !== ' ' && e !== '')
      .join(':* & ') + ':*'
  );
};

export const getSearchSuggestions = async () => {
  const { data, error } = await supabase(get(authStore)?.token)
    .from('search')
    .select('*')
    .order('modifiedAt', { ascending: false })
    .range(0, 24);
  return data;
};

export const globalSearch = async (search: string, signal: any) => {
  const textSearch = getSearchString(search);

  let query = supabase(get(authStore)?.token)
    .from('search')
    .select('id,name,module')
    .abortSignal(signal);

  // Regular expression for validating a UUID
  const uuidPattern =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

  // Check if the search is a valid UUID
  if (uuidPattern.test(search)) {
    query = query.eq('id', search); // Search by id
  } else {
    query = query.textSearch('suggest', textSearch); // Normal text search
  }

  // and (('org_2mG3INCfahGiUtSMsutaDk9hUwD' = ("orgId")::text) OR ((requesting_tenant_id() IS NULL) AND ("orgId" IS NULL) AND (requesting_user_id() = ("userId")::text)));
  // query = query.eq('orgId', 'org_2mG3INCfahGiUtSMsutaDk9hUwD');
  /*
    (((requesting_tenant_id())::text = ("orgId")::text) OR ((requesting_tenant_id() IS NULL) AND ("orgId" IS NULL) AND (requesting_user_id() = ("userId")::text)))
    */

  const { data, error } = await query.range(0, 24);

  if (error) {
    if (error.message === 'AbortError: signal is aborted without reason') {
      console.debug('Fetch aborted');
      return null;
    } else {
      throw Error(error.message);
    }
  } else {
    return data;
  }
};

export const getTodos = async (
  filter: {
    search?: string;
    consultantId?: string;
    flagged?: boolean;
    done?: boolean;
    priority?: (typeof PriorityType)[number];
    type?: string;
    timeFilter?: {
      value: string;
    };
  },
  from = 0,
  to = 10,
) => {
  let query = supabase(get(authStore)?.token)
    .from('activities')
    .select('*', { count: 'estimated' });

  if (!filter.type || filter.type === 'Todo') {
    query = query.eq('type', ActivityType.enum.Todo);
  } else {
    query = query.eq('type', filter.type);
  }

  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const nextWeek = new Date(today);
  nextWeek.setDate(nextWeek.getDate() + 7);

  // Time-based filtering
  if (filter.timeFilter) {
    switch (filter.timeFilter.value) {
      case 'To-do':
        query = query.eq('done', false);
        break;
      case 'Overdue':
        query = query.lt('dueDate', today.toISOString()).eq('done', false);
        break;
      case 'Today':
        const endOfToday = new Date(today);
        endOfToday.setHours(23, 59, 59, 999);
        query = query
          .gte('dueDate', today.toISOString())
          .lte('dueDate', endOfToday.toISOString());
        break;
      case 'Tomorrow':
        const endOfTomorrow = new Date(tomorrow);
        endOfTomorrow.setHours(23, 59, 59, 999);
        query = query
          .gte('dueDate', tomorrow.toISOString())
          .lte('dueDate', endOfTomorrow.toISOString());
        break;
      case 'This week': {
        const endOfWeek = new Date(today);
        endOfWeek.setDate(endOfWeek.getDate() + (6 - endOfWeek.getDay()));
        endOfWeek.setHours(23, 59, 59, 999);
        query = query
          .gte('dueDate', today.toISOString())
          .lte('dueDate', endOfWeek.toISOString());
        break;
      }
      case 'Next week': {
        const startOfNextWeek = new Date(today);
        startOfNextWeek.setDate(
          startOfNextWeek.getDate() + (7 - startOfNextWeek.getDay() + 1),
        );
        startOfNextWeek.setHours(0, 0, 0, 0);

        const endOfNextWeek = new Date(startOfNextWeek);
        endOfNextWeek.setDate(endOfNextWeek.getDate() + 6);
        endOfNextWeek.setHours(23, 59, 59, 999);

        query = query
          .gte('dueDate', startOfNextWeek.toISOString())
          .lte('dueDate', endOfNextWeek.toISOString());
        break;
      }
    }
  } else if (!filter || filter.done === undefined) {
    query = query.or(
      `and(done.eq.true,dueDate.gt.${now.toISOString()}),done.eq.false`,
    );
  } else if (filter.done) {
    query = query.eq('done', true);
  }

  if (filter.search) {
    query = query.ilike('name', `%${filter.search}%`);
  }

  if (filter.consultantId) {
    query = query.eq('consultant', filter.consultantId);
  } else {
    query = query.eq('consultant', get(currentUserStore)?.id);
  }

  if (filter.flagged) {
    query = query.eq('flagged', true);
  }

  if (filter.priority) {
    query = query.eq('priority', filter.priority);
  }

  query = query
    .order('done', { ascending: true })
    .order('modifiedAt', { ascending: false });

  const result = await query.range(from, to);

  if (result.error) {
    throw Error(result.error.message);
  } else {
    return result;
  }
};

export const getFavorites = async () => {
  const user = await getCurrentUser();
  if (!user) {
    return [];
  }
  const { data, error } = await supabase(get(authStore)?.token)
    .from('favorites')
    .select(
      `*,
      account:accounts(id, name, logo),
      contact:contacts(id, name, photo),
      deal:deals(id, name),
      lead:leads(id, name),
      order:orders(id, name),
      issue:issues(id, name),
      invoice:invoices(id, name)
    `,
    )
    .eq('userId', user.id)
    .order('createdAt', { ascending: false });
  return data;
};

export const getItems = async (
  {
    collection,
    from,
    to,
    search,
    select,
    sort,
    filters,
    fulltextsearch,
    asCsv,
  }: QueryStore,
  signal = undefined,
) => {
  let collectionName = collection;
  let type = '';

  if (!collection) {
    return { data: [], count: 0, page: 0 };
  }
  if (collection.startsWith('generic-')) {
    collectionName = 'generic';
    type = collection.substring('generic-'.length);
  }

  let selectQuery = select ?? '*';
  if (
    (collectionName === 'leads' || collectionName === 'orders') &&
    selectQuery &&
    !selectQuery.includes('shipment:')
  ) {
    selectQuery += ',shipment:shipments!inner(*)';
  }

  let query = supabase(get(authStore)?.token)
    .from(collectionName)
    .select(selectQuery ?? '*', { count: 'exact' });

  if (type) {
    query.eq('type', type);
  }

  if (search) {
    /*query.textSearch('name', `'${search}':*`, {
        config: 'english', // You can adjust the text search configuration
        type: 'websearch', // Use 'websearch' to mimic web search behavior
      });*/
    /*
      query.textSearch('name', search, {
        type: 'websearch',
        config: 'english',
      }); */
    if (fulltextsearch) {
      const searchTerm = getSearchString(search);
      console.debug(searchTerm);
      query.textSearch('fts', searchTerm);
    } else {
      // Regular expression for validating a UUID
      const uuidPattern =
        /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

      if (uuidPattern.test(search)) {
        query = query.eq('id', search); // Filter by id if it's a valid UUID
      } else {
        query = query.or(`name.ilike.%${search}%,refId.ilike.%${search}%`); // Filter by name OR refId
      }
    }
  }

  if (filters) {
    const supabaseFilters = createFilters(filters);
    supabaseFilters.forEach(filter => {
      query.filter(filter.field, filter.operator, filter.value);
    });
  }
  const isAggregated = select?.includes('sum()');
  if (sort?.column) {
    query.order(sort.column, {
      ascending: sort.order === 'asc',
      nullsFirst: false,
    });
  } else {
    if (!isAggregated) {
      query.order('modifiedAt', { ascending: false });
      query.order('createdAt', { ascending: false });
    }
  }

  // if deleted is true, don't show the item
  query.not('deleted', 'eq', true);

  if (asCsv) {
    // Get the data first without CSV format to process it
    const rawResult = await query.range(0, 10000).abortSignal(signal);

    if (rawResult.error) {
      throw Error(rawResult.error.message);
    }

    // Helper function to safely flatten nested objects with proper path handling
    const flattenObject = (obj, prefix = '') => {
      const result = {};

      // Return empty object for null/undefined
      if (obj === null || obj === undefined) {
        return result;
      }

      Object.keys(obj).forEach(key => {
        const value = obj[key];
        const newKey = prefix ? `${prefix}_${key}` : key;

        // Skip functions and symbols
        if (typeof value === 'function' || typeof value === 'symbol') {
          return;
        }

        // Handle different value types
        if (value === null || value === undefined) {
          // Include null/undefined values as empty in the result
          result[newKey] = '';
        } else if (typeof value === 'object') {
          if (Array.isArray(value)) {
            // For arrays, convert to string representation
            // Filter out objects from the array to avoid circular references
            const nonObjectValues = value.filter(
              item =>
                item === null || item === undefined || typeof item !== 'object',
            );

            // For simple arrays, join with commas
            if (nonObjectValues.length === value.length) {
              result[newKey] = value.join(', ');
            } else {
              // For complex arrays, try to extract IDs or names
              result[newKey] = value
                .map(item => {
                  if (item && typeof item === 'object') {
                    return item.id || item.name || JSON.stringify(item);
                  }
                  return item;
                })
                .join(', ');

              // Also flatten any objects in the array with array indices
              value.forEach((item, index) => {
                if (item && typeof item === 'object' && !Array.isArray(item)) {
                  const flattenedItem = flattenObject(
                    item,
                    `${newKey}_${index}`,
                  );
                  Object.assign(result, flattenedItem);
                }
              });
            }
          } else {
            // For objects, recursively flatten
            const flattenedObj = flattenObject(value, newKey);
            Object.assign(result, flattenedObj);
          }
        } else {
          // For primitive values, add directly
          result[newKey] = value;
        }
      });

      return result;
    };

    // Process the data to flatten nested objects
    const flattenedData = rawResult.data.map(item => flattenObject(item));

    // Get all possible column names from all items
    const allColumns = new Set<string>();
    flattenedData.forEach(item => {
      Object.keys(item).forEach(key => {
        allColumns.add(key);
      });
    });

    // Convert to array and sort alphabetically for consistent order
    const sortedColumns = Array.from(allColumns).sort();

    // Create CSV with consistent column order
    const headers = sortedColumns
      .map(header => {
        // Escape quotes in headers
        const escaped = header.replace(/"/g, '""');
        return `"${escaped}"`;
      })
      .join(',');

    const rows = flattenedData.map(item =>
      sortedColumns
        .map(column => {
          const value = item[column];

          // Handle different value types
          if (value === null || value === undefined) {
            return '';
          } else if (typeof value === 'string') {
            // Escape quotes by doubling them
            const escaped = value.replace(/"/g, '""');
            return `"${escaped}"`;
          } else if (typeof value === 'object') {
            // Shouldn't happen after flattening, but just in case
            try {
              const jsonStr = JSON.stringify(value).replace(/"/g, '""');
              return `"${jsonStr}"`;
            } catch (e) {
              return '""';
            }
          } else {
            // Numbers, booleans, etc.
            return value;
          }
        })
        .join(','),
    );

    const csv = [headers, ...rows].join('\n');
    return { data: csv };
  }

  const result = await query.range(from ?? 0, to ?? 10).abortSignal(signal);

  console.debug(result);
  if (result.error) {
    throw Error(result.error.message);
  } else {
    const page = from === 0 ? 0 : (from - 1) / (to + 1 - from);
    return {
      data: result.data,
      count: result.count,
      page,
    };
  }
};

export async function getLov(name: string) {
  const { data, error } = await supabase(get(authStore)?.token)
    .from('lovs')
    .select('*')
    .eq('name', name)
    .single();

  if (error) {
    throw Error(error.message);
  } else {
    return data;
  }
}

export async function findActivities({
  module,
  id,
  type,
  search = '',
  page,
  itemsPerPage = 10,
  filters = [],
}) {
  let query = supabase(get(authStore)?.token).rpc(
    'search_activities',
    {
      p_id: id,
      p_module: module,
      p_search: search,
      p_type: type,
      p_filters: filters,
    },
    { count: 'exact' },
  );

  if (type && type !== 'all') {
    // query.filter('type', 'eq', type);
  }

  if (module && id) {
    // query.filter(`${module}.id`, 'eq', id);
    // query.filter('accounts', 'cs', JSON.stringify([{ id }]));
    // query.containedBy(`refIds`, ['5e85a5b2e96ab1001faab415']);
  }

  const limit = itemsPerPage || 10;
  // query.order('startDate', { ascending: false });
  query.range((page - 1) * limit, page * limit);
  const { data, error, count } = await query;
  if (error) {
    throw Error(error.message);
  } else {
    return { data, count, page };
  }
}

export async function findAccountsByLinkedInLink(linkedin: string): Account[] {
  let response = await supabase(get(authStore)?.token)
    .from('accounts')
    .select()
    .eq('linkedin', linkedin);
  const { data, error } = response;

  if (error) {
    throw Error(error.toString());
  } else {
    return data;
  }
}

export async function findContactsByLinkedInLink(linkedin: string): Contact[] {
  let response = await supabase(get(authStore)?.token)
    .from('contacts')
    .select()
    .eq('linkedin', linkedin);

  if (response.error) {
    throw Error(response.error.message);
  } else {
    return response.data;
  }
}

export async function executeQuery<T>(
  collection: string,
  from = 0,
  to = 100,
): Promise<T[] | any> {
  return await supabase(get(authStore)?.token)
    .from(collection)
    .select('*', { count: 'estimated' })
    .order('createdAt', { ascending: false })
    .range(from, to);
}

export function queryCollection({
  collection,
  from = 0,
  to = 50,
  filter,
}: {
  collection: string;
  from: number;
  to: number;
  filter?: string;
}) {
  const { set, update, subscribe } = writable<QueryStore>({
    items: [],
    count: 0,
    from,
    to,
    collection,
    filter,
    status: 'preparing',
  });

  let subscribers = 0;

  const getItems = async (collection, from, to) => {
    const result = await supabase(get(authStore)?.token)
      .from(collection)
      .select('*', { count: 'estimated' })
      .range(from, to);
    set({ items: result.data, collection, status: 'ready' });
  };

  getItems(collection, from, to);

  const subscription = supabase(get(authStore)?.token, false)
    .channel('schema-db-changes')
    .on(
      'postgres_changes',
      { event: '*', schema: 'public', table: collection },
      () => getItems(collection, from, to),
    )
    .subscribe();

  return {
    subscribe(run, invalidate) {
      const unsubscribe = subscribe(run, invalidate);
      subscribers++;

      return () => {
        unsubscribe();
        subscribers--;
        if (subscribers === 0) {
          supabase(get(authStore)?.token).removeChannel(subscription);
        }
      };
    },
  };
}

export async function getActivity(id) {
  let query = supabase(get(authStore)?.token)
    .from('activities')
    .select(
      `
  *,
  consultantObj:users(id, name, photo),
  dealObj:deals(id, name),    
  accountObj:accounts(id, name, logo, linkedin),    
  candidateObj:contacts(id, name, photo, role)
`,
      { count: 'exact' },
    );

  query.eq('id', id);

  const { data, error, count } = await query;
  if (error) {
    throw Error(error.message);
  } else {
    const result = data.length > 0 ? data[0] : {};

    // FIX data migration: go over properties: contacts, accounts, deals array inside the result, and remove empty elemnts with no properties
    ['contacts', 'accounts', 'deals', 'candidates'].forEach(prop => {
      if (result[prop] && Array.isArray(result[prop])) {
        result[prop] = result[prop].filter(item => {
          return item && Object.keys(item).length > 1;
        });
      }

      if ((prop === 'contacts' || prop === 'candidates') && result[prop]) {
        result[prop] = result[prop].map(item => {
          if (item.role) {
            return item;
          }
          return {
            ...item,
            role:
              prop === 'contacts'
                ? ContactRoleType.enum.Contact
                : ContactRoleType.enum.Candidate,
          };
        });
      }
    });

    // get all deals and getItem(id) for each deal and set the customer
    if (result.deals) {
      result.deals = await Promise.all(
        result.deals.map(async deal => {
          if (deal.id) {
            const dealObj = await getItemById('deals', deal.id);
            return { ...deal, customer: dealObj.customer };
          } else {
            return deal;
          }
        }),
      );
    }

    return result;
  }
}

export async function findAssignments({
  id,
  module = 'deals',
  search = '',
  page,
  itemsPerPage = 10,
  filters = [],
}) {
  // todo: filters
  return findActivities({
    module,
    id,
    type: 'Assignment',
    search,
    page,
    itemsPerPage,
    filters,
  });

  let query = supabase(get(authStore)?.token)
    .from('activities')
    .select(
      `
  *,    
  dealObj:deals${module === 'deals' ? '!inner' : ''}(id, name, value:annualGrossSalary),    
  accountObj:accounts${module === 'accounts' ? '!inner' : ''}(id, name, logo, linkedin),    
  candidateObj:contacts${module === 'contacts' ? '!inner' : ''}(id, name, photo)
`,
      { count: 'exact' },
    );

  query.filter('type', 'eq', ActivityType.enum.Assignment);
  if (module === 'deals') {
    query.filter('dealObj.id', 'eq', id);
  } else if (module === 'contacts') {
    query.filter('candidate', 'eq', id);
  } else if (module === 'accounts') {
    query.filter('accountObj.id', 'eq', id);
  }

  if (filters) {
    const supabaseFilters = createFilters(filters, true);
    supabaseFilters.forEach(filter => {
      query.filter(filter.field, filter.operator, filter.value);
    });
  }

  const limit = itemsPerPage || 10;
  query.order('startDate', { ascending: false });
  query.range((page - 1) * limit, page * limit);
  const { data, error, count } = await query;
  if (error) {
    throw Error(error.message);
  } else {
    return { data, count, page };
  }
}

export async function findMessageByMessageId(messageId: string) {
  const { data, error } = await supabase(get(authStore)?.token)
    .from('activities')
    .select()
    .eq('messageId', messageId);

  if (error) {
    console.debug('error', error);
    throw new Error(error.message);
  }
  return data;
}

export async function findConsultantByName(name: string) {
  const { data, error } = await supabase(get(authStore)?.token)
    .from('users')
    .select()
    .eq('name', name);

  if (error) {
    console.debug('error', error);
    throw new Error(error.message);
  }
  return data;
}

export async function findLiContactByName(name: string) {
  const { data, error } = await supabase(get(authStore)?.token)
    .from('contacts')
    .select()
    .neq('linkedin', null)
    .eq('name', name);

  if (error) {
    console.debug('error', error);
    throw new Error(error.message);
  }
  return data;
}

export async function getAccountLeads(accountId: string) {
  const { data, error } = await supabase(get(authStore)?.token)
    .from('leads')
    .select('*, shipment:shipments(*,rate:rates(*))')
    .eq('customer', accountId)
    .order('date', { ascending: false });

  if (error) {
    throw Error(error.message);
  } else {
    return data;
  }
}

export async function getAccountOrders(accountId: string) {
  const { data, error } = await supabase(get(authStore)?.token)
    .from('orders')
    .select('*, shipment:shipments(*,rate:rates(*))')
    .eq('customer', accountId)
    .order('orderDate', { ascending: false });

  if (error) {
    throw Error(error.message);
  } else {
    return data;
  }
}

export async function getItemsCount({
  module,
  filters = [],
  search = '',
  fulltextsearch = false,
  sort = null,
}: {
  module: string;
  filters?: FilterType[];
  search?: string;
  fulltextsearch?: boolean;
  sort?: { field?: string; direction?: 'asc' | 'desc' } | null;
}) {
  const queryStore: QueryStore = {
    collection: module,
    from: 0,
    to: 1, // We only need the count
    filters: filters.filter(f => f.active || f.active === undefined),
    search,
    fulltextsearch,
    sort: sort
      ? { column: sort.field || '', order: sort.direction || 'asc' }
      : undefined,
  };

  const result = await getItems(queryStore);
  return result.count || 0;
}

export async function getExpensesByCategory() {
  const { data, error } = await supabase(get(authStore)?.token)
    .from('expenses')
    .select('*')
    .order('expenseDate', { ascending: false });

  if (error) throw error;
  return data;
}
