const system = [
  { label: 'Created At', value: 'createdAt', sortable: true, type: 'string' },
  {
    label: 'Modified At',
    value: 'modifiedAt',
    sortable: true,
    type: 'string',
  },
];

export const fields = {
  tariffs: [
    ...system,
    { label: 'Name', value: 'name', sortable: true, type: 'string' },
    { label: 'Status', value: 'status', sortable: true, type: 'string' },
    {
      label: 'Description',
      value: 'description',
      sortable: false,
      type: 'string',
    },
  ],
  manifests: [
    ...system,

    { label: 'Tracking Number', value: 'name', sortable: true, type: 'string' },
    { label: 'Date', value: 'date', sortable: true, type: 'string' },
    { label: 'Status', value: 'status', sortable: true, type: 'string' },
    { label: 'Weight', value: 'weight', sortable: true, type: 'number' },
  ],
  credits: [
    ...system,
    { label: 'ID', value: 'id', sortable: false, type: 'string' },
    { label: 'Name', value: 'name', sortable: true, type: 'string' },
    {
      label: 'Description',
      value: 'description',
      sortable: false,
      type: 'string',
    },
    {
      label: 'Amount',
      value: 'amount',
      sortable: true,
      type: 'number',
    },
    {
      label: 'Remaining amount',
      value: 'remainingAmount',
      sortable: true,
      type: 'number',
    },
  ],
  accounts: [
    { label: 'ID', value: 'id', sortable: false, type: 'string' },
    { label: 'Name', value: 'name', sortable: true, type: 'string' },
    {
      label: 'Description',
      value: 'description',
      sortable: false,
      type: 'string',
    },
    {
      label: 'Current Balance',
      value: 'currentBalance',
      sortable: true,
      type: 'number',
    },
    { label: 'Logo', value: 'logo', sortable: false, type: 'string' },

    { label: 'LinkedIn', value: 'linkedin', sortable: false, type: 'string' },
    { label: 'URL', value: 'url', sortable: false, type: 'string' },
    {
      label: 'Customer since',
      value: 'customerSince',
      sortable: true,
      type: 'string',
    },
    { label: 'Phone', value: 'phone', sortable: false, type: 'string' },
    {
      label: 'Website URL',
      value: 'websiteUrl',
      sortable: false,
      type: 'string',
    },
    {
      label: 'Account Status',
      value: 'accountStatus',
      sortable: true,
      type: 'string',
    },
    { label: 'Industry', value: 'industry', sortable: true, type: 'string' },
    { label: 'Employees', value: 'employees', sortable: true, type: 'string' },
    { label: 'Revenue', value: 'revenue', sortable: true, type: 'string' },
    { label: 'Address', value: 'address', sortable: false, type: 'string' },
    {
      label: 'Communication',
      value: 'communication',
      sortable: false,
      type: 'string',
    },
    {
      label: 'Company Profile',
      value: 'companyProfile',
      sortable: false,
      type: 'string',
    },
    {
      label: 'Philosophy',
      value: 'philosophy',
      sortable: false,
      type: 'string',
    },
    {
      label: 'Universal Name',
      value: 'universalName',
      sortable: false,
      type: 'string',
    },
    {
      label: 'Employee Count',
      value: 'employeeCount',
      sortable: true,
      type: 'string',
    },
    {
      label: 'Employee Count Range',
      value: 'employeeCountRange',
      sortable: true,
      type: 'string',
    },
    {
      label: 'Official Name',
      value: 'officialName',
      sortable: true,
      type: 'string',
    },
    { label: 'Tagline', value: 'tagline', sortable: false, type: 'string' },
    { label: 'Founded On', value: 'foundedOn', sortable: true, type: 'string' },
    {
      label: 'Specialities',
      value: 'specialities',
      sortable: false,
      type: 'string',
    },
    {
      label: 'Crunchbase Funding Data',
      value: 'crunchbaseFundingData',
      sortable: false,
      type: 'string',
    },
    ...system,
  ],
  leads: [
    ...system,
    { label: 'Name', value: 'name', sortable: true, type: 'string' },
    { label: 'Status', value: 'status', sortable: true, type: 'string' },
  ],

  manifest: [
    ...system,
    { label: 'Name', value: 'name', sortable: true, type: 'string' },
    { label: 'Status', value: 'status', sortable: true, type: 'string' },
  ],

  invoices: [
    ...system,
    { label: 'ID', value: 'invoiceId', sortable: true, type: 'string' },
    { label: 'Name', value: 'name', sortable: true, type: 'string' },
    { label: 'Status', value: 'status', sortable: true, type: 'string' },
    {
      label: 'Invoice date',
      value: 'invoiceDate',
      sortable: true,
      type: 'date',
    },
    {
      label: 'Fixed on',
      value: 'fixedOn',
      sortable: true,
      type: 'date',
    },
    // { label: 'Amount', value: 'net', sortable: true, type: 'string' },
    // { label: 'Subject', value: 'subject', sortable: true, type: 'string' },
    {
      label: 'Due Date',
      value: 'paymentDeadline',
      sortable: true,
      type: 'date',
    },

    { label: 'Net', value: 'net', sortable: true, type: 'string' },
    { label: 'Gross', value: 'gross', sortable: true, type: 'string' },
    // { label: 'Debit', value: 'debit', sortable: true, type: 'string' },
  ],
};
