import { now, getLocalTimeZone } from '@internationalized/date';
import type { DateRange } from './hanta-date-range';

export type RelativeDateRange = {
  label: string;
  getValue: () => {
    start: any;
    end: any;
  };
  type: 'closed' | 'from' | 'until';
  meta?: {
    relative: string;
  };
};

// Relative date ranges that can be used across the application
export const relativeDateRanges: RelativeDateRange[] = [
  {
    label: 'Custom',
    getValue: () => ({
      start: now(getLocalTimeZone()),
      end: now(getLocalTimeZone()),
    }),
    type: 'closed',
  },
  {
    label: 'Today',
    getValue: () => ({
      start: now(getLocalTimeZone()),
      end: now(getLocalTimeZone()),
    }),
    type: 'closed',
    meta: {
      relative: 'Today',
    },
  },
  {
    label: 'Yesterday',
    getValue: () => ({
      start: now(getLocalTimeZone()).subtract({ days: 1 }),
      end: now(getLocalTimeZone()).subtract({ days: 1 }),
    }),
    type: 'closed',
    meta: {
      relative: 'Yesterday',
    },
  },
  {
    label: 'Last 7 days',
    getValue: () => ({
      start: now(getLocalTimeZone()).subtract({ days: 6 }),
      end: now(getLocalTimeZone()),
    }),
    type: 'closed',
    meta: {
      relative: 'Last 7 days',
    },
  },
  {
    label: 'Last 30 days',
    getValue: () => ({
      start: now(getLocalTimeZone()).subtract({ days: 29 }),
      end: now(getLocalTimeZone()),
    }),
    type: 'closed',
    meta: {
      relative: 'Last 30 days',
    },
  },
  {
    label: 'This month',
    getValue: () => ({
      start: now(getLocalTimeZone()).set({ day: 1 }),
      end: now(getLocalTimeZone()),
    }),
    type: 'closed',
    meta: {
      relative: 'This month',
    },
  },
  {
    label: 'Last month',
    getValue: () => {
      const lastMonth = now(getLocalTimeZone()).subtract({ months: 1 });
      const lastMonthYear = lastMonth.year;
      const lastMonthNum = lastMonth.month;
      // Get the last day of the month by getting the first day of next month and subtracting 1 day
      const lastDayOfMonth = new Date(lastMonthYear, lastMonthNum, 0).getDate();

      return {
        start: lastMonth.set({ day: 1 }),
        end: lastMonth.set({ day: lastDayOfMonth }),
      };
    },
    type: 'closed',
    meta: {
      relative: 'Last month',
    },
  },
  {
    label: 'This quarter',
    getValue: () => {
      const currentQuarter = Math.floor(
        (now(getLocalTimeZone()).month - 1) / 3,
      );
      const quarterStart = now(getLocalTimeZone()).set({
        month: currentQuarter * 3 + 1,
        day: 1,
      });
      return { start: quarterStart, end: now(getLocalTimeZone()) };
    },
    type: 'closed',
    meta: {
      relative: 'This quarter',
    },
  },
  {
    label: 'Year to date',
    getValue: () => ({
      start: now(getLocalTimeZone()).set({ month: 1, day: 1 }),
      end: now(getLocalTimeZone()),
    }),
    type: 'closed',
    meta: {
      relative: 'Year to date',
    },
  },
  {
    label: 'Last 365 days',
    getValue: () => ({
      start: now(getLocalTimeZone()).subtract({ days: 365 }),
      end: now(getLocalTimeZone()),
    }),
    type: 'closed',
    meta: {
      relative: 'Last 365 days',
    },
  },
  {
    label: 'Last 2 years',
    getValue: () => ({
      start: now(getLocalTimeZone()).subtract({ years: 2 }),
      end: now(getLocalTimeZone()),
    }),
    type: 'closed',
    meta: {
      relative: 'Last 2 years',
    },
  },
  {
    label: 'Last 5 years',
    getValue: () => ({
      start: now(getLocalTimeZone()).subtract({ years: 5 }),
      end: now(getLocalTimeZone()),
    }),
    type: 'closed',
    meta: {
      relative: 'Last 5 years',
    },
  },
  {
    label: 'Last 10 years',
    getValue: () => ({
      start: now(getLocalTimeZone()).subtract({ years: 10 }),
      end: now(getLocalTimeZone()),
    }),
    type: 'closed',
    meta: {
      relative: 'Last 10 years',
    },
  },
  {
    label: 'From date...',
    getValue: () => ({
      start: now(getLocalTimeZone()),
      end: undefined,
    }),
    type: 'from',
    meta: {
      relative: 'From date...',
    },
  },
  {
    label: 'Until date...',
    getValue: () => ({
      start: undefined,
      end: now(getLocalTimeZone()),
    }),
    type: 'until',
    meta: {
      relative: 'Until date...',
    },
  },
];

// Helper function to get a date range by its label
export function getRelativeDateRangeByLabel(
  label: string,
): RelativeDateRange | undefined {
  return relativeDateRanges.find(range => range.label === label);
}

// Helper function to get a date range value by its label
export function getRelativeDateRangeValueByLabel(
  label: string,
): DateRange | undefined {
  const range = getRelativeDateRangeByLabel(label);
  if (!range) return undefined;

  const { start, end } = range.getValue();
  return {
    start,
    end,
    meta: range.meta,
  };
}
