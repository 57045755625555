<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  import { appStore } from '$lib/stores/app-store';
  import { Input } from '$lib/components/ui/input';
  import { Label } from '$lib/components/ui/label';
  import { Button } from '$lib/components/ui/button';
  import { parseManifest } from '$lib/api/parse-manifest';
  import Icon from '@iconify/svelte';
  import AccountSelector from '$lib/widgets/accounts/account-selector.svelte';
  import { currentUserStore } from '$lib/stores/current-user-store';
  import HantaInputTextLean from '$lib/widgets/input/hanta-input-text-lean.svelte';
  import HantaInputLovSelect from '$lib/widgets/input/hanta-input-lov-select.svelte';
  import Spreadsheet from '$lib/components/spreadsheet.svelte';
  import { parseBalance } from '$lib/api/parse-balance';
  import { parseCredit } from '$lib/api/parse-credit';

  export let module: string;
  $: isUploadModule =
    module === 'manifest' ||
    module === 'balance' ||
    module === 'payment' ||
    module === 'credit';

  const dispatch = createEventDispatcher();

  let item = {};
  let showReview = false;
  let isApplying = false;
  let manifestData = {
    headers: [],
    data: [],
  };

  $: {
    item = getInitialItemState(module);
  }

  function getInitialItemState(module: string) {
    switch (module) {
      case 'accounts':
      case 'deals':
        return { name: '' };
      case 'manifests':
        return {
          weight: 0,
          date: new Date(),
        };
      case 'invoices':
      case 'contracts':
      case 'leads':
      case 'issues':
      case 'orders':
        return {
          customer:
            $appStore.selected?.module === 'accounts' && $appStore.selected?.id
              ? {
                  id: $appStore.selected?.id,
                }
              : null,
        };
      case 'candidates':
      case 'contacts':
        return { firstname: '', lastname: '' };
      default:
        return {};
    }
  }

  let selectedFile: File | null = null;
  let isParsing = false;
  let parseError: string | null = null;

  async function handleSubmit() {
    try {
      if (isUploadModule) {
        if (!selectedFile) {
          throw new Error('Please select a file');
        }

        if (!showReview) {
          isParsing = true;
          parseError = null;
          try {
            const result =
              module === 'manifest'
                ? await parseManifest(selectedFile)
                : module === 'balance'
                  ? await parseBalance(selectedFile)
                  : await parseCredit(selectedFile);

            manifestData = result;
            showReview = true;
            return;
          } catch (error) {
            parseError = error.message;
            return;
          } finally {
            isParsing = false;
          }
        } else {
          // Apply the manifest data
          isApplying = true;
          try {
            dispatch('submit', {
              name: 'Manifest Upload',
              type: 'manifest',
              data: manifestData.data,
            });
          } catch (error) {
            parseError = error.message;
            return;
          } finally {
            isApplying = false;
          }
          return;
        }
      }

      let newItem = { ...item };

      if (module === 'candidates' || module === 'contacts') {
        newItem.name = `${item.firstname} ${item.lastname}`;
        newItem.role = module === 'candidates' ? 'Candidate' : 'Contact';
      } else if (module === 'contracts') {
        newItem = {
          ...newItem,
          name: 'New Contract',
          status: 'In development',
          salesId: $currentUserStore?.id,
          accountId: newItem.customer?.id,
          customFields: {
            zonesTnt: ['0', '1', '2', '3', '4', '5', '6'],
            zonesFedex: [
              'A',
              'B',
              'C',
              'D',
              'E',
              'F',
              'G',
              'H',
              'I',
              'J',
              'K',
              'L',
            ],
          },
        };
        delete newItem.customer;
      } else if (module === 'tariffs') {
        newItem = {
          ...newItem,
          startDate: new Date(),
          type: 'Customer',
          currency: 'EUR',
          name: item.name,
          pricelists: [],
        };
      } else if (module === 'invoices') {
        newItem = {
          ...newItem,
          name: 'New Invoice',
          customer: item.customer?.id,
          status: 'Draft',
          positions: [],
          tags: [],
        };
      } else if (module === 'leads') {
        newItem = {
          ...newItem,
          country: item.country,
          name: 'New Lead',
          customer: item.customer?.id,
          expectedCloseDate: new Date(),
          assignedTo: $currentUserStore?.id,
        };
      } else if (module === 'orders') {
        newItem = {
          ...newItem,
          country: item.country,
          name: item.name,
          customer: item.customer?.id,
          orderDate: new Date(),
          assignedTo: $currentUserStore?.id,
        };
      } else if (module === 'expenses') {
        newItem = {
          ...newItem,
          name: 'New Expense',
          expenseDate: new Date(),
          amount: 0,
        };
      } else if (module === 'issues') {
        newItem = {
          ...newItem,
          name: 'New Issue',
          status: 'New',
          issuedOn: new Date(),
          accountId: item.customer?.id,
          reporterId: $currentUserStore?.id,
        };
        delete newItem.customer;
      } else if (module === 'activities') {
        newItem = {
          ...newItem,
          startDate: new Date(),
          dueDate: new Date(),
          consultant: $currentUserStore?.id,
        };
      }

      dispatch('submit', newItem);
    } catch (error) {
      console.error(error);
    }
  }
</script>

<div class="h-[90vh] flex flex-col">
  <div class="p-6 flex-1 overflow-y-auto">
    <h1 class="mb-4 text-xl capitalize" data-testid={`${module}-table`}>
      {module}
    </h1>
    <form class="space-y-8" on:submit|preventDefault={handleSubmit}>
      {#if isUploadModule}
        <div class="space-y-2">
          <Label for="manifestFile"
            >Upload {module === 'manifest'
              ? 'Manifest'
              : module === 'balance'
                ? 'Balance'
                : 'Payment'} Excel File</Label
          >
          <div class="flex items-center gap-4">
            <Input
              type="file"
              id="manifestFile"
              accept={module === 'manifest'
                ? '.xlsx,.xls'
                : module === 'balance'
                  ? '.csv'
                  : '.xml'}
              on:change={e => (selectedFile = e.target.files?.[0] || null)}
              class="flex-1"
            />
            {#if isParsing}
              <Icon icon="mdi:loading" class="animate-spin h-5 w-5" />
            {/if}
          </div>
          {#if parseError}
            <p class="text-red-500 text-sm">{parseError}</p>
          {/if}
        </div>
      {:else if module === 'manifests'}
        <div>
          <Label for="weight">Tracking Number</Label>
          <Input
            id="weight"
            bind:value={item.name}
            class="w-full mt-2"
            data-testid="title-input"
            autofocus
            required
          />
        </div>
        <div>
          <Label for="weight">Weight</Label>
          <Input
            id="weight"
            bind:value={item.weight}
            class="w-full mt-2"
            data-testid="title-input"
            autofocus
            required
          />
        </div>
      {:else if ['accounts', 'deals', 'tariffs', 'balances'].includes(module)}
        <div>
          <Label for="name">Name</Label>
          <Input
            id="name"
            bind:value={item.name}
            class="w-full mt-2"
            data-testid="title-input"
            autofocus
            required
          />
        </div>
      {:else if ['invoices', 'contracts', 'leads', 'issues', 'orders'].includes(module)}
        <div class="h-[300px] flex flex-col space-y-8">
          <AccountSelector
            bind:value={item.customer}
            initialValue={item.customer?.id}
          />

          {#if ['orders', 'leads'].includes(module)}
            <HantaInputLovSelect
              bind:value={item.country}
              label="Country"
              type="Countries"
              multiple={false}
            />
          {/if}
          {#if module === 'orders'}
            <div>
              <HantaInputTextLean
                bind:value={item.name}
                label="AWB Number"
                data-testid="title-input"
              />
            </div>
          {/if}
        </div>
      {:else if ['candidates', 'contacts'].includes(module)}
        <div>
          <Label for="firstName">First name</Label>
          <Input
            id="firstName"
            bind:value={item.firstname}
            class="w-full"
            data-testid="title-input"
            autofocus
          />
        </div>
        <div>
          <Label for="lastName">Last name</Label>
          <Input
            id="lastName"
            bind:value={item.lastname}
            class="w-full"
            data-testid="description-input"
          />
        </div>
      {/if}

      <div class="flex gap-4">
        <Button
          type="button"
          variant="secondary"
          class="w-full"
          data-testid="cancel-button"
          on:keydown={event => {
            if (event.key === 'Enter') {
              dispatch('cancel');
            }
          }}
          on:click={() => dispatch('cancel')}>Cancel</Button
        >
        <Button
          variant="default"
          class="w-full"
          disabled={isApplying}
          data-testid="save-button"
          on:keydown={event => {
            if (event.key === 'Enter') {
              handleSubmit();
            }
          }}
          on:click={handleSubmit}
        >
          {#if isApplying}
            <Icon icon="mdi:loading" class="animate-spin h-5 w-5 mr-2" />
            Applying...
          {:else if isUploadModule}
            {showReview ? 'Apply' : 'Review'}
          {:else}
            Save
          {/if}
        </Button>
      </div>
    </form>

    {#if isUploadModule && showReview}
      <div class="mt-8">
        <h2 class="text-lg font-semibold mb-4">
          Review {module === 'manifest'
            ? 'Manifest'
            : module === 'balance'
              ? 'Balance'
              : 'Payment'} Data
        </h2>
        <div
          class="border rounded-lg overflow-hidden h-[500px] w-128 overflow-y-scroll"
        >
          <Spreadsheet
            columnHeaders={manifestData.headers.map(header => ({
              title: header.title,
              width: 150, // Default width for all columns
            }))}
            data={manifestData.data}
          />
        </div>
      </div>
    {/if}
  </div>
</div>
