<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  import { getBucketsForQuery } from '$lib/api/aggregations';
  import { onMount, onDestroy } from 'svelte';
  import { VisXYContainer, VisGroupedBar, VisAxis } from '@unovis/svelte';
  import { Switch } from '$lib/components/ui/switch';
  import { fade } from 'svelte/transition';
  import HantaDateRange from '$lib/components/hanta/date/hanta-date-range.svelte';
  import HantaNumberFilter from '$lib/components/hanta/number/hanta-number-filter.svelte';
  import HantaBooleanFilter from '$lib/components/hanta/boolean/hanta-boolean-filter.svelte';
  import { getLocalTimeZone, parseDate } from '@internationalized/date';

  import Badge from '$lib/components/ui/badge/badge.svelte';
  import Button from '$lib/components/ui/button/button.svelte';
  import { cn } from '$lib/utils/hanta-utils';
  import Input from '$lib/components/ui/input/input.svelte';
  import { Skeleton } from '$lib/components/ui/skeleton';
  import { getRelativeDateRangeValueByLabel } from '$lib/components/hanta/date/relative-date-ranges';

  const dispatch = createEventDispatcher();

  export let fieldDefinition;
  export let filters;
  export let search = '';
  export let fulltextsearch = false;
  export let collection;
  export let fieldLabel = undefined;
  export let field = '';
  export let fieldType = '';
  export let fieldMeta = undefined;
  export let withGraphs = false;

  let findBy = '';
  let dateRange = undefined;
  let numberFilter = undefined;
  let booleanFilter: boolean | null = null;
  let numberFilterDebounceTimeout: ReturnType<typeof setTimeout>;
  let loading = false;

  $: filteredData = (data || []).filter(
    a =>
      findBy === '' ||
      (a.field || '').toLowerCase().includes(findBy.toLowerCase()),
  );

  let checked = false;
  let data = [];
  let currentAbortController: AbortController | null = null;

  async function loadData(
    collection,
    field,
    filters,
    search,
    fulltextsearch,
    fieldDefinition,
  ) {
    if (filters?.find(f => f.field === field)) {
      checked = true;
    }
    if (!checked) return;

    // if date, number or boolean filter is active, don't load buckets
    if (dateRange || numberFilter) return;

    // Set loading state
    loading = true;

    // Abort previous request if it exists
    if (currentAbortController) {
      currentAbortController.abort();
    }

    // Create new abort controller
    currentAbortController = new AbortController();

    console.debug(
      'loadData',
      collection,
      field,
      filters,
      search,
      fulltextsearch,
      fieldDefinition,
    );

    try {
      data = await getBucketsForQuery({
        collection,
        field,
        search,
        fulltextsearch,
        filters: (filters || []).filter(f => f.field !== field),
        is_array: fieldDefinition?.isArray,
        join_table: fieldDefinition?.joinTable || null,
        join_condition: fieldDefinition?.joinCondition || null,
        signal: currentAbortController.signal,
      });
    } catch (error) {
      if (error.name === 'AbortError') {
        console.debug('Request aborted', field);
      } else {
        console.error('Error loading data:', error);
      }
    } finally {
      loading = false;
    }
  }

  $: {
    const existingFilter = filters?.find(f => f.field === field && f.active);
    if (existingFilter) {
      if (fieldType === 'date' && !dateRange) {
        try {
          if (
            existingFilter.meta?.relative &&
            existingFilter.meta?.relative !== 'Custom'
          ) {
            dateRange = getRelativeDateRangeValueByLabel(
              existingFilter.meta.relative,
            );
          } else {
            const formatDateForParse = (dateStr: string) => {
              if (!dateStr) return null; // Handle null/undefined values
              const date = new Date(dateStr);
              return date.toISOString().split('T')[0];
            };

            // Handle potential undefined/null values in the array
            const startValue = existingFilter.value?.[0] || null;
            const endValue = existingFilter.value?.[1] || null;

            dateRange = {
              start: startValue
                ? parseDate(formatDateForParse(startValue))
                : undefined,
              end: endValue
                ? parseDate(formatDateForParse(endValue))
                : undefined,
              meta: {
                relative: 'Custom',
              },
            };
          }
        } catch (e) {
          console.error(
            'Failed to parse existing date filter:',
            e,
            existingFilter,
          );
          // Provide a fallback in case of error
          dateRange = { start: undefined, end: undefined };
        }
      } else if (fieldType === 'number' && !numberFilter) {
        try {
          numberFilter = {
            operator: existingFilter.operator || 'eq',
            value: existingFilter.value,
          };
        } catch (e) {
          console.warn('Failed to parse existing number filter:', e);
        }
      } else if (fieldType === 'boolean' && booleanFilter === null) {
        try {
          booleanFilter = existingFilter.value;
        } catch (e) {
          console.warn('Failed to parse existing boolean filter:', e);
        }
      }
    }
  }

  $: loadData(
    collection,
    field,
    filters,
    search,
    fulltextsearch,
    fieldDefinition,
  );

  onMount(async () => {
    await loadData(
      collection,
      field,
      filters,
      search,
      fulltextsearch,
      fieldDefinition,
    );
  });

  onDestroy(() => {
    if (numberFilterDebounceTimeout) {
      clearTimeout(numberFilterDebounceTimeout);
    }
  });

  function toggleFilterCriteria(item) {
    dispatch('toggleFilterCriteria', item);
  }

  $: dispatch('toggleCheck', checked);

  function handleDateChange() {
    // Convert to ISO date strings for database queries
    const startDate =
      dateRange.start &&
      dateRange.start.toDate(getLocalTimeZone()).toISOString().split('T')[0];
    const endDate =
      dateRange.end &&
      dateRange.end.toDate(getLocalTimeZone()).toISOString().split('T')[0];

    dispatch('toggleFilterCriteria', {
      field: field,
      type: 'date',
      value: [startDate, endDate],
      key: field,
      meta: dateRange.meta,
    });
  }

  function handleNumberChange() {
    if (!numberFilter) return;

    dispatch('toggleFilterCriteria', {
      field: field,
      type: 'number',
      operator: numberFilter.operator,
      value: numberFilter.value,
      key: field,
    });
  }

  function handleBooleanChange(event) {
    dispatch('toggleFilterCriteria', {
      field: field,
      type: 'boolean',
      value: booleanFilter,
      key: field,
    });
  }

  $: if (dateRange && (dateRange.start || dateRange.end)) {
    handleDateChange();
  }

  $: if (numberFilter) {
    if (numberFilterDebounceTimeout) {
      clearTimeout(numberFilterDebounceTimeout);
    }
    numberFilterDebounceTimeout = setTimeout(() => {
      handleNumberChange();
    }, 500);
  }

  $: if (booleanFilter !== null) {
    handleBooleanChange();
  }

  const tickFormat = (tick: number) => data[tick].field;
  const x = (d: { count: number; field: string }, i: number) => i;
  const y = (d: { count: number; field: string }) => d.count;
</script>

<div class="relative mt-3">
  <div class="flex items-center space-x-2">
    <Switch includeInput bind:checked />
    <h2 class="text-xl capitalize thin">{fieldLabel ?? field}</h2>
  </div>

  {#if checked}
    {#if fieldType === 'date'}
      <div class="mt-4 h-[300px]" transition:fade>
        <HantaDateRange
          bind:value={dateRange}
          selectedRelativeRange={dateRange?.meta?.relative}
        />
      </div>
    {:else if fieldType === 'number'}
      <div class="mt-4" transition:fade>
        <HantaNumberFilter bind:value={numberFilter} />
      </div>
    {:else if fieldType === 'boolean'}
      <div class="mt-4" transition:fade>
        <HantaBooleanFilter
          bind:value={booleanFilter}
          counts={data?.reduce(
            (acc, item) => {
              if (item.field === 'true') acc.true = item.count;
              else if (item.field === 'false') acc.false = item.count;
              else if (item.field === null || item.field === '' || !item.field)
                acc.null = item.count;
              return acc;
            },
            { true: 0, false: 0, null: 0 },
          )}
        />
      </div>
    {:else if withGraphs}
      <div class="h-[100px]">
        {#if loading}
          <Skeleton class="w-full h-full" />
        {:else}
          <VisXYContainer {data} height={100}>
            <VisGroupedBar {x} {y} color={'hsl(var(--primary) / 0.7)'} />
            <VisAxis
              type="x"
              label={field}
              {tickFormat}
              numTicks={data.length > 10 ? 5 : data.length}
            />
            <VisAxis type="y" label="Count" />
          </VisXYContainer>
        {/if}
      </div>
    {:else}
      <select class="hidden mt-2 ml-12 w-48 text-lg">
        <option value="contains">contains</option>
        <option value="does not contain">does not contain</option>
      </select>
      <div class="relative mt-4 p-4 overflow-scroll max-h-[200px]">
        {#if data?.length > 9}
          <div class="sticky left-2 -top-5 p-1 w-full bg-transparent">
            <Input bind:value={findBy} placeholder="Filter..." />
          </div>
        {/if}
        {#if loading}
          <div class="flex space-x-2">
            <Skeleton class="w-32 h-8" />
            <Skeleton class="w-16 h-8" />
            <Skeleton class="w-24 h-8" />
            <Skeleton class="h-8 w-18" />
            <Skeleton class="w-16 h-8" />
          </div>{:else}
          {#each filteredData as item}
            {@const isActive = filters?.find(
              f =>
                f.field === field &&
                f.value?.filter(i => i.key === item.key)?.length > 0,
            )}
            <Button
              size="sm"
              variant={isActive ? 'default' : 'outline'}
              class={cn('truncate ml-2 mt-1 max-w-[calc(100%-10px)]')}
              on:click={() => toggleFilterCriteria(item)}
            >
              <span class="truncate">
                {item.field === '' ? '-' : item.field || 'n/a'}
              </span>

              <Badge variant="secondary" class="ml-2">{item.count}</Badge>
            </Button>
          {/each}
        {/if}
      </div>
    {/if}
  {/if}
</div>
