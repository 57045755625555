<script lang="ts">
  import * as Avatar from '$lib/components/ui/avatar';

  import Icon from '@iconify/svelte';
  import Address from '$lib/components/hanta/address.svelte';

  import { type ActivityWithRefs, type AccountWithRefs } from '$db/schema';
  import ActivityActionBar from '../activities/activity-action-bar.svelte';
  import LinkedinButton from '$lib/components/hanta/linkedin-button.svelte';
  import { appStore } from '$lib/stores/app-store';
  import Badge from '$lib/components/ui/badge/badge.svelte';
  import { updateAccountLogo } from '$lib/api/mutations';
  import UsersSelector from '$lib/widgets/users/users-selector.svelte';
  import { formatCurrency } from '$lib/utils/hanta-utils';

  export let crmAccount: AccountWithRefs;
  export let activity: ActivityWithRefs;

  let logo = crmAccount.logo;
</script>

<div
  class="flex relative flex-col items-start p-4 space-y-2 w-full md:space-x-8 md:flex-row"
>
  {#if $appStore.isMobile}
    <div
      class="absolute left-0 z-[-1] w-full h-[calc(100%-65px)] bg-blue-300/10 dark:bg-slate-800 top-20 rounded-t-3xl"
    ></div>
  {/if}

  <Avatar.Root class="rounded-none size-48">
    <Avatar.Image
      src={crmAccount?.logo}
      alt="Company Logo"
      class="object-scale-down rounded-none"
    />

    <Avatar.Fallback
      class="w-full p-1 text-[8px] text-center text-muted-foreground rounded-none bg-gradient-to-r from-gray-300 to-gray-100 "
    >
      {crmAccount?.name ?? ''}
    </Avatar.Fallback>
    <Avatar.AvatarUpload
      on:upload={async newLogo => {
        logo = newLogo.detail.base64;
        crmAccount.logo = logo;
        await updateAccountLogo(crmAccount.id, logo);
      }}
    />
  </Avatar.Root>

  <div class="w-full">
    <div class="flex justify-between items-center space-x-4">
      <div class="flex flex-1 justify-between items-center">
        <div class="flex items-center text-xl font-bold">
          <span data-testid="account-name">
            {crmAccount?.name ?? ''}
          </span>
          {#if crmAccount?.linkedin}
            <div class="block">
              <LinkedinButton linkedin={crmAccount.linkedin} />
            </div>
          {/if}
          {#if crmAccount?.status}
            <Badge variant="secondary" class="ml-2">
              {crmAccount?.status}
            </Badge>
          {/if}
          {#if crmAccount?.currentBalance != 0}
            <Badge variant="destructive" class="ml-2 font-thin">
              <span class="text-xs mr-2">Balance:</span>
              {crmAccount?.currentBalance?.toFixed(0)}
            </Badge>
          {/if}
        </div>
      </div>
    </div>

    <div class="mt-2 space-y-2 text-xs text-muted-foreground">
      {#if crmAccount?.address}
        <div class="flex space-x-2">
          <Address address={crmAccount?.address} />
        </div>
      {/if}

      {#if crmAccount?.websiteUrl}
        <div class="flex space-x-2">
          <Icon icon="mdi:globe" />
          <div>
            <a
              class="underline text-primary/80 hover:text-primary/80 visited:text-primary/80"
              href={crmAccount?.websiteUrl}
              target="_blank"
            >
              {crmAccount?.websiteUrl}
            </a>
          </div>
        </div>
      {/if}

      {#if crmAccount?.phone}
        <div class="flex space-x-2">
          <Icon icon="mdi:phone" />
          <div>
            {crmAccount?.phone}
          </div>
        </div>
      {/if}

      {#if crmAccount?.employeeCountRange || crmAccount?.foundedOn}
        <div class="flex space-x-2">
          <Icon icon="mdi:building" />
          {#if crmAccount?.employeeCountRange}
            <div>
              {crmAccount?.employeeCountRange} Employees
            </div>
          {/if}
          {#if crmAccount?.foundedOn}
            <div
              class={crmAccount?.employeeCountRange
                ? 'border-l border-primary-500 border-solid pl-2'
                : ''}
            >
              Founded in {crmAccount?.foundedOn}
            </div>
          {/if}
        </div>
      {/if}
      <!--
      {#if crmAccount?.officialAddress || crmAccount?.officialName}
        <div class="space-y-2">
          <span class="text-xs font-light">Official</span>
          <div>{crmAccount?.officialName ?? ''}</div>
          <div class="flex space-x-2">
            <Address address={crmAccount?.officialAddress} />
          </div>
        </div>
      {/if}
      -->
    </div>
    <div class="flex items-center gap-2">
      {#if crmAccount.sales}
        <div class="flex gap-2 text-sm my-6 relative">
          <div class="font-thin absolute top-5 left-9 text-primary">Sales</div>
          <UsersSelector
            bind:value={crmAccount.sales}
            label="Sales"
            multiple={false}
            readOnly={true}
            hideLabel={true}
          />
        </div>
      {/if}
      {#if crmAccount.fiscalCode}
        <div class="ml-2 text-base">
          <span class="font-normal mr-1 text-primary"
            >{crmAccount.fiscalCode}</span
          ><span class="font-thin">Fiscal Code</span>
        </div>
      {/if}
    </div>
    <div class="my-4">
      <ActivityActionBar {activity} />
    </div>
  </div>
</div>
