<script lang="ts">
  import * as Card from '$lib/components/ui/card';
  import InvoiceRecipientPopover from './invoice-recipient-popover.svelte';
  import HantaInputDate from '$lib/widgets/input/hanta-input-date.svelte';

  import { currencyFormatter } from '$lib/app/utils';
  import { calculateSum } from './invoice-utils';
  import Badge from '$lib/components/ui/badge/badge.svelte';
  import Button from '$lib/components/ui/button/button.svelte';
  import { appStore } from '$lib/stores/app-store';
  import { authStore } from '$lib/stores/auth-store';

  export let form;
  export let formData;
  export let isLocked;
  export let sum = { sum: 0, tax: 0, sumNational: 0 };

  $: sum = calculateSum($formData);
</script>

<div class="flex flex-row gap-4">
  <Card.Root class="w-1/2">
    <Card.Header>
      <Card.Title class="relative">
        <div class="w-full">Billed to</div>
        {#if !isLocked}
          <div class="absolute -top-[50%] -right-2 min-w-0">
            <InvoiceRecipientPopover {form} />
          </div>
        {/if}
      </Card.Title>
    </Card.Header>
    <Card.Content>
      {#if $formData.customerObj}
        <div class="relative">
          <div class="">
            <Button
              class="text-xl"
              variant="ghost"
              on:click={() => {
                appStore.select({
                  id: $formData.customerObj.id,
                  module: 'accounts',
                });
                appStore.openPopup(true);
              }}
            >
              <div>{$formData.customerObj.name}</div>
            </Button>

            {#if $formData.customerObj.logo}
              <img
                src={$formData.customerObj.logo}
                alt="Customer Logo"
                class="object-scale-down absolute top-0 right-0 size-36"
              />
            {/if}
          </div>
          <div class="mt-4 ml-4 text-muted-foreground">
            {$formData.customerObj.address?.street ?? ''}
          </div>
          <div class="my-1 ml-4 text-muted-foreground">
            {$formData.customerObj.address?.postalCode ?? ''}
            {$formData.customerObj.address?.city ?? ''}
          </div>
          <div class="ml-4 text-muted-foreground">
            {$formData.customerObj.address?.country ?? ''}
          </div>
        </div>
      {/if}
    </Card.Content>
  </Card.Root>
  <Card.Root class="w-1/2">
    <Card.Header>
      <Card.Title>Invoice Summary</Card.Title>
      <Card.Description class="flex flex-col mt-12">
        {#if $formData.billingMethod}
          <div class="text-lg text-muted-foreground">
            Billing Method: <span class="text-primary"
              >{$formData.billingMethod}</span
            >
          </div>
        {/if}

        {#if $formData.status === 'Open' || $formData.status === 'Overdue'}
          <div class="text-lg text-muted-foreground">
            Debit:
            {#if $formData.debitNational > 0}
              <span class="text-primary">
                {$formData.debitNational ?? 0} MDL</span
              >
            {/if}
            <span class="text-muted-foreground">
              {$formData.debit?.toFixed(2) ?? 0} €</span
            >
          </div>
        {/if}
      </Card.Description>
    </Card.Header>
    <Card.Content>
      <div class="grid grid-cols-2 gap-4">
        <div>
          {#if $formData.positions?.length > 0}
            <div class="flex items-center my-2 space-x-2">
              <Badge>{$formData.positions.length}</Badge>
              <div>Positions</div>
            </div>
          {/if}
          {#if $formData.ordersRefs?.length > 0}
            <div class="flex items-center my-2 space-x-2">
              <Badge>{$formData.ordersRefs.length}</Badge>
              <div>Orders</div>
            </div>
          {/if}
        </div>
        <div>
          {#if $formData.currency && $formData.currency !== 'EUR'}
            <div class="flex justify-end items-center mb-4 space-x-2">
              {#if $formData.currency === 'MDL'}
                <div class="text-2xl font-semibold">
                  {currencyFormatter('MDL').format(sum?.sumNational ?? 0)}
                </div>
              {/if}
              <div class="hidden w-6 text-sm text-muted-foreground">
                Total {$formData.currency}
              </div>
            </div>
          {/if}
          <div class="flex justify-end items-center mb-4 space-x-2">
            <div class="text-2xl font-semibold">
              {currencyFormatter('EUR').format(sum?.sum ?? 0)}
            </div>
            <div class="w-6 text-sm text-muted-foreground">Total</div>
          </div>

          <div class="flex justify-end items-center space-x-2">
            <div class="text-md text-muted-foreground">
              {currencyFormatter('EUR').format(
                (sum?.sum ?? 0) - (sum?.tax ?? 0),
              )}
            </div>
            <div class="w-6 text-sm text-muted-foreground">Net</div>
          </div>
          <div class="flex justify-end items-center space-x-2">
            <div class="text-md text-muted-foreground">
              {currencyFormatter('EUR').format(sum?.tax ?? 0)}
            </div>
            <div class="w-6 text-sm text-muted-foreground">VAT</div>
          </div>
          {#if authStore.isLogistics()}
            <div
              class="flex justify-end items-center space-x-2 mt-2 border-t pt-2 mr-1"
            >
              <div class="text-xs text-muted-foreground">
                {currencyFormatter('EUR').format($formData?.supplierTotal ?? 0)}
              </div>
              <div class="w-6 text-xs text-muted-foreground">Supplier</div>
            </div>
            <div class="flex justify-end items-center space-x-2 mr-1">
              <div class="text-xs text-muted-foreground">
                {currencyFormatter('EUR').format($formData?.revenueTotal ?? 0)}
              </div>
              <div class="w-6 text-xs text-muted-foreground">Revenue</div>
            </div>
          {/if}
        </div>

        <div class="">
          <div class="text-sm text-muted-foreground">Invoice date</div>
          <HantaInputDate {form} name="invoiceDate" readOnly={isLocked} />
        </div>
        <div class="">
          <div class="text-sm text-muted-foreground">Due date</div>
          <HantaInputDate
            {form}
            name="paymentDeadline"
            readOnly={isLocked}
            placeholder="Pick a date"
            withOffset={true}
            offsets={[
              { label: '3 days', value: 3 },
              { label: '7 days', value: 7 },
              { label: '14 days', value: 14 },
              { label: '30 days', value: 30 },
              { label: '60 days', value: 60 },
              { label: '90 days', value: 90 },
            ]}
          />
        </div>
      </div>
    </Card.Content>
  </Card.Root>
</div>
