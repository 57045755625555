<script lang="ts">
  import * as Tabs from '$lib/components/ui/tabs';
  import { Switch } from '$lib/components/ui/switch';
  import { Label } from '$lib/components/ui/label';
  import PricelistTable from './pricelist-table.svelte';
  import { Button } from '$lib/components/ui/button';
  import Dimensions from './dimensions.svelte';
  import { toast } from 'svelte-sonner';
  import { createEventDispatcher } from 'svelte';

  const dispatch = createEventDispatcher();
  export let pricelist: any;

  const removePricelist = () => {
    if (confirm('Are you sure you want to remove this pricelist?')) {
      dispatch('remove', { name: pricelist.name });
      toast.success('Pricelist removed');
    }
  };

  function handleGeneratePriceGrid(event: CustomEvent) {
    const { priceEntries } = event.detail;

    // Initialize prices array if it doesn't exist
    if (!pricelist.prices) {
      pricelist.prices = [];
    }

    // Get active dimension names in correct order
    const dimensionCount = pricelist.dimensions.length;

    // Create new prices array
    const newPrices = priceEntries.map(entry => {
      // Create an array with empty strings
      // First 5 positions for price, min price, unit price, unit, percents, then dimensions
      const priceArray = new Array(5 + dimensionCount).fill('');

      priceArray[0] = ''; // price
      priceArray[1] = ''; // min price
      priceArray[2] = ''; // unit price
      priceArray[3] = ''; // unit
      priceArray[4] = ''; // percents

      // Fill in the dimension values at the end
      Object.entries(entry).forEach(([key, value]) => {
        const dimIndex = pricelist.dimensions.findIndex(
          dim => dim.name === key,
        );
        if (dimIndex >= 0) {
          // Add 5 to dimIndex to account for price, unit, operator, percents at start
          priceArray[dimIndex + 5] = value?.toString() || '';
        }
      });

      return priceArray;
    });

    // Force reactivity by creating a new array
    pricelist.prices = newPrices;

    // Force a refresh of the pricelist
    pricelist = { ...pricelist };
    toast.success('Prices updated successfully');
  }
</script>

{#if pricelist}
  {#key pricelist?.name}
    <div
      class="flex absolute right-0 -top-4 justify-between items-center mt-4 space-x-4"
    >
      <div class="flex items-center space-x-2">
        <Switch id="active" bind:checked={pricelist.active} />
        <Label for="active">Active</Label>
      </div>
      <Button variant="destructive" size="sm" on:click={removePricelist}
        >Remove Pricelist</Button
      >
    </div>
    <Tabs.Root value="dimensions">
      <Tabs.List>
        <Tabs.Trigger value="dimensions">Dimensions</Tabs.Trigger>
        <Tabs.Trigger value="prices">Prices</Tabs.Trigger>
      </Tabs.List>
      <Tabs.Content value="dimensions">
        <Dimensions
          bind:dimensions={pricelist.dimensions}
          on:generatePriceGrid={handleGeneratePriceGrid}
        />
      </Tabs.Content>
      <Tabs.Content value="prices">
        {#key `${pricelist.prices?.length}-${pricelist.dimensions?.map(d => d.name).join('-')}`}
          <PricelistTable
            dimensions={pricelist?.dimensions}
            bind:data={pricelist.prices}
          />
        {/key}
      </Tabs.Content>
    </Tabs.Root>
  {/key}
{/if}
