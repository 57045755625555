<script>
  import { onMount, onDestroy } from 'svelte';
  import jspreadsheet from 'jspreadsheet-ce';
  import 'jspreadsheet-ce/dist/jspreadsheet.css';
  import 'jsuites/dist/jsuites.css';
  import Button from '$lib/components/ui/button/button.svelte';

  export let columnHeaders = [];
  export let data = [];

  let jssInstance;
  let spreadsheetContainer;

  onMount(() => {
    jssInstance = jspreadsheet(spreadsheetContainer, {
      data,
      columns: columnHeaders,
      filters: false,
      contextMenu: false,
      copyCompatibility: true,
      tableOverflow: true,
      tableWidth: '100%',
      tableHeight: '100%',
      freezeColumns: 1,
      onbeforechange: (instance, cell, x, y, value) => {
        data[y][x] = value;
      },
    });

    return () => {
      if (jssInstance && typeof jssInstance.destroy === 'function') {
        jssInstance.destroy();
      }
    };
  });

  onDestroy(() => {
    if (jssInstance && typeof jssInstance.destroy === 'function') {
      jssInstance.destroy();
    }
  });

  function downloadSpreadsheet() {
    if (jssInstance && typeof jssInstance.download === 'function') {
      jssInstance.download();
    }
  }
</script>

<Button class="hidden" on:click={downloadSpreadsheet}>Download</Button>
<div bind:this={spreadsheetContainer} class="spreadsheet-container"></div>

<style>
  .spreadsheet-container {
    width: 100%;
    height: 100%;
    overflow: auto;
    position: relative;
  }

  .spreadsheet-container :global(.jexcel_container) {
    width: 100%;
    height: 100%;
  }

  .spreadsheet-container :global(.jexcel_content) {
    overflow: visible;
  }

  .spreadsheet-container :global(table.jexcel) {
    min-width: 100%;
  }
</style>
