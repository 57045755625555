<script lang="ts">
  import { Button } from '$lib/components/ui/button';
  import Icon from '@iconify/svelte';
  import NewPipelineDialog from './new-pipeline-dialog.svelte';
  import { pipelineStore } from '$lib/stores/pipeline-store';
  import type { Pipeline } from '$lib/types/pipeline';
  import { getAllRoutes } from '$lib/config/routes';

  export let onOpen: () => void;
  export let onCreate: () => void;
  const supportedModules = [
    'accounts',
    'deals',
    'contacts',
    'contracts',
    'issues',
    'activities',
    'orders',
    'leads',
    'tariffs',
    'payments',
    'expenses',
    'invoices',
  ];

  const moduleTypes = getAllRoutes().filter(m =>
    supportedModules.includes(m?.module),
  );

  let showNewPipelineDialog = false;
  let newPipeline: Pipeline = createEmptyPipeline();

  function createEmptyPipeline(): Pipeline {
    return {
      id: crypto.randomUUID(),
      name: '',
      module: moduleTypes[0].module,
      modifySource: false,
      sourceColumn: '',
      phases: [],
    };
  }

  async function handleCreatePipeline(phases: Array<string>) {
    // Create phases based on field values
    newPipeline.phases = phases.map((name, index) => ({
      id: crypto.randomUUID(),
      name,
      phaseIndex: index,
      pipelineId: newPipeline.id,
    }));

    const savedPipeline = await pipelineStore.addPipeline(newPipeline);
    showNewPipelineDialog = false;
    newPipeline = createEmptyPipeline();
    if (onCreate) {
      onCreate();
    }
  }
</script>

<Button
  variant="ghost"
  size="icon"
  on:click={() => (showNewPipelineDialog = true)}
>
  <Icon icon="mdi:plus" class="w-4 h-4" />
  <span class="sr-only">New Pipeline</span>
</Button>

<NewPipelineDialog
  bind:showDialog={showNewPipelineDialog}
  bind:pipeline={newPipeline}
  {moduleTypes}
  onOpen={() => {
    onOpen && onOpen();
  }}
  onClose={() => {
    showNewPipelineDialog = false;
    newPipeline = createEmptyPipeline();
  }}
  onCreate={handleCreatePipeline}
/>
