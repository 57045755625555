<script lang="ts">
  import ActivityActionBar from './activity-action-bar.svelte';
  import { Input } from '$lib/components/ui/input';
  import ActivitiesTabs from './activities-tabs.svelte';

  import { findActivities } from '$lib/api/queries';
  import ActivityItem from './activity-item.svelte';
  import { derived, writable } from 'svelte/store';
  import { createInfiniteQuery } from '@tanstack/svelte-query';
  import Icon from '@iconify/svelte';
  import { cn } from '$lib/utils/hanta-utils';
  import { type ActivityWithRefs } from '$db/schema';
  import ActivityIcon from './activity-icon.svelte';

  export let module = null;
  export let id = null;
  export let type = 'Email';
  export let search = '';
  export let withSearch = true;
  export let withTabs = true;
  export let hideLabels = false;
  export let withToolbar = false;
  export let activity: ActivityWithRefs | undefined;
  let pinned = undefined;
  let scroll;

  const moduleStore = writable(module);
  const idStore = writable(id);
  const typeStore = writable(type);
  const searchStore = writable(search);

  $: moduleStore.set(module);
  $: idStore.set(id);
  $: searchStore.set(search);
  $: typeStore.set(type);

  const itemsPerPage = 25;
  const query = createInfiniteQuery(
    derived(
      [moduleStore, idStore, typeStore, searchStore],
      ([$module, $id, $type, $search]) => ({
        enabled: !!$module,
        initialPageParam: 1,
        getNextPageParam: lastPage => {
          const { page, count } = lastPage;
          if (page * itemsPerPage < count) {
            return page + 1;
          } else {
            return undefined;
          }
        },
        queryKey: ['activities', $module, $id, $type, $search],
        queryFn: async ({ pageParam }) =>
          await findActivities({
            module: $module,
            id: $id,
            type: $type,
            search: $search,
            page: pageParam,
            itemsPerPage,
          }),
      }),
    ),
  );
</script>

<div class="flex relative flex-col md:p-2 p-4">
  {#if withToolbar}
    <div class="flex justify-end">
      <ActivityActionBar mode="collapsed" {activity} />
    </div>
  {/if}

  {#if withSearch}
    <div class="relative mt-4">
      <Icon
        icon="mdi:magnify"
        class="absolute left-2 top-2.5 text-muted-foreground size-5"
      />
      <Input
        bind:value={search}
        class="pl-8 mb-8 w-full shrink-0"
        placeholder="Search activities"
      />
    </div>
  {/if}

  {#if withTabs}
    <ActivitiesTabs bind:type {hideLabels} />
  {/if}

  <div>
    {#if $query.isFetching}
      <p class="">Loading...</p>
    {/if}
    {#if $query.isError}
      <p>Error: {$query.error.message}</p>
    {/if}
    {#if $query.isSuccess}
      {@const count = $query.data.pages[0].count}
      {#if count === 0}
        <p class="">No activities found.</p>
      {:else}
        {count} activities found.

        {#if pinned}
          TEST Pinned
        {/if}

        {#if pinned}
          Upcoming
        {/if}

        <div class="flex flex-col pb-12 w-full mt-6" bind:this={scroll}>
          {#each $query.data.pages || [] as { data: items, page }}
            {#each items || [] as item, idx}
              <div class="relative w-full pl-9 md:pl-16">
                {#if idx === 0 || (items[idx - 1] && new Date(item.startDate).toLocaleDateString( 'de', { year: 'numeric', month: 'long' }, ) !== new Date(items[idx - 1].startDate).toLocaleDateString( 'de', { year: 'numeric', month: 'long' }, ))}
                  <div
                    class={cn(
                      'sticky z-[1] py-2 mt-4 -top-6 bg-gradient-to-b from-primary-50 to-transparent',
                      withSearch && 'top-12',
                    )}
                  >
                    <div class="relative">
                      <div class="flex absolute inset-0 items-center">
                        <span
                          class="w-full border-t border-solid border-t-primary-200/20"
                        ></span>
                      </div>
                      <div
                        class="flex relative justify-center text-xs uppercase"
                      >
                        <span
                          class="px-2 w-32 text-center bg-secondary text-muted-foreground"
                        >
                          {#if new Date(item.startDate) > new Date()}
                            Upcoming
                          {:else}
                            {new Date(item.startDate).toLocaleDateString('de', {
                              year: 'numeric',
                              month: 'long',
                            })}
                          {/if}
                        </span>
                      </div>
                    </div>
                  </div>
                {/if}

                <div
                  class="absolute left-[1.125rem] top-0 h-full border-l border-l-primary/20"
                >
                  <div
                    class="absolute -left-[1.9rem] top-6 p-3 bg-primary rounded-full"
                  >
                    <ActivityIcon type={item.type} />
                  </div>
                </div>
                {#if idx === 0 && page === 0}
                  <div
                    class="absolute left-[1.125rem] top-0 bg-background w-1 h-6"
                  ></div>
                {/if}
                <div class="py-6">
                  <ActivityItem {item} {id} {module} compact={true} />
                </div>
              </div>
            {/each}
          {/each}
          <div>
            <!--InfiniteLoading
              on:infinite={async ({ detail: { loaded, complete } }) => {
                const result = await $query.fetchNextPage();
                console.debug(result);
                loaded();
                if (!result.hasNextPage) {
                  complete();
                }
              }}
            /-->

            <button
              class={cn(
                'w-full h-12 ',
                $query.hasNextPage &&
                  'hover:bg-primary-800 hover:text-primary-50',
              )}
              on:click={() => $query.fetchNextPage()}
              disabled={!$query.hasNextPage || $query.isFetchingNextPage}
            >
              {#if $query.isFetching}
                <div class="relative w-full h-full">
                  <div
                    class="absolute left-[1.125rem] top-0 w-0.5 h-full border-dashed border-l-primary border-l"
                  ></div>

                  <div
                    class="ml-4 hover:bg-secondary h-full flex items-center justify-center font-bold"
                  >
                    Loading
                    <Icon icon="mdi:loading" class="size-4 animate-spin" />
                  </div>
                </div>
              {:else if $query.hasNextPage}
                <div class="relative w-full h-full">
                  <div
                    class="absolute left-[1.125rem] top-0 w-0.5 h-full border-dashed border-l-primary/50 border-l"
                  ></div>

                  <div
                    class="ml-4 hover:bg-secondary h-full flex items-center justify-center font-bold"
                  >
                    Load More
                  </div>
                </div>
              {:else}
                <div class="relative">
                  <div class="text-xs text-muted-foreground">
                    Nothing more to load
                  </div>
                  <div
                    class="absolute left-[-0.75rem] -top-4 p-3 bg-primary rounded-full"
                  >
                    <Icon icon="mdi:check" class="size-4 text-secondary" />
                  </div>
                </div>
              {/if}
            </button>
          </div>
        </div>
      {/if}
    {/if}
  </div>
</div>
