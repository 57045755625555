<script lang="ts">
  import { onMount, createEventDispatcher } from 'svelte';
  import { supabase } from '$lib/api/supabase';
  import { authStore } from '$lib/stores/auth-store';
  import { get } from 'svelte/store';
  import { formatDate } from '$lib/app/utils';
  import Button from '$lib/components/ui/button/button.svelte';
  import * as Card from '$lib/components/ui/card';
  import { Loader2 } from 'lucide-svelte';
  import { currentUserStore } from '$lib/stores/current-user-store';
  import { toast } from 'svelte-sonner';
  import { formatCurrency } from '$lib/utils/hanta-utils';
  import Icon from '@iconify/svelte';
  import { popupStore } from '$lib/stores/app-store';
  import CrmAvatar from '$lib/components/hanta/crm-avatar.svelte';

  export let fiscalCode: string;
  export let amount: number;
  export let paymentId: string;

  const dispatch = createEventDispatcher();

  let loading = true;
  let invoices: any[] = [];
  let error: string | null = null;
  let linkingInvoiceId: string | null = null;

  let customer: any = {};

  async function fetchOpenInvoices() {
    if (!fiscalCode) {
      loading = false;
      return;
    }

    try {
      loading = true;
      error = null;

      // First, find accounts with matching fiscal code
      const { data: accountsData, error: accountsError } = await supabase(
        get(authStore)?.token,
        false,
      )
        .from('accounts')
        .select('id, name')
        .ilike('fiscalCode', `%${fiscalCode}%`);

      if (accountsError) throw accountsError;

      if (accountsData.length > 1) {
        toast.error('Multiple accounts found with this fiscal code');
        return;
      }

      customer = accountsData[0];

      if (!accountsData || accountsData.length === 0) {
        // No accounts found with this fiscal code
        invoices = [];
        loading = false;
        return;
      }

      // Extract account IDs
      const accountIds = accountsData.map(account => account.id);

      // Using batched queries for better performance with large datasets as specified in the sales reporting feature
      const batchSize = 1000;
      let lastId = null;
      let allInvoices: any[] = [];
      let hasMore = true;

      while (hasMore) {
        let query = supabase(get(authStore)?.token, false)
          .from('invoices')
          .select('*')
          .in('status', ['Open', 'Overdue'])
          .in('customer', accountIds)
          .order('paymentDeadline', { ascending: true })
          .limit(batchSize);

        // Add pagination if we have a lastId
        if (lastId) {
          query = query.gt('id', lastId);
        }

        const { data, error: fetchError } = await query;

        if (fetchError) {
          throw fetchError;
        }

        if (data && data.length > 0) {
          allInvoices = [...allInvoices, ...data];
          lastId = data[data.length - 1].id;
        }

        // If we got fewer results than the batch size, we've reached the end
        hasMore = data && data.length === batchSize;
      }

      // Sort by due date (oldest first)
      invoices = allInvoices.sort((a, b) => {
        return (
          new Date(a.paymentDeadline).getTime() -
          new Date(b.paymentDeadline).getTime()
        );
      });
    } catch (err: any) {
      console.error('Error fetching open invoices:', err);
      error = err.message || 'Failed to fetch open invoices';
    } finally {
      loading = false;
    }
  }

  async function linkPaymentToInvoice(invoiceId: string) {
    if (!paymentId || !invoiceId) {
      toast.error('Missing payment or invoice ID');
      return;
    }

    try {
      linkingInvoiceId = invoiceId;
      const userId = get(currentUserStore)?.id;
      const now = new Date().toISOString();

      // 1. Update the payment with the invoice ID
      const { error: updateError } = await supabase(
        get(authStore)?.token,
        false,
      )
        .from('payments')
        .update({
          invoiceId: invoiceId,
          modifiedAt: now,
          modifiedBy: userId,
        })
        .eq('id', paymentId);

      if (updateError) throw updateError;

      // 2. Get the invoice to update its balance
      const { data: invoiceData, error: invoiceError } = await supabase(
        get(authStore)?.token,
        false,
      )
        .from('invoices')
        .select('*')
        .eq('id', invoiceId)
        .single();

      if (invoiceError) throw invoiceError;

      // 3. Calculate new balance
      const currentBalance = parseFloat(
        invoiceData.balance || invoiceData.total || 0,
      );
      const newBalance = Math.max(0, currentBalance - amount);

      // 4. Update the invoice balance
      const { error: balanceError } = await supabase(
        get(authStore)?.token,
        false,
      )
        .from('invoices')
        .update({
          balance: newBalance,
          status: newBalance <= 0 ? 'Paid' : 'Partial',
          modifiedAt: now,
          modifiedBy: userId,
        })
        .eq('id', invoiceId);

      if (balanceError) throw balanceError;

      // 5. Notify parent component that the payment has been linked
      dispatch('paymentLinked', { invoiceId, paymentId });

      toast.success(
        `Payment linked to invoice ${invoiceData.refId || invoiceId.substring(0, 8)}`,
      );

      // 6. Refresh the list
      await fetchOpenInvoices();
    } catch (err: any) {
      console.error('Error linking payment to invoice:', err);
      toast.error(err.message || 'Failed to link payment to invoice');
    } finally {
      linkingInvoiceId = null;
    }
  }

  onMount(() => {
    fetchOpenInvoices();
  });
</script>

<div class="space-y-4">
  <h3 class="text-lg font-medium">
    Open Invoices for Customer
    {#if customer?.id}
      <CrmAvatar module="accounts" id={customer.id} />
    {/if}
  </h3>
  <div class="flex justify-between items-center">
    <p class="text-sm text-muted-foreground">
      Fiscal Code: <span class="font-medium"
        >{fiscalCode || 'Not provided'}</span
      >
    </p>
    <p class="text-sm text-muted-foreground">
      Payment Amount: <span class="font-medium"
        >{formatCurrency(amount, 'MDL')}</span
      >
    </p>
  </div>

  {#if loading}
    <div class="flex justify-center py-8">
      <Loader2 class="h-8 w-8 animate-spin text-primary" />
    </div>
  {:else if error}
    <div class="bg-destructive/20 p-4 rounded-md">
      <p class="text-destructive">{error}</p>
      <Button variant="outline" class="mt-2" on:click={fetchOpenInvoices}
        >Retry</Button
      >
    </div>
  {:else if invoices.length === 0}
    <Card.Root>
      <Card.Content class="pt-6">
        <p class="text-center text-muted-foreground">
          No open invoices found for this customer.
        </p>
      </Card.Content>
    </Card.Root>
  {:else}
    <div class="border rounded-md overflow-hidden">
      <table class="w-full">
        <thead>
          <tr class="bg-muted/50">
            <th class="text-left p-2 font-medium">Invoice #</th>
            <th class="text-left p-2 font-medium">Date</th>
            <th class="text-left p-2 font-medium">Payment Deadline</th>
            <th class="text-right p-2 font-medium">Amount</th>
            <th class="text-right p-2 font-medium">Balance</th>
            <th class="text-center p-2 font-medium">Action</th>
          </tr>
        </thead>
        <tbody>
          {#each invoices as invoice}
            <tr class="border-t hover:bg-muted/20">
              <td class="p-2">
                <Button
                  variant="link"
                  on:click={() => {
                    popupStore.openPopup('crmObject', {
                      module: 'invoices',
                      id: invoice.id,
                    });
                  }}
                >
                  {invoice.invoiceId}
                </Button>
              </td>
              <td class="p-2">{formatDate(invoice.invoiceDate)}</td>
              <td class="p-2">{formatDate(invoice.paymentDeadline)}</td>
              <td class="p-2 text-right"
                >{formatCurrency(invoice.grossNational, invoice.currency)}</td
              >
              <td class="p-2 text-right"
                >{formatCurrency(
                  invoice.balance || invoice.total,
                  invoice.currency,
                )}</td
              >
              <td class="p-2 text-center">
                {#if linkingInvoiceId === invoice.id}
                  <Button size="sm" variant="outline" disabled>
                    <Loader2 class="h-3 w-3 mr-1 animate-spin" />
                    Linking...
                  </Button>
                {:else}
                  <Button
                    size="sm"
                    variant={parseFloat(
                      invoice.grossNational || invoice.total,
                    ) <= amount
                      ? 'default'
                      : 'outline'}
                    class={Math.abs(amount - invoice.grossNational) < 1
                      ? 'bg-green-500'
                      : ''}
                    on:click={() => linkPaymentToInvoice(invoice.id)}
                  >
                    {parseFloat(invoice.grossNational || invoice.total) <=
                    amount
                      ? 'Pay in Full'
                      : 'Partial Payment'}
                  </Button>
                {/if}
              </td>
            </tr>
          {/each}
        </tbody>
      </table>
    </div>

    <div class="flex justify-between items-center text-sm">
      <div class="text-muted-foreground">
        Found {invoices.length} open invoice{invoices.length === 1 ? '' : 's'} for
        this customer.
      </div>
      <Button variant="outline" size="sm" on:click={fetchOpenInvoices}>
        <Icon icon="mdi:refresh" class="h-3 w-3 mr-1" />
        Refresh
      </Button>
    </div>
  {/if}
</div>
