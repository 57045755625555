<script lang="ts">
  import Icon from '@iconify/svelte';
  import { StatusBadge } from '$lib/components/ui/status-badge';
  import UsersSelector from '$lib/widgets/users/users-selector.svelte';
  import HantaInputText from '$lib/widgets/input/hanta-input-text.svelte';
  import HantaInputTextarea from '$lib/widgets/input/hanta-input-textarea.svelte';
  import { findOpenInvoices, getItemById } from '$lib/api/queries';
  import { createItem } from '$lib/api/mutations.js';
  import { appStore, contextStore } from '$lib/stores/app-store';
  import { Button, buttonVariants } from '$lib/components/ui/button/index.js';
  import * as Dialog from '$lib/components/ui/dialog/index.js';
  import { Input } from '$lib/components/ui/input/index.js';
  import { Label } from '$lib/components/ui/label/index.js';
  import HantaInputDate from '$lib/widgets/input/hanta-input-date.svelte';
  import {
    finalizeInvoice,
    saveInvoice,
    updateInvoiceStatus,
  } from '$lib/api/invoice-api';
  import { alertStore } from '$lib/stores/alert-store';
  import { formatDate } from '$lib/app/utils';
  import CrmAvatar from '$lib/components/hanta/crm-avatar.svelte';

  export let form;
  let { form: formData, enhance, errors } = form;

  export let readOnly;
  export let createOrder = undefined;
  export let type: 'Order' | 'Lead' = 'Lead';

  const statusTransitions = {
    DRAFT: { next: 'PROCESSING', label: 'Send for processing' },
    PROCESSING: { next: 'ARCHIVE', label: 'Send to archive', prev: 'DRAFT' },
    ARCHIVE: {},
  };

  let nextButtonDisabled = false;
  let loading = false;

  async function changeStatus(direction: 'next' | 'prev') {
    nextButtonDisabled = true;
    try {
      const currentStatus = $formData.status;
      let newStatus = statusTransitions[currentStatus]?.[direction];
      const bankTransfer = $formData.shipment.paymentMethod === 'Перечислением';

      if (newStatus) {
        $formData.status = newStatus;

        if (newStatus === 'ARCHIVE') {
          await addDealToInvoice();

          if ($formData.account?.creditRating !== 'Платит вовремя') {
            newStatus = 'PENDING';
            $formData.status = newStatus;
          }
        }
      }

      await form.submit();
      // 3-second delay for synchronisation with replica
      await new Promise(resolve => setTimeout(resolve, 3000));

      if (newStatus === 'ARCHIVE' || newStatus === 'PENDING') {
        let invoice = await getItemById('invoices', $formData.invoiceId);
        if (invoice) {
          invoice.billingMethod = $formData.shipment.paymentMethod;
          const savedInvoice = await saveInvoice(invoice);
          console.log('savedInvoice', savedInvoice);
          invoice = await getItemById('invoices', $formData.invoiceId);
          if (!bankTransfer) {
            await finalizeInvoice($formData.invoiceId);
            await updateInvoiceStatus($formData.invoiceId, 'Paid');
          }
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      nextButtonDisabled = false;
    }
  }

  async function addDealToInvoice() {
    try {
      loading = true;
      if ($formData.shipment.paymentMethod === 'Оплата другой стороной') {
        return;
      }

      const tag = $formData.shipment.direction;
      const invoices = await findOpenInvoices($formData.customer, tag);
      if (
        tag === 'IMPORT' ||
        $formData.shipment.paymentMethod !== 'Перечислением' ||
        invoices.length === 0
      ) {
        try {
          const newInvoice = await createItem('invoices', {
            name: 'New Invoice',
            status: 'Draft',
            customer: $formData.customer,
            positions: [],
            tags: [tag],
            invoiceDate: $formData.orderDate,
            currency: 'MDL',
          });

          // 5-second delay for synchronisation with replica
          await new Promise(resolve => setTimeout(resolve, 5000));

          $formData.invoiceId = newInvoice[0].id;
        } catch (e) {
          console.error(e);
          alertStore.show(
            'Failed to create invoice',
            e.message || 'Failed to save invoice',
            undefined,
            () => {},
          );
        }
      } else {
        $formData.invoiceId = invoices[0].id;
      }
    } finally {
      loading = false;
    }
  }

  let awbNumber = '';
  $: statusActions = statusTransitions[$formData.status] || {};
</script>

<div class="p-6 rounded-lg shadow-md bg-card">
  <h2 class="flex items-center mb-4 text-2xl font-semibold">
    <Icon icon="mdi:package" class="mr-2 size-6" />
    {type} Info
    {#if loading}
      <div class="animate-spin ml-2">
        <Icon icon="mdi:loading" class="w-4 h-4" />
      </div>
    {/if}
  </h2>
  <div class="space-y-4">
    <span class="text-muted-foreground">{$formData.id ?? '-'}</span>

    {#if type === 'Lead'}
      <div class="flex flex-col gap-2">
        <div class="text-muted-foreground flex items-center gap-2">
          Created at <span class="text-foreground"
            >{formatDate($formData.createdAt)}</span
          >
          {#if $formData.createdBy}
            by
            <CrmAvatar module="users" id={$formData.createdBy} asDiv={true} />
          {/if}
        </div>

        <div class="text-muted-foreground flex items-center gap-2">
          {#if $formData.modifiedBy}
            Modified at <span class="text-foreground"
              >{formatDate($formData.modifiedAt)}</span
            >
            {#if $formData.modifiedBy}
              by
              <CrmAvatar
                module="users"
                id={$formData.modifiedBy}
                asDiv={true}
              />
            {/if}
          {/if}
        </div>
      </div>
    {/if}

    <div class="flex items-center">
      <strong class="w-32 text-gray-600">{type} No:</strong>
      <span class="mr-2">{$formData.refId ?? $formData.name}</span>
    </div>

    <div class="flex items-center">
      <strong class="w-32 text-gray-600">Status:</strong>
      <div class="mr-4">
        <StatusBadge status={$formData.status} />
      </div>
      {#if type === 'Lead'}
        {#if $formData.status === 'NEW'}
          <Dialog.Root>
            <Dialog.Trigger class={buttonVariants({ variant: 'default' })}
              >Convert to Order</Dialog.Trigger
            >
            <Dialog.Content class="sm:max-w-[425px]">
              <Dialog.Header>
                <Dialog.Title>Convert to Order</Dialog.Title>
              </Dialog.Header>
              <div class="grid gap-4 py-4">
                <div class="grid grid-cols-4 gap-4 items-center">
                  <Label for="name" class="text-right">AWB Number</Label>
                  <Input id="name" bind:value={awbNumber} class="col-span-3" />
                </div>
              </div>
              <Dialog.Footer>
                <Button on:click={() => createOrder && createOrder(awbNumber)}
                  >Convert</Button
                >
              </Dialog.Footer>
            </Dialog.Content>
          </Dialog.Root>
        {/if}
      {:else}
        <div class="flex items-center flex-wrap gap-2">
          {#if statusActions.prev}
            <Button
              class="ml-2 w-full"
              variant="secondary"
              disabled={nextButtonDisabled}
              on:click={async () => await changeStatus('prev')}
            >
              Return
            </Button>
          {/if}
          {#if statusActions.next}
            <Button
              class="ml-2 w-full"
              variant="default"
              disabled={nextButtonDisabled}
              on:click={async () => await changeStatus('next')}
            >
              {statusActions.label}
            </Button>
          {/if}
        </div>
      {/if}
    </div>
    {#if type === 'Lead'}
      <HantaInputDate {form} name="date" label="Lead Date" {readOnly} />

      <HantaInputDate
        {form}
        name="expectedCloseDate"
        label="Expected Close Date"
        {readOnly}
      />
    {/if}

    {#if type === 'Order'}
      <HantaInputDate {form} name="orderDate" label="Order Date" {readOnly} />
      <HantaInputText {form} name="name" label="AWB Number" {readOnly} />

      <div class="flex items-center">
        {#if $formData.invoiceId}
          <Button
            variant="outline"
            on:click={() => {
              appStore.setCurrentModule('invoices');
              appStore.select({
                id: $formData.invoiceId,
                module: 'invoices',
              });
              /*
              contextStore.openPopup('crmObject', {
                module: 'invoices',
                id: $formData.invoiceId,
              });
              */
            }}
          >
            INVOICE
          </Button>
          <span class="ml-2 text-xs text-muted-foreground">
            {$formData.invoiceId}
          </span>
        {/if}
      </div>
    {/if}
    <UsersSelector
      bind:value={$formData.user}
      name="assignedTo"
      label="Registered by"
      multiple={false}
      {readOnly}
    />
    <HantaInputTextarea {form} name="notes" label="Notes" {readOnly} />
  </div>
</div>
