<script lang="ts">
  import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
  } from '$lib/components/ui/card';
  import { Label } from '$lib/components/ui/label';
  import { Input } from '$lib/components/ui/input';
  import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
  } from '$lib/components/ui/select';
  import HantaInputLovSelect from '$lib/widgets/input/hanta-input-lov-select.svelte';
  import HantaInputDate from '$lib/widgets/input/hanta-input-date.svelte';
  import HantaInputTextareaRichtext from '$lib/widgets/input/hanta-input-textarea-richtext/hanta-input-textarea-richtext.svelte';

  export let form;
  export let readOnly = false;

  let { form: formData, errors } = form;
</script>

<Card>
  <CardHeader>
    <CardTitle>
      Manifest Details

      <div class="text-muted-foreground text-xs mt-2 font-light">
        <div class="text-muted-foreground">
          <span class="font-semibold"> Manifest Nr: </span>
          {$formData.manifestNr ?? '-'}
        </div>
      </div>
    </CardTitle>
  </CardHeader>
  <CardContent class="space-y-4">
    <div class="grid grid-cols-3 gap-4">
      <div class="space-y-2">
        <Label for="name">Manifest Nr</Label>
        <Input
          id="name"
          bind:value={$formData.manifestNr}
          {readOnly}
          placeholder="Enter manifest number"
        />
        {#if $errors.manifestNr}
          <p class="text-red-500 text-sm">{$errors.manifestNr}</p>
        {/if}
      </div>
      <div class="space-y-2">
        <Label for="name">Tracking number</Label>
        <Input
          id="name"
          bind:value={$formData.name}
          {readOnly}
          placeholder="Enter tracking number"
        />
        {#if $errors.name}
          <p class="text-red-500 text-sm">{$errors.name}</p>
        {/if}
      </div>

      <div class="space-y-2">
        <HantaInputDate id="date" {readOnly} label="Date" {form} name="date" />
        {#if $errors.date}
          <p class="text-red-500 text-sm">{$errors.date}</p>
        {/if}
      </div>

      <div class=" space-y-2">
        <HantaInputLovSelect
          {form}
          bind:value={$formData.status}
          label="Status"
          type="Manifest > Status"
          multiple={false}
          addable={true}
          {readOnly}
        />

        {#if $errors.status}
          <p class="text-red-500 text-sm">{$errors.status}</p>
        {/if}
      </div>

      <div class="space-y-2">
        <Label for="category">Category</Label>
        <Input id="category" bind:value={$formData.category} {readOnly} />
        {#if $errors.category}
          <p class="text-red-500 text-sm">{$errors.category}</p>
        {/if}
      </div>

      <div class="space-y-2">
        <Label for="declarationNumber">Declaration Number</Label>
        <Input
          id="declarationNumber"
          bind:value={$formData.declarationNumber}
          {readOnly}
        />
        {#if $errors.declarationNumber}
          <p class="text-red-500 text-sm">{$errors.declarationNumber}</p>
        {/if}
      </div>
      <div class="space-y-2">
        <HantaInputLovSelect
          {form}
          bind:value={$formData.tags}
          type="Manifest > Tags"
          label="Tags"
          placeholder="Select a tag"
          multiple={true}
          addable={true}
          icon="mdi:tags"
        />
      </div>

      <div class="space-y-2 col-span-3">
        <HantaInputTextareaRichtext {form} name="description" rows={10} />
      </div>
    </div>

    <div class="grid grid-cols-4 gap-4">
      <div class="space-y-2">
        <Label for="destinationCity">Destination City</Label>
        <Input
          id="destinationCity"
          bind:value={$formData.destinationCity}
          {readOnly}
          placeholder="Enter destination city"
        />
        {#if $errors.destinationCity}
          <p class="text-red-500 text-sm">{$errors.destinationCity}</p>
        {/if}
      </div>

      <div class="space-y-2">
        <Label for="destinationCountry">Destination Country</Label>
        <Input
          id="destinationCountry"
          bind:value={$formData.destinationCountry}
          {readOnly}
          placeholder="Enter destination country"
        />
        {#if $errors.destinationCountry}
          <p class="text-red-500 text-sm">{$errors.destinationCountry}</p>
        {/if}
      </div>

      <div class="space-y-2">
        <Label for="originCountry">Origin Country</Label>
        <Input
          id="originCountry"
          bind:value={$formData.originCountry}
          {readOnly}
          placeholder="Enter origin country"
        />
        {#if $errors.originCountry}
          <p class="text-red-500 text-sm">{$errors.originCountry}</p>
        {/if}
      </div>
    </div>
    <div class="space-y-2 col-span-2">
      <Label for="destinationCity">Recipient Address</Label>
      <Input
        id="destinationCity"
        bind:value={$formData.recipientAddress}
        {readOnly}
        placeholder="Enter recipient address"
      />
      {#if $errors.recipientAddress}
        <p class="text-red-500 text-sm">{$errors.recipientAddress}</p>
      {/if}
    </div>

    <div class="grid grid-cols-2 gap-4">
      <div class="space-y-2">
        <Label for="recipientNameAndCompany">Recipient</Label>
        <Input
          id="recipientNameAndCompany"
          bind:value={$formData.recipientNameAndCompany}
          {readOnly}
          placeholder="Enter recipient name and company"
        />
        {#if $errors.recipientNameAndCompany}
          <p class="text-red-500 text-sm">{$errors.recipientNameAndCompany}</p>
        {/if}
      </div>
      <div class="space-y-2">
        <Label for="shipperNameAndCompany">Shipper</Label>
        <Input
          id="shipperNameAndCompany"
          bind:value={$formData.shipperNameAndCompany}
          {readOnly}
          placeholder="Enter shipper name and company"
        />
        {#if $errors.shipperNameAndCompany}
          <p class="text-red-500 text-sm">{$errors.shipperNameAndCompany}</p>
        {/if}
      </div>
    </div>

    <div class="grid grid-cols-3 gap-4">
      <div class="space-y-2">
        <Label for="weight">Weight</Label>
        <Input
          id="weight"
          type="number"
          bind:value={$formData.weight}
          {readOnly}
          placeholder="Enter weight"
          asFloat={true}
          step={0.01}
        />
        {#if $errors.weight}
          <p class="text-red-500 text-sm">{$errors.weight}</p>
        {/if}
      </div>

      <div class="space-y-2">
        <Label for="weightUnit">Unit</Label>
        <Select bind:value={$formData.weightUnit} {readOnly}>
          <SelectTrigger>
            <SelectValue placeholder="Select unit" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="KG">KG</SelectItem>
            <SelectItem value="LB">LB</SelectItem>
          </SelectContent>
        </Select>
        {#if $errors.weightUnit}
          <p class="text-red-500 text-sm">{$errors.weightUnit}</p>
        {/if}
      </div>
    </div>

    <div class="grid grid-cols-2 gap-4">
      <div class="space-y-2">
        <Label for="customsValue">Customs Value</Label>
        <Input
          id="customsValue"
          type="number"
          bind:value={$formData.customsValue}
          {readOnly}
          placeholder="Enter customs value"
          asFloat={true}
          step={0.01}
        />
        {#if $errors.customsValue}
          <p class="text-red-500 text-sm">{$errors.customsValue}</p>
        {/if}
      </div>
      <div class="space-y-2">
        <Label for="pcs">PCS</Label>
        <Input
          id="pcs"
          type="number"
          bind:value={$formData.packageCount}
          {readOnly}
          asFloat={true}
          step={0.01}
        />
        {#if $errors.packageCount}
          <p class="text-red-500 text-sm">{$errors.packageCount}</p>
        {/if}
      </div>
      <div class="space-y-2">
        <Label for="goods">Goods</Label>
        <Input
          id="goods"
          bind:value={$formData.commodityDescription}
          {readOnly}
        />
        {#if $errors.commodityDescription}
          <p class="text-red-500 text-sm">{$errors.commodityDescription}</p>
        {/if}
      </div>
      <div class="space-y-2">
        <Label for="recipientPhone">Phone</Label>
        <Input
          id="recipientPhone"
          bind:value={$formData.recipientPhone}
          {readOnly}
        />
        {#if $errors.recipientPhone}
          <p class="text-red-500 text-sm">{$errors.recipientPhone}</p>
        {/if}
      </div>
      <div class="space-y-2">
        <Label for="paymentPayorCode">Payment Payor Code</Label>
        <Input
          id="paymentPayorCode"
          bind:value={$formData.paymentPayorCode}
          {readOnly}
        />
        {#if $errors.paymentPayorCode}
          <p class="text-red-500 text-sm">{$errors.paymentPayorCode}</p>
        {/if}
      </div>

      <div class="space-y-2">
        <HantaInputLovSelect
          {form}
          bind:value={$formData.currencyCode}
          label="Currency"
          type="Currency"
          multiple={false}
          addable={true}
          {readOnly}
        />

        {#if $errors.currencyCode}
          <p class="text-red-500 text-sm">{$errors.currencyCode}</p>
        {/if}
      </div>
    </div>
  </CardContent>
</Card>
