<script lang="ts">
  import { Button } from '$lib/components/ui/button/index.js';
  import * as DropdownMenu from '$lib/components/ui/dropdown-menu/index.js';
  import { RangeCalendar } from '$lib/components/ui/range-calendar/index.js';
  import * as Popover from '$lib/components/ui/popover/index.js';
  import { cn } from '$lib/utils/hanta-utils.js';
  import { type DateRange } from './hanta-date-range';

  import Icon from '@iconify/svelte';
  import {
    DateFormatter,
    type DateValue,
    getLocalTimeZone,
    today,
    now,
    parseDate,
  } from '@internationalized/date';
  import { createEventDispatcher } from 'svelte';
  import Calendar from '$lib/components/ui/calendar/calendar.svelte';
  import { relativeDateRanges } from './relative-date-ranges';

  // Create event dispatcher
  const dispatch = createEventDispatcher();

  // Constants
  const DATE_FORMATTER = new DateFormatter('en-US', { dateStyle: 'medium' });
  const DEFAULT_END_DATE = today(getLocalTimeZone());
  const DEFAULT_START_DATE = DEFAULT_END_DATE.set({ month: 1, day: 1 });

  // Props
  export let showYearsToolbar = false;
  export let showMonthsToolbar = false;
  export let showQuartersToolbar = false;

  export let value: DateRange | undefined = {
    start: DEFAULT_START_DATE,
    end: DEFAULT_END_DATE,
  };
  export let selectedRelativeRange = 'Custom';

  // Local state
  let startValue: DateValue | undefined = value?.start;
  let endValue: DateValue | undefined = value?.end;
  let rangeTypeMode = 'closed'; // 'closed', 'from', 'until'
  let singleDateInput = '';

  // Selection tracking
  let selectedDateType: 'year' | 'quarter' | 'month' | null = null;
  let selectedYear: number | null = null;
  let selectedQuarter: number | null = null;
  let selectedMonth: number | null = null;

  // Helper functions
  function formatDateRange(range: DateRange): string {
    if (!range || (!range.start && !range.end)) return 'Pick a date';

    const formatDate = date => {
      if (!date) return '';
      if (typeof date === 'string') {
        return DATE_FORMATTER.format(new Date(date));
      }
      return DATE_FORMATTER.format(date.toDate(getLocalTimeZone()));
    };

    if (rangeTypeMode === 'from' && range.start) {
      return `From ${formatDate(range.start)}`;
    } else if (rangeTypeMode === 'until' && range.end) {
      return `Until ${formatDate(range.end)}`;
    } else {
      const start = range.start ? formatDate(range.start) : '';
      const end = range.end ? formatDate(range.end) : '';
      return end && start ? `${start} - ${end}` : start || end || 'Pick a date';
    }
  }

  function getYearRange(yearsAgo: number) {
    const year = today(getLocalTimeZone()).subtract({ years: yearsAgo });
    return {
      start: year.set({ month: 1, day: 1 }),
      end: year.set({ month: 12, day: 31 }),
    };
  }

  function getMonthRange(monthsAgo: number) {
    const currentDate = today(getLocalTimeZone());
    const targetDate = currentDate.subtract({ months: monthsAgo });
    return {
      start: targetDate.set({ day: 1 }),
      end: targetDate.set({
        day: new Date(targetDate.year, targetDate.month, 0).getDate(),
      }),
    };
  }

  function getQuarterRange(
    quarter: number,
    year: number = today(getLocalTimeZone()).year,
  ) {
    const startMonth = (quarter - 1) * 3 + 1; // Q1=1, Q2=4, Q3=7, Q4=10
    const endMonth = startMonth + 2;

    return {
      start: parseDate(`${year}-${startMonth.toString().padStart(2, '0')}-01`),
      end: parseDate(
        `${year}-${endMonth.toString().padStart(2, '0')}-${new Date(year, endMonth, 0).getDate()}`,
      ),
    };
  }

  // Handle relative date selection from dropdown
  function handleRelativeDateSelect(
    range: DateRange,
    type: string,
    label: string,
  ) {
    // Set the value and mode
    value = range;
    rangeTypeMode = type;
    selectedRelativeRange = label;

    // Update local state
    startValue = range.start;
    endValue = range.end;

    // Clear any selected date types
    selectedDateType = null;
    selectedYear = null;
    selectedQuarter = null;
    selectedMonth = null;

    value.meta = {
      relative: label,
    };

    // Dispatch change event
    dispatch('change', value);
  }

  // Handle year selection from toolbar
  function handleYearSelect(range: DateRange, year: number) {
    // Clear any previously selected quarters or months
    value = range;
    rangeTypeMode = 'closed';
    selectedRelativeRange = 'Custom';

    // Update local state
    startValue = range.start;
    endValue = range.end;

    // Track selection
    selectedDateType = 'year';
    selectedYear = year;
    selectedQuarter = null;
    selectedMonth = null;

    // Dispatch change event
    dispatch('change', value);
  }

  // Handle quarter selection from toolbar
  function handleQuarterSelect(
    range: DateRange,
    quarter: number,
    year: number,
  ) {
    // Clear any previously selected months
    value = range;
    rangeTypeMode = 'closed';
    selectedRelativeRange = 'Custom';

    // Update local state
    startValue = range.start;
    endValue = range.end;

    // Track selection
    selectedDateType = 'quarter';
    selectedYear = year;
    selectedQuarter = quarter;
    selectedMonth = null;

    // Dispatch change event
    dispatch('change', value);
  }

  // Handle month selection from toolbar
  function handleMonthSelect(range: DateRange, month: number, year: number) {
    value = range;
    rangeTypeMode = 'closed';
    selectedRelativeRange = 'Custom';

    // Update local state
    startValue = range.start;
    endValue = range.end;

    // Track selection
    selectedDateType = 'month';
    selectedYear = year;
    selectedMonth = month;

    // Dispatch change event
    dispatch('change', value);
  }

  // Handle date selection in "From" mode
  function handleFromDateSelect(date: DateValue) {
    if (!date) return;

    // Update value with new start date, keep end as undefined
    value = { start: date, end: undefined };
    startValue = date;

    // Clear any selected date types when custom date is selected
    selectedDateType = null;
    selectedYear = null;
    selectedQuarter = null;
    selectedMonth = null;

    // Update mode
    selectedRelativeRange = 'Custom';

    // Dispatch change event
    dispatch('change', value);
  }

  // Handle date selection in "Until" mode
  function handleUntilDateSelect(date: DateValue) {
    if (!date) return;

    // Update value with new end date, keep start as undefined
    value = { start: undefined, end: date };
    endValue = date;

    // Clear any selected date types when custom date is selected
    selectedDateType = null;
    selectedYear = null;
    selectedQuarter = null;
    selectedMonth = null;

    // Update mode
    selectedRelativeRange = 'Custom';

    // Dispatch change event
    dispatch('change', value);
  }

  // Handle date selection in closed range mode
  function handleClosedRangeSelect(e: CustomEvent<DateRange>) {
    // Update value with new range
    value = e.detail;

    // Clear any selected date types when custom date is selected
    selectedDateType = null;
    selectedYear = null;
    selectedQuarter = null;
    selectedMonth = null;

    // Update local state
    startValue = value.start;
    endValue = value.end;
    selectedRelativeRange = 'Custom';

    // Dispatch change event
    dispatch('change', value);
  }

  function formatDate(date) {
    if (!date) return '';
    if (typeof date === 'string') {
      return DATE_FORMATTER.format(new Date(date));
    }
    return DATE_FORMATTER.format(date.toDate(getLocalTimeZone()));
  }

  function getRangeTypeLabel() {
    if (rangeTypeMode === 'from') {
      return 'From Date';
    } else if (rangeTypeMode === 'until') {
      return 'Until Date';
    } else {
      return selectedRelativeRange;
    }
  }

  // Initialize range type and mode based on value
  $: {
    if (value && rangeTypeMode === undefined) {
      // Only run on initial load
      if (value.start && !value.end) {
        rangeTypeMode = 'from';
        selectedRelativeRange = 'From date...';
      } else if (!value.start && value.end) {
        rangeTypeMode = 'until';
        selectedRelativeRange = 'Until date...';
      } else {
        rangeTypeMode = 'closed';
        selectedRelativeRange = 'Custom';
      }
    }
  }

  // Update display text based on current value and mode
  $: displayText = value ? formatDateRange(value) : 'Pick a date';

  // Update range description based on current value and mode
  $: rangeDescription = (() => {
    if (!value) return '';

    if ((rangeTypeMode === 'from' && value.start) || !value.end) {
      return `Showing records from ${formatDate(value.start)} onwards`;
    } else if ((rangeTypeMode === 'until' && value.end) || !value.start) {
      return `Showing records until ${formatDate(value.end)}`;
    } else if (value.start && value.end) {
      return `Selected range: ${formatDate(value.start)} to ${formatDate(value.end)}`;
    }

    return '';
  })();
</script>

<div class="flex flex-col gap-2">
  {#if showYearsToolbar}
    <div class="flex gap-2">
      {#each [0, 1, 2, 3, 4].sort((a, b) => a - b) as yearsAgo}
        {@const year = today(getLocalTimeZone()).subtract({
          years: yearsAgo,
        }).year}
        <Button
          variant={selectedDateType === 'year' && selectedYear === year
            ? 'default'
            : 'outline'}
          size="sm"
          on:click={() => {
            const range = getYearRange(yearsAgo);
            handleYearSelect(range, year);
          }}
        >
          {year}
        </Button>
      {/each}
    </div>
  {/if}

  {#if showQuartersToolbar}
    <div class="flex flex-wrap gap-2">
      {#if !selectedYear}
        <!-- Show all quarters when no year is selected - newest first -->
        <!-- Current year quarters -->
        {#each [4, 3, 2, 1].filter(q => q <= Math.ceil(today(getLocalTimeZone()).month / 3)) as quarter}
          {@const currentYear = today(getLocalTimeZone()).year}
          <Button
            variant={(selectedDateType === 'quarter' &&
              selectedYear === currentYear &&
              selectedQuarter === quarter) ||
            (selectedDateType === 'year' && selectedYear === currentYear)
              ? 'default'
              : 'outline'}
            size="sm"
            on:click={() => {
              const range = getQuarterRange(quarter, currentYear);
              handleQuarterSelect(range, quarter, currentYear);
            }}
          >
            Q{quarter} '{currentYear.toString().slice(-2)}
          </Button>
        {/each}

        <!-- Previous year quarters -->
        {#each [4, 3, 2, 1] as quarter}
          {@const prevYear = today(getLocalTimeZone()).year - 1}
          <Button
            variant={(selectedDateType === 'quarter' &&
              selectedYear === prevYear &&
              selectedQuarter === quarter) ||
            (selectedDateType === 'year' && selectedYear === prevYear)
              ? 'default'
              : 'outline'}
            size="sm"
            on:click={() => {
              const range = getQuarterRange(quarter, prevYear);
              handleQuarterSelect(range, quarter, prevYear);
            }}
          >
            Q{quarter} '{prevYear.toString().slice(-2)}
          </Button>
        {/each}

        <!-- Two years ago quarters -->
        {#each [4, 3, 2, 1] as quarter}
          {@const prevYear2 = today(getLocalTimeZone()).year - 2}
          <Button
            variant={(selectedDateType === 'quarter' &&
              selectedYear === prevYear2 &&
              selectedQuarter === quarter) ||
            (selectedDateType === 'year' && selectedYear === prevYear2)
              ? 'default'
              : 'outline'}
            size="sm"
            on:click={() => {
              const range = getQuarterRange(quarter, prevYear2);
              handleQuarterSelect(range, quarter, prevYear2);
            }}
          >
            Q{quarter} '{prevYear2.toString().slice(-2)}
          </Button>
        {/each}
      {:else}
        <!-- Show only quarters from the selected year - in reverse order -->
        {#each [4, 3, 2, 1] as quarter}
          {@const currentYear = today(getLocalTimeZone()).year}
          {@const isCurrentYear = selectedYear === currentYear}
          {@const currentQuarter = Math.ceil(
            today(getLocalTimeZone()).month / 3,
          )}
          {#if !isCurrentYear || (isCurrentYear && quarter <= currentQuarter)}
            <Button
              variant={selectedDateType === 'quarter' &&
              selectedQuarter === quarter
                ? 'default'
                : 'outline'}
              size="sm"
              on:click={() => {
                const range = getQuarterRange(quarter, selectedYear);
                handleQuarterSelect(range, quarter, selectedYear);
              }}
            >
              Q{quarter} '{selectedYear.toString().slice(-2)}
            </Button>
          {/if}
        {/each}
      {/if}
    </div>
  {/if}

  {#if showMonthsToolbar}
    <div class="flex flex-wrap gap-2">
      {#if !selectedYear}
        <!-- Show all months when no year is selected - newest first -->
        <!-- Current year months - limit to 12 total months between current and previous year -->
        {#each Array.from({ length: Math.min(today(getLocalTimeZone()).month, 12) }, (_, i) => i).sort((a, b) => a - b) as monthsAgo}
          {@const currentDate = today(getLocalTimeZone()).subtract({
            months: monthsAgo,
          })}
          {@const month = currentDate.month}
          {@const year = currentDate.year}
          {@const quarter = Math.ceil(month / 3)}
          {@const monthName = new DateFormatter('en-US', {
            month: 'short',
          }).format(currentDate.toDate(getLocalTimeZone()))}
          <Button
            variant={(selectedDateType === 'month' &&
              selectedYear === year &&
              selectedMonth === month) ||
            (selectedDateType === 'quarter' &&
              selectedYear === year &&
              selectedQuarter === quarter) ||
            (selectedDateType === 'year' && selectedYear === year)
              ? 'default'
              : 'outline'}
            size="sm"
            on:click={() => {
              const range = getMonthRange(monthsAgo);
              handleMonthSelect(range, month, year);
            }}
          >
            {monthName} '{year.toString().slice(-2)}
          </Button>
        {/each}

        <!-- Previous year months - only show enough to make total 12 months -->
        {#if today(getLocalTimeZone()).month < 12}
          {#each [12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1]
            .slice(0, 12 - today(getLocalTimeZone()).month)
            .reverse() as month}
            {@const prevYear = today(getLocalTimeZone()).year - 1}
            {@const quarter = Math.ceil(month / 3)}
            {@const monthDate = parseDate(
              `${prevYear}-${month.toString().padStart(2, '0')}-01`,
            )}
            {@const monthName = new DateFormatter('en-US', {
              month: 'short',
            }).format(monthDate.toDate(getLocalTimeZone()))}
            <Button
              variant={(selectedDateType === 'month' &&
                selectedYear === prevYear &&
                selectedMonth === month) ||
              (selectedDateType === 'quarter' &&
                selectedYear === prevYear &&
                selectedQuarter === quarter) ||
              (selectedDateType === 'year' && selectedYear === prevYear)
                ? 'default'
                : 'outline'}
              size="sm"
              on:click={() => {
                const start = parseDate(
                  `${prevYear}-${month.toString().padStart(2, '0')}-01`,
                );
                const lastDay = new Date(prevYear, month, 0).getDate();
                const end = parseDate(
                  `${prevYear}-${month.toString().padStart(2, '0')}-${lastDay}`,
                );
                const range = { start, end };
                handleMonthSelect(range, month, prevYear);
              }}
            >
              {monthName} '{prevYear.toString().slice(-2)}
            </Button>
          {/each}
        {/if}
      {:else}
        <!-- Show only months from the selected year - in reverse order -->
        {#each [12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1] as month}
          {@const currentYear = today(getLocalTimeZone()).year}
          {@const isCurrentYear = selectedYear === currentYear}
          {#if !isCurrentYear || (isCurrentYear && month <= today(getLocalTimeZone()).month)}
            {@const quarter = Math.ceil(month / 3)}
            {@const monthDate = parseDate(
              `${selectedYear}-${month.toString().padStart(2, '0')}-01`,
            )}
            {@const monthName = new DateFormatter('en-US', {
              month: 'short',
            }).format(monthDate.toDate(getLocalTimeZone()))}
            <Button
              variant={(selectedDateType === 'month' &&
                selectedMonth === month) ||
              (selectedDateType === 'quarter' && selectedQuarter === quarter)
                ? 'default'
                : 'outline'}
              size="sm"
              on:click={() => {
                const start = parseDate(
                  `${selectedYear}-${month.toString().padStart(2, '0')}-01`,
                );
                const lastDay = new Date(selectedYear, month, 0).getDate();
                const end = parseDate(
                  `${selectedYear}-${month.toString().padStart(2, '0')}-${lastDay}`,
                );
                const range = { start, end };
                handleMonthSelect(range, month, selectedYear);
              }}
            >
              {monthName} '{selectedYear.toString().slice(-2)}
            </Button>
          {/if}
        {/each}
      {/if}
    </div>
  {/if}

  <div class="flex gap-2 flex-wrap">
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild let:builder>
        <Button
          builders={[builder]}
          variant="outline"
          class="w-[200px] justify-between"
        >
          {getRangeTypeLabel()}
          <Icon icon="mdi:chevron-down" class="ml-2 w-4 h-4" />
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content class="w-56">
        {#each relativeDateRanges as range}
          <DropdownMenu.Item
            on:click={() =>
              handleRelativeDateSelect(
                range.getValue(),
                range.type,
                range.label,
              )}
          >
            {range.label}
          </DropdownMenu.Item>
        {/each}
      </DropdownMenu.Content>
    </DropdownMenu.Root>

    <Popover.Root>
      <Popover.Trigger asChild let:builder>
        <Button
          variant="outline"
          class={cn(
            'w-[300px] justify-start text-left font-normal',
            !value && 'text-muted-foreground',
          )}
          builders={[builder]}
        >
          <Icon icon="mdi:calendar" class="mr-2 w-4 h-4" />
          {displayText}
        </Button>
      </Popover.Trigger>
      <Popover.Content class="p-0 w-auto" align="start">
        {#if rangeTypeMode === 'from'}
          <Calendar
            value={startValue}
            onValueChange={date => handleFromDateSelect(date)}
          />
        {:else if rangeTypeMode === 'until'}
          <Calendar
            value={endValue}
            onValueChange={date => handleUntilDateSelect(date)}
          />
        {:else}
          <RangeCalendar
            bind:value
            initialFocus
            numberOfMonths={2}
            on:select={handleClosedRangeSelect}
          />
        {/if}
      </Popover.Content>
    </Popover.Root>
  </div>

  {#if rangeDescription}
    <div class="text-sm text-muted-foreground">
      {rangeDescription}
    </div>
  {/if}
</div>
