<script lang="ts">
  import Icon from '@iconify/svelte';
  import { getItems } from '$lib/api/queries';

  import { appStore } from '$lib/stores/app-store';
  import { tariffStore } from './tariff-store';

  import Pricelists from './pricelists.svelte';
  import PricelistCountries from './pricelist-countries.svelte';

  import PageWithTitle from '../../ui/page-with-title.svelte';
  import Files from '$lib/components/hanta/files/files.svelte';
  import HantaInputTextarea from '$lib/widgets/input/hanta-input-textarea.svelte';
  import HantaInputText from '$lib/widgets/input/hanta-input-text.svelte';

  import { Button } from '$lib/components/ui/button';
  import { superForm } from 'sveltekit-superforms';
  import { zodClient } from 'sveltekit-superforms/adapters';
  import * as Tabs from '$lib/components/ui/tabs';
  import { createMutation, useQueryClient } from '@tanstack/svelte-query';

  import { saveTariff, createItem } from '$lib/api/mutations';
  import { insertTariffSchema, type Tariff } from '$db/schema';

  import Prices from '../prices/prices.svelte';
  import HantaInputLovSelect from '$lib/widgets/input/hanta-input-lov-select.svelte';
  import FormActions from '$lib/app/forms/form-actions.svelte';
  import TariffCustomers from './tariff-customers.svelte';

  const moduleName = 'tariffs';
  export let item;

  const client = useQueryClient();

  const duplicate = async () => {
    const tariff = {
      name: 'Copy_' + $formData.name,
      currency: $formData.currency,
      exceptions: $formData.exceptions || [],
      pricelists: $formData.pricelists || [],
      startDate: new Date(),
    };

    const data = await createItem('tariffs', tariff);
    appStore.select({ id: data[0].id, module: 'tariff' });
    appStore.openPopup(true);
  };

  const addMutation = createMutation({
    mutationFn: (variables): Promise<Tariff> =>
      Promise.resolve(saveTariff(variables)),
    onSuccess: () => client.invalidateQueries({ queryKey: [moduleName] }),
    onMutate: async newItem => {
      await client.cancelQueries({ queryKey: [moduleName, item.id] });
      const previousItems = client.getQueryData<Tariff[]>([
        moduleName,
        item.id,
      ]);

      client.setQueryData([moduleName, item.id], { ...newItem });

      return { previousItems };
    },
    onError: (err: any, variables: any, context: any) => {
      if (context?.previousItems) {
        client.setQueryData([moduleName], context.previousItems);
      }
    },
    onSettled: () => {
      client.invalidateQueries({ queryKey: [moduleName, item.id] });
    },
  });

  const form = superForm<Tariff>(
    {
      ...item,
      pricelists: item.pricelists || [],
      exceptions: item.exceptions || [],
    },
    {
      validators: zodClient(insertTariffSchema),
      resetForm: false,
      SPA: true,
      dataType: 'json',
      onSubmit: async () => {
        if (Object.keys($errors).length > 0) {
          console.error('Validation errors', $errors, { form: $formData });
          $errors = {};
          return;
        }

        console.debug('Form data', $formData);

        $addMutation.mutate($formData, {
          onError(error) {
            console.error('Error', error);
          },
          onSuccess: tariff => {
            tariffStore.setTariff(tariff);
          },
        });
      },
    },
  );

  const { form: formData, errors, enhance } = form;

  // Update store when item changes
  $: if (item) {
    tariffStore.setTariff(item);
  }

  // Use store for tab state
  $: currentTab = $tariffStore.selectedTab;
  $: if (currentTab !== $tariffStore.selectedTab) {
    tariffStore.setTab(currentTab);
  }

  let activity: ActivityWithRefs;
  $: activity = {
    name: '',
    description: '',
    accounts: [],
    deals: [],
    candidates: [],
    contacts: [],
    tariffs: [
      {
        id: item?.id,
        name: item?.name,
      },
    ],
  };

  let customers = [];
  $: if (false && item?.id) {
    getItems({
      collection: 'accounts',
      from: 0,
      to: 100,
      select: 'id, name, logo',
    }).then(result => {
      customers = result.data;
    });
  }
  let currentTab = 'pricelists';
</script>

<PageWithTitle>
  <div slot="content" class="p-1">
    <Tabs.Root
      value={currentTab}
      class="w-full"
      onValueChange={value => (currentTab = value)}
    >
      <Tabs.List>
        <Tabs.Trigger value="pricelists">Price Lists</Tabs.Trigger>
        <Tabs.Trigger value="exceptions">Exceptions</Tabs.Trigger>
        <Tabs.Trigger value="tables">Tables</Tabs.Trigger>
        <Tabs.Trigger value="files">Files</Tabs.Trigger>
        <Tabs.Trigger value="basic">Standard Rates</Tabs.Trigger>
        <Tabs.Trigger value="customer">Custom Rates</Tabs.Trigger>
      </Tabs.List>

      <Tabs.Content value="pricelists">
        {#if currentTab === 'pricelists'}
          <Pricelists
            bind:pricelists={$formData.pricelists}
            on:change={() => tariffStore.markDirty()}
          />
        {/if}
      </Tabs.Content>
      <Tabs.Content value="exceptions">
        {#if currentTab === 'exceptions' && $formData.exceptions}
          <PricelistCountries bind:data={$formData.exceptions} />
        {/if}
      </Tabs.Content>
      <Tabs.Content value="tables">
        {#if currentTab === 'tables'}
          <Prices customerTariff={item?.id} />
        {/if}
      </Tabs.Content>
      <Tabs.Content value="files">
        {#if currentTab === 'files'}
          <Files
            folder={`tariffs/${item?.id}`}
            compact={true}
            title=""
            maxColumns={1}
            uploadOnEnd={true}
          />
        {/if}
      </Tabs.Content>
      <Tabs.Content value="basic">
        {#if currentTab === 'basic'}
          <TariffCustomers baseTariff={item?.id} />
        {/if}
      </Tabs.Content>
      <Tabs.Content value="customer">
        {#if currentTab === 'customer'}
          <TariffCustomers customerTariff={item?.id} />
        {/if}
      </Tabs.Content>
    </Tabs.Root>
  </div>
  <div slot="sidebar">
    <form method="POST" use:enhance class="p-4 space-y-3">
      <Button variant="default" on:click={duplicate}>
        <Icon icon="mdi:content-copy" class="size-4" />
        <span> Duplicate</span>
      </Button>
      <HantaInputText {form} name="refId" label="id" />
      <div class="text-muted-foreground">{$formData.id}</div>
      <HantaInputText {form} name="name" label="Name" />

      <HantaInputLovSelect
        {form}
        bind:value={$formData.currency}
        name="currency"
        type="Tariff > Currency"
        label="Currency"
        multiple={false}
      />

      <HantaInputLovSelect
        {form}
        bind:value={$formData.type}
        name="type"
        type="Tariff > Type"
        label="Type"
        multiple={false}
      />

      <HantaInputTextarea
        {form}
        name="description"
        rows={10}
        label="Description"
      />

      <FormActions {form} />
    </form>
  </div>
</PageWithTitle>
