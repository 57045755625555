<script lang="ts">
  import { Popover, PopoverContent, PopoverTrigger } from './popover';
  import { Button } from './button';
  import colors from 'tailwindcss/colors';

  import Icon from '@iconify/svelte';
  import { cn } from '$lib/utils/hanta-utils';
  export let value: string = '';
  export let onChange: (color: string) => void;
  export let compact: boolean = false; // Add compact mode option

  const colorGroups = Object.entries(colors)
    .slice(5)
    .map(([name, shades]) => ({
      name,
      shades: Object.entries(shades).map(([shade, color]) => ({
        name: `${name}-${shade}`,
        value: color,
      })),
    }));

  let open = false;

  function handleSelect(colorValue: string) {
    value = colorValue;
    onChange(colorValue);
    open = false;
  }
</script>

<Popover bind:open>
  <PopoverTrigger asChild let:builder>
    {#if compact}
      <Button
        variant="ghost"
        size="sm"
        class="h-8 w-8 p-0 rounded-full"
        builders={[builder]}
        title="Select color"
      >
        <div class="h-6 w-6 rounded-full border shadow-sm" style:background-color={value || '#6366f1'} />
      </Button>
    {:else}
      <Button
        variant="outline"
        size="sm"
        class="w-[140px] justify-start text-left font-normal"
        builders={[builder]}
      >
        {#if value}
          <div class="flex items-center gap-2">
            <div class="h-4 w-4 rounded border" style:background-color={value} />
            <span class="truncate">{value}</span>
          </div>
        {:else}
          <span>Pick a color...</span>
        {/if}
      </Button>
    {/if}
  </PopoverTrigger>
  <PopoverContent class="w-64 p-3 z-[100]" side="top">
    <div class="space-y-3">
      {#each colorGroups as group}
        <div class="space-y-1.5">
          <div class="text-xs font-medium capitalize text-muted-foreground">
            {group.name}
          </div>
          <div class="grid grid-cols-11 gap-1">
            {#each group.shades as color}
              <button
                class={cn(
                  'h-4 w-4 rounded-sm ring-offset-background focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
                  value === color.value && 'ring-2 ring-ring ring-offset-2',
                )}
                style:background-color={color.value}
                on:click={() => handleSelect(color.value)}
                type="button"
              >
                {#if value === color.value}
                  <Icon icon="mdi:check" class="h-3 w-3 text-white" />
                {/if}
              </button>
            {/each}
          </div>
        </div>
      {/each}
    </div>
  </PopoverContent>
</Popover>
