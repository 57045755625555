<script lang="ts">
  import { Card } from '$lib/components/ui/card';
  import { getItems } from '$lib/api/queries';
  import { appStore } from '$lib/stores/app-store';
  import { onMount } from 'svelte';
  import { cn } from '$lib/utils/utils';

  let selectedCategory = '';
  let payments = [];

  onMount(() => {
    getItems({
      collection: 'payments',
      filters: $appStore.filters,
      from: 0,
      to: 1000, // Use batched queries for better performance with large datasets
    }).then(result => {
      payments = result.data;
    });
  });

  $: categories = payments
    ? [...new Set(payments?.map(p => p.paymentMethod || 'Uncategorized'))]
        .map(category => {
          const categoryPayments = payments.filter(
            p => (p.paymentMethod || 'Uncategorized') === category,
          );
          const total = categoryPayments.reduce(
            (sum, p) => sum + (p.amount || 0),
            0,
          );

          // Get payment statuses and their totals
          const statuses = [
            ...new Set(categoryPayments.map(p => p.status || 'Unknown')),
          ]
            .map(status => ({
              name: status,
              total: categoryPayments
                .filter(p => (p.status || 'Unknown') === status)
                .reduce((sum, p) => sum + (p.amount || 0), 0),
            }))
            .sort((a, b) => b.total - a.total);

          return {
            name: category,
            total,
            statuses,
          };
        })
        .sort((a, b) => b.total - a.total)
    : [];

  function formatCurrency(amount) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'EUR',
    }).format(amount);
  }

  function handleCategoryClick(category) {
    if (selectedCategory === category) {
      selectedCategory = '';
    } else {
      selectedCategory = category;
    }
  }
</script>

<div class="w-full space-y-8">
  <div class="flex justify-between items-center">
    <h2 class="text-3xl font-bold tracking-tight">Payments by Method</h2>
  </div>

  <div class="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
    {#each categories as category}
      <Card
        class={cn(
          'p-4 cursor-pointer hover:shadow-md transition-shadow',
          selectedCategory === category.name ? 'ring-2 ring-primary' : '',
        )}
        on:click={() => handleCategoryClick(category.name)}
      >
        <div class="flex justify-between items-start">
          <div>
            <h3 class="text-lg font-semibold">{category.name}</h3>
            <p class="text-sm text-muted-foreground">
              {category.statuses.length} payment status{category.statuses.length !== 1 ? 'es' : ''}
            </p>
          </div>
          <div class="text-xl font-bold">{formatCurrency(category.total)}</div>
        </div>

        {#if selectedCategory === category.name}
          <div class="mt-4 space-y-2">
            {#each category.statuses as status}
              <div class="flex justify-between items-center p-2 bg-muted rounded">
                <span>{status.name}</span>
                <span class="font-medium">{formatCurrency(status.total)}</span>
              </div>
            {/each}
          </div>
        {/if}
      </Card>
    {/each}
  </div>

  {#if payments.length === 0}
    <div class="text-center p-8">
      <p class="text-muted-foreground">No payments found</p>
    </div>
  {/if}
</div>
