<script lang="ts">
  import { dndzone, type DndEvent } from 'svelte-dnd-action';
  import PipelinePhaseCard from './pipeline-phase-card.svelte';
  import type { Phase } from '$lib/types/pipeline';
  import type { Pipeline } from '$lib/types/pipeline';
  import BuilderActions from './builder-actions.svelte';
  import ColorPicker from '$lib/components/ui/color-picker.svelte';
  import { fade, scale, fly } from 'svelte/transition';
  import { flip } from 'svelte/animate';
  import { quintOut } from 'svelte/easing';
  import { updatePipelinePhase } from '$lib/api/mutations';
  import { isLoading } from '$lib/stores/loading-store';
  import { error } from '$lib/stores/pipeline-ui-store';
  import { addPipelineItem, removePipelineItem } from '$lib/api/pipeline-api';
  import { Button } from '$lib/components/ui/button';
  import Icon from '@iconify/svelte';

  export let pipeline: Pipeline;
  export let isPreviewMode: boolean;
  export let onPhaseRemove: (id: string) => void;
  export let onPhaseReorder: (phases: Phase[]) => void;
  export let onPhaseAdd: () => void;
  export let onSavePipeline: () => void;

  const flipDurationMs = 300;

  function handleDndConsider(e: CustomEvent<DndEvent<Phase>>) {
    if (!e.detail.items) return;
    // Only update local reference, don't change parent yet
    const newPhases = [...e.detail.items];
    pipeline.phases = newPhases;
  }

  function handleDndFinalize(e: CustomEvent<DndEvent<Phase>>) {
    if (!e.detail.items) return;

    // Create a copy to prevent reference issues
    const updatedPhases = [...e.detail.items];

    // Update phases with correct sort values
    const reorderedPhases = updatedPhases.map((phase, index) => ({
      ...phase,
      sort: index,
      phaseIndex: index, // For backward compatibility
    }));

    // Update local reference
    pipeline.phases = reorderedPhases;

    // Then notify parent about the change using callback
    // Wrap in try/catch to handle case where callback is not defined
    try {
      if (typeof onPhaseReorder === 'function') {
        onPhaseReorder(reorderedPhases);

        // Comment: Changes are now tracked but not automatically saved
        // User must click Save Pipeline button to commit changes
      } else {
        console.warn('onPhaseReorder is not a function', onPhaseReorder);
      }
    } catch (error) {
      console.error('Error in onPhaseReorder:', error);
    }
  }

  $: phases = pipeline?.phases || [];

  async function onAddItem(phaseId: string, item: any) {
    try {
      isLoading.set(true);
      error.set(null);

      if (pipeline.modifySource) {
        const phase = pipeline.phases.find(p => p.id === phaseId);
        if (!phase) throw new Error('Phase not found');
        phase.items.push(item);
      } else {
        await addPipelineItem(phaseId, pipeline.module, item);
      }
    } catch (e) {
      error.set(e instanceof Error ? e.message : 'Failed to add item');
      console.error('Error adding item:', e);
    } finally {
      isLoading.set(false);
    }
  }

  async function onRemoveItem(phaseId: string, itemId: string) {
    try {
      isLoading.set(true);
      error.set(null);

      if (pipeline.modifySource) {
        const phase = pipeline.phases.find(p => p.id === phaseId);
        if (!phase) throw new Error('Phase not found');
        phase.items = phase.items.filter(item => item.id !== itemId);
      } else {
        await removePipelineItem(itemId);
      }
    } catch (e) {
      error.set(e instanceof Error ? e.message : 'Failed to remove item');
      console.error('Error removing item:', e);
    } finally {
      isLoading.set(false);
    }
  }

  async function handleColorChange(phaseId: string, color: string) {
    try {
      isLoading.set(true);
      const customFields = {
        ...pipeline.phases.find(p => p.id === phaseId).customFields,
        color,
      };

      // Wait for the API call to complete
      await updatePipelinePhase(phaseId, {
        customFields,
      });

      // Only update the UI after successful API call
      pipeline.phases = pipeline.phases.map(p =>
        p.id === phaseId ? { ...p, customFields } : p,
      );
    } catch (error) {
      console.error('Failed to update phase color:', error);
      // Optionally show error toast
    } finally {
      isLoading.set(false);
    }
  }

  async function handlePhaseRemove(phaseId: string) {
    try {
      isLoading.set(true);
      error.set(null);

      // Use the callback provided by parent component
      if (typeof onPhaseRemove === 'function') {
        await onPhaseRemove(phaseId);

        // Update local reference after successful removal
        pipeline.phases = (pipeline.phases || []).filter(
          phase => phase.id !== phaseId,
        );
      } else {
        console.warn('onPhaseRemove is not defined or not a function');
      }
    } catch (e) {
      error.set(e instanceof Error ? e.message : 'Failed to remove phase');
      console.error('Error removing phase:', e);
    } finally {
      isLoading.set(false);
    }
  }

  // Local function to notify changes that need saving
  function notifySaveNeeded() {
    if (onSavePipeline) {
      onSavePipeline();
    }
  }
</script>

{#if $error}
  <div
    role="alert"
    class="error-message"
    in:fly={{ y: -20, duration: 300, easing: quintOut }}
    out:fade={{ duration: 200 }}
  >
    <div class="flex items-center gap-2">
      <svg
        class="w-4 h-4"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
      {$error}
    </div>
  </div>
{/if}

<div class="mb-2">
  <BuilderActions {onPhaseAdd} onSave={notifySaveNeeded} />
</div>

<section
  use:dndzone={{ items: phases, flipDurationMs, type: 'phases' }}
  on:consider={handleDndConsider}
  on:finalize={handleDndFinalize}
  class="phases-container flex flex-wrap gap-5 p-2 min-h-[200px] relative transition-all"
  role="list"
  aria-label="Pipeline phases"
>
  {#each phases as phase, i (phase.id)}
    <div
      role="listitem"
      class="phase-item relative transition-transform"
      animate:flip={{ duration: flipDurationMs }}
    >
      {#if !isPreviewMode}
        <div
          class="absolute -top-2 -right-2 z-10 w-6 h-6 rounded-full bg-primary text-white flex items-center justify-center text-xs shadow-sm"
        >
          {i + 1}
        </div>
      {/if}

      <div class="group">
        <div
          class="absolute -top-2 -left-2 z-10 opacity-0 group-hover:opacity-100 transition-opacity"
        >
          {#if !isPreviewMode}
            <div
              class="w-6 h-6 rounded-full bg-muted/90 backdrop-blur-sm border-2 border-background shadow flex items-center justify-center cursor-move"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <circle cx="9" cy="7" r="1" />
                <circle cx="15" cy="7" r="1" />
                <circle cx="9" cy="12" r="1" />
                <circle cx="15" cy="12" r="1" />
                <circle cx="9" cy="17" r="1" />
                <circle cx="15" cy="17" r="1" />
              </svg>
            </div>
          {/if}
        </div>

        <PipelinePhaseCard
          {phase}
          {isPreviewMode}
          module={pipeline.module}
          onRemove={() => handlePhaseRemove(phase.id)}
          onAddItem={item => onAddItem(phase.id, item)}
          onRemoveItem={(phaseId, itemId) => onRemoveItem(phaseId, itemId)}
        >
          <ColorPicker
            value={phase.customFields?.color || ''}
            onChange={color => handleColorChange(phase.id, color)}
            compact={true}
          />
        </PipelinePhaseCard>
      </div>
    </div>
  {/each}
</section>

{#if phases.length === 0}
  <div
    class="empty-state flex flex-col items-center justify-center p-12 border-2 border-dashed border-muted rounded-xl bg-muted/5 min-h-[300px]"
    role="status"
    in:fade={{ duration: 200 }}
  >
    <svg
      class="w-16 h-16 text-muted-foreground/40 mb-4"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
        d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
      />
    </svg>
    <p class="text-lg font-medium text-foreground/80 text-center">
      No phases added yet
    </p>
    <p class="text-sm text-muted-foreground mt-2 max-w-md text-center">
      Click "Add Phase" to begin building your pipeline structure
    </p>
    <div class="mt-6">
      <Button
        on:click={onPhaseAdd}
        variant="default"
        size="sm"
        class="rounded-full px-5 gap-2"
      >
        <Icon icon="mdi:plus" class="w-4 h-4" />
        Add First Phase
      </Button>
    </div>
  </div>
{/if}

{#if $isLoading}
  <div
    class="loading-indicator"
    in:fly={{ y: 20, duration: 300, easing: quintOut }}
    out:fade={{ duration: 200 }}
  >
    <span>Processing</span>
    <div class="flex gap-1">
      <div class="loading-dot" style="animation-delay: 0ms" />
      <div class="loading-dot" style="animation-delay: 100ms" />
      <div class="loading-dot" style="animation-delay: 200ms" />
    </div>
  </div>
{/if}

<style lang="postcss">
  .phases-container {
    @apply relative flex flex-wrap gap-5 p-2 min-h-[200px] transition-all;
  }

  .phase-item {
    @apply relative transition-transform;
  }

  .empty-state {
    @apply flex flex-col items-center justify-center p-12 border-2 border-dashed border-muted rounded-xl bg-muted/5 min-h-[300px];
  }

  .error-message {
    @apply fixed top-4 right-4 bg-red-50 text-red-700 px-6 py-3 rounded-lg shadow-lg
           border border-red-200 backdrop-blur-sm;
  }

  .loading-indicator {
    @apply fixed bottom-4 right-4 bg-primary/90 text-white px-6 py-3 
           rounded-lg shadow-lg backdrop-blur-sm flex items-center gap-2;
  }

  .loading-dot {
    @apply w-2 h-2 bg-white rounded-full animate-pulse;
  }

  /* Custom scrollbar styles */
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #aaa;
  }

  ::-webkit-scrollbar-track {
    background-color: #f0f0f0;
    border-radius: 10px;
  }
</style>
