import { writable } from 'svelte/store';
import { appStore } from '$lib/stores/app-store';
import { derived } from 'svelte/store';

// Create the base store
const createTabStore = () => {
  const { subscribe, set, update } = writable<string>('overview');

  return {
    subscribe,
    set,
    update,
    // Reset to default based on mobile status
    reset: () => {
      const isMobile = get(appStore).isMobile;
      set(isMobile ? 'about' : 'overview');
    },
  };
};

export const currentTabStore = createTabStore();

// Optional: Create a derived store that automatically updates based on mobile status
export const defaultTab = derived(appStore, $appStore =>
  $appStore.isMobile ? 'about' : 'overview',
);
