<script lang="ts">
  import { onMount, createEventDispatcher } from 'svelte';
  import { supabase } from '$lib/api/supabase';
  import { authStore } from '$lib/stores/auth-store';
  import { get } from 'svelte/store';
  import { formatDate } from '$lib/app/utils';
  import Button from '$lib/components/ui/button/button.svelte';
  import * as Card from '$lib/components/ui/card';
  import { Loader2 } from 'lucide-svelte';
  import { currentUserStore } from '$lib/stores/current-user-store';
  import { toast } from 'svelte-sonner';
  import { formatCurrency } from '$lib/utils/hanta-utils';
  import Icon from '@iconify/svelte';
  import { popupStore } from '$lib/stores/app-store';
  import CrmAvatar from '$lib/components/hanta/crm-avatar.svelte';

  export let accountId: string;
  export let amount: number;
  export let remainingAmount: number;
  export let creditId: string;

  const dispatch = createEventDispatcher();

  let loading = true;
  let payments: any[] = [];
  let appliedPayments: any[] = [];
  let error: string | null = null;
  let linkingPaymentId: string | null = null;
  let undoingPaymentId: string | null = null;

  let customer: any = {};

  async function fetchPendingPayments() {
    if (!accountId) {
      loading = false;
      return;
    }

    try {
      loading = true;
      error = null;

      // Using batched queries for better performance with large datasets as specified in the sales reporting feature
      const batchSize = 1000;
      let lastId = null;
      let allPayments: any[] = [];
      let hasMore = true;

      while (hasMore) {
        let query = supabase(get(authStore)?.token, false)
          .from('payments')
          .select(
            `
    *, 
    invoice:invoices!inner(
      id, 
      name, 
      invoiceId, 
      account:accounts!inner(id, name)
    )
  `,
          )
          .or('status.eq.Pending,creditId.eq.' + creditId)
          .eq('invoice.account.id', accountId) // Use the foreign key column name directly
          .order('paymentDate', { ascending: true })
          .limit(batchSize);
        // Add pagination if we have a lastId
        if (lastId) {
          query = query.gt('id', lastId);
        }

        const { data, error: fetchError } = await query;

        if (fetchError) {
          throw fetchError;
        }

        if (data && data.length > 0) {
          allPayments = [...allPayments, ...data];
          lastId = data[data.length - 1].id;
        }

        // If we got fewer results than the batch size, we've reached the end
        hasMore = data && data.length === batchSize;
      }

      // Sort by due date (oldest first)
      appliedPayments = allPayments.filter(p => p.creditId === creditId);
      payments = allPayments.filter(p => p.status === 'Pending');
    } catch (err: any) {
      console.error('Error fetching pending payments:', err);
      error = err.message || 'Failed to fetch pending payments';
    } finally {
      loading = false;
    }
  }

  async function linkCreditToPayment(paymentId: string) {
    if (!creditId || !paymentId) return;

    try {
      linkingPaymentId = paymentId;
      const userId = get(currentUserStore)?.id;

      const { error } = await supabase(get(authStore)?.token, false).rpc(
        'apply_credit_to_payment',
        {
          paymentid: paymentId,
          creditid: creditId,
          userid: userId,
        },
      );

      if (error) throw error;

      await fetchPendingPayments();
      toast.success('Credit applied successfully');
    } catch (err) {
      toast.error(err.message || 'Failed to apply credit');
    } finally {
      linkingPaymentId = null;
    }
  }

  async function unlinkCreditFromPayment(paymentId: string) {
    try {
      undoingPaymentId = paymentId;

      const { error } = await supabase(get(authStore)?.token, false).rpc(
        'undo_credit_application',
        {
          paymentid: paymentId,
          creditid: creditId,
        },
      );

      if (error) throw error;

      await fetchPendingPayments();
      toast.success('Application reversed successfully');
    } catch (err) {
      toast.error(err.message || 'Failed to reverse application');
    } finally {
      undoingPaymentId = null;
    }
  }

  onMount(() => {
    fetchPendingPayments();
  });
</script>

<div class="space-y-4">
  <div class="flex justify-between items-center">
    <h3 class="text-lg font-medium">
      Pending Payments for Customer
      {#if accountId}
        <CrmAvatar module="accounts" id={accountId} />
      {/if}
    </h3>
    <Button variant="outline" size="sm" on:click={fetchPendingPayments}>
      <Icon icon="mdi:refresh" class="h-3 w-3 mr-1" />
      Refresh
    </Button>
  </div>

  <div class="flex justify-between items-center">
    <div class="text-sm text-muted-foreground"></div>
    <div class="text-sm text-muted-foreground space-x-4">
      <span
        >Total Amount: <span class="font-medium"
          >{formatCurrency(amount, 'MDL')}</span
        ></span
      >
      <span
        >Remaining: <span class="font-medium font-bold text-primary"
          >{formatCurrency(remainingAmount, 'MDL')}</span
        ></span
      >
    </div>
  </div>

  {#if loading}
    <div class="flex justify-center py-8">
      <Loader2 class="h-8 w-8 animate-spin text-primary" />
    </div>
  {:else if error}
    <div class="bg-destructive/20 p-4 rounded-md">
      <p class="text-destructive">{error}</p>
      <Button variant="outline" class="mt-2" on:click={fetchPendingPayments}
        >Retry</Button
      >
    </div>
  {:else if payments.length === 0 && appliedPayments.length === 0}
    <Card.Root>
      <Card.Content class="pt-6">
        <p class="text-center text-muted-foreground">
          No pending payments found for this customer.
        </p>
      </Card.Content>
    </Card.Root>
  {:else}
    {#if remainingAmount > 0}
      <div class="flex justify-between items-center">
        <h3 class="text-lg font-medium">Pending Payments</h3>

        <div class="text-sm text-muted-foreground">
          Total Amount of Pending Payments: <span
            class="font-medium text-primary"
            >{formatCurrency(
              payments.reduce((sum, payment) => sum + payment.amount, 0),
              'MDL',
            )}</span
          >
        </div>
      </div>
      <div class="text-sm text-muted-foreground">
        {#if !loading && !error}
          Found {payments.length} pending payment{payments.length === 1
            ? ''
            : 's'} for this customer.
        {/if}
      </div>
      <div class="border rounded-md overflow-hidden">
        <table class="w-full">
          <thead>
            <tr class="bg-muted/50">
              <th class="text-left p-2 font-medium">Payment Reference</th>
              <th class="text-left p-2 font-medium">Invoice #</th>
              <th class="text-left p-2 font-medium">Payment Date</th>
              <th class="text-right p-2 font-medium">Amount</th>
              <th class="text-center p-2 font-medium">Action</th>
            </tr>
          </thead>
          <tbody>
            {#each payments as payment}
              <tr class="border-t hover:bg-muted/20">
                <td class="p-2">{payment.id?.slice(0, 8)}</td>
                <td class="p-2">
                  <Button
                    variant="link"
                    on:click={() => {
                      popupStore.openPopup('crmObject', {
                        module: 'invoices',
                        id: payment.invoice.id,
                      });
                    }}
                  >
                    {payment.invoice.invoiceId}
                  </Button>
                </td>
                <td class="p-2">{formatDate(payment.paymentDate)}</td>
                <td class="p-2 text-right"
                  >{formatCurrency(payment.amount, 'MDL')}</td
                >
                <td class="p-2 text-center">
                  {#if linkingPaymentId === payment.id}
                    <Button size="sm" variant="outline" disabled>
                      <Loader2 class="h-3 w-3 mr-1 animate-spin" />
                      Linking...
                    </Button>
                  {:else}
                    <Button
                      size="sm"
                      variant="default"
                      class={remainingAmount === payment.amount
                        ? 'bg-green-500 hover:bg-green-600'
                        : ''}
                      on:click={() => linkCreditToPayment(payment.id)}
                    >
                      Apply Credit
                    </Button>
                  {/if}
                </td>
              </tr>
            {/each}
          </tbody>
        </table>
      </div>
    {/if}

    {#if appliedPayments.length > 0}
      <div class="space-y-4 mt-8">
        <div class="flex justify-between items-center mb-2">
          <h3 class="text-lg font-medium">Applied Payments</h3>
          <div class="text-sm text-muted-foreground">
            Total Applied: <span class="font-medium"
              >{formatCurrency(
                appliedPayments.reduce(
                  (sum, payment) => sum + payment.amount,
                  0,
                ),
                'MDL',
              )}</span
            >
          </div>
        </div>
        <div class="border rounded-md overflow-hidden">
          <table class="w-full">
            <thead>
              <tr class="bg-muted/50">
                <th class="text-left p-2 font-medium">Payment Reference</th>
                <th class="text-left p-2 font-medium">Invoice #</th>
                <th class="text-left p-2 font-medium">Applied Date</th>
                <th class="text-right p-2 font-medium">Amount</th>
                <th class="text-center p-2 font-medium">Action</th>
              </tr>
            </thead>
            <tbody>
              {#each appliedPayments as payment}
                <tr class="border-t hover:bg-muted/20">
                  <td class="p-2">{payment.id?.slice(0, 8)}</td>
                  <td class="p-2">
                    <Button
                      variant="link"
                      on:click={() => {
                        popupStore.openPopup('crmObject', {
                          module: 'invoices',
                          id: payment.invoice.id,
                        });
                      }}
                    >
                      {payment.invoice.invoiceId}
                    </Button>
                  </td>
                  <td class="p-2">{formatDate(payment.modifiedAt)}</td>
                  <td class="p-2 text-right">
                    {formatCurrency(payment.amount, 'MDL')}
                  </td>
                  <td class="p-2 text-center">
                    {#if undoingPaymentId === payment.id}
                      <Button size="sm" variant="outline" disabled>
                        <Loader2 class="h-3 w-3 mr-1 animate-spin" />
                        Undoing...
                      </Button>
                    {:else}
                      <Button
                        size="sm"
                        variant="destructive"
                        on:click={() => unlinkCreditFromPayment(payment.id)}
                      >
                        Undo
                      </Button>
                    {/if}
                  </td>
                </tr>
              {/each}
            </tbody>
          </table>
        </div>
      </div>
    {/if}

    <!-- Refresh button and payment count moved to the top -->
  {/if}
</div>
