import { authStore } from '$lib/stores/auth-store';
import { get } from 'svelte/store';
import { supabase } from './supabase';

export async function parseCv(contactId: string) {
  const { data, error } = await supabase(
    get(authStore)?.token,
  ).functions.invoke('ai-cv-parser', {
    body: {
      id: contactId,
    },
  });

  if (error) console.error(error);
  return data;
}
