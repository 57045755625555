<script lang="ts">
  import { tabs } from '$lib/config/crm-config';
  import * as Tabs from '$lib/components/ui/tabs';
  import Icon from '@iconify/svelte';
  import { getIconForActivityType } from '$lib/config/crm-config';
  import { appStore } from '$lib/stores/app-store';
  import HantaInputMultiSelectLean from '$lib/widgets/input/hanta-input-multi-select-lean.svelte';

  export let type = 'all';
  export let hideLabels = false;
</script>

{#if $appStore.isMobile}
  <HantaInputMultiSelectLean
    class="w-64"
    bind:value={type}
    options={tabs}
    multiple={false}
    placeholder="Filter by Type"
  >
    <div
      class="flex overflow-hidden items-center w-full h-full"
      let:index
      let:item
      slot="item"
    >
      {item.label}
    </div>
    <div
      class="flex overflow-hidden items-center w-full h-full"
      let:selection
      slot="selection"
    >
      {selection.label}
    </div>
  </HantaInputMultiSelectLean>
{:else}
  <Tabs.Root class="w-full" bind:value={type}>
    <Tabs.List class="bg-sky-50/50 w-fit">
      {#each tabs as tab}
        <Tabs.Trigger value={tab.value} class="flex items-center gap-2">
          {#if tab.value !== 'all'}
            <Icon icon={getIconForActivityType(tab.value)} class="size-4" />
          {/if}
          {#if !hideLabels}
            {tab.label}
          {:else if tab.value === 'all'}
            <span class="text-muted-foreground">All</span>
          {/if}
        </Tabs.Trigger>
      {/each}
    </Tabs.List>
    <Tabs.TabsContent value="all"></Tabs.TabsContent>
  </Tabs.Root>
{/if}
