<script lang="ts">
  import Button from '../../components/ui/button/button.svelte';
  import * as DropdownMenu from '$lib/components/ui/dropdown-menu/index';
  import { appStore } from '$lib/stores/app-store';
  import { currentUserStore } from '$lib/stores/current-user-store';
  import { ActivityType, type ActivityWithRefs } from '$db/schema';

  import Icon from '@iconify/svelte';

  type $$Props = {
    mode?: 'collapsed' | 'expanded' | 'button';
    activity?: ActivityWithRefs;
  };

  export let activity: ActivityWithRefs = {
    contacts: [],
    deals: [],
    accounts: [],
    candidates: [],
    issues: [],
  };

  export let mode = 'expanded';

  let ActivityEnums = ActivityType.enum;
  const actions = [
    { type: ActivityEnums.Todo, label: 'To-Do', icon: 'mdi:checkbox-marked' },
    { type: ActivityEnums.Email, label: 'E-Mail', icon: 'mdi:email' },
    {
      type: ActivityEnums.Note,
      label: 'Note',
      icon: 'mdi:sticky-note',
    },

    { type: ActivityEnums.Call, label: 'Call', icon: 'mdi:phone' },
    { type: ActivityEnums.LinkedIn, label: 'Message', icon: 'mdi:chat-bubble' },
    {
      type: ActivityEnums.Meeting,
      label: 'Meeting',
      icon: 'mdi:calendar-clock',
    },
    {
      type: ActivityEnums.Deadline,
      label: 'Deadline',
      icon: 'mdi:flag',
    },
    {
      type: ActivityEnums.Lunch,
      label: 'Lunch',
      icon: 'mdi:food-fork-drink',
    },
  ];

  const openActivity = (type: keyof typeof ActivityType.enum) => {
    appStore.openActivity({
      ...activity,
      startDate: new Date().toISOString(),
      dueDate: new Date().toISOString(),
      consultant: $currentUserStore?.id,
      consultantObj: {
        id: $currentUserStore?.id,
        name: $currentUserStore?.name,
        photo: $currentUserStore?.photo,
      },
      type,
    });
  };
</script>

{#if mode === 'expanded'}
  <div class="flex space-x-3 overflow-scroll">
    {#each actions as { type, label, icon }}
      <button
        on:click={() => openActivity(type)}
        class="flex flex-col items-center h-auto border-none outline-none group hover:bg-none"
      >
        <span
          class="p-3 bg-transparent rounded-full border border-solid transition-colors group-hover:bg-muted border-muted-foreground"
        >
          <Icon
            {icon}
            class="w-4 h-4 text-muted-foreground group-hover:text-accent-foreground"
          />
        </span>
        <span
          class="mt-1.5 text-xs text-muted-foreground group-hover:text-accent-foreground"
          >{label}</span
        >
      </button>
    {/each}
  </div>
{:else if mode === 'button'}
  <Button on:click={() => openActivity('Todo')} variant="default" class="gap-2">
    <Icon icon="mdi:plus" class="mr-2 w-4 h-4" />
    Create Activity
  </Button>
{:else}
  <DropdownMenu.Root>
    <DropdownMenu.Trigger asChild let:builder>
      <Button variant="default" builders={[builder]}>
        <Icon icon="mdi:plus" class="mr-2 w-4 h-4" />
        Add Activity
      </Button>
    </DropdownMenu.Trigger>
    <DropdownMenu.Content class="z-20 w-40" align="end">
      <DropdownMenu.Separator />
      {#each actions as { type, label, icon }}
        {#if type === ActivityEnums.Assignment && activity?.candidates?.length === 0}
          <!-- do nothing -->
        {:else}
          <DropdownMenu.Item on:click={() => openActivity(type)}>
            <Icon {icon} class="mr-4 w-4 h-4 text-muted-foreground" />
            {label}
          </DropdownMenu.Item>
        {/if}
      {/each}
    </DropdownMenu.Content>
  </DropdownMenu.Root>
{/if}
