<script lang="ts">
  import FiltersWithDrawer from './filters-with-drawer.svelte';
  import { createEventDispatcher } from 'svelte';
  import VirtualScroll from 'svelte-virtual-scroll-list';
  import { cn } from '$lib/utils/hanta-utils';
  import Icon from '@iconify/svelte';
  import { createInfiniteQuery } from '@tanstack/svelte-query';
  import { getItems } from '$lib/api/queries';
  import { derived, writable } from 'svelte/store';
  import Sorts from './sorts.svelte';
  import { Checkbox } from '$lib/components/ui/checkbox';
  import { debounce } from '$lib/utils/hanta-utils';
  import { Input } from '$lib/components/ui/input';
  import { Label } from '$lib/components/ui/label';
  import ListItem from './list-item.svelte';
  import SavedFilters from './saved-filters.svelte';
  import Button from '$lib/components/ui/button/button.svelte';

  const dispatch = createEventDispatcher();

  export let selected = [];
  export let current = undefined;
  export let module = 'accounts';
  export let sort = undefined;
  export let filters = undefined;
  export let count = 0;
  export let fulltextsearch = false;
  export let withFilters = true;
  export let avatarAsCheckbox = false;
  export let searchTerm = '';
  export let disabledIds = [];

  const debounceSearch = debounce(() => {
    search();
  }, 500);

  $: debounceSearch(searchTerm);

  function search() {
    dispatch('search', searchTerm);
  }

  const moduleStore = writable(module);
  const searchStore = writable(searchTerm);
  const fulltextsearchStore = writable(fulltextsearch);
  const sortStore = writable(sort);
  const filtersStore = writable(filters);

  $: searchStore.set(searchTerm);
  $: moduleStore.set(module);
  $: sortStore.set(sort);
  $: filtersStore.set(filters);
  $: fulltextsearchStore.set(fulltextsearch);

  $: count = $query?.data?.pages[0]?.count || 0;

  const itemsPerPage = 25;
  const query = createInfiniteQuery(
    derived(
      [moduleStore, searchStore, fulltextsearchStore, sortStore, filtersStore],
      ([
        $module,
        $searchTerm,
        $fulltextsearchStore,
        $sortStore,
        $filtersStore,
      ]) => ({
        enabled: !!$module,
        initialPageParam: 0,
        getNextPageParam: lastPage => {
          const { page, count } = lastPage;
          if ((page + 1) * itemsPerPage < count) {
            return page + 1;
          } else {
            return undefined;
          }
        },
        queryKey: [
          $module,
          $searchTerm,
          $fulltextsearchStore,
          $sortStore,
          $filtersStore,
        ],
        queryFn: ({ pageParam, signal }) => {
          return getItems(
            {
              collection: $module,
              from: pageParam === 0 ? 0 : pageParam * itemsPerPage + 1,
              to:
                pageParam === 0
                  ? itemsPerPage - 1
                  : (pageParam + 1) * itemsPerPage,
              search: $searchTerm,
              fulltextsearch: $fulltextsearchStore,
              sort: $sortStore,
              filters: $filtersStore,
              select: getSelectFields($module),
            },
            signal,
          );
        },
      }),
    ),
  );

  function getSelectFields(module) {
    switch (module) {
      case 'global':
        return 'id, _name';
      case 'accounts':
        return 'id, name, logo, address,linkedin,currentBalance';
      case 'activities':
        return 'id, name, status, type, startDate, dueDate, user:users(id, name),priority,accounts';
      case 'tariffs':
        return 'id, name, refId';
      case 'invoices':
        return 'id, name,billingMethod,status,invoiceDate,invoiceId,gross,customer:accounts(id, name),paidOn,tags';
      case 'leads':
        return 'id,refId,name,status,customer:accounts(id, name),date';
      case 'orders':
        return 'id,refId,name,status,customer:accounts(id, name, creditRating, debtReason),orderDate,totalAmountEUR';
      case 'deals':
        return 'id, name, accounts:accounts(id, name)';
      case 'issues':
        return 'refId, id, name, status, account:accounts(id, name)';
      case 'contracts':
        return 'refId, id, name, status, issuedOn, account:accounts!contracts_accountId_accounts_id_fk(id, name)';
      case 'contacts':
        return 'id, name, photo, firstname, lastname, position, linkedin';
      case 'balances':
        return 'id, name, amount,status,difference';
      case 'payments':
        return 'id, name, account:accounts!accountId(id, name),amount';
      case 'credits':
        return 'id, name,paymentDate,status,account:accounts(id, name),amount,remainingAmount';
      case 'expenses':
        return 'id, name, amount, category, subCategory, expenseDate';
      case 'manifests':
        return 'id, name,status,shipperNameAndCompany,recipientCompany,recipientNameAndCompany';
      default:
        return 'id, name';
    }
  }

  function getDefaultSort() {
    switch (module) {
      case 'invoices':
        return 'id';
      default:
        return 'id, name';
    }
  }

  async function exportToExcel() {
    const select =
      $moduleStore === 'invoices'
        ? '*,customer:accounts(id, name, salesRep:users(id, name))'
        : '*';
    const csvData = await getItems({
      collection: $moduleStore,
      filters: $filtersStore,
      sort: $sortStore,
      asCsv: true,
      select,
    });

    let csv = csvData.data;

    // Create a blob from the CSV data
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

    // Create a download link
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);

    link.setAttribute('href', url);
    link.setAttribute(
      'download',
      `${module}_export_${new Date().toISOString().split('T')[0]}.csv`,
    );
    link.style.visibility = 'hidden';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
</script>

<div
  class={cn(
    withFilters &&
      'px-2 supports-[backdrop-filter]:bg-background/60 shadow-md pb-2',
  )}
>
  {#if withFilters}
    <SavedFilters
      {module}
      {searchTerm}
      {fulltextsearch}
      {sort}
      currentFilters={filters}
      on:filters={event => {
        if (event.detail.filters) {
          dispatch('filters', event.detail.filters);
        }
        if (event.detail.search !== undefined) {
          searchTerm = event.detail.search;
          dispatch('search', event.detail.search);
        }
        if (event.detail.fulltextsearch !== undefined) {
          fulltextsearch = event.detail.fulltextsearch;
        }
        if (event.detail.sort !== undefined) {
          sort = event.detail.sort;
          dispatch('sort', event.detail.sort);
        }
      }}
    />
    <div class="mt-1 flex z-[9999] space-x-1 items-center justify-between">
      <FiltersWithDrawer
        collection={module}
        {filters}
        on:filters={event => dispatch('filters', event.detail)}
      />

      <div class="flex space-x-1">
        {#if module === 'orders' || module === 'invoices' || module === 'leads' || module === 'issues' || module === 'expenses' || module === 'activities' || module === 'manifests'}
          <Button variant="ghost" on:click={exportToExcel} size="sm">
            <Icon icon="vscode-icons:file-type-excel2" />
          </Button>
        {/if}
        <Sorts
          collection={module}
          bind:sort
          on:sort={event => dispatch('sort', event.detail)}
        />
      </div>
    </div>
  {/if}
  <div class="relative space-y-2">
    <Icon
      icon="mdi:magnify"
      class="absolute left-2 top-3 w-4 h-4 text-muted-foreground"
    />

    <Input placeholder="Search" class="pl-8" bind:value={searchTerm} />

    {#if module === 'contacts'}
      <div class="flex items-center pt-2 pb-0">
        <Checkbox
          id="fts"
          class="bg-primary-800"
          bind:checked={fulltextsearch}
        />
        <Label
          for="fts"
          class="ml-4 text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
        >
          Full text search
        </Label>
      </div>
    {/if}
  </div>
</div>

<div class={cn(module === 'contacts' ? 'vs-contacts' : 'vs')}>
  {#if $query.isError}
    <p>Error: {$query.error.message}</p>
  {:else if $query.isSuccess}
    {#if $query.data?.pages[0]?.count === 0}
      <div class="flex flex-col justify-center items-center">
        <p class="mt-2 text-xl font-thin text-muted-foreground">
          Nothing found
        </p>
        <Icon
          icon="mdi:file-search"
          class="mt-8 opacity-50 text-muted-foreground"
        />
      </div>
    {:else}
      {@const items = $query.data.pages?.map(el => el.data).flat()}
      <VirtualScroll
        data={items}
        key="id"
        on:bottom={() => {
          if ($query.hasNextPage) {
            $query.fetchNextPage();
          }
        }}
        let:data={item}
        let:index
      >
        <button
          data-testid={`list-item-${index}`}
          class={cn(
            'w-full overflow-hidden flex flex-col items-start gap-2 rounded-lg border-solid border p-3 text-left text-sm transition-all hover:bg-muted  mb-1 group',
            'border-l-4',
            current?.id === item.id
              ? 'bg-muted border-l-primary'
              : 'border-border',
          )}
          on:click={() => {
            dispatch('current', { module, id: item.id });
          }}
        >
          <div class="flex flex-col gap-1 w-full">
            <div
              class="text-muted-foreground flex items-center font-semibold leading-none tracking-tight"
            >
              <ListItem
                {item}
                {module}
                withSelection={avatarAsCheckbox}
                {disabledIds}
                {selected}
                on:select={e => {
                  dispatch('select', e.detail);
                  e.stopPropagation();
                }}
              />
            </div>
          </div>
        </button>
      </VirtualScroll>
    {/if}
  {/if}

  {#if $query.isFetching}
    <div
      class="absolute bottom-2 left-0 right-0 z-[999999] w-full h-1 bg-gradient-to-r from-blue-500 via-purple-500 to-blue-500 bg-[length:200%_100%] animate-[loading_2s_ease-in-out_infinite] mx-2 shadow-2xl"
    ></div>
  {/if}
</div>

<style>
  .vs {
    height: calc(100vh - 210px);
    overflow: hidden;
    @apply shadow-sm border border-border bg-background rounded-sm;
  }
  .vs-contacts {
    height: calc(100vh - 240px);
    overflow: hidden;
  }

  @media (max-width: 600px) {
    .vs {
      height: calc(100svh - 180px);
    }
    .vs-contacts {
      height: calc(100svh - 240px);
    }
  }

  .border-top {
    width: 100%;
    border-top-width: 1px;
    border-top-style: solid;
    border-color: rgb(229 231 235 / var(--tw-border-opacity));
  }
</style>
