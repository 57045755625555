<script lang="ts">
  import Icon from '@iconify/svelte';
  import HantaInputNumber from '$lib/widgets/input/hanta-input-number.svelte';
  import PriceService from './price-service.svelte';

  export let form;
  export let readOnly = false;
  let { form: formData, enhance, errors } = form;

  export let billedWeight;

  function handleRateChange(event) {
    if (readOnly) {
      return;
    }
    $formData.shipment.rate = event.detail.rate;
  }
</script>

<div class="p-6 rounded-lg shadow-md bg-card dark:bg-gray-800">
  <h2
    class="flex items-center mb-4 text-2xl font-semibold text-gray-900 dark:text-gray-100"
  >
    <Icon icon="mdi:cash-usd" class="mr-2" />
    Financial Details
  </h2>

  <HantaInputNumber
    {form}
    {readOnly}
    name="shipment.manualDiscount"
    label="Manual discount in % (0-100)"
    asFloat={true}
    type="number"
    min={0}
    max={100}
    step={0.1}
  />

  <h1>Price Service : {readOnly ? 'YES' : 'NO'}</h1>
  {#if !readOnly}
    <PriceService
      bind:lead={$formData.shipment}
      supplierTarifId={$formData.account?.supplierTariff?.id}
      customerTarifId={$formData.account?.customerTariff?.id}
      baseTarifId={$formData.account?.baseTariff?.id}
      refDate={$formData.shipment?.date ||
        $formData.orderDate ||
        $formData.date ||
        $formData.createdAt}
      weight={billedWeight}
      on:rateChange={handleRateChange}
    />
  {/if}

  <div
    class="grid grid-cols-1 gap-3 p-4 my-8 text-2xl rounded-lg shadow-md bg-card/10 dark:bg-gray-700/10"
  >
    <div
      class="bg-blue-50 dark:bg-blue-900/30 p-4 rounded-lg border-l-4 border-blue-500"
    >
      <p class="text-sm text-blue-600 dark:text-blue-400 font-medium">
        Total EUR
      </p>
      <p class="text-2xl font-bold text-gray-900 dark:text-gray-100">
        {$formData.shipment?.totalEUR ?? 0} EUR
      </p>
    </div>

    <div
      class="bg-amber-50 dark:bg-amber-900/30 p-4 rounded-lg border-l-4 border-amber-500"
    >
      <p class="text-sm text-amber-600 dark:text-amber-400 font-medium">
        Total National
      </p>
      <p class="text-2xl font-bold text-gray-900 dark:text-gray-100">
        {$formData.shipment?.totalNational ?? 0} MDL
      </p>
    </div>

    {#if $formData.shipment?.rate?.discountTotalEur}
      <div
        class="bg-red-50 dark:bg-red-900/30 p-4 rounded-lg border-l-4 border-red-500"
      >
        <p class="text-sm text-red-600 dark:text-red-400 font-medium">
          Total Discount
        </p>
        <p class="text-2xl font-bold text-gray-900 dark:text-gray-100">
          {-1 * ($formData.shipment?.rate?.discountTotalEur ?? 0).toFixed(2)}
          EUR
        </p>
      </div>
    {/if}
  </div>
</div>
