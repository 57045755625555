<script lang="ts">
  import { Button } from '$lib/components/ui/button/index.js';
  import Icon from '@iconify/svelte';
  import * as Popover from '$lib/components/ui/popover/index.js';
  import { type ActivityWithRefs } from '$db/schema';
  import { type SuperForm } from 'sveltekit-superforms';
  import Badge from '$lib/components/ui/badge/badge.svelte';
  import ActivityRefsContent from './activity-refs-content.svelte';

  export let form: SuperForm<ActivityWithRefs>;
  export let onSelectChanged: (
    value: { id: string; name: string; role: string }[],
  ) => void = () => {};
  export let withPopover = true;

  let selected = 'all';
  const { form: formData } = form;

  $: all = [
    'contacts',
    'deals',
    'accounts',
    'candidates',
    'issues',
    'leads',
    'orders',
  ].reduce((acc, key) => {
    const array = $formData[key] || [];
    return [...acc, ...array];
  }, []);
</script>

{#if withPopover}
  <Popover.Root portal={null}>
    <Popover.Trigger asChild let:builder>
      <Button
        builders={[builder]}
        class="p-0 my-1 font-bold hover:bg-transparent group"
        on:click={() => {
          selected = 'all';
        }}
        variant="ghost"
      >
        <span
          class="transition-colors group-hover:text-foreground text-muted-foreground"
          >Associated with
          <Badge class="mx-2" variant="secondary">{all?.length ?? 0}</Badge>
          records
        </span>
      </Button>
    </Popover.Trigger>
    <Popover.Content class="w-full md:w-[650px] overflow-visible p-0">
      <ActivityRefsContent
        {form}
        {onSelectChanged}
        {selected}
        setSelected={value => (selected = value)}
      />
    </Popover.Content>
  </Popover.Root>
{:else}
  <ActivityRefsContent
    {form}
    {onSelectChanged}
    {selected}
    setSelected={value => (selected = value)}
  />
{/if}
