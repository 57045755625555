<script lang="ts">
  import { createQuery } from '@tanstack/svelte-query';
  import { getAccountOrders } from '$lib/api/queries';
  import { appStore } from '$lib/stores/app-store';
  import Icon from '@iconify/svelte';
  import { newItemStore } from '$lib/stores/new-item-store';

  import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
  } from '$lib/components/ui/table';
  import { Button } from '$lib/components/ui/button';
  import { formatCurrency } from '$lib/utils/hanta-utils';

  import { popupStore } from '$lib/stores/app-store';
  import { formatDate } from 'date-fns';
  import { formatDateForPrint } from '$lib/app/utils';

  export let accountId: string;

  const ordersQuery = createQuery({
    queryKey: ['accountOrders', accountId],
    queryFn: () => getAccountOrders(accountId),
    enabled: !!accountId,
  });

  $: ({ data: orders, isLoading, isError, error } = $ordersQuery);

  function getStatusColor(status: string) {
    switch (status.toUpperCase()) {
      case 'NEW':
        return 'bg-blue-100 text-blue-800';
      case 'QUALIFIED':
        return 'bg-green-100 text-green-800';
      case 'PROPOSAL':
        return 'bg-yellow-100 text-yellow-800';
      case 'NEGOTIATION':
        return 'bg-purple-100 text-purple-800';
      case 'CLOSED WON':
        return 'bg-green-500 text-white';
      case 'CLOSED LOST':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  }

  function createNewItem() {
    if ($appStore.currentModule) {
      newItemStore.createItem('orders');
    }
    newItemStore.openDialog();
  }
</script>

<Button
  on:click={createNewItem}
  variant="outline"
  data-testid="nav-new-item"
  class="my-2"
>
  <Icon icon="mdi:plus" class="size-4 text-primary" aria-hidden="true" />
  <span class="ml-2">Create Order</span>
  <span class="ml-auto text-xs text-muted-foreground hidden">⌘K</span>
</Button>
{#if isLoading}
  <p>Loading orders...</p>
{:else if isError}
  <p>Error loading orders: {error.message}</p>
{:else if orders && orders.length > 0}
  <Table>
    <TableHeader>
      <TableRow>
        <TableHead>Nr</TableHead>
        <TableHead>Actions</TableHead>

        <TableHead>Id</TableHead>

        <TableHead>Date</TableHead>
        <TableHead>Status</TableHead>
        <TableHead>AWB</TableHead>
        <TableHead>Direction</TableHead>
        <TableHead>Operator</TableHead>
        <TableHead>Product</TableHead>
        <TableHead>Country</TableHead>
        <TableHead>Weight</TableHead>
        <TableHead>Total</TableHead>
        <TableHead>Total MDL</TableHead>
      </TableRow>
    </TableHeader>
    <TableBody>
      {#each orders as order, num}
        <TableRow>
          <TableCell>{num + 1}</TableCell>
          <TableCell>
            <Button
              variant="outline"
              size="sm"
              on:click={() => {
                popupStore.openPopup('crmObject', {
                  id: order.id,
                  module: 'orders',
                });
              }}>View</Button
            >
          </TableCell>
          <TableCell class="text-xs text-muted-foreground"
            >{order.refId ?? ''}</TableCell
          >
          <TableCell>{formatDateForPrint(order.orderDate)}</TableCell>

          <TableCell>
            <span
              class={`px-2 py-1 rounded-full text-xs font-medium ${getStatusColor(order.status)}`}
            >
              {order.status}
            </span>
          </TableCell>

          <TableCell>{order.name}</TableCell>

          <TableCell>{order.shipment?.direction}</TableCell>
          <TableCell>{order.shipment?.operator}</TableCell>
          <TableCell>{order.shipment?.product}</TableCell>
          <TableCell>{order.shipment?.country}</TableCell>
          <TableCell>{order.shipment?.weight?.toFixed(2)} kg</TableCell>
          <TableCell>
            {order.shipment?.rate?.totalEUR
              ? formatCurrency(order.shipment?.rate?.totalEUR)
              : '-'}</TableCell
          >
          <TableCell>
            {order.shipment?.totalNational ?? '-'}
            <span class="text-xs text-muted-foreground">MDL</span></TableCell
          >
        </TableRow>
      {/each}
    </TableBody>
  </Table>
{:else}
  <p>No leads found for this account.</p>
{/if}
