<script lang="ts">
  import DealsCard from './deals-card.svelte';
  import TimelineCard from './timeline-card.svelte';
  import Prices from '../prices/prices.svelte';

  import * as Tabs from '$lib/components/ui/tabs';
  import { appStore } from '$lib/stores/app-store';
  import { authStore } from '$lib/stores/auth-store';
  import Activities from '../activities/activities.svelte';
  import { cn } from '$lib/utils';

  let currentTab = 'timeline';
</script>

{#if $appStore.isMobile}
  <div
    class="grid-cols-1 gap-4 overflow-scroll tn-grid h-[calc(100vh-7rem)] pt-12"
  >
    {#if authStore.isLogistics()}
      <div class="col-span-1 pr-6 w-full text-lg p-1">
        <Prices
          withMdl={true}
          withCalculator={true}
          currency="MDL"
          refDate={new Date()}
        />
      </div>
    {:else if authStore.isHeadhunter()}
      <DealsCard class="w-full min-h-96" />
    {/if}
    <div
      class={cn(
        'mt-4 overflow-scroll pt-4 px-4',
        authStore.isLogistics()
          ? 'col-span-1'
          : authStore.isHeadhunter()
            ? 'col-span-2'
            : 'col-span-3',
      )}
    >
      <Activities
        module="dashboard"
        search={''}
        type=""
        withSearch={false}
        withTabs={true}
        hideLabels={true}
      />
    </div>
  </div>
{:else}
  <div
    class="grid-cols-1 gap-4 overflow-scroll tn-grid md:grid-cols-2 xl:grid-cols-3 h-[calc(100vh-7rem)] pt-12"
  >
    {#if authStore.isLogistics()}
      <div class="col-span-2 pr-6 w-full text-lg p-1">
        <Prices
          withMdl={true}
          withCalculator={true}
          currency="MDL"
          refDate={new Date()}
        />
      </div>
    {:else if authStore.isHeadhunter()}
      <DealsCard class="w-full min-h-96" />
    {/if}
    <div
      class={cn(
        'mt-4  overflow-scroll pt-4 px-4',
        authStore.isLogistics()
          ? 'col-span-1'
          : authStore.isHeadhunter()
            ? 'col-span-2'
            : 'col-span-3',
      )}
    >
      <Activities
        module="dashboard"
        search={''}
        type=""
        withSearch={false}
        withTabs={true}
        hideLabels={true}
      />
    </div>
    <!--TimelineCard class="col-span-2 w-full xl:col-span-1" /-->
  </div>
{/if}
