<script lang="ts">
  import { onMount } from 'svelte';
  import { superForm } from 'sveltekit-superforms';
  import { zodClient } from 'sveltekit-superforms/adapters';
  import { tweened } from 'svelte/motion';
  import { cubicOut } from 'svelte/easing';
  import { toast } from 'svelte-sonner';
  import { createMutation, useQueryClient } from '@tanstack/svelte-query';
  import { alertStore } from '$lib/stores/alert-store';
  import { appStore, popupStore } from '$lib/stores/app-store';

  import PageWithTitle from '$lib/ui/page-with-title.svelte';
  import * as Tabs from '$lib/components/ui/tabs';
  import { Label } from '$lib/components/ui/label';
  import * as Card from '$lib/components/ui/card';
  import Files from '$lib/components/hanta/files/files.svelte';
  import FormActions from '$lib/app/forms/form-actions.svelte';

  import HantaInputText from '$lib/widgets/input/hanta-input-text.svelte';
  import HantaInputNumber from '$lib/widgets/input/hanta-input-number.svelte';
  import HantaInputDate from '$lib/widgets/input/hanta-input-date.svelte';
  import HantaInputLovSelect from '$lib/widgets/input/hanta-input-lov-select.svelte';
  import HantaInputTextareaRichtext from '$lib/widgets/input/hanta-input-textarea-richtext/hanta-input-textarea-richtext.svelte';
  import AccountSelector from '$lib/widgets/accounts/account-selector.svelte';
  import Button from '$lib/components/ui/button/button.svelte';
  import { formatDate } from '$lib/app/utils';
  import PaymentOpenInvoices from './payment-open-invoices.svelte';
  import { formatCurrency } from '$lib/utils/hanta-utils';
  import CrmObjectSelector from '$lib/widgets/crm-object-selector.svelte';

  export let item: any = {}; // Replace with proper type when available
  const moduleName = 'payments';
  const queryClient = useQueryClient();

  // Mock function for now - replace with actual implementation
  async function savePayment(payment) {
    // This would be replaced with an actual API call
    console.log('Saving payment:', payment);
    return payment;
  }

  const mutation = createMutation({
    mutationFn: (data: any) => savePayment(data),
    onSuccess: () => {
      toast.success('Payment saved successfully');
      queryClient.invalidateQueries({ queryKey: [moduleName] });
      appStore.closeActivity();
    },
    onError: (error: any) => {
      alertStore.show(
        'Error Saving Payment',
        error.message || 'Failed to save payment',
      );
    },
  });

  const form = superForm(item, {
    dataType: 'json',
    validators: zodClient({}), // Replace with proper schema
    resetForm: false,
    SPA: true,
    onSubmit: async () => {
      try {
        if (Object.keys($errors).length > 0) {
          alertStore.show(
            'Validation Error',
            'Please check the form for errors',
          );
          console.error('Validation errors', $errors);
          $errors = {};
          return;
        }

        $mutation.mutate($formData);
      } catch (e) {
        console.error('Error saving payment', e);
        toast.error(e.message || 'An error occurred while saving');
      }
    },
  });

  let { form: formData, enhance, errors } = form;
  let currentTab = 'details';

  $: amount = tweened($formData.amount || 0, {
    duration: 500,
    easing: cubicOut,
  });
  $: isLocked =
    $formData.status === 'Processed' || $formData.status === 'Completed';

  $: metadata = JSON.parse($formData.metadata || '{}');
  $: debitFiscalCode = metadata?.clientInfo?.debitFiscalCode || '';
  $: creditFiscalCode = metadata?.clientInfo?.creditFiscalCode || '';
</script>

<PageWithTitle>
  <div slot="short-card" class="w-full p-4">
    <HantaInputText {form} name="name" label="Payment Name" hideLabel={true} />
  </div>

  <div slot="content" class="grid grid-cols-3 p-1">
    <div class="col-span-3">
      <div class="mt-4">
        <Tabs.Root bind:value={currentTab} class="w-full">
          <Tabs.List>
            <Tabs.Trigger value="details">Details</Tabs.Trigger>
            <Tabs.Trigger value="metadata">Metadata</Tabs.Trigger>
            <Tabs.Trigger value="files">Files</Tabs.Trigger>
          </Tabs.List>

          <Tabs.Content value="details">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
              <div class="space-y-4">
                <Card.Root>
                  <Card.Header>
                    <Card.Title>Payment Information</Card.Title>
                  </Card.Header>
                  <Card.Content>
                    <div class="grid grid-cols-2 gap-2">
                      <div>
                        <Label>Reference ID</Label>
                        <div>{$formData.refId || 'N/A'}</div>
                      </div>
                      <div>
                        <Label>Amount</Label>
                        <div class="text-lg font-bold">
                          {formatCurrency($amount, 'MDL')}
                        </div>
                      </div>
                      <div>
                        <Label>Payment Date</Label>
                        <div>{formatDate($formData.paymentDate) || 'N/A'}</div>
                      </div>
                      <div>
                        <Label>Status</Label>
                        <div class="capitalize">
                          {$formData.status || 'Pending'}
                        </div>
                      </div>
                    </div>
                  </Card.Content>
                </Card.Root>
              </div>

              <div class="space-y-4">
                <Card.Root>
                  <Card.Header>
                    <Card.Title>Additional Details</Card.Title>
                  </Card.Header>
                  <Card.Content>
                    <div class="prose prose-sm dark:prose-invert text-base">
                      {#if $formData.description}
                        {@html $formData.description}
                      {:else}
                        <p class="text-muted-foreground">
                          No additional details provided
                        </p>
                      {/if}
                    </div>
                  </Card.Content>
                </Card.Root>
              </div>
              <Card.Root class="col-span-2">
                <Card.Header>
                  <Card.Title>Invoice Information</Card.Title>
                </Card.Header>
                <Card.Content>
                  {#if $formData.invoice}
                    <div class="grid grid-cols-2 gap-2">
                      <div>
                        <Label>Invoice Id</Label>
                        <div>{$formData.invoice.name || 'N/A'}</div>
                      </div>

                      <Button
                        variant="default"
                        on:click={() => {
                          popupStore.openPopup('crmObject', {
                            module: 'invoices',
                            id: $formData.invoice.id,
                          });
                        }}
                      >
                        View Invoice
                      </Button>

                      <div>
                        <Label>Invoice internal ID</Label>
                        <div>{$formData.invoice.id || 'N/A'}</div>
                      </div>
                    </div>
                  {:else if $formData.name !== 'Payment to CORST GRUP SRL'}
                    <p class="text-red-500 my-4">
                      This payment is not associated with any invoice
                    </p>

                    <PaymentOpenInvoices
                      fiscalCode={debitFiscalCode || creditFiscalCode}
                      amount={$formData.amount}
                      paymentId={$formData.id}
                      on:paymentLinked={() => {
                        // Reload the page to reflect the changes
                        window.location.reload();
                      }}
                    />
                  {:else}
                    <p class="text-muted-foreground">
                      No invoice associated with this payment
                    </p>
                  {/if}
                </Card.Content>
              </Card.Root>
            </div>
          </Tabs.Content>

          <Tabs.Content value="metadata">
            <pre class="text-xs font-mono">{JSON.stringify(
                metadata,
                null,
                2,
              )}</pre>
          </Tabs.Content>
          <Tabs.Content value="files">
            <div class="my-4">
              <Label>Files</Label>
              <Files
                folder={`payments/${item?.id}`}
                compact={true}
                title=""
                maxColumns={2}
                uploadOnEnd={true}
              />
            </div>
          </Tabs.Content>
        </Tabs.Root>
      </div>
    </div>
  </div>

  <div slot="sidebar">
    <form method="POST" use:enhance class="p-4 space-y-3">
      <HantaInputText
        {form}
        name="refId"
        label="Reference ID"
        disabled={isLocked}
      />
      <HantaInputText
        {form}
        name="name"
        label="Payment Name"
        disabled={isLocked}
      />
      <HantaInputNumber
        {form}
        name="amount"
        label="Amount in MDL"
        disabled={isLocked}
      />

      <HantaInputLovSelect
        {form}
        bind:value={$formData.paymentMethod}
        type="Payment > Method"
        label="Payment Method"
        multiple={false}
        addable={true}
        disabled={isLocked}
      />

      <HantaInputLovSelect
        {form}
        bind:value={$formData.status}
        type="Payment > Status"
        label="Status"
        multiple={false}
        addable={false}
      />

      <CrmObjectSelector
        module="accounts"
        bind:value={$formData.account}
        label="Account"
        withOpenButton={true}
      />

      <HantaInputDate
        {form}
        name="paymentDate"
        label="Payment Date"
        disabled={isLocked}
      />

      <HantaInputTextareaRichtext
        {form}
        name="description"
        rows={6}
        disabled={isLocked}
      />

      <FormActions {form} />
    </form>
  </div>
</PageWithTitle>
