<script lang="ts">
  import { guardFinanceAccess, guardSalesAccess } from '$lib/auth/guards';

  export let rate;
  export let form;

  let { form: formData, enhance, errors } = form;
</script>

{#if rate && (guardFinanceAccess() || guardSalesAccess())}
  <div
    class="mt-6 p-6 bg-white dark:bg-gray-800 rounded-lg shadow-fuse border border-gray-200 dark:border-gray-700 space-y-6"
  >
    <!-- Rate Details Section -->
    <div class="space-y-4">
      <h2
        class="text-xl font-bold text-gray-900 dark:text-gray-100 border-b-2 border-blue-500 pb-2"
      >
        Rate Details
      </h2>

      <div class="space-y-4">
        <div
          class="overflow-x-auto rounded-lg border border-gray-200 dark:border-gray-700"
        >
          <table class="w-full text-lg">
            <thead class="bg-secondary dark:bg-gray-700/50">
              <tr>
                <th
                  class="px-4 py-3 text-left font-bold text-primary dark:text-gray-200"
                  >Service</th
                >

                <th
                  class="px-4 py-3 text-right font-bold text-primary dark:text-gray-200"
                  >Price</th
                >

                <th
                  class="px-4 py-3 text-right font-bold text-primary dark:text-gray-200"
                  >Supplier</th
                >

                <th
                  class="px-4 py-3 text-right font-bold text-primary dark:text-gray-200"
                  >Revenue</th
                >

                <th
                  class="px-4 py-3 text-right font-bold text-primary dark:text-gray-200"
                  >Discount</th
                >

                <th
                  class="px-4 py-3 text-right font-bold text-primary dark:text-gray-200"
                  >Customer Price</th
                >
              </tr>
            </thead>

            <tbody class="divide-y divide-gray-200 dark:divide-gray-700">
              <tr
                class="hover:bg-gray-50 dark:hover:bg-gray-700/50 transition-colors group"
              >
                <td
                  class="px-4 py-3 font-medium text-gray-900 dark:text-gray-100"
                >
                  {$formData.shipment?.operator ?? ''}
                  {$formData.shipment?.product ?? ''}
                  {($formData.shipment?.weight ?? '0').toFixed(2)}kg

                  <p class="text-gray-400 dark:text-gray-500">
                    {$formData.shipment?.country ?? ''}
                  </p>
                </td>

                <td class="px-4 py-3 text-right font-mono dark:text-gray-300"
                  >{(rate.price ?? 0).toFixed(2)} EUR</td
                >

                <td
                  class="px-4 py-3 text-right text-blue-600 dark:text-blue-400 font-mono"
                >
                  {(rate.supplierEur ?? 0).toFixed(2)} EUR
                  <span class="text-gray-400 dark:text-gray-500"
                    >({(rate.supplierPercents * 100).toFixed(2)}%)</span
                  >
                </td>

                <td
                  class="px-4 py-3 text-right text-green-600 dark:text-green-400 font-mono"
                >
                  {(rate.revenueEur ?? 0).toFixed(2)} EUR
                  <span class="text-gray-400 dark:text-gray-500"
                    >({(rate.revenuePercents * 100).toFixed(2)}%)</span
                  >
                </td>

                <td
                  class="px-4 py-3 text-right text-pink-600 dark:text-pink-400 font-mono relative"
                >
                  {(rate.discountTotalEur ?? 0).toFixed(2)} EUR
                  <div class="text-gray-400 dark:text-gray-500">
                    ({((rate.discountTotalEur / rate.price) * 100).toFixed(2)}%)
                  </div>
                </td>

                <td class="px-4 py-3 text-right font-mono dark:text-gray-300"
                  >{(rate.customerPrice - rate.manualDiscountEur).toFixed(2)} EUR</td
                >
              </tr>
              {#if rate.manualDiscountEur}
                <tr>
                  <td colspan="5">
                    <div
                      class="text-md flex flex-col text-right w-full gap-0 font-mono text-pink-600 dark:text-pink-400 py-2 text-xs"
                    >
                      {(rate.discountEur ?? 0).toFixed(2)} EUR
                      <div class="mb-4">
                        <span class="font-bold text-primary dark:text-gray-200">
                          Customer
                        </span>
                        <span class="text-gray-400 dark:text-gray-500"
                          >({((rate.discountEur / rate.price) * 100).toFixed(
                            2,
                          )}%)</span
                        >
                      </div>

                      {(rate.manualDiscountEur ?? 0).toFixed(2)} EUR

                      <div>
                        <span class="font-bold text-primary dark:text-gray-200">
                          Manual
                        </span>
                        <span class="text-gray-400 dark:text-gray-500"
                          >({(
                            (rate.manualDiscountEur / rate.price) *
                            100
                          ).toFixed(2)}%)</span
                        >
                      </div>
                    </div>
                  </td>
                </tr>
              {/if}
              {#each (rate?.services || []).sort((a, b) => b.price - a.price) as service}
                <tr
                  class="hover:bg-gray-50 dark:hover:bg-gray-700/50 transition-colors group"
                >
                  <td
                    class="px-4 py-3 font-medium text-gray-900 dark:text-gray-100"
                  >
                    {service.product}
                    {#if service.week}
                      <span
                        class="block text-sm text-gray-500 dark:text-gray-400 mt-1"
                        >Week {service.week}</span
                      >
                    {:else}
                      <span
                        class="block text-sm text-gray-500 dark:text-gray-400 mt-1"
                        >{service.weight}</span
                      >
                    {/if}
                  </td>

                  <td class="px-4 py-3 text-right font-mono dark:text-gray-300"
                    >{service.price.toFixed(2)} {service.dealCurrency}</td
                  >

                  <td
                    class="px-4 py-3 text-right text-blue-600 dark:text-blue-400 font-mono"
                  >
                    {(service.supplierEur ?? 0).toFixed(2)} EUR
                    <span class="text-gray-400 dark:text-gray-500"
                      >({(service.supplierPercents * 100).toFixed(2)}%)</span
                    >
                  </td>
                  <td
                    class="px-4 py-3 text-right text-green-600 dark:text-green-400 font-mono"
                  >
                    {(service.revenueEur ?? 0).toFixed(2)} EUR
                    <span class="text-gray-400 dark:text-gray-500"
                      >({(service.revenuePercents * 100).toFixed(2)}%)</span
                    >
                  </td>

                  <td
                    class="px-4 py-3 text-right text-pink-600 dark:text-pink-400 font-mono"
                  >
                    {(service.discountEur ?? 0).toFixed(2)} EUR
                    <span class="text-gray-400 dark:text-gray-500"
                      >({(service.discountPercents * 100).toFixed(2)}%)</span
                    >
                  </td>

                  <td class="px-4 py-3 text-right font-mono dark:text-gray-300"
                    >{service.price.toFixed(2)} {service.dealCurrency}</td
                  >
                </tr>
              {/each}
            </tbody>
          </table>
        </div>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-4 gap-4 text-right font-mono">
        <!-- Key Metrics -->
        <div
          class="bg-cyan-50 dark:bg-cyan-900/30 p-4 rounded-lg border-l-4 border-cyan-500"
        >
          <p class="text-sm text-cyan-600 dark:text-cyan-400 font-mono">
            Supplier
          </p>
          <p class="text-2xl font-bold text-gray-900 dark:text-gray-100">
            {(rate.supplierTotalEur ?? 0).toFixed(2)} EUR
          </p>
          <p class="mt-1 text-sm text-cyan-600 dark:text-cyan-400">
            {(
              (rate.supplierTotalEur /
                (rate.totalEUR + (rate.discountTotalEur ?? 0))) *
              100
            ).toFixed(2)}%
          </p>
          {#if rate.services?.find(s => s.product?.includes('Страховка'))}
            {@const insurancePrice =
              rate.services.find(s => s.product?.includes('Страховка'))
                ?.supplierEur ?? 0}

            <div
              class="mt-4 text-xl font-bold text-gray-900 dark:text-gray-100"
            >
              {((rate.supplierTotalEur ?? 0) - insurancePrice).toFixed(2)} EUR
            </div>
            <div class="text-xs text-gray-500 dark:text-gray-400">
              Without insurance
            </div>

            <div
              class="mt-4 text-xl font-bold text-gray-900 dark:text-gray-100"
            >
              {insurancePrice.toFixed(2)} EUR
            </div>
            <div class="text-xs text-gray-500 dark:text-gray-400">
              Insurance
            </div>
          {/if}
        </div>

        <div
          class="bg-lime-50 dark:bg-lime-900/30 p-4 rounded-lg border-l-4 border-lime-500"
        >
          <p class="text-sm text-lime-600 dark:text-lime-400 font-medium">
            Revenue
          </p>
          <p class="text-2xl font-bold text-gray-900 dark:text-gray-100">
            {(rate.revenueTotalEur ?? 0).toFixed(2)} EUR
          </p>
          <p class="mt-1 text-sm text-lime-600 dark:text-lime-400">
            {(
              (rate.revenueTotalEur /
                (rate.totalEUR + (rate.discountTotalEur ?? 0))) *
              100
            ).toFixed(2)}%
          </p>
        </div>

        <div
          class="bg-pink-50 dark:bg-pink-900/30 p-4 rounded-lg border-l-4 border-pink-500"
        >
          <p class="text-sm text-pink-600 dark:text-pink-400 font-medium">
            Discount
          </p>
          <p class="text-2xl font-bold text-gray-900 dark:text-gray-100">
            {(rate.discountTotalEur ?? 0).toFixed(2)} EUR
          </p>
          <p class="mt-1 text-sm text-pink-600 dark:text-pink-400">
            {(
              (rate.discountTotalEur /
                (rate.totalEUR + (rate.discountTotalEur ?? 0))) *
              100
            ).toFixed(2)}%
          </p>
        </div>

        <div
          class="bg-blue-50 dark:bg-blue-900/30 p-4 rounded-lg border-l-4 border-blue-500"
        >
          <p class="text-sm text-blue-600 dark:text-blue-400 font-medium">
            Customer Price
          </p>
          <p class="text-2xl font-bold text-gray-900 dark:text-gray-100">
            {(rate.totalEUR ?? 0).toFixed(2)} EUR
          </p>
          <p class="mt-2 text-2xl font-bold text-blue-800 dark:text-blue-100">
            {(rate.exchangeRate * (rate.totalEUR ?? 0)).toFixed(0)} MDL
          </p>
        </div>
      </div>
    </div>

    <!-- Exchange Rate -->
    <div
      class="bg-gray-50 dark:bg-gray-700/50 px-4 py-2 rounded-md flex justify-between items-center text-xl"
    >
      <span class="text-gray-600 dark:text-gray-400">Exchange Rate*</span>
      <span class="font-mono font-bold dark:text-gray-200"
        >{(rate.exchangeRate ?? 0).toFixed(4)}</span
      >
    </div>
  </div>
{/if}
