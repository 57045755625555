import { authStore } from '$lib/stores/auth-store';

import { guardSalesAccess, guardFinanceAccess } from '$lib/auth/guards';

export type Route = {
  title: string;
  label: string;
  icon: string;
  module?: string;
  variant: 'default' | 'ghost';

  children?: Route[];
};

const crmRoutes: Route[] = [
  {
    title: 'Accounts',
    label: '',
    icon: 'mdi:building',
    variant: 'ghost',
    module: 'accounts',
  },
  {
    title: 'Contacts',
    label: '',
    icon: 'mdi:users',
    variant: 'default',
    module: 'contacts',
  },
  {
    title: 'Activities',
    label: '',
    icon: 'mdi:calendar',
    variant: 'default',
    module: 'activities',
  },
];

const headhunterRoutes: Route[] = [
  {
    title: 'Deals',
    label: '',
    icon: 'fluent:people-search-20-filled',
    variant: 'default',
    module: 'deals',
  },
];

export const logisticsRoutes: Route[] = [
  {
    title: 'CRM',
    label: '',
    icon: 'simple-icons:civicrm',
    variant: 'default',
    module: 'crm',
    children: [
      // ...crmRoutes,

      {
        title: 'Orders',
        label: '',
        icon: 'subway:world-1',
        variant: 'default',
        module: 'orders',
      },
      {
        title: 'Leads',
        label: '',
        icon: 'mdi:shopping-cart',
        variant: 'default',
        module: 'leads',
      },
      {
        title: 'Issues',
        label: '',
        icon: 'hugeicons:customer-service',
        variant: 'default',
        module: 'issues',
      },
    ],
  },
  {
    title: 'Invoices',
    label: '',
    icon: 'mdi:euro',
    variant: 'default',
    module: 'invoices',
  },
  {
    title: 'Manifests',
    label: '',
    icon: 'mdi:truck',
    variant: 'default',
    module: 'manifests',
  },
  {
    title: 'Finance',
    label: '',
    icon: 'mdi:bank',
    variant: 'default',
    module: 'finance',
    children: [
      {
        module: 'tariffs',
        title: 'Tariffs',
        icon: 'entypo:price-tag',
        label: 'Tarif',
        variant: 'default',
      },

      {
        title: 'Expenses',
        label: '',
        icon: 'mdi:invoice-outline',
        variant: 'default',
        module: 'expenses',
      },
      {
        title: 'Credits',
        label: '',
        icon: 'mdi:payment',
        variant: 'default',
        module: 'credits',
      },
      /* {
        title: 'Payments',
        label: '',
        icon: 'mdi:payment',
        variant: 'default',
        module: 'payments',
      },*/
      {
        title: 'Balance',
        label: '',
        icon: 'mdi:alert-circle',
        variant: 'default',
        module: 'balances',
      },
    ],
  },
  {
    title: 'Contracts',
    label: '',
    icon: 'mdi:handshake',
    variant: 'default',
    module: 'contracts',
  },
  {
    title: 'Analytics',
    label: '',
    icon: 'fluent:chart-multiple-20-regular',
    variant: 'default',
    module: 'analytics',
  },
  /*
  {
    title: 'Sales Reports',
    label: '',
    icon: 'carbon:sales-ops',
    variant: 'default',
    module: 'salesreports',
  }*/ /*
  {
    title: 'Settings',
    label: '',
    icon: 'mdi:settings',
    variant: 'ghost',
    module: 'settings',
  },*/
  ,
];

const timelogsRoutes: Route[] = [
  {
    title: 'Timelogs',
    label: '',
    icon: 'fluent-mdl2:stopwatch',
    variant: 'default',
    module: 'timelogs',
  },
];

const freelanceRoutes: Route[] = [
  {
    title: 'Invoices',
    label: '',
    icon: 'mdi:euro',
    variant: 'default',
    module: 'invoices',
  },
  ...timelogsRoutes,
];

export function getPrimaryRoutes(): Route[] {
  const filteredLogisticsRoutes = logisticsRoutes.filter(route => {
    if (route.module === 'finance') {
      return guardFinanceAccess();
    }
    if (
      route.module === 'analytics' ||
      route.module === 'contracts' ||
      route.module === 'tariffs' ||
      route.module === 'salesreports'
    ) {
      return guardSalesAccess() || guardFinanceAccess();
    }
    return true;
  });

  const routes: Route[] = [
    {
      title: 'Dashboard',
      label: '',
      icon: 'material-symbols:dashboard',
      variant: 'ghost',
      module: 'dashboard',
    },
    ...(authStore.isCRM() ? crmRoutes : []),

    ...(authStore.isHeadhunter() ? headhunterRoutes : []),
    ...(authStore.isLogistics() ? filteredLogisticsRoutes : []),
    ...(authStore.isTimelogs() ? timelogsRoutes : []),
    ...(authStore.isFreelance() ? freelanceRoutes : []),
    /*
    {
      title: 'Settings',
      label: '',
      icon: Icons.Settings,
      variant: 'default',
      module: 'settings',
    },
    */
  ];
  // filter duplicates
  return routes.filter(
    (value, index, self) =>
      index === self.findIndex(t => t.title === value.title),
  );
}

export function getAllRoutes(): Route[] {
  const flattenRoutes = (routes: Route[]): Route[] => {
    return routes.reduce((acc, route) => {
      acc.push(route);
      if (route?.children?.length > 0) {
        acc.push(...flattenRoutes(route.children));
      }
      return acc;
    }, [] as Route[]);
  };

  return flattenRoutes([
    ...crmRoutes,
    ...headhunterRoutes,
    ...logisticsRoutes,
    ...timelogsRoutes,
    ...freelanceRoutes,
  ]);
}

export const secondaryRoutes: Route[] = [
  {
    title: 'Notifications',
    label: '12',
    icon: 'mdi:bell',
    variant: 'ghost',
    module: 'notifications',
  },
  {
    title: 'Trash',
    label: '12',
    icon: 'mdi:trash-can',
    variant: 'ghost',
    module: 'trash',
  },
  {
    title: 'Settings',
    label: '',
    icon: 'mdi:settings',
    variant: 'ghost',
    module: 'settings',
  },
];

const system = [
  { label: 'Created At', value: 'createdAt', sortable: true, type: 'string' },
  {
    label: 'Modified At',
    value: 'modifiedAt',
    sortable: true,
    type: 'string',
  },
];

export const fields = {
  tariffs: [
    ...system,
    { label: 'Name', value: 'name', sortable: true, type: 'string' },
    { label: 'Status', value: 'status', sortable: true, type: 'string' },
    {
      label: 'Description',
      value: 'description',
      sortable: false,
      type: 'string',
    },
  ],
  accounts: [
    { label: 'ID', value: 'id', sortable: false, type: 'string' },
    { label: 'Name', value: 'name', sortable: true, type: 'string' },
    {
      label: 'Description',
      value: 'description',
      sortable: false,
      type: 'string',
    },
    { label: 'Logo', value: 'logo', sortable: false, type: 'string' },
    { label: 'LinkedIn', value: 'linkedin', sortable: false, type: 'string' },
    { label: 'URL', value: 'url', sortable: false, type: 'string' },
    { label: 'Phone', value: 'phone', sortable: false, type: 'string' },
    {
      label: 'Website URL',
      value: 'websiteUrl',
      sortable: false,
      type: 'string',
    },
    {
      label: 'Account Status',
      value: 'accountStatus',
      sortable: true,
      type: 'string',
    },

    { label: 'Industry', value: 'industry', sortable: true, type: 'string' },
    { label: 'Employees', value: 'employees', sortable: true, type: 'string' },
    { label: 'Revenue', value: 'revenue', sortable: true, type: 'string' },
    { label: 'Address', value: 'address', sortable: false, type: 'string' },
    {
      label: 'Communication',
      value: 'communication',
      sortable: false,
      type: 'string',
    },
    {
      label: 'Company Profile',
      value: 'companyProfile',
      sortable: false,
      type: 'string',
    },
    {
      label: 'Philosophy',
      value: 'philosophy',
      sortable: false,
      type: 'string',
    },
    {
      label: 'Universal Name',
      value: 'universalName',
      sortable: false,
      type: 'string',
    },
    {
      label: 'Employee Count',
      value: 'employeeCount',
      sortable: true,
      type: 'string',
    },
    {
      label: 'Employee Count Range',
      value: 'employeeCountRange',
      sortable: true,
      type: 'string',
    },
    {
      label: 'Official Name',
      value: 'officialName',
      sortable: true,
      type: 'string',
    },
    { label: 'Tagline', value: 'tagline', sortable: false, type: 'string' },
    { label: 'Founded On', value: 'foundedOn', sortable: true, type: 'string' },
    {
      label: 'Specialities',
      value: 'specialities',
      sortable: false,
      type: 'string',
    },
    {
      label: 'Crunchbase Funding Data',
      value: 'crunchbaseFundingData',
      sortable: false,
      type: 'string',
    },
    ...system,
  ],
  leads: [
    ...system,
    { label: 'Name', value: 'name', sortable: true, type: 'string' },
    { label: 'Status', value: 'status', sortable: true, type: 'string' },
  ],

  invoices: [
    ...system,
    { label: 'ID', value: 'invoiceId', sortable: true, type: 'string' },
    { label: 'Name', value: 'name', sortable: true, type: 'string' },
    { label: 'Status', value: 'status', sortable: true, type: 'string' },
    {
      label: 'Invoice date',
      value: 'invoiceDate',
      sortable: true,
      type: 'date',
    },
    {
      label: 'Fixed on',
      value: 'fixedOn',
      sortable: true,
      type: 'date',
    },
    { label: 'Amount', value: 'amount', sortable: true, type: 'string' },
    { label: 'Subject', value: 'subject', sortable: true, type: 'string' },
    { label: 'Due Date', value: 'dueDate', sortable: true, type: 'date' },
    { label: 'Debit', value: 'debit', sortable: true, type: 'string' },
    { label: 'Gross', value: 'gross', sortable: true, type: 'string' },
    { label: 'Net', value: 'Net', sortable: true, type: 'string' },
  ],
};
