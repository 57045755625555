<script lang="ts">
  import { formatDate } from '$lib/app/utils';
  import * as Card from '$lib/components/ui/card';
  import { Badge } from '$lib/components/ui/badge';
  import { getPayments } from '$lib/api/queries';
  import { formatCurrency } from '$lib/utils/hanta-utils';
  import { createQuery } from '@tanstack/svelte-query';
  import Icon from '@iconify/svelte';
  import * as Dialog from '$lib/components/ui/dialog';
  import { Input } from '$lib/components/ui/input';
  import { Label } from '$lib/components/ui/label';
  import {
    savePayment,
    updatePayment,
    undoSplitPayment,
  } from '$lib/api/mutations';
  import { Button } from '$lib/components/ui/button';
  import { useQueryClient } from '@tanstack/svelte-query';
  import { popupStore } from '$lib/stores/app-store';
  export let invoiceId: string;

  const queryClient = useQueryClient();

  const paymentsQuery = createQuery({
    queryKey: ['payments', invoiceId],
    queryFn: () => getPayments(invoiceId),
    enabled: !!invoiceId,
  });

  function getStatusStyle(status) {
    switch (status) {
      case 'Pending':
        return 'bg-yellow-100/80 dark:bg-yellow-500/20 text-yellow-900 dark:text-yellow-300 border border-yellow-300 dark:border-yellow-600';
      case 'Incomplete':
        return 'bg-orange-100/80 dark:bg-orange-500/20 text-orange-900 dark:text-orange-300 border border-orange-300 dark:border-orange-600';
      case 'Cancelled':
      case 'Annuliert':
        return 'bg-red-100/80 dark:bg-red-500/20 text-red-900 dark:text-red-300 border border-red-300 dark:border-red-600';
      case 'Succeeded':
      case 'Completed':
        return 'bg-emerald-100/80 dark:bg-emerald-500/20 text-emerald-900 dark:text-emerald-300 border border-emerald-300 dark:border-emerald-600';
      default:
        return 'bg-gray-100/80 dark:bg-gray-500/20 text-gray-900 dark:text-gray-300 border border-gray-300 dark:border-gray-600';
    }
  }

  function getStatusIcon(status) {
    switch (status) {
      case 'Pending':
        return 'mdi:clock-outline';
      case 'Incomplete':
        return 'mdi:alert-circle-outline';
      case 'Cancelled':
      case 'Annuliert':
        return 'mdi:close-circle-outline';
      case 'Succeeded':
      case 'Completed':
        return 'mdi:check-circle-outline';
      default:
        return 'mdi:help-circle-outline';
    }
  }

  let splitDialog = false;
  let paymentToSplit: any = null;
  let splitAmount = 0;

  async function handleSplitPayment() {
    try {
      if (
        !paymentToSplit ||
        splitAmount <= 0 ||
        splitAmount >= paymentToSplit.amount
      ) {
        throw new Error('Invalid split amount');
      }

      // Calculate remaining amount
      const remainingAmount = paymentToSplit.amount - splitAmount;

      // Create new payment with split amount
      await savePayment({
        invoiceId,
        amount: +splitAmount,
        currency: paymentToSplit.currency,
        status: 'Succeeded',
        name: `${paymentToSplit.name} (Split Payment)`,
        splitFrom: paymentToSplit.id,
        paymentDate: new Date().toISOString(),
        paymentSucceededAt: new Date().toISOString(),
        accountId: paymentToSplit.accountId,
        amountEur: paymentToSplit.amountEur
          ? (splitAmount * paymentToSplit.amountEur) / paymentToSplit.amount
          : undefined,
      });

      // Update original payment with reduced amount
      await updatePayment({
        ...paymentToSplit,
        amount: remainingAmount,
      });

      splitDialog = false;
      paymentToSplit = null;
      splitAmount = 0;

      // Refresh payments data
      // paymentsQuery.invalidate();

      queryClient.invalidateQueries({ queryKey: ['payments', invoiceId] });
    } catch (error) {
      console.error('Failed to split payment:', error);
      // Show error toast or message
    }
  }

  async function handleUndoSplit(payment) {
    try {
      await undoSplitPayment(payment.id);
      queryClient.invalidateQueries({ queryKey: ['payments', invoiceId] });
    } catch (error) {
      console.error('Failed to undo split payment:', error);
      // Show error toast or message
    }
  }
</script>

<div class="mt-6">
  <Card.Root class="border-border">
    <Card.Header>
      <Card.Title class="text-foreground">Payments</Card.Title>
    </Card.Header>
    <Card.Content>
      {#if $paymentsQuery.isLoading}
        <div class="flex justify-center items-center py-8">
          <div class="overflow-hidden w-full h-1 rounded-full bg-muted/30">
            <div
              class="w-1/2 h-full bg-primary/70 animate-[slide-loop_1s_ease-in-out_infinite]"
            />
          </div>
        </div>
      {:else if $paymentsQuery.isError}
        <div class="py-4 text-destructive">
          Error: {$paymentsQuery.error.message}
        </div>
      {:else if $paymentsQuery.data?.length > 0}
        <table class="w-full">
          <thead>
            <tr class="border-b border-border/50">
              <th class="w-12"></th>
              <th class="pb-2 font-semibold text-muted-foreground">Date</th>
              <th class="pb-2 font-semibold text-left text-muted-foreground"
                >Description</th
              >
              <th class="pb-2 font-semibold text-right text-muted-foreground"
                >Status</th
              >
              <th class="pb-2 font-semibold text-right text-muted-foreground"
                >Amount</th
              >
            </tr>
          </thead>
          <tbody>
            {#each $paymentsQuery.data as payment}
              <tr
                class="border-b transition-colors border-border/30 hover:bg-muted/50"
              >
                <td>
                  {#if !payment.creditId && payment.status === 'Succeeded'}
                    <Button
                      variant="destructive"
                      size="sm"
                      class="m-2"
                      on:click={() => {
                        handleUndoSplit(payment);
                      }}
                    >
                      Undo
                    </Button>
                  {/if}
                </td>
                <td class="py-4 text-foreground">
                  {formatDate(payment.paymentDate)}
                </td>
                <td class="py-4 text-foreground">
                  <div>
                    <div>{payment.name || 'N/A'}</div>

                    <div class="text-xs text-muted-foreground mt-2">
                      {payment.id || 'N/A'}
                    </div>
                    {#if payment.creditId}
                      <div class="text-xs text-muted-foreground mt-2">
                        Credit ID:
                        {payment.creditId}
                        <Button
                          variant="ghost"
                          size="sm"
                          class="h-8 w-8 p-0"
                          on:click={() => {
                            popupStore.openPopup('crmObject', {
                              id: payment.creditId,
                              module: 'credits',
                            });
                          }}
                        >
                          <Icon icon="mdi:open-in-new" class="h-4 w-4" />
                        </Button>
                      </div>
                    {/if}
                    {#if payment.splitFrom}
                      <div class="text-xs text-muted-foreground mt-2">
                        Split from:
                        {payment.splitFrom}
                      </div>
                    {/if}
                  </div>
                </td>
                <td class="py-4">
                  <div class="flex justify-end">
                    <Badge
                      variant="secondary"
                      class="ml-2 px-2.5 py-1 text-xs rounded-full flex items-center shadow-sm {getStatusStyle(
                        payment.status,
                      )}"
                    >
                      <Icon
                        icon={getStatusIcon(payment.status)}
                        class="mr-1.5 size-4"
                      />
                      {payment.status}
                    </Badge>
                    {#if payment.status === 'Pending'}
                      <Button
                        variant="secondary"
                        size="sm"
                        class="ml-2"
                        on:click={() => {
                          paymentToSplit = payment;
                          splitAmount = 0;
                          splitDialog = true;
                        }}
                      >
                        <Icon icon="mdi:call-split" class="size-4" />
                      </Button>
                    {/if}
                  </div>
                  {#if payment.status === 'Succeeded' && payment.modifiedAt}
                    <div class="text-xs text-muted-foreground text-right">
                      {formatDate(payment.modifiedAt)}
                    </div>
                  {/if}
                </td>
                <td class="py-4 text-right">
                  <div class="text-base">
                    <span class="font-medium text-foreground">
                      {formatCurrency(payment.amount, payment.currency)}
                    </span>
                    {#if payment.currency !== 'EUR' && payment.amountEur !== undefined}
                      <div class="text-sm font-medium text-muted-foreground">
                        {formatCurrency(payment.amountEur, 'EUR')}
                      </div>
                    {/if}
                  </div>
                </td>
              </tr>
            {/each}
          </tbody>
        </table>
      {:else}
        <p class="py-4 text-muted-foreground">
          No transfers found for this invoice.
        </p>
      {/if}
    </Card.Content>
  </Card.Root>
</div>

<!-- Add Split Payment Dialog -->
<Dialog.Root bind:open={splitDialog}>
  <Dialog.Content class="sm:max-w-[425px]">
    <Dialog.Header>
      <Dialog.Title>Record Partial Payment</Dialog.Title>
      <Dialog.Description>
        Enter the received amount from the total payment of {formatCurrency(
          paymentToSplit?.amount || 0,
          paymentToSplit?.currency,
        )}
      </Dialog.Description>
    </Dialog.Header>

    <form class="grid gap-4 py-4" on:submit|preventDefault={handleSplitPayment}>
      <div class="grid gap-2">
        <Label for="splitAmount">Received Amount</Label>
        <Input
          id="splitAmount"
          type="number"
          step="0.01"
          bind:value={splitAmount}
          max={paymentToSplit?.amount - 0.01}
          required
        />
        {#if splitAmount > 0 && paymentToSplit}
          <p class="text-sm text-muted-foreground">
            Outstanding amount will be: {formatCurrency(
              paymentToSplit.amount - splitAmount,
              paymentToSplit.currency,
            )}
          </p>
        {/if}
      </div>

      <Dialog.Footer>
        <Button
          type="button"
          variant="outline"
          on:click={() => (splitDialog = false)}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          disabled={!splitAmount ||
            splitAmount >= (paymentToSplit?.amount || 0)}
        >
          Record Payment
        </Button>
      </Dialog.Footer>
    </form>
  </Dialog.Content>
</Dialog.Root>

<style>
  @keyframes slide-loop {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(200%);
    }
  }
</style>
