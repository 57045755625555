<script lang="ts">
  import { superForm } from 'sveltekit-superforms';

  import Icon from '@iconify/svelte';
  import { Button } from '$lib/components/ui/button';
  import ShippingDetailsCard from '../leads_orders/shipping-details-card.svelte';
  import WeightVolumeCard from '../leads_orders/weight-volume-card.svelte';
  import FinancialDetailsCard from '../leads_orders/financial-details-card.svelte';

  import CustomerInfoCard from '../leads_orders/customer-info-card.svelte';
  import { generateRefId, saveLead } from '$lib/api/mutations';
  import { appStore } from '$lib/stores/app-store';
  import { currentUserStore } from '$lib/stores/current-user-store';

  let billedWeight = 0;

  const defaultData = {
    date: new Date().toISOString(),
    orderDate: new Date().toISOString(),
    account: null,
    contactObj: null,
    user: null,
    shipment: {
      operator: 'TNT',
      direction: 'EXPORT',
      type: 'DOX',
      product: 'DOX',
      extraService: '9:00',
      weightUnit: 'kg',
      weightPh: 0.25,
      packages: [],
      weight: 0.25,
      paymentSide: 'SENDER',
      status: 'DRAFT',
      packageType: 'PL - FedEx Envelope',
      securityFee: true,
      fuelFee: true,
      paymentMethod: 'Перечислением',
      rate: {},
      totalEUR: 0,
      totalNational: 0,
      exchangeRate: 0,
    },
  };

  const form = superForm(defaultData, {
    resetForm: false,
    SPA: true,
    validationMethod: 'oninput',
    dataType: 'json',
    onSubmit: async () => {
      form.reset();
    },
  });

  const createLead = async () => {
    try {
      const refId = await generateRefId('leads');

      const newLead = await saveLead({
        ...$formData,
        name: refId + '',
        date: new Date().toISOString(),
        refId,
        assignedTo: $currentUserStore?.id,

        shipment: {
          ...$formData.shipment,
          weight: billedWeight,
        },
      });

      setTimeout(() => {
        appStore.select({
          module: 'leads',
          id: newLead.id,
        });
        appStore.openPopup(true);
      }, 500);
    } catch (error) {
      console.error('Failed to create lead:', error);
    }
  };

  let { form: formData, enhance, errors } = form;
</script>

<main class="overflow-scroll h-full p-6 bg-cool">
  <form class="space-y-6 w-full" method="POST" use:enhance>
    <div class="mx-auto">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
        <div class=" flex flex-col text-xs space-y-2">
          <WeightVolumeCard {form} bind:billedWeight simpleMode={true} />
          <ShippingDetailsCard {form} simpleMode={true} />
        </div>
        <div class=" flex flex-col text-xs space-y-2">
          <CustomerInfoCard {form} simpleMode={true} />

          <FinancialDetailsCard {form} {billedWeight} />
        </div>
      </div>

      <div class="flex gap-4 mt-6 justify-end">
        <Button variant="secondary" on:click={() => form.reset()}>
          <Icon icon="mdi:refresh" class="mr-2" />
          Reset
        </Button>
        <Button variant="default" on:click={createLead}>
          <Icon icon="mdi:account-plus" class="mr-2" />
          Create Lead
        </Button>
      </div>
    </div>
  </form>
</main>
