import { writable, get } from 'svelte/store';
import {
  getTrashItems,
  restoreItem,
  deleteItemPermanently,
  emptyTrash,
} from '../api/trash-api';

function createTrashStore() {
  const { subscribe, set, update } = writable<any[]>([]);

  async function initialize() {
    const trashItems = await getTrashItems();
    if (trashItems) {
      set(trashItems);
    }
  }

  // Initialize the store when it's created
  initialize();

  return {
    subscribe,

    // Load all trash items
    loadTrashItems: async () => {
      const trashItems = await getTrashItems();
      if (trashItems) {
        set(trashItems);
      }
      return trashItems;
    },

    // Restore an item from trash
    restoreItem: async (module: string, id: string) => {
      await restoreItem(module, id);
      update(items => items.filter(item => !(item.module === module && item.id === id)));
    },

    // Delete an item permanently
    deleteItemPermanently: async (module: string, id: string) => {
      await deleteItemPermanently(module, id);
      update(items => items.filter(item => !(item.module === module && item.id === id)));
    },

    // Empty the trash
    emptyTrash: async () => {
      await emptyTrash();
      set([]);
    },
  };
}

export const trashStore = createTrashStore();
