import { PriceCalculator } from './PriceCalculator';

import { ExchangeRateService } from './ExchangeRateService';
import { ServicePreparator } from './ServicePreparator';
import { TotalsCalculator } from './TotalsCalculator';
import { ExchangesData, PriceRequest, Tariff } from './prices';

export class MainPriceService {
  constructor(
    private readonly exchangeRateService: ExchangeRateService,
    private readonly priceCalculator: PriceCalculator,
    private readonly servicePreparator: ServicePreparator,
    private readonly totalsCalculator: TotalsCalculator,
  ) {}

  public async calculatePrices(
    request: PriceRequest,
    tariffs: {
      supplierTarif: Tariff;
      customerTarif: Tariff;
      baseTarif: Tariff;
    },
    exchangesData: ExchangesData[],
    withMdl?: boolean,
  ): Promise<PriceRequest> {
    const timeStart = performance.now();

    try {
      // Reset and prepare item
      this.priceCalculator.resetPrices(request);
      this.filterServices(request);

      // Handle exchange rates
      if (withMdl) {
        this.exchangeRateService.updateExchanges(request, exchangesData);
        this.exchangeRateService.setMdl2eurOnServices(request);
      }
      // Calculate main item price
      await this.priceCalculator.calculatePrice(
        request,
        tariffs,
        exchangesData,
      );

      // Prepare and calculate service prices
      this.servicePreparator.prepareServices(request);
      await Promise.all(
        request.services.map((service: any) =>
          this.priceCalculator.calculatePrice(service, tariffs, exchangesData),
        ),
      );

      // Calculate totals and round final prices
      this.totalsCalculator.calculateTotals(request, withMdl);
      this.priceCalculator.roundPrices(request);

      request.priceRequestId = crypto.randomUUID();
      return request;
    } finally {
      const timeEnd = performance.now();
      console.debug(
        `MainPriceService.calculatePrices took ${(timeEnd - timeStart).toFixed(2)} milliseconds`,
      );
    }
  }

  private filterServices(item: PriceRequest): void {
    item.services = []; /*
      item.services?.filter(
        (service: any) =>
          service.product &&
          !service.product.includes('Надбавка за безопасность') &&
          !service.product.includes('Топливная надбавка') &&
          !service.product.includes('ODA Surcharge') &&
          !service.product.includes('Страховка '),
      ) ?? []; */
  }
}
