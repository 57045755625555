<script lang="ts">
  import {
    saveContact,
    updateAccountContact,
    updateContactPhoto,
  } from '$lib/api/mutations';
  import { formatNumberWithSuffix } from '$lib/utils/hanta-utils';
  import * as Avatar from '$lib/components/ui/avatar';
  import Address from '$lib/components/hanta/address.svelte';

  import {
    type ActivityWithRefs,
    type ContactWithRefs,
    ActivityType,
  } from '$db/schema';

  import LinkedinButton from '$lib/components/hanta/linkedin-button.svelte';
  import Communication from '$lib/components/hanta/communication.svelte';
  import Button from '$lib/components/ui/button/button.svelte';
  import ActivityActionBar from '../activities/activity-action-bar.svelte';
  import DocxTemplates from '$lib/widgets/docx-template/docx-templates.svelte';
  import { cn } from '$lib/utils/hanta-utils';

  import { isCandidate, isContact } from '$lib/app/utils.js';
  import { appStore } from '$lib/stores/app-store';
  import Icon from '@iconify/svelte';
  import * as Tooltip from '$lib/components/ui/tooltip';
  import CrmAvatar from '$lib/components/hanta/crm-avatar.svelte';
  import AccountSelector from '$lib/widgets/accounts/account-selector.svelte';
  import ContactRoleSelector from './contact-role-selector.svelte';
  import ValuesButton from '$lib/components/hanta/values-button.svelte';
  import BgRemoverComponent from './bg-remover-component.svelte';

  export let activity: ActivityWithRefs;
  export let crmContact: ContactWithRefs;

  export let isPluginMode = false;
  let refresh = 0;

  function normalize(value) {
    return value === null || value === '' || value === undefined ? null : value;
  }

  export function getUnequalProperties(obj1, obj2, properties) {
    const unequalProperties = [];
    for (const property of properties) {
      if (normalize(obj1[property]) !== normalize(obj2[property])) {
        unequalProperties.push(property);
      }
    }
    return unequalProperties;
  }

  const propertiesToCompare = [
    'linkedin',
    'name',
    'firstname',
    'lastname',
    'linkedInPhoto',
    'focus',
    'candidateCompany',
    'resume',
    'position',
  ];
  $: unequalProperties =
    $appStore.linkedInContact && crmContact
      ? getUnequalProperties(
          $appStore.linkedInContact,
          crmContact,
          propertiesToCompare,
        )
      : [];

  $: changesDetected = unequalProperties.length > 0;

  async function updateLinkedInInfo() {
    if ($appStore.linkedInContact) {
      const contactToSave = {
        ...crmContact,
        linkedin: $appStore.linkedInContact.linkedin,
        name: $appStore.linkedInContact.name,
        firstname: $appStore.linkedInContact.firstname,
        lastname: $appStore.linkedInContact.lastname,
        photo: $appStore.linkedInContact.photo,
        linkedInPhoto: $appStore.linkedInContact.linkedInPhoto,
        focus: $appStore.linkedInContact.focus,
        candidateCompany: $appStore.linkedInContact.candidateCompany,
        resume: $appStore.linkedInContact.resume,
        resumeLinkedIn: $appStore.linkedInContact.resumeLinkedIn,
        position: $appStore.linkedInContact.position,
        contact: $appStore.linkedInContact.contact,
      };

      await saveContact(contactToSave);
    }
  }

  function openItem({ module, id }) {
    appStore.select({
      module,
      id,
    });
    appStore.openPopup(true);
  }

  export async function updatePhoto(contactId: string, photo: string) {
    try {
      await updateContactPhoto(contactId, photo);
      crmContact = { ...crmContact, photo };
    } catch (error) {
      console.debug('error', error);
      throw error;
    }
  }

  let photo = crmContact?.photo;
</script>

<div
  class="flex relative flex-col items-start p-4 space-y-2 w-full md:space-x-8 md:flex-row"
>
  {#if $appStore.isMobile}
    <div
      class="absolute left-0 z-[-1] w-full h-[calc(100%-65px)] bg-blue-300/10 dark:bg-slate-800 top-20 rounded-t-3xl"
    ></div>
  {/if}
  <div
    class={cn(
      'w-full flex flex-1 flex-col items-center',
      $appStore.isMobile ? 'gap-4' : 'gap-0',
    )}
  >
    <Avatar.Root
      class={cn(
        'rounded',
        isPluginMode
          ? 'size-24'
          : $appStore.isMobile
            ? 'size-32 rounded-3xl border-black border-4'
            : 'size-48',
      )}
    >
      <Avatar.Image alt="Contact Photo" src={crmContact?.photo} />

      <Avatar.Fallback
        class="w-full p-1 text-[8px] text-center text-muted-foreground rounded-none bg-gradient-to-r from-gray-300 to-gray-100 "
      >
        {crmContact?.title ?? ''}
        {crmContact?.name ?? ''}
      </Avatar.Fallback>
      <Avatar.AvatarUpload
        on:upload={async newPhoto => {
          photo = newPhoto.detail.base64;
          crmContact.photo = photo;
          await updatePhoto(crmContact.id, photo);
        }}
      />
    </Avatar.Root>
    <div class="hidden my-2">
      <BgRemoverComponent
        initialPhoto={crmContact?.photo}
        onPhotoUpdate={() => updateContactPhoto(crmContact.id, photo)}
      />
    </div>
    {#if isPluginMode && changesDetected}
      <Tooltip.Root>
        <Tooltip.Trigger asChild let:builder>
          <Button
            builders={[builder]}
            variant="outline"
            on:click={updateLinkedInInfo}
          >
            <Icon icon="mdi:replace" class="mr-2 size-4" />
            <span>Update</span>
          </Button>
        </Tooltip.Trigger>
        <Tooltip.Content>
          <div>
            <p>What's new:</p>
            <table>
              <thead>
                <tr>
                  <th>Property</th>
                  <th>Old Value</th>
                  <th>New Value</th>
                </tr>
              </thead>
              <tbody>
                {#each unequalProperties as prop}
                  <tr>
                    <td class="px-2 font-bold">{prop}</td>
                    <td>{crmContact[prop] ?? 'null'}</td>
                    <td>{$appStore.linkedInContact[prop] ?? 'null'}</td>
                  </tr>
                {/each}
              </tbody>
            </table>
          </div>
        </Tooltip.Content>
      </Tooltip.Root>
    {/if}
  </div>

  <div class="w-full">
    <div class="flex justify-between items-center space-x-4 w-full">
      <div class="flex flex-1 justify-between items-start w-full">
        <div class="flex justify-between items-start w-full">
          <div
            class="flex flex-col items-start text-xl text-left md:mt-6 w-full"
          >
            <ContactRoleSelector {crmContact} />

            <div class="flex flex-row items-center mt-2 w-full font-bold">
              {crmContact?.title ?? ''}
              {crmContact?.name ?? ''}
              {#if !isPluginMode}
                <LinkedinButton linkedin={crmContact.linkedin} />
              {/if}

              <ValuesButton
                values={crmContact?.communicationPrivate?.values ||
                  crmContact?.communicationWork?.values}
              />

              {#if isCandidate(crmContact.role) && crmContact?.annualGrossTarget > 0}
                <div
                  class="right-4 top-24 md:relative md:block md:top-0 md:left-0"
                  class:absolute={$appStore.isMobile}
                >
                  <div
                    class="flex items-center px-2 space-x-1 rounded border bg-muted/10"
                  >
                    <Icon icon="mdi:banknote" class="size-5" />
                    <div class="text-sm font-thin">
                      {formatNumberWithSuffix(crmContact?.annualGrossTarget)}
                    </div>
                  </div>
                </div>
              {/if}
            </div>

            <div class="text-sm font-medium text-muted-foreground">
              {crmContact?.position ?? ''}
            </div>
            {#if isCandidate(crmContact.role) && crmContact?.candidateCompany}
              <div class="mt-1 text-sm text-primary/80">
                {crmContact?.candidateCompany}
              </div>
            {/if}

            {#if isContact(crmContact.role)}
              {#if crmContact?.customer?.id}
                <div class="mt-4">
                  <CrmAvatar
                    on:open={() =>
                      openItem({
                        module: 'accounts',
                        id: crmContact?.customer?.id,
                      })}
                    id={crmContact?.customer?.id}
                    module="accounts"
                    name={crmContact?.customer?.name ?? ''}
                    size="size-12"
                  />
                </div>
              {:else if crmContact && crmContact?.id}
                <div class="mt-1 w-full">
                  <AccountSelector
                    hideLabel={true}
                    onChange={async account => {
                      await updateAccountContact(crmContact?.id, account);
                      setTimeout(() => {
                        refresh = refresh + 1;
                      }, 500);
                    }}
                  />
                </div>
              {/if}
            {/if}
          </div>
        </div>
      </div>
    </div>

    {#if crmContact?.cluster?.length}
      <div class="flex flex-wrap my-2 text-sm">
        <!-- only top 3 -->
        {#each crmContact.cluster.slice(0, 3) as tag}
          <span
            class="px-1.5 py-1 mt-0.5 mr-1 text-gray-100 rounded bg-muted-foreground text-nowrap"
          >
            {tag ?? ''}
          </span>
        {/each}
      </div>
    {/if}

    {#if !isCandidate(crmContact?.role)}
      <!-- TODO Avatar Komponente -->
      {crmContact?.accounts?.length > 0 ? crmContact?.accounts[0].name : ''}
    {/if}

    {#if isCandidate(crmContact.role)}
      <div class="flex pt-4 mt-6 border-t md:pt-0 md:border-t-0 md:w-52">
        <div class="w-full">
          <DocxTemplates data={crmContact} templateName="profile.docx" />
        </div>

        <Button
          variant="ghost"
          class="w-full"
          on:click={() => {
            appStore.openActivity({
              type: ActivityType.enum.Assignment,
              contacts: [],
              deals: [],
              accounts: [],
              candidates: [],
              candidateObj: crmContact,
            });
          }}
        >
          <Icon icon="mdi:link" class="mr-2 w-4 h-4" />
          <span>Assign</span>
        </Button>
      </div>
    {/if}
    <hr class="my-4" />
    <div class="flex mb-4 space-x-12 text-xs text-muted-foreground">
      {#if isContact(crmContact?.role)}
        <div>
          <Address address={crmContact?.addressWork} />
          <Communication communication={crmContact?.communicationWork} />
        </div>
      {/if}

      {#if isCandidate(crmContact?.role)}
        <div>
          <Address address={crmContact?.addressPrivate} isPrivate={true} />
          <Communication
            communication={crmContact?.communicationPrivate}
            isPrivate={true}
          />
        </div>
      {/if}
    </div>

    <ActivityActionBar {activity} />
  </div>
</div>
