<script lang="ts">
  import * as Form from '$lib/components/ui/form/index.js';
  import { Input } from '$lib/components/ui/input';
  import { onMount } from 'svelte';
  import { Loader } from '@googlemaps/js-api-loader';
  import Icon from '@iconify/svelte';
  import HantaInputText from './hanta-input-text.svelte';
  import HantaInputTextLean from './hanta-input-text-lean.svelte';
  import { authStore } from '$lib/stores/auth-store';

  export let form;
  export let name;
  export let description = '';

  const { form: formData } = form;

  let addressInput = '';
  let predictions: google.maps.places.AutocompletePrediction[] = [];
  let autocompleteService: google.maps.places.AutocompleteService;
  let placesService: google.maps.places.PlacesService;

  onMount(async () => {
    const apiKey = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;

    if (!apiKey) {
      console.error('Google Maps API key is not set in environment variables');
      return;
    }

    const loader = new Loader({
      apiKey,
      version: 'weekly',
      libraries: ['places'],
    });

    try {
      const google = await loader.load();
      autocompleteService = new google.maps.places.AutocompleteService();
      placesService = new google.maps.places.PlacesService(
        document.createElement('div'),
      );
    } catch (error) {
      console.error('Error loading Google Maps API:', error);
    }
  });

  async function handleInput() {
    if (addressInput.length > 2 && autocompleteService) {
      const { predictions: newPredictions } =
        await autocompleteService.getPlacePredictions({
          input: addressInput,
          types: ['address'],
        });
      predictions = newPredictions;
    } else {
      predictions = [];
    }
  }

  function selectPrediction(
    prediction: google.maps.places.AutocompletePrediction,
  ) {
    addressInput = prediction.description;
    predictions = [];

    placesService.getDetails(
      { placeId: prediction.place_id },
      (place, status) => {
        if (
          status === google.maps.places.PlacesServiceStatus.OK &&
          place &&
          place.address_components
        ) {
          $formData[name] = {
            street: `${getComponent(place.address_components, 'route')} ${getComponent(place.address_components, 'street_number')}`,
            city: getComponent(place.address_components, 'locality'),
            postalCode: getComponent(place.address_components, 'postal_code'),
            country: getComponent(place.address_components, 'country'),
          };
        }
      },
    );
  }

  function getComponent(
    components: google.maps.GeocoderAddressComponent[],
    type: string,
  ) {
    const component = components.find(c => c.types.includes(type));
    return component ? component.long_name : '';
  }

  let selectedIndex = -1;

  function handleKeydown(event) {
    if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
      event.preventDefault();
      if (event.key === 'ArrowDown') {
        selectedIndex = (selectedIndex + 1) % predictions.length;
      } else {
        selectedIndex =
          (selectedIndex - 1 + predictions.length) % predictions.length;
      }
    } else if (event.key === 'Enter' && selectedIndex !== -1) {
      event.preventDefault();
      selectPrediction(predictions[selectedIndex]);
    }
  }
</script>

<form on:submit|preventDefault class="relative py-2 mb-4 rounded-lg border">
  <Form.Field class="flex items-center space-y-1" {form} {name}>
    <Form.Control let:attrs>
      <Icon icon="mdi:magnify" class="inline-block mx-3 size-6" />

      <Input
        {...attrs}
        bind:value={addressInput}
        on:input={handleInput}
        placeholder="Search for an address"
        class="w-full border-none ring-0"
        autocomplete="new-password"
        on:keydown={handleKeydown}
      />
    </Form.Control>
    <Form.Description>{description}</Form.Description>
    <Form.FieldErrors />
  </Form.Field>

  <ul class="absolute left-12 z-auto mt-2 shadow-lg bg-secondary">
    {#each predictions as prediction, i}
      <li
        tabindex="0"
        role="option"
        aria-selected={i === selectedIndex}
        class="px-3 py-2 text-sm list-none rounded-md transition-colors duration-200 ease-in-out cursor-pointer select-none hover:bg-muted hover:text-accent-foreground"
        on:keydown={e => {
          if (e.key === 'Enter' || e.key === ' ') {
            // Handle selection
          }
        }}
        class:selected={i === selectedIndex}
        on:click={() => selectPrediction(prediction)}
      >
        {prediction.description}
      </li>
    {/each}
  </ul>
</form>

{#if $formData[name]}
  <Form.Field class="space-y-1" {form} name={`${name}.street`}>
    <Form.Control let:attrs>
      <Form.Label>Street</Form.Label>
      <HantaInputTextLean
        {...attrs}
        bind:value={$formData[name].street}
        class="w-full"
      />
    </Form.Control>
    <Form.FieldErrors />
  </Form.Field>

  {#if authStore.isLogistics()}
    <Form.Field class="space-y-1" {form} name={`${name}.houseNumber`}>
      <Form.Control let:attrs>
        <Form.Label>House Number</Form.Label>
        <HantaInputTextLean
          {...attrs}
          bind:value={$formData[name].houseNumber}
          class="w-full"
        />
      </Form.Control>
      <Form.FieldErrors />
    </Form.Field>

    <Form.Field class="space-y-1" {form} name={`${name}.office`}>
      <Form.Control let:attrs>
        <Form.Label>Office</Form.Label>
        <HantaInputTextLean
          {...attrs}
          bind:value={$formData[name].office}
          class="w-full"
        />
      </Form.Control>
      <Form.FieldErrors />
    </Form.Field>
  {/if}

  <Form.Field class="space-y-1" {form} name={`${name}.city`}>
    <Form.Control let:attrs>
      <Form.Label>City</Form.Label>
      <HantaInputTextLean
        {...attrs}
        bind:value={$formData[name].city}
        class="w-full"
      />
    </Form.Control>
    <Form.FieldErrors />
  </Form.Field>

  <Form.Field class="space-y-1" {form} name={`${name}.postalCode`}>
    <Form.Control let:attrs>
      <Form.Label>Postal Code</Form.Label>
      <HantaInputTextLean
        {...attrs}
        bind:value={$formData[name].postalCode}
        class="w-full"
      />
    </Form.Control>
    <Form.FieldErrors />
  </Form.Field>

  <Form.Field class="space-y-1" {form} name={`${name}.country`}>
    <Form.Control let:attrs>
      <Form.Label>Country</Form.Label>
      <HantaInputTextLean
        {...attrs}
        bind:value={$formData[name].country}
        class="w-full"
      />
    </Form.Control>
    <Form.FieldErrors />
  </Form.Field>
{/if}

<style>
  .selected {
    background-color: #e0e0e0;
  }
</style>
