import { authStore } from '$lib/stores/auth-store';
import { get } from 'svelte/store';
import { supabase } from './supabase';

const bucketName = 'files';

const getRootFolder = () => {
  const orgId = get(authStore)?.organization?.id;

  if (!orgId) {
    throw new Error('Organization not found');
  }

  return `orgs/${orgId}`;
};

function normalizePath(...parts: string[]) {
  return parts.filter(Boolean).join('/').replace(/\/+/g, '/'); // Replace multiple slashes with single slash
}

const getBucket = async () => {
  return supabase(get(authStore)?.token, false).storage.from(bucketName);
};

export async function uploadBlob(blob, filename) {
  if (!blob || !filename) {
    return;
  }
  const bucket = await getBucket();
  const { error } = await bucket.upload(`${getRootFolder()}/${filename}`, blob);
  if (error) {
    throw new Error(error.message);
  }
}

async function createFileBackup(
  bucket,
  filePath: string,
  folder: string,
  filename: string,
) {
  try {
    // Check if file exists before creating backup
    const { data } = await bucket.download(filePath);
    if (data) {
      const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
      const backupFolder = normalizePath(getRootFolder(), folder, '__backups');
      const backupPath = normalizePath(
        backupFolder,
        `${filename}.${timestamp}`,
      );

      // Create an empty file in the backup directory to ensure it exists
      const emptyFile = new File([''], '.keep');
      await bucket.upload(normalizePath(backupFolder, '.keep'), emptyFile, {
        upsert: true,
      });

      // Create backup of existing file
      const { error: copyError } = await bucket.copy(filePath, backupPath);
      if (copyError) {
        console.error('Failed to create backup:', copyError);
        throw copyError; // Propagate the error to handle it in uploadFile
      }
    }
  } catch (error) {
    if (error?.message?.includes('Object not found')) {
      // File doesn't exist, no backup needed
      console.debug('No existing file to backup');
    } else {
      // Other errors should be propagated
      throw error;
    }
  }
}

export async function uploadFile(
  file,
  folder,
  filename = undefined,
  override = false,
  createBackup = false,
) {
  if (!file || !folder) {
    return;
  }
  const bucket = await getBucket();
  // replace all non-alphanumeric characters with empty string
  const cleanedFilename = (filename ? filename : file.name).replace(
    /[^a-zA-Z0-9.]/g,
    '_',
  );
  if (!cleanedFilename) {
    throw new Error('Invalid filename');
  }

  const filePath = normalizePath(getRootFolder(), folder, cleanedFilename);

  if (createBackup && override) {
    try {
      await createFileBackup(bucket, filePath, folder, cleanedFilename);
    } catch (error) {
      console.error('Backup creation failed:', error);
      // You might want to throw here depending on your requirements
      // throw error;
    }
  }

  const { error } = await bucket.upload(filePath, file, {
    upsert: override,
  });

  if (error) {
    throw new Error(error.message);
  }
}

export async function deleteFolder(folder) {
  if (!folder) {
    return;
  }
  const bucket = await getBucket();
  const { data, error } = await bucket.remove([
    normalizePath(getRootFolder(), folder),
  ]);
  if (error) {
    throw new Error(error.message);
  }
}

export async function deleteFile(folder, filename) {
  if (!filename || !folder) {
    return;
  }
  const bucket = await getBucket();
  const { data, error } = await bucket.remove([
    normalizePath(getRootFolder(), folder, filename),
  ]);
  if (error) {
    throw new Error(error.message);
  }
}

export async function subscribeToStorage() {}

export async function getFileUrl(folder, filename, expiresIn = 60 * 60) {
  if (!filename || !folder) {
    return;
  }
  const bucket = await getBucket();
  const { data, error } = await bucket.createSignedUrl(
    normalizePath(getRootFolder(), folder, filename),
    expiresIn,
  );
  if (error) {
    throw new Error(error.message);
  }
  return data;
}

export async function downloadFile(folder, filename) {
  if (!filename || !folder) {
    return;
  }
  const bucket = await getBucket();

  const { data, error } = await bucket.download(
    normalizePath(getRootFolder(), folder, filename),
  );
  if (error) {
    throw new Error(error.message);
  }
  return data;
}

type FilterFunction = (item: any) => boolean;

async function listItems(
  folder: string,
  filterFn: FilterFunction,
): Promise<any[]> {
  if (!folder) {
    throw new Error('Folder parameter is required');
  }

  const bucket = await getBucket();
  const rootFolder = getRootFolder();
  const fullFolder = normalizePath(rootFolder, folder);

  try {
    const { data, error } = await bucket.list(fullFolder);

    if (error) {
      throw error;
    }

    if (!data) {
      return [];
    }

    return data.filter(
      item => item.name !== '.emptyFolderPlaceholder' && filterFn(item),
    );
  } catch (error) {
    console.error(`Error listing items in folder ${folder}:`, error);
    throw error;
  }
}

export async function listFiles(folder: string): Promise<any[]> {
  return listItems(folder, item => !!item.id);
}

export async function listFolders(folder: string): Promise<any[]> {
  return listItems(folder, item => !item.id);
}

// Optional: If you need a function to list both files and folders
export async function listAllItems(folder: string): Promise<any[]> {
  return listItems(folder, () => true);
}

export async function extractCvFromPdf(folder: string, filename: string) {
  const { data, error } = await supabase(
    get(authStore)?.token,
    false,
  ).functions.invoke('pdf-extract', {
    body: {
      path: normalizePath(getRootFolder(), folder, filename),
      destination: folder,
    },
  });

  if (error) console.error(error);
  return data.text;
}

async function findAllByFilename(
  bucketName: string,
  filename: string,
  prefix: string = '',
): Promise<string[]> {
  const { data, error } = await supabase(get(authStore)?.token, false)
    .storage.from(bucketName)
    .list(prefix);
  if (error) throw error;

  let results: string[] = [];

  for (const item of data || []) {
    const fullPath = prefix ? `${prefix}/${item.name}` : item.name;

    if (item.metadata) {
      results = results.concat(
        await findAllByFilename(bucketName, filename, fullPath),
      );
    } else if (item.name === filename) {
      results.push(fullPath);
    }
  }

  return results;
}
