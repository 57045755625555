<script lang="ts">
  import { onMount } from 'svelte';
  import { getItems } from '$lib/api/queries';
  import { formatCurrency } from '$lib/utils/hanta-utils';
  import { formatDate } from '$lib/app/utils';
  import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
  } from '$lib/components/ui/card';
  import { popupStore } from '$lib/stores/app-store';
  import Button from '$lib/components/ui/button/button.svelte';
  import Icon from '@iconify/svelte';
  import { Badge } from '$lib/components/ui/badge';

  export let accountId: string;

  let payments = [];
  let loading = true;
  let error = null;
  let totalPending = 0;

  async function loadDebits() {
    try {
      loading = true;
      error = null;

      const batchSize = 1000;
      let allPayments = [];
      let hasMore = true;
      let offset = 0;

      while (hasMore) {
        const result = await getItems({
          select: '*,invoice:invoices(id,name,invoiceId)',
          collection: 'payments',
          filters: [
            {
              field: 'status',
              operator: 'eq',
              value: 'Pending',
              active: true,
            },
            {
              field: 'accountId',
              operator: 'eq',
              value: accountId,
              active: true,
            },
          ],
          from: offset,
          to: offset + batchSize,
        });

        if (result.error) throw result.error;

        if (result.data?.length) {
          allPayments = [...allPayments, ...result.data];
          offset += result.data.length;
        }

        hasMore = result.data?.length === batchSize;
      }

      payments = allPayments.sort(
        (a, b) => new Date(a.paymentDate) - new Date(b.paymentDate),
      );

      totalPending = payments.reduce(
        (sum, payment) => sum + (payment.amount || 0),
        0,
      );
    } catch (err) {
      console.error('Error loading debits:', err);
      error = err.message || 'Failed to load pending payments';
    } finally {
      loading = false;
    }
  }

  function openInvoice(id) {
    popupStore.openPopup('crmObject', {
      module: 'invoices',
      id,
    });
  }

  onMount(() => {
    loadDebits();
  });
</script>

<Card class="my-4 bg-secondary/50">
  <CardHeader class="flex flex-row items-center justify-between space-y-0 pb-2">
    <CardTitle class="text-xl font-medium mb-12">Pending Debits</CardTitle>
    <div class="flex items-center gap-2">
      <div class="text-sm font-bold bg-yellow-600 text-yellow-50 p-2">
        {formatCurrency(totalPending, 'MDL')}
      </div>
    </div>
  </CardHeader>
  <CardContent>
    {#if loading}
      <div class="flex justify-center py-4">
        <Icon icon="mdi:loading" class="animate-spin h-6 w-6" />
      </div>
    {:else if error}
      <div class="text-red-500 text-sm">{error}</div>
    {:else if payments.length === 0}
      <div class="text-center py-2 text-sm text-muted-foreground">
        No pending debits
      </div>
    {:else}
      <div class="space-y-3">
        {#each payments as payment}
          <div class="flex justify-between items-center border-b pb-2">
            <div>
              <div class="font-medium text-sm truncate max-w-[200px]">
                <Badge variant="default" class="text-xs">
                  {payment.invoice?.invoiceId || 'N/A'}
                </Badge>
              </div>
              <div
                class="mt-1 text-xs text-muted-foreground flex items-center gap-1"
              >
                <span>{formatDate(payment.paymentDate)}</span> /
                <span
                  >{payment.paymentReference || payment.id?.slice(0, 8)}
                </span>
              </div>
            </div>
            <div class="flex items-center gap-2">
              <div class="text-sm font-semibold">
                {formatCurrency(payment.amount, 'MDL')}
              </div>

              <Button
                variant="ghost"
                size="sm"
                class="h-8 w-8 p-0"
                on:click={() => openInvoice(payment.invoice?.id)}
              >
                <Icon icon="mdi:open-in-new" class="h-4 w-4" />
              </Button>
            </div>
          </div>
        {/each}

        <div class="pt-2 flex justify-between items-center">
          <span class="text-sm font-medium">Total Pending</span>
          <span class="text-sm font-bold">
            {formatCurrency(totalPending, 'MDL')}
          </span>
        </div>
      </div>
    {/if}
  </CardContent>
</Card>
