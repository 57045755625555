<script lang="ts">
  import { createQuery } from '@tanstack/svelte-query';
  import * as Tabs from '$lib/components/ui/tabs/index.js';
  import * as Card from '$lib/components/ui/card/index.js';
  import { Badge } from '$lib/components/ui/badge';
  import { getCustomerActivityReport } from '$lib/api/reports-api';
  import { type DateRange } from '$lib/components/hanta/date/hanta-date-range';
  import { today, getLocalTimeZone } from '@internationalized/date';
  import { popupStore } from '$lib/stores/app-store';
  import Button from '$lib/components/ui/button/button.svelte';
  import Icon from '@iconify/svelte';
  import { Input } from '$lib/components/ui/input';

  const DEFAULT_END_DATE = today(getLocalTimeZone()).set({
    month: 12,
    day: 31,
  });
  const DEFAULT_START_DATE = DEFAULT_END_DATE.set({ month: 1, day: 1 });

  export let dateRange: DateRange;

  // Ensure dateRange has valid start and end dates
  $: safeStartDate = dateRange?.start || DEFAULT_START_DATE;
  $: safeEndDate = dateRange?.end || DEFAULT_END_DATE;

  let activeTab = 'all'; // State to track the active tab
  let searchQuery = ''; // State to track the search query

  $: customerReportQuery = createQuery({
    queryKey: ['customer_activity_report', safeStartDate, safeEndDate],
    queryFn: () =>
      getCustomerActivityReport({
        startDate: safeStartDate.toString(),
        endDate: safeEndDate.toString(),
      }),
  });

  // Filter customers based on search query and active tab
  $: customers = (
    (activeTab === 'lost'
      ? $customerReportQuery.data?.lostCustomers
      : $customerReportQuery.data?.topCustomers) || []
  ).map(el => el.jsonb_build_object);

  // Sort lost customers by total spent in descending order if on lost tab
  $: sortedCustomers =
    activeTab === 'lost'
      ? [...customers].sort(
          (a, b) =>
            (b.previous?.totalSpent || 0) - (a.previous?.totalSpent || 0),
        )
      : customers;

  $: filteredCustomers = (sortedCustomers || []).filter(el => {
    // First filter by tab
    const tabFilter =
      (activeTab === 'new' && el.new_customer) ||
      activeTab === 'lost' ||
      activeTab === 'all';

    // Then filter by search query if present
    const searchFilter =
      !searchQuery ||
      (el.customerName &&
        el.customerName.toLowerCase().includes(searchQuery.toLowerCase()));

    return tabFilter && searchFilter;
  });

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('de', {
      style: 'currency',
      currency: 'EUR',
    }).format(value);
  };
</script>

<div class="space-y-4 p-4">
  <Tabs.Root bind:value={activeTab}>
    <Tabs.List class="grid grid-cols-3 md:grid-cols-6 mb-4">
      <Tabs.Trigger value="all">All</Tabs.Trigger>
      <Tabs.Trigger value="lost"
        >Lost <span class="hidden md:block ml-1">Customers</span></Tabs.Trigger
      >
      <Tabs.Trigger value="new"
        >Won <span class="hidden md:block ml-1">Customers</span></Tabs.Trigger
      >
    </Tabs.List>
  </Tabs.Root>

  <!-- Search input -->
  <div class="relative">
    <div
      class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
    >
      <Icon icon="mdi:magnify" class="size-5 text-muted-foreground" />
    </div>
    <Input
      type="search"
      placeholder="Search customers..."
      bind:value={searchQuery}
      class="pl-10"
    />
  </div>

  {#if $customerReportQuery.isLoading}
    <div class="text-center">Loading report...</div>
  {:else if $customerReportQuery.isError}
    <div class="text-red-500">Error loading report</div>
  {:else if $customerReportQuery.data}
    {#if filteredCustomers.length === 0}
      <div class="text-center py-8 text-muted-foreground">
        <Icon icon="mdi:account-search-outline" class="size-12 mx-auto mb-2" />
        <p>No customers found matching your criteria</p>
        {#if searchQuery}
          <Button
            variant="ghost"
            size="sm"
            class="mt-2"
            on:click={() => (searchQuery = '')}
          >
            Clear search
          </Button>
        {/if}
      </div>
    {:else}
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {#each filteredCustomers as report, index}
          <Card.Root>
            <Card.Header>
              <Card.Title class="flex items-center">
                <span class="text-muted-foreground text-base mr-1"
                  >{index + 1}.</span
                >
                <Button
                  variant="link"
                  class="-ml-3"
                  size="sm"
                  on:click={() => {
                    popupStore.openPopup('crmObject', {
                      module: 'accounts',
                      id: report.customer,
                    });
                  }}
                >
                  {report.customerName || 'Unknown Customer'}
                  <Icon
                    icon="mdi:open-in-new"
                    class="size-5 ml-1 hover:text-blue-900 text-primary/80"
                  />
                </Button>
                {#if report.new_customer}
                  <Badge class="ml-2">New</Badge>
                {/if}
                {#if report.lost_customer}
                  <Badge class="ml-2" variant="outline">Lost</Badge>
                {/if}
              </Card.Title>
              <div class="text-xs text-muted-foreground">
                {report.customer}
              </div>
            </Card.Header>
            <Card.Content>
              {#if activeTab !== 'lost'}
                <div class="space-y-2">
                  <div class="flex justify-between">
                    <span>Total Spent:</span>
                    <Badge variant="secondary">
                      {report.current?.totalSpent
                        ? formatCurrency(report.current.totalSpent)
                        : 'N/A'}
                    </Badge>
                  </div>
                  <div class="flex justify-between">
                    <span>Avg Purchase:</span>
                    <Badge variant="outline">
                      {report.current?.avgPurchase
                        ? formatCurrency(report.current.avgPurchase)
                        : 'N/A'}
                    </Badge>
                  </div>
                  <div class="flex justify-between">
                    <span>First Purchase:</span>
                    <span
                      >{report.current?.firstPurchase
                        ? new Date(
                            report.current.firstPurchase,
                          ).toLocaleDateString()
                        : 'N/A'}</span
                    >
                  </div>
                  <div class="flex justify-between">
                    <span>Last Purchase:</span>
                    <span
                      >{report.current?.lastPurchase
                        ? new Date(
                            report.current.lastPurchase,
                          ).toLocaleDateString()
                        : 'N/A'}</span
                    >
                  </div>
                </div>
              {:else}
                <!-- Lost customers statistics -->
                <div class="space-y-2">
                  <div class="text-sm font-medium mb-2">
                    Previous Period Activity:
                  </div>
                  <div class="flex justify-between">
                    <span>Total Spent:</span>
                    <Badge variant="secondary">
                      {report.previous?.totalSpent
                        ? formatCurrency(report.previous.totalSpent)
                        : '€0.00'}
                    </Badge>
                  </div>
                  <div class="flex justify-between">
                    <span>Invoices:</span>
                    <Badge variant="outline">
                      {report.previous?.invoiceCount || 0}
                    </Badge>
                  </div>
                  <div class="flex justify-between">
                    <span>Avg Purchase:</span>
                    <Badge variant="outline">
                      {report.previous?.avgPurchase
                        ? formatCurrency(report.previous.avgPurchase)
                        : '€0.00'}
                    </Badge>
                  </div>
                  <div class="flex justify-between">
                    <span>Last Purchase:</span>
                    <span
                      >{report.previous?.lastPurchase
                        ? new Date(
                            report.previous.lastPurchase,
                          ).toLocaleDateString()
                        : 'N/A'}</span
                    >
                  </div>
                  <div class="flex justify-between mt-3 pt-2 border-t">
                    <span>Last Activity:</span>
                    <span class="text-red-500 font-medium"
                      >{report.lastInvoiceDate
                        ? new Date(report.lastInvoiceDate).toLocaleDateString()
                        : 'Unknown'}</span
                    >
                  </div>
                  <div class="flex justify-between">
                    <span>Days Inactive:</span>
                    <span class="text-red-500 font-medium"
                      >{report.daysSinceLastPurchase || 'Unknown'} days</span
                    >
                  </div>
                </div>
              {/if}
            </Card.Content>
          </Card.Root>
        {/each}
      </div>
    {/if}
  {/if}
</div>
