<script lang="ts">
  import * as Card from '$lib/components/ui/card';
  import { Input } from '$lib/components/ui/input';
  import { Textarea } from '$lib/components/ui/textarea';
  import { Button } from '$lib/components/ui/button';
  import Icon from '@iconify/svelte';
  import ItemSelectionDialog from './item-selection-dialog.svelte';
  import type { Phase } from '$lib/types/pipeline';
  import { writable } from 'svelte/store';
  import { updatePipelinePhase } from '$lib/api/mutations';
  import { toast } from 'svelte-sonner';
  import { fade, scale } from 'svelte/transition';
  import { quintOut } from 'svelte/easing';

  export let phase: Phase;
  export let isPreviewMode = false;
  export let module: string;
  export let onRemove: (id: string) => void;
  export let onAddItem: (item: any) => void;
  export let onRemoveItem: (phaseId: string, itemId: string) => void;

  let showItemDialog = false;
  const isLoading = writable(false);
  let isRemoving = false; // Track removal state

  // Store original values to detect changes
  let originalName = phase.name;
  let originalDescription = phase.description;

  async function handleAddItem(item: any) {
    try {
      $isLoading = true;
      await onAddItem(item);
    } finally {
      $isLoading = false;
      showItemDialog = false;
    }
  }

  async function handleRemoveItem(phaseId: string, itemId: string) {
    try {
      $isLoading = true;
      await onRemoveItem(phaseId, itemId);
    } finally {
      $isLoading = false;
    }
  }

  // Track changes without saving immediately
  function handleInputChange() {
    // Changes will be saved by the central save button
    console.log('Phase changed, waiting for save:', phase.id);
  }

  // Handle removal with transition
  function handleRemove() {
    isRemoving = true;
    // Delay the actual removal to allow the transition to complete
    setTimeout(() => {
      onRemove(phase.id);
    }, 300); // Match this with the transition duration
  }

  async function savePhaseChanges() {
    try {
      $isLoading = true;
      // Only save if there are actual changes
      if (
        phase.name !== originalName ||
        phase.description !== originalDescription
      ) {
        console.log('Saving phase changes:', {
          name: phase.name,
          description: phase.description,
          originalDescription,
        });

        await updatePipelinePhase(phase.id, {
          name: phase.name,
          description: phase.description,
        });

        // Update original values after successful save
        originalName = phase.name;
        originalDescription = phase.description;
        toast.success('Phase saved successfully');
      }
    } catch (error) {
      console.error('Failed to save phase:', error);
      toast.error('Failed to save phase');
    } finally {
      $isLoading = false;
    }
  }
</script>

<ItemSelectionDialog
  bind:showDialog={showItemDialog}
  {module}
  onSelect={handleAddItem}
  onClose={() => (showItemDialog = false)}
/>

{#if !isRemoving}
  <div transition:scale={{ duration: 300, easing: quintOut }}>
    <Card.Root
      class="w-72 relative border rounded-lg shadow-sm hover:shadow-md transition-all duration-200"
      style="border-top: 4px solid {phase.customFields?.color || '#6366f1'};"
    >
      <Card.Content class="p-0">
        {#if isPreviewMode}
          <div class="space-y-4 p-5">
            <div
              class="flex justify-between items-center pb-2 border-b border-muted/40"
            >
              <h3
                class="font-semibold text-md truncate"
                title={phase.name || 'Untitled Phase'}
              >
                {phase.name || 'Untitled Phase'}
              </h3>
              <Button
                variant="ghost"
                size="sm"
                class="h-7 w-7 p-0 rounded-full bg-primary/10 hover:bg-primary/20 text-primary"
                on:click={() => (showItemDialog = true)}
                disabled={$isLoading}
                title="Add item"
              >
                <Icon icon="mdi:plus" class="w-4 h-4" />
              </Button>
            </div>

            <div class="space-y-3">
              {#if phase.description}
                <div
                  class="bg-muted/30 rounded-md p-3 border-l-2 border-primary/40"
                >
                  <div class="flex gap-2 items-start">
                    <Icon
                      icon="mdi:information-outline"
                      class="w-4 h-4 text-primary mt-0.5 flex-shrink-0"
                    />
                    <p
                      class="text-sm text-muted-foreground leading-relaxed line-clamp-3"
                      title={phase.description}
                    >
                      {phase.description}
                    </p>
                  </div>
                </div>
              {/if}

              <div
                class="flex items-center gap-2 text-xs text-muted-foreground"
              >
                <Icon icon="mdi:cube-outline" class="w-3.5 h-3.5" />
                <span>
                  {(phase.items || []).length}
                  {(phase.items || []).length === 1 ? 'item' : 'items'}
                </span>
                <span
                  class="ml-auto bg-primary/10 text-primary px-2 py-0.5 rounded-full font-medium"
                >
                  Phase {phase.sort !== undefined
                    ? phase.sort + 1
                    : phase.order !== undefined
                      ? phase.order + 1
                      : '-'}
                </span>
              </div>
            </div>

            <!-- Items List -->
            <div
              class="space-y-2 max-h-48 overflow-y-auto pr-1 custom-scrollbar border-t border-muted/40 pt-3"
            >
              <div class="flex items-center justify-between mb-1">
                <h4
                  class="text-xs font-medium text-muted-foreground flex items-center gap-1.5"
                >
                  <Icon icon="mdi:format-list-bulleted" class="w-3.5 h-3.5" />
                  Items
                </h4>
                {#if (phase.items || []).length > 0}
                  <span class="text-xs text-muted-foreground"
                    >{(phase.items || []).length} total</span
                  >
                {/if}
              </div>

              {#each phase.items || [] as item (item.id)}
                <div
                  class="flex justify-between items-center p-2 rounded-lg bg-muted/60 hover:bg-muted transition-colors border border-muted/30"
                >
                  <span class="text-sm truncate flex-1" title={item.name}
                    >{item.name}</span
                  >
                  <button
                    class="text-destructive opacity-70 hover:opacity-100 h-6 w-6 rounded-full flex items-center justify-center hover:bg-destructive/10 transition-colors"
                    on:click={() => handleRemoveItem(phase.id, item.id)}
                    disabled={$isLoading}
                    title="Remove item"
                  >
                    <Icon icon="mdi:close" class="w-3.5 h-3.5" />
                  </button>
                </div>
              {/each}

              {#if (phase.items || []).length === 0}
                <div
                  class="flex items-center justify-center py-4 border border-dashed rounded-lg border-muted-foreground/30"
                >
                  <p class="text-xs text-muted-foreground">No items added</p>
                </div>
              {/if}
            </div>
          </div>
        {:else}
          <div class="p-5">
            <div class="flex items-center justify-between mb-4 border-b pb-2">
              <h3 class="font-medium text-md">Edit Phase</h3>

              <div class="flex gap-1">
                <Button
                  variant="ghost"
                  size="sm"
                  class="h-7 w-7 p-0 rounded-full hover:bg-muted"
                  title="Color picker"
                >
                  <div
                    class="w-4 h-4 rounded-full"
                    style="background-color: {phase.customFields?.color ||
                      '#6366f1'};"
                  ></div>
                </Button>

                <Button
                  variant="ghost"
                  size="sm"
                  class="h-7 w-7 p-0 rounded-full text-destructive hover:bg-destructive/10"
                  on:click={handleRemove}
                  disabled={isRemoving || $isLoading}
                  title="Remove phase"
                >
                  <Icon icon="mdi:trash" class="w-4 h-4" />
                </Button>
              </div>
            </div>

            <slot />
            <div class="space-y-4">
              <div>
                <label
                  for="phase-name-{phase.id}"
                  class="text-xs font-medium flex items-center gap-1 text-foreground mb-1.5"
                >
                  <Icon icon="mdi:tag-outline" class="w-3.5 h-3.5" />
                  Phase Name
                </label>
                <Input
                  id="phase-name-{phase.id}"
                  bind:value={phase.name}
                  placeholder="Enter phase name"
                  on:input={handleInputChange}
                  class="border-muted focus-visible:ring-1 focus-visible:ring-primary"
                />
              </div>

              <div>
                <label
                  for="phase-desc-{phase.id}"
                  class="text-xs font-medium flex items-center gap-1 text-foreground mb-1.5"
                >
                  <Icon icon="mdi:text-box-outline" class="w-3.5 h-3.5" />
                  Description
                </label>
                <div class="relative">
                  <Textarea
                    id="phase-desc-{phase.id}"
                    bind:value={phase.description}
                    placeholder="Add details about this phase and what it represents in your pipeline..."
                    rows={4}
                    on:input={handleInputChange}
                    class="border-muted resize-none focus-visible:ring-1 focus-visible:ring-primary pr-20"
                  />
                  <div
                    class="absolute bottom-2.5 right-2.5 text-xs text-muted-foreground/60 pointer-events-none"
                  >
                    {phase.description?.length || 0} chars
                  </div>
                </div>
                <p class="text-xs text-muted-foreground mt-1">
                  A clear description helps team members understand the purpose
                  of this phase.
                </p>
              </div>

              <div
                class="flex justify-between items-center mt-4 pt-3 border-t border-muted"
              >
                <div class="text-xs text-muted-foreground">
                  Position: <span class="font-medium"
                    >{phase.sort !== undefined
                      ? phase.sort + 1
                      : phase.order !== undefined
                        ? phase.order + 1
                        : '-'}</span
                  >
                </div>

                <Button
                  variant="outline"
                  size="sm"
                  class="h-8 rounded-md hidden"
                  on:click={savePhaseChanges}
                  disabled={$isLoading}
                >
                  <Icon
                    icon="mdi:content-save-outline"
                    class="w-3.5 h-3.5 mr-1"
                  />
                  <span class="text-xs">Save Phase</span>
                </Button>
              </div>
            </div>
          </div>
        {/if}
      </Card.Content>
    </Card.Root>
  </div>
{/if}
