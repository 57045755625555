import { writable, derived } from 'svelte/store';
import type { Pipeline, Phase } from '../types/pipeline';
import { pipelineStore } from './pipeline-store';

// UI State stores
export const isLoading = writable(false);
export const error = writable<string | null>(null);
export const selectedPipelineId = writable<string | null>(null);
export const isPreviewMode = writable(false);
export const isDragging = writable(false);

// Derived stores
export const selectedPipeline = derived(
  [selectedPipelineId, pipelineStore],
  ([$selectedPipelineId, $pipelines]) => 
    $pipelines.find(p => p.id === $selectedPipelineId) || null
);

export const selectedPhases = derived(
  selectedPipeline,
  $selectedPipeline => $selectedPipeline?.phases || []
);

function createPipelineUIStore() {
  return {
    // Pipeline selection
    selectPipeline: (id: string | null) => {
      selectedPipelineId.set(id);
    },

    // Preview mode
    togglePreviewMode: () => {
      isPreviewMode.update(value => !value);
    },
    
    setPreviewMode: (value: boolean) => {
      isPreviewMode.set(value);
    },

    // Loading state
    startLoading: () => {
      isLoading.set(true);
      error.set(null);
    },

    finishLoading: () => {
      isLoading.set(false);
    },

    // Error handling
    setError: (message: string | null) => {
      error.set(message);
    },

    // Drag state
    setDragging: (value: boolean) => {
      isDragging.set(value);
    },

    // Reset all UI state
    reset: () => {
      selectedPipelineId.set(null);
      isPreviewMode.set(false);
      isLoading.set(false);
      error.set(null);
      isDragging.set(false);
    }
  };
}

export const pipelineUIStore = createPipelineUIStore();
