<script lang="ts">
  import { createQuery } from '@tanstack/svelte-query';
  import * as Tabs from '$lib/components/ui/tabs/index.js';
  import * as Card from '$lib/components/ui/card/index.js';
  import { Badge } from '$lib/components/ui/badge';
  import Icon from '@iconify/svelte';
  import { format } from 'date-fns';
  import { formatDate } from '$lib/utils/hanta-utils';
  import { getSalesReport, getSalesBonusReport } from '$lib/api/reports-api';
  import { type DateRange } from '$lib/components/hanta/date/hanta-date-range';
  import { today, getLocalTimeZone } from '@internationalized/date';
  import * as Table from '$lib/components/ui/table/index.js';
  import { Separator } from '$lib/components/ui/separator/index.js';
  import { Progress } from '$lib/components/ui/progress/index.js';
  import InvoiceStatus from '$lib/components/hanta/invoice-status.svelte';
  import Button from '$lib/components/ui/button/button.svelte';
  import { popupStore } from '$lib/stores/app-store';
  import { onMount } from 'svelte';
  import { getItems } from '$lib/api/queries';
  import FedexReport from './fedex-report.svelte';

  const DEFAULT_END_DATE = today(getLocalTimeZone());
  const DEFAULT_START_DATE = DEFAULT_END_DATE.subtract({ years: 1 });

  export let dateRange: DateRange;
  let activeTab = 'overview';
  let activeSalesRepTab = '';

  // Function to format dates for PostgreSQL
  const formatDateForPostgres = date => {
    if (!date) return null;
    // Convert the internationalized date to a JavaScript Date
    // Extract the date part in YYYY-MM-DD format
    const parts = date.toString().split('T')[0];
    return parts;
  };

  $: salesReportQuery = createQuery({
    queryKey: ['sales_report', dateRange?.start, dateRange?.end],
    queryFn: () =>
      getSalesReport({
        startDate: formatDateForPostgres(dateRange?.start),
        endDate: formatDateForPostgres(dateRange?.end),
      }),
  });

  $: salesBonusReportQuery = createQuery({
    queryKey: ['sales_bonus_report', dateRange?.start, dateRange?.end],
    queryFn: () =>
      getSalesBonusReport({
        startDate: formatDateForPostgres(dateRange?.start),
        endDate: formatDateForPostgres(dateRange?.end),
      }),
  });

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('de', {
      style: 'currency',
      currency: 'EUR',
    }).format(value);
  };

  const formatCurrencyMDL = (value: number) => {
    return new Intl.NumberFormat('de', {
      style: 'currency',
      currency: 'MDL',
      maximumFractionDigits: 0,
    }).format(value);
  };

  const calculatePercentage = (value: number, total: number) => {
    if (total === 0) return 0;
    return (value / total) * 100;
  };

  let users = [];
  onMount(() => {
    getItems({
      select: 'id,name,customFields',
      collection: 'users',
      from: 0,
      to: 100,
    }).then(({ data }) => {
      users = (data ?? []).filter(
        user =>
          user.customFields?.bonus?.old > 0 ||
          user.customFields?.bonus?.new > 0,
      );
    });
  });

  // Function to calculate bonus amount for an invoice
  function calculateBonus(invoice, salesRep) {
    if (!salesRep || !salesRep.customFields?.bonus) {
      console.log('No sales rep or bonus data for invoice:', invoice?.id);
      return 0;
    }
    if (!invoice || !invoice.revenueNational) {
      console.log('No invoice or revenueNational for invoice:', invoice?.id);
      return 0;
    }

    // Ensure we have valid bonus rates
    const newClientRate = Number(salesRep.customFields.bonus.new || 0);
    const existingClientRate = Number(salesRep.customFields.bonus.old || 0);

    // Determine which rate to use based on customer status
    const bonusRate = invoice.customer.isNew
      ? newClientRate
      : existingClientRate;

    // Calculate the bonus amount
    const bonusAmount = invoice.revenueNational * bonusRate;

    // Log detailed bonus calculation
    console.log('Bonus calculation details:', {
      invoiceId: invoice.id,
      salesRepId: salesRep.id,
      isNewCustomer: invoice.customer.isNew,
      revenueNational: invoice.revenueNational,
      newClientRate,
      existingClientRate,
      appliedRate: bonusRate,
      calculatedBonus: bonusAmount,
      finalBonus: isNaN(bonusAmount) || bonusAmount < 0 ? 0 : bonusAmount,
    });

    // Return 0 if NaN or negative, otherwise return the calculated amount
    return isNaN(bonusAmount) || bonusAmount < 0 ? 0 : bonusAmount;
  }

  // Function to calculate total bonus for a sales rep
  function calculateTotalBonus(salesRep, invoices) {
    if (!salesRep || !invoices || !Array.isArray(invoices)) return 0;

    const salesRepUser = users?.find(user => user.id === salesRep.salesRep.id);
    if (!salesRepUser) return 0;

    try {
      return invoices
        .filter(invoice => invoice.salesRep.id === salesRep.salesRep.id)
        .reduce((total, invoice) => {
          const bonus = calculateBonus(invoice, salesRepUser);
          return total + (isNaN(bonus) ? 0 : bonus);
        }, 0);
    } catch (error) {
      console.error('Error calculating total bonus:', error);
      return 0;
    }
  }

  // Calculate bonus for a customer
  function calculateCustomerBonus(customer, invoices, salesRepId) {
    if (!customer || !invoices || !Array.isArray(invoices) || !salesRepId)
      return 0;

    const salesRepUser = users?.find(user => user.id === salesRepId);
    if (!salesRepUser) return 0;

    try {
      return invoices
        .filter(
          invoice =>
            invoice.customer.id === customer.id &&
            invoice.salesRep.id === salesRepId,
        )
        .reduce((total, invoice) => {
          const bonus = calculateBonus(invoice, salesRepUser);
          return total + (isNaN(bonus) ? 0 : bonus);
        }, 0);
    } catch (error) {
      console.error('Error calculating customer bonus:', error);
      return 0;
    }
  }

  // Calculate total bonus for all sales reps
  function calculateTotalBonusAllReps(invoices) {
    if (!invoices || !Array.isArray(invoices)) return 0;

    try {
      return invoices.reduce((total, invoice) => {
        const salesRepUser = users?.find(
          user => user.id === invoice.salesRep.id,
        );
        if (salesRepUser) {
          const bonus = calculateBonus(invoice, salesRepUser);
          return total + (isNaN(bonus) ? 0 : bonus);
        }
        return total;
      }, 0);
    } catch (error) {
      console.error('Error calculating total bonus for all reps:', error);
      return 0;
    }
  }

  // Track expanded customer sections
  let expandedCustomers = {};

  function toggleCustomerExpanded(customerId) {
    expandedCustomers[customerId] = !expandedCustomers[customerId];
    expandedCustomers = { ...expandedCustomers }; // Trigger reactivity
  }

  // Group invoices by customer
  function groupInvoicesByCustomer(invoices, salesRepId) {
    // Filter invoices by sales rep
    const filteredInvoices = invoices.filter(
      invoice => invoice.salesRep.id === salesRepId,
    );

    // Group by customer
    const groupedInvoices = {};
    filteredInvoices.forEach(invoice => {
      const customerId = invoice.customer.id;
      if (!groupedInvoices[customerId]) {
        groupedInvoices[customerId] = {
          customer: invoice.customer,
          invoices: [],
          totalRevenue: 0,
          exportInvoices: [],
          importInvoices: [],
          exportRevenue: 0,
          importRevenue: 0,
        };
      }
      groupedInvoices[customerId].invoices.push(invoice);
      groupedInvoices[customerId].totalRevenue += invoice.revenueTotal;

      // Separate export and import invoices
      if (invoice.type === 'export') {
        groupedInvoices[customerId].exportInvoices.push(invoice);
        groupedInvoices[customerId].exportRevenue += invoice.revenueTotal;
      } else {
        groupedInvoices[customerId].importInvoices.push(invoice);
        groupedInvoices[customerId].importRevenue += invoice.revenueTotal;
      }
    });

    // Convert to array and sort by total revenue
    return Object.values(groupedInvoices).sort(
      (a, b) => b.totalRevenue - a.totalRevenue,
    );
  }
</script>

<div class="space-y-4 p-4">
  <Tabs.Root bind:value={activeTab}>
    <Tabs.List class="grid grid-cols-5 mb-4">
      <Tabs.Trigger value="overview">Overview</Tabs.Trigger>
      <Tabs.Trigger value="export-import">Sales Bonus</Tabs.Trigger>

      <Tabs.Trigger value="by-sales-rep">By Sales Rep</Tabs.Trigger>
      <Tabs.Trigger value="invoices">Invoices</Tabs.Trigger>
    </Tabs.List>

    {#if $salesReportQuery.isLoading || $salesBonusReportQuery.isLoading}
      <div class="text-center py-8">
        <Icon icon="line-md:loading-twotone-loop" class="w-8 h-8 mx-auto" />
        <p class="mt-2">Loading sales report...</p>
      </div>
    {:else if $salesReportQuery.isError || $salesBonusReportQuery.isError}
      <div class="text-red-500 text-center py-8">
        <Icon icon="line-md:alert" class="w-8 h-8 mx-auto text-red-500" />
        <p class="mt-2">Error loading sales report</p>
        <p class="text-sm text-red-400 mt-2">
          {$salesReportQuery.error?.message ||
            $salesBonusReportQuery.error?.message ||
            'Unknown error'}
        </p>
      </div>
    {:else if $salesReportQuery.data && $salesBonusReportQuery.data}
      <Tabs.Content value="overview">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
          <Card.Root>
            <Card.Header>
              <Card.Title>Sales Summary</Card.Title>
              <Card.Description>
                {formatDate(dateRange?.start.toString())} - {formatDate(
                  dateRange?.end.toString(),
                )}
              </Card.Description>
            </Card.Header>
            <Card.Content>
              <div class="space-y-4">
                <div class="flex justify-between items-center">
                  <span class="font-medium">Total Invoices:</span>
                  <Badge variant="outline"
                    >{$salesReportQuery.data.totalInvoices}</Badge
                  >
                </div>
                <div class="flex justify-between items-center">
                  <span class="font-medium">Total Net:</span>
                  <span>{formatCurrency($salesReportQuery.data.totalNet)}</span>
                </div>
                <div class="flex justify-between items-center">
                  <span class="font-medium">Total Gross:</span>
                  <span
                    >{formatCurrency($salesReportQuery.data.totalGross)}</span
                  >
                </div>
                <div class="flex justify-between items-center">
                  <span class="font-medium">Total Revenue:</span>
                  <span
                    >{formatCurrency($salesReportQuery.data.revenueTotal)}</span
                  >
                </div>
                <div class="flex justify-between items-center">
                  <span class="font-medium">Total Supplier:</span>
                  <span
                    >{formatCurrency(
                      $salesReportQuery.data.supplierTotal,
                    )}</span
                  >
                </div>
              </div>
            </Card.Content>
          </Card.Root>

          <Card.Root>
            <Card.Header>
              <Card.Title>Top Sales Representatives</Card.Title>
              <Card.Description>
                Based on total revenue in selected period
              </Card.Description>
            </Card.Header>
            <Card.Content>
              <div class="space-y-4">
                {#each $salesReportQuery.data.salesBySalesRep.slice(0, 5) as salesRep}
                  <div class="space-y-1">
                    <div class="flex items-center justify-between">
                      <div class="flex items-center gap-2">
                        <span class="text-sm font-medium"
                          >{salesRep.salesRep.name}</span
                        >
                      </div>
                      <div class="flex items-center gap-2">
                        <span class="text-sm"
                          >{formatCurrency(salesRep.revenueTotal)}</span
                        >
                      </div>
                    </div>
                    <Progress
                      value={calculatePercentage(
                        salesRep.revenueTotal,
                        $salesReportQuery.data.revenueTotal,
                      )}
                    />
                  </div>
                {/each}
              </div>
            </Card.Content>
          </Card.Root>
        </div>
      </Tabs.Content>

      <Tabs.Content value="by-sales-rep">
        <div class="space-y-6">
          {#each $salesReportQuery.data.salesBySalesRep as salesRep}
            <Card.Root>
              <Card.Header>
                <div class="flex items-center gap-3">
                  <div>
                    <Card.Title>{salesRep.salesRep.name}</Card.Title>
                    <Card.Description>
                      {salesRep.invoiceCount} invoices | {formatCurrency(
                        salesRep.revenueTotal,
                      )} revenue
                      {#if users?.some(user => (user.id === salesRep.salesRep.id && user.customFields?.bonus?.old > 0) || user.customFields?.bonus?.new > 0)}
                        | {formatCurrency(
                          calculateTotalBonus(
                            salesRep,
                            $salesReportQuery.data.invoices,
                          ),
                        )} bonus
                      {/if}
                    </Card.Description>
                  </div>
                </div>
              </Card.Header>
              <Card.Content>
                <div class="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                  <div class="p-3 bg-muted rounded-md hidden">
                    <div class="text-sm text-muted-foreground">Total Net</div>
                    <div class="text-lg font-semibold">
                      {formatCurrency(salesRep.totalNet)}
                    </div>
                  </div>
                  <div class="p-3 bg-muted rounded-md">
                    <div class="text-sm text-muted-foreground">Total Gross</div>
                    <div class="text-lg font-semibold">
                      {formatCurrency(salesRep.totalGross)}
                    </div>
                  </div>
                  <div class="p-3 bg-muted rounded-md">
                    <div class="text-sm text-muted-foreground">
                      Total Supplier
                    </div>
                    <div class="text-lg font-semibold">
                      {formatCurrency(salesRep.supplierTotal)}
                    </div>
                  </div>
                  <div class="p-3 bg-muted rounded-md">
                    <div class="text-sm text-muted-foreground">
                      Total Revenue
                    </div>
                    <div class="text-lg font-semibold">
                      {formatCurrency(salesRep.revenueTotal)}
                    </div>
                  </div>
                </div>

                <Separator class="my-4" />

                <div class="text-sm font-medium mb-2">
                  Customers ({salesRep.customers.length})
                </div>
                {#if salesRep.customers.length > 0}
                  <Table.Root>
                    <Table.Header>
                      <Table.Row>
                        <Table.Head>Customer</Table.Head>
                        <Table.Head class="text-right">Invoices</Table.Head>
                        <Table.Head class="text-right">Total</Table.Head>
                        <Table.Head class="text-right">Revenue</Table.Head>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {#each salesRep.customers.sort((a, b) => b.totalSpent - a.totalSpent) as customer}
                        <Table.Row>
                          <Table.Cell>
                            <Button
                              variant="link"
                              size="sm"
                              on:click={() => {
                                popupStore.openPopup('crmObject', {
                                  module: 'accounts',
                                  id: customer.id,
                                });
                              }}
                            >
                              {customer.name}
                            </Button>
                            {#if $salesBonusReportQuery.data?.invoices.some(invoice => invoice.customer.id === customer.id && invoice.customer.isNew)}
                              <Badge
                                variant="outline"
                                class="ml-1 bg-blue-500 text-white"
                              >
                                New Client {customer.newClientMonth
                                  ? `(Month ${customer.newClientMonth})`
                                  : ''}
                              </Badge>
                            {/if}
                          </Table.Cell>
                          <Table.Cell class="text-right"
                            >{customer.invoiceCount}</Table.Cell
                          >
                          <Table.Cell class="text-right"
                            >{formatCurrency(customer.totalSpent)}</Table.Cell
                          >
                          <Table.Cell class="text-right">
                            {formatCurrency(customer.revenueTotal)}
                            {#if users?.some(user => (user.id === salesRep.salesRep.id && user.customFields?.bonus?.old > 0) || user.customFields?.bonus?.new > 0)}
                              <div class="text-xs text-green-600 font-semibold">
                                ({formatCurrency(
                                  calculateCustomerBonus(
                                    customer,
                                    $salesReportQuery.data.invoices,
                                    salesRep.salesRep.id,
                                  ),
                                )})
                              </div>
                            {/if}
                          </Table.Cell>
                        </Table.Row>
                      {/each}
                    </Table.Body>
                  </Table.Root>
                {:else}
                  <div class="text-center py-4 text-muted-foreground">
                    No customers found
                  </div>
                {/if}
              </Card.Content>
            </Card.Root>
          {/each}
        </div>
      </Tabs.Content>

      <Tabs.Content value="invoices">
        <div class="rounded-md border">
          <Table.Root>
            <Table.Header>
              <Table.Row>
                <Table.Head>Invoice ID</Table.Head>
                <Table.Head>Date</Table.Head>
                <Table.Head>Paid On</Table.Head>
                <Table.Head>Customer</Table.Head>
                <Table.Head>Sales Rep</Table.Head>
                <Table.Head class="text-right">Gross</Table.Head>
                <Table.Head class="text-right">Supplier</Table.Head>
                <Table.Head class="text-right">Revenue</Table.Head>
                <Table.Head>Status</Table.Head>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {#each $salesReportQuery.data.invoices as invoice}
                <Table.Row>
                  <Table.Cell class="font-medium">
                    <Button
                      variant="link"
                      size="sm"
                      on:click={() => {
                        popupStore.openPopup('crmObject', {
                          module: 'invoices',
                          id: invoice.id,
                        });
                      }}
                    >
                      {invoice.number}
                    </Button>
                  </Table.Cell>
                  <Table.Cell>{formatDate(invoice.date)}</Table.Cell>
                  <Table.Cell>{formatDate(invoice.paidOn)}</Table.Cell>
                  <Table.Cell>
                    {invoice.customer.name}
                    {#if invoice.customer.isNew}
                      <Badge
                        variant="outline"
                        class="ml-1 bg-blue-500 text-white"
                      >
                        New Client {invoice.customer.newClientMonth
                          ? `(Month ${invoice.customer.newClientMonth})`
                          : ''}
                      </Badge>
                    {/if}
                  </Table.Cell>
                  <Table.Cell>
                    <div class="flex items-center gap-2">
                      <span>{invoice.salesRep.name}</span>
                    </div>
                  </Table.Cell>

                  <Table.Cell class="text-right"
                    >{formatCurrency(invoice.gross)}</Table.Cell
                  >
                  <Table.Cell class="text-right"
                    >{formatCurrency(invoice.supplierTotal)}</Table.Cell
                  >
                  <Table.Cell class="text-right">
                    {formatCurrency(invoice.revenueTotal)}
                  </Table.Cell>
                  <Table.Cell>
                    <div class="flex items-center gap-2">
                      <InvoiceStatus status={invoice.status || 'unknown'} />
                      <Badge variant="outline" class="bg-blue-100 text-blue-800"
                        >Export</Badge
                      >
                    </div>
                  </Table.Cell>
                </Table.Row>
              {/each}
            </Table.Body>
          </Table.Root>
        </div>
      </Tabs.Content>

      <Tabs.Content value="export-import">
        <Card.Root>
          <Card.Header>
            <Card.Title>Sales Bonus Report</Card.Title>
            <Card.Description>
              Special invoices - {formatDate(dateRange?.start.toString())} to {formatDate(
                dateRange?.end.toString(),
              )}
            </Card.Description>
          </Card.Header>
          <Card.Content>
            {#if $salesBonusReportQuery.data && $salesBonusReportQuery.data.salesBySalesRep.length > 0}
              <!-- Summary section -->
              <div class="mb-6 p-4 border rounded-md bg-muted/30">
                <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
                  <div>
                    <h3 class="text-sm font-medium text-muted-foreground mb-1">
                      Total Invoices
                    </h3>
                    <p class="text-2xl font-semibold">
                      {$salesBonusReportQuery.data.totalInvoices}
                    </p>
                  </div>

                  <div>
                    <h3 class="text-sm font-medium text-muted-foreground mb-1">
                      Total Revenue
                    </h3>
                    <p class="text-2xl font-semibold">
                      {formatCurrency($salesBonusReportQuery.data.revenueTotal)}
                    </p>
                  </div>
                  <div>
                    <h3 class="text-sm font-medium text-muted-foreground mb-1">
                      Total Bonus
                    </h3>
                    <p class="text-2xl font-semibold text-green-600">
                      {formatCurrencyMDL(
                        calculateTotalBonusAllReps(
                          $salesBonusReportQuery.data.invoices,
                        ),
                      )}
                    </p>
                    <div class="flex gap-2 mt-1">
                      <span
                        class="text-xs px-2 py-1 bg-blue-100 text-blue-800 rounded-md"
                      >
                        New: {users
                          .map(
                            u => Number(u.customFields?.bonus?.new || 0) * 100,
                          )
                          .filter(v => v > 0)
                          .join('%, ')}%
                      </span>
                      <span
                        class="text-xs px-2 py-1 bg-gray-100 text-gray-800 rounded-md"
                      >
                        Regular: {users
                          .map(
                            u => Number(u.customFields?.bonus?.old || 0) * 100,
                          )
                          .filter(v => v > 0)
                          .join('%, ')}%
                      </span>
                    </div>
                  </div>
                </div>

                <div class="mt-4">
                  <h3 class="text-sm font-medium text-muted-foreground mb-2">
                    Bonus Statistics
                  </h3>
                  <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div class="p-3 bg-blue-50 rounded-md">
                      <div class="flex justify-between">
                        <span class="text-sm text-blue-700"
                          >New Client Revenue</span
                        >
                        <span class="text-sm font-semibold text-blue-700">
                          {formatCurrency(
                            $salesBonusReportQuery.data.invoices
                              .filter(invoice => invoice.customer.isNew)
                              .reduce(
                                (sum, invoice) => sum + invoice.revenueTotal,
                                0,
                              ),
                          )}
                        </span>
                      </div>
                      <div class="flex justify-between mt-1">
                        <span class="text-sm text-blue-700"
                          >New Client Bonus</span
                        >
                        <span class="text-sm font-semibold text-blue-700">
                          {formatCurrencyMDL(
                            $salesBonusReportQuery.data.invoices
                              .filter(invoice => invoice.customer.isNew)
                              .reduce((sum, invoice) => {
                                const salesRepUser = users?.find(
                                  user => user.id === invoice.salesRep.id,
                                );
                                return (
                                  sum + calculateBonus(invoice, salesRepUser)
                                );
                              }, 0),
                          )}
                        </span>
                      </div>
                    </div>
                    <div class="p-3 bg-gray-50 rounded-md">
                      <div class="flex justify-between">
                        <span class="text-sm text-gray-700"
                          >Regular Client Revenue</span
                        >
                        <span class="text-sm font-semibold text-gray-700">
                          {formatCurrency(
                            $salesBonusReportQuery.data.invoices
                              .filter(invoice => !invoice.customer.isNew)
                              .reduce(
                                (sum, invoice) => sum + invoice.revenueTotal,
                                0,
                              ),
                          )}
                        </span>
                      </div>
                      <div class="flex justify-between mt-1">
                        <span class="text-sm text-gray-700"
                          >Regular Client Bonus</span
                        >
                        <span class="text-sm font-semibold text-gray-700">
                          {formatCurrencyMDL(
                            $salesBonusReportQuery.data.invoices
                              .filter(invoice => !invoice.customer.isNew)
                              .reduce((sum, invoice) => {
                                const salesRepUser = users?.find(
                                  user => user.id === invoice.salesRep.id,
                                );
                                return (
                                  sum + calculateBonus(invoice, salesRepUser)
                                );
                              }, 0),
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Create tabs for each sales rep -->
              <Tabs.Root bind:value={activeSalesRepTab}>
                <Tabs.List class="mb-4 grid grid-cols-4 h-auto gap-2">
                  {#each $salesBonusReportQuery.data.salesBySalesRep?.filter( salesRep => users.some(user => user.id === salesRep.salesRep.id), ) as salesRep, i}
                    <Tabs.Trigger
                      value={salesRep.salesRep.id || `unassigned-${i}`}
                    >
                      {salesRep.salesRep.name}
                    </Tabs.Trigger>
                  {/each}
                </Tabs.List>

                {#each $salesBonusReportQuery.data.salesBySalesRep as salesRep, i}
                  <Tabs.Content
                    value={salesRep.salesRep.id || `unassigned-${i}`}
                  >
                    <div class="mb-4">
                      <div class="flex items-center mb-4">
                        <h3 class="text-lg font-semibold">
                          {salesRep.salesRep.name}
                        </h3>

                        <Badge variant="outline" class="ml-2">
                          {salesRep.invoiceCount} invoices
                        </Badge>
                        <Badge variant="outline" class="ml-2">
                          Revenue: {formatCurrency(salesRep.revenueTotal)}
                        </Badge>
                        <Badge
                          variant="outline"
                          class="ml-2 bg-green-600 text-green-50"
                        >
                          Bonus: {formatCurrencyMDL(
                            calculateTotalBonus(
                              salesRep,
                              $salesBonusReportQuery.data.invoices,
                            ),
                          )}
                        </Badge>
                      </div>

                      <!-- Group by customer -->
                      {#if groupInvoicesByCustomer($salesBonusReportQuery.data.invoices, salesRep.salesRep.id).length > 0}
                        <div class="space-y-4">
                          {#each groupInvoicesByCustomer($salesBonusReportQuery.data.invoices, salesRep.salesRep.id) as customerGroup}
                            <div class="border rounded-md overflow-hidden">
                              <!-- Customer Header -->
                              <div
                                class="p-3 bg-secondary/30 flex items-center justify-between cursor-pointer"
                                on:click={() =>
                                  toggleCustomerExpanded(
                                    customerGroup.customer.id,
                                  )}
                              >
                                <div class="flex items-center">
                                  <Button
                                    variant="link"
                                    on:click={e => {
                                      popupStore.openPopup('crmObject', {
                                        module: 'accounts',
                                        id: customerGroup.customer.id,
                                      });
                                      e.stopPropagation();
                                    }}
                                  >
                                    <span class="font-medium"
                                      >{customerGroup.customer.name}</span
                                    >
                                  </Button>
                                  {#if customerGroup.customer.isNew}
                                    <Badge
                                      variant="outline"
                                      class="ml-1 bg-blue-500 text-white"
                                    >
                                      New Client {customerGroup.customer
                                        .newClientMonth
                                        ? `(Month ${customerGroup.customer.newClientMonth})`
                                        : ''}
                                    </Badge>
                                  {/if}
                                </div>
                                <div class="flex items-center gap-3">
                                  <Badge variant="outline"
                                    >{customerGroup.invoices.length} invoices</Badge
                                  >
                                  <Badge variant="outline"
                                    >Revenue: {formatCurrency(
                                      customerGroup.totalRevenue,
                                    )}</Badge
                                  >
                                  <Badge
                                    variant="outline"
                                    class="bg-green-600 text-green-50"
                                  >
                                    Bonus: {formatCurrencyMDL(
                                      customerGroup.invoices.reduce(
                                        (total, invoice) =>
                                          total +
                                          calculateBonus(
                                            invoice,
                                            users?.find(
                                              user =>
                                                user.id ===
                                                salesRep.salesRep.id,
                                            ),
                                          ),
                                        0,
                                      ),
                                    )}
                                  </Badge>
                                  <div class="text-sm">
                                    {expandedCustomers[
                                      customerGroup.customer.id
                                    ]
                                      ? '▼'
                                      : '▶'}
                                  </div>
                                </div>
                              </div>

                              <!-- Invoices Tables (Collapsible) -->
                              {#if expandedCustomers[customerGroup.customer.id]}
                                <div class="p-3 space-y-4">
                                  <!-- Export Invoices -->
                                  {#if customerGroup.exportInvoices.length > 0}
                                    <div>
                                      <h4 class="text-md font-medium mb-2">
                                        Export Invoices ({customerGroup
                                          .exportInvoices.length})
                                      </h4>
                                      <div class="rounded-md">
                                        <Table.Root>
                                          <Table.Header>
                                            <Table.Row>
                                              <Table.Head>Invoice ID</Table.Head
                                              >
                                              <Table.Head>Date</Table.Head>
                                              <Table.Head>Paid On</Table.Head>
                                              <Table.Head class="text-right"
                                                >Gross</Table.Head
                                              >
                                              <Table.Head class="text-right"
                                                >Supplier</Table.Head
                                              >
                                              <Table.Head class="text-right"
                                                >Revenue</Table.Head
                                              >
                                              <Table.Head>Status</Table.Head>
                                            </Table.Row>
                                          </Table.Header>
                                          <Table.Body>
                                            {#each customerGroup.exportInvoices as invoice}
                                              <Table.Row>
                                                <Table.Cell>
                                                  <Button
                                                    variant="link"
                                                    class="p-0 h-auto"
                                                    on:click={e => {
                                                      popupStore.openPopup(
                                                        'crmObject',
                                                        {
                                                          module: 'invoices',
                                                          id: invoice.id,
                                                        },
                                                      );
                                                      e.stopPropagation();
                                                    }}
                                                  >
                                                    {invoice.number}
                                                  </Button>
                                                </Table.Cell>
                                                <Table.Cell
                                                  >{formatDate(
                                                    invoice.date,
                                                  )}</Table.Cell
                                                >
                                                <Table.Cell
                                                  >{invoice.paidOn
                                                    ? formatDate(invoice.paidOn)
                                                    : ''}</Table.Cell
                                                >
                                                <Table.Cell class="text-right">
                                                  {formatCurrency(
                                                    invoice.revenueTotal,
                                                  )}
                                                </Table.Cell>
                                                <Table.Cell class="text-right">
                                                  {formatCurrency(
                                                    invoice.supplierTotal,
                                                  )}
                                                </Table.Cell>
                                                <Table.Cell class="text-right">
                                                  {formatCurrency(
                                                    invoice.revenueTotal,
                                                  )}
                                                  <span
                                                    class="text-xs text-muted-foreground"
                                                  >
                                                    {invoice.revenueNational?.toFixed(
                                                      0,
                                                    )} MDL
                                                  </span>

                                                  {#if invoice.customer.isNew}
                                                    <div
                                                      class="text-xs text-blue-500 font-semibold"
                                                    >
                                                      Bonus: {formatCurrencyMDL(
                                                        calculateBonus(
                                                          invoice,
                                                          users?.find(
                                                            user =>
                                                              user.id ===
                                                              salesRep.salesRep
                                                                .id,
                                                          ),
                                                        ),
                                                      )}
                                                      ({Number(
                                                        users?.find(
                                                          user =>
                                                            user.id ===
                                                            salesRep.salesRep
                                                              .id,
                                                        )?.customFields?.bonus
                                                          ?.new || 0,
                                                      ) * 100}%)
                                                    </div>
                                                  {:else}
                                                    <div
                                                      class="text-xs text-gray-500 font-semibold"
                                                    >
                                                      Bonus: {formatCurrencyMDL(
                                                        calculateBonus(
                                                          invoice,
                                                          users?.find(
                                                            user =>
                                                              user.id ===
                                                              salesRep.salesRep
                                                                .id,
                                                          ),
                                                        ),
                                                      )}
                                                      ({Number(
                                                        users?.find(
                                                          user =>
                                                            user.id ===
                                                            salesRep.salesRep
                                                              .id,
                                                        )?.customFields?.bonus
                                                          ?.old || 0,
                                                      ) * 100}%)
                                                    </div>
                                                  {/if}
                                                </Table.Cell>
                                                <Table.Cell>
                                                  <div
                                                    class="flex items-center gap-2"
                                                  >
                                                    <InvoiceStatus
                                                      status={invoice.status ||
                                                        'unknown'}
                                                    />
                                                    <Badge
                                                      variant="outline"
                                                      class="bg-blue-100 text-blue-800"
                                                      >Export</Badge
                                                    >
                                                  </div>
                                                </Table.Cell>
                                              </Table.Row>
                                            {/each}
                                          </Table.Body>
                                        </Table.Root>
                                      </div>
                                    </div>
                                  {/if}

                                  <!-- Import Invoices -->
                                  {#if customerGroup.importInvoices.length > 0}
                                    <div>
                                      <h4 class="text-md font-medium mb-2">
                                        Import Invoices ({customerGroup
                                          .importInvoices.length})
                                      </h4>
                                      <div class="rounded-md">
                                        <Table.Root>
                                          <Table.Header>
                                            <Table.Row>
                                              <Table.Head>Invoice ID</Table.Head
                                              >
                                              <Table.Head>Date</Table.Head>
                                              <Table.Head>Paid On</Table.Head>
                                              <Table.Head class="text-right"
                                                >Gross</Table.Head
                                              >
                                              <Table.Head class="text-right"
                                                >Supplier</Table.Head
                                              >
                                              <Table.Head class="text-right"
                                                >Revenue</Table.Head
                                              >
                                              <Table.Head>Status</Table.Head>
                                            </Table.Row>
                                          </Table.Header>
                                          <Table.Body>
                                            {#each customerGroup.importInvoices as invoice}
                                              <Table.Row>
                                                <Table.Cell>
                                                  <Button
                                                    variant="link"
                                                    class="p-0 h-auto"
                                                    on:click={e => {
                                                      popupStore.openPopup(
                                                        'crmObject',
                                                        {
                                                          module: 'invoices',
                                                          id: invoice.id,
                                                        },
                                                      );
                                                      e.stopPropagation();
                                                    }}
                                                  >
                                                    {invoice.number}
                                                  </Button>
                                                </Table.Cell>
                                                <Table.Cell
                                                  >{formatDate(
                                                    invoice.date,
                                                  )}</Table.Cell
                                                >
                                                <Table.Cell
                                                  >{invoice.paidOn
                                                    ? formatDate(invoice.paidOn)
                                                    : ''}</Table.Cell
                                                >
                                                <Table.Cell class="text-right">
                                                  {formatCurrency(
                                                    invoice.gross,
                                                  )}
                                                </Table.Cell>
                                                <Table.Cell class="text-right">
                                                  {formatCurrency(
                                                    invoice.supplierTotal,
                                                  )}
                                                </Table.Cell>
                                                <Table.Cell class="text-right">
                                                  {formatCurrency(
                                                    invoice.revenueTotal,
                                                  )}
                                                  <span
                                                    class="text-xs text-muted-foreground"
                                                  >
                                                    {invoice.revenueNational?.toFixed(
                                                      0,
                                                    )} MDL
                                                  </span>

                                                  {#if invoice.customer.isNew}
                                                    <div
                                                      class="text-xs text-blue-500 font-semibold"
                                                    >
                                                      Bonus: {formatCurrencyMDL(
                                                        calculateBonus(
                                                          invoice,
                                                          users?.find(
                                                            user =>
                                                              user.id ===
                                                              salesRep.salesRep
                                                                .id,
                                                          ),
                                                        ),
                                                      )}
                                                      ({Number(
                                                        users?.find(
                                                          user =>
                                                            user.id ===
                                                            salesRep.salesRep
                                                              .id,
                                                        )?.customFields?.bonus
                                                          ?.new || 0,
                                                      ) * 100}%)
                                                    </div>
                                                  {:else}
                                                    <div
                                                      class="text-xs text-gray-500 font-semibold"
                                                    >
                                                      Bonus: {formatCurrencyMDL(
                                                        calculateBonus(
                                                          invoice,
                                                          users?.find(
                                                            user =>
                                                              user.id ===
                                                              salesRep.salesRep
                                                                .id,
                                                          ),
                                                        ),
                                                      )}
                                                      ({Number(
                                                        users?.find(
                                                          user =>
                                                            user.id ===
                                                            salesRep.salesRep
                                                              .id,
                                                        )?.customFields?.bonus
                                                          ?.old || 0,
                                                      ) * 100}%)
                                                    </div>
                                                  {/if}
                                                </Table.Cell>
                                                <Table.Cell>
                                                  <div
                                                    class="flex items-center gap-2"
                                                  >
                                                    <InvoiceStatus
                                                      status={invoice.status ||
                                                        'unknown'}
                                                    />
                                                    <Badge
                                                      variant="outline"
                                                      class="bg-purple-100 text-purple-800"
                                                      >Import</Badge
                                                    >
                                                  </div>
                                                </Table.Cell>
                                              </Table.Row>
                                            {/each}
                                          </Table.Body>
                                        </Table.Root>
                                      </div>
                                    </div>
                                  {/if}
                                </div>
                              {/if}
                            </div>
                          {/each}
                        </div>
                      {:else}
                        <div class="text-center p-4 text-muted-foreground">
                          No invoices found for this sales rep.
                        </div>
                      {/if}
                    </div>
                  </Tabs.Content>
                {/each}
              </Tabs.Root>
            {:else}
              <div class="text-center py-8">
                <p>No export or import invoices found for this period.</p>
              </div>
            {/if}
          </Card.Content>
        </Card.Root>
      </Tabs.Content>
    {/if}
  </Tabs.Root>
</div>
