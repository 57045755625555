import { SupabaseClient, createClient } from '@supabase/supabase-js';

const environment = import.meta.env.VITE_SUPABASE_ENVIRONMENT;
const SUPABASE_URL = import.meta.env[
  `VITE_SUPABASE_URL_${environment.toUpperCase()}`
];
const SUPABASE_URL_REPLICA = import.meta.env[
  `VITE_SUPABASE_URL_REPLICA_${environment.toUpperCase()}`
];
const SUPABASE_ANON_KEY = import.meta.env[
  `VITE_SUPABASE_ANON_KEY_${environment.toUpperCase()}`
];

const AUTH_PROVIDER = import.meta.env.VITE_AUTH_PROVIDER;

let supabaseClient: SupabaseClient = createClient(
  SUPABASE_URL,
  SUPABASE_ANON_KEY,
);

let supabaseMainPool: Record<string, SupabaseClient> = {};
let supabaseReplicaPool: Record<string, SupabaseClient> = {};

export const refreshClient = () => {
  supabaseClient = createClient(SUPABASE_URL, SUPABASE_ANON_KEY);
};

export const supabase = (accessToken?: string, replica: boolean = true) => {
  // First check global client for unauthenticated requests
  if (!accessToken) {
    return supabaseClient;
  }

  // For authenticated requests, reuse existing client from pool when possible
  const pool = replica ? supabaseReplicaPool : supabaseMainPool;
  if (accessToken && pool[accessToken]) {
    return pool[accessToken];
  }

  // Cleanup old clients to prevent memory leaks
  purgePoolOnBrowser();

  // Create new client with authentication
  const url = (replica ? SUPABASE_URL_REPLICA : SUPABASE_URL) ?? SUPABASE_URL;
  const client = createClient(
    url,
    SUPABASE_ANON_KEY,
    AUTH_PROVIDER !== 'supabase' && {
      global: { headers: { Authorization: `Bearer ${accessToken}` } },
    },
  );
  
  // Set auth token for realtime subscriptions
  client.realtime.setAuth(accessToken);

  // Store in appropriate pool for future reuse
  if (accessToken) {
    pool[accessToken] = client;
  }

  return client;
};

const purgePoolOnBrowser = () => {
  if (typeof window !== 'undefined') {
    supabaseMainPool = {};
    supabaseReplicaPool = {};
  }
};
