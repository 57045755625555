<script lang="ts">
  import { Card } from '$lib/components/ui/card';
  import { getItems } from '$lib/api/queries';
  import { appStore } from '$lib/stores/app-store';
  import { onMount } from 'svelte';
  import { cn } from '$lib/utils/utils';

  let selectedCategory = '';
  let expenses = [];

  onMount(() => {
    getItems({
      collection: 'expenses',
      filters: $appStore.filters,
      from: 0,
      to: 10000,
    }).then(result => {
      expenses = result.data;
    });
  });

  $: categories = expenses
    ? [...new Set(expenses?.map(e => e.category || 'Uncategorized'))]
        .map(category => {
          const categoryExpenses = expenses.filter(
            e => (e.category || 'Uncategorized') === category,
          );
          const total = categoryExpenses.reduce(
            (sum, e) => sum + (e.amount || 0),
            0,
          );

          // Get subcategories and their totals
          const subcategories = [
            ...new Set(categoryExpenses.map(e => e.subCategory || 'Other')),
          ]
            .map(sub => ({
              name: sub,
              total: categoryExpenses
                .filter(e => (e.subCategory || 'Other') === sub)
                .reduce((sum, e) => sum + (e.amount || 0), 0),
            }))
            .sort((a, b) => b.total - a.total);

          return {
            name: category,
            total,
            subcategories,
          };
        })
        .sort((a, b) => b.total - a.total)
        .map(c => c.name)
    : [];

  $: categoryDetails = expenses
    ? categories.map(category => {
        const categoryExpenses = expenses.filter(
          e => (e.category || 'Uncategorized') === category,
        );
        const total = categoryExpenses.reduce(
          (sum, e) => sum + (e.amount || 0),
          0,
        );

        const subcategories = [
          ...new Set(categoryExpenses.map(e => e.subCategory || 'Other')),
        ]
          .map(sub => ({
            name: sub,
            total: categoryExpenses
              .filter(e => (e.subCategory || 'Other') === sub)
              .reduce((sum, e) => sum + (e.amount || 0), 0),
          }))
          .sort((a, b) => b.total - a.total);

        return {
          name: category,
          total,
          subcategories,
        };
      })
    : [];

  $: filteredExpenses =
    selectedCategory && expenses
      ? expenses?.filter(
          e => (e.category || 'Uncategorized') === selectedCategory,
        )
      : expenses || [];

  function handleExpenseClick(id: string) {
    // selectedCategory = id;
  }

  function formatCurrency(amount: number) {
    return new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount);
  }
</script>

<div class="w-full space-y-8">
  <div class="flex justify-between items-center">
    <h2 class="text-3xl font-bold tracking-tight">Expenses by Category</h2>
  </div>

  <div class="grid gap-4 md:grid-cols-2 lg:grid-cols-4 max-w-[70%]">
    {#each categoryDetails as category}
      <Card
        class={cn(
          'p-6 cursor-pointer hover:bg-accent/50 transition-colors',
          (category.name === 'Taxes' || category.name === 'Depreciation') &&
            'bg-secondary',
        )}
        on:click={() => (selectedCategory = category.name)}
      >
        <div class="flex flex-col gap-4">
          <div class="flex items-center justify-between space-x-4">
            <h3 class="text-xl font-semibold">{category.name}</h3>
          </div>
          <div>
            <p class="text-2xl font-bold">
              {formatCurrency(category.total)}
            </p>
            <p class="text-sm text-muted-foreground mb-2">
              {expenses.filter(
                e => (e.category || 'Uncategorized') === category.name,
              ).length} expenses
            </p>

            <div class="space-y-1 mt-4 border-t pt-2">
              {#each category.subcategories as sub}
                <div class="flex justify-between items-center text-sm">
                  <span class="text-muted-foreground">{sub.name}</span>
                  <span class="font-medium">{formatCurrency(sub.total)}</span>
                </div>
              {/each}
            </div>
          </div>
        </div>
      </Card>
    {/each}
  </div>

  {#if selectedCategory}
    <div class="mt-8">
      <h3 class="text-2xl font-semibold mb-4">{selectedCategory} Expenses</h3>
      <div class="space-y-4">
        {#each filteredExpenses as expense}
          <Card
            class="p-4 cursor-pointer hover:bg-accent/50 transition-colors"
            on:click={() => handleExpenseClick(expense.id)}
          >
            <div class="flex justify-between items-center">
              <div>
                <p class="font-medium">{expense.name}</p>
                <p class="text-sm text-muted-foreground">
                  {new Date(expense.expenseDate).toLocaleDateString()}
                </p>
              </div>
              <p class="text-xl font-semibold">
                {formatCurrency(expense.amount || 0)}
              </p>
            </div>
          </Card>
        {/each}
      </div>
    </div>
  {/if}
</div>
