<script lang="ts">
  import { createQuery } from '@tanstack/svelte-query';
  import { getAccountInvoicesWithFilter } from '$lib/api/invoice-api';

  import { writable } from 'svelte/store';

  import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
  } from '$lib/components/ui/table';
  import { Button } from '$lib/components/ui/button';
  import { format } from 'date-fns';
  import { appStore } from '$lib/stores/app-store';
  import { formatCurrency } from '$lib/utils/hanta-utils';
  import { authStore } from '$lib/stores/auth-store';

  export let filter: {
    status?: string;
    paymentDeadlineStart?: string;
    paymentDeadlineEnd?: string;
    startDate?: string;
    endDate?: string;
  };

  $: withPaidOnColumn = filter.status === 'Paid';

  // Sorting state
  const sortConfig = writable<{ key: string; direction: 'asc' | 'desc' }>({
    key: 'invoiceDate',
    direction: 'desc',
  });

  function toggleSort(key: string) {
    $sortConfig = {
      key,
      direction:
        $sortConfig.key === key
          ? $sortConfig.direction === 'asc'
            ? 'desc'
            : 'asc'
          : 'asc',
    };
  }

  const invoicesQuery = createQuery({
    queryKey: ['accountInvoices', filter],
    queryFn: () => getAccountInvoicesWithFilter(filter),
    enabled: !!filter,
  });

  $: ({ data: invoices, isLoading, isError, error } = $invoicesQuery);

  // Sort invoices based on current sort configuration
  $: sortedInvoices = invoices
    ? [...invoices].sort((a, b) => {
        const direction = $sortConfig.direction === 'asc' ? 1 : -1;

        switch ($sortConfig.key) {
          case 'invoiceId':
            return (
              (a.invoiceId?.localeCompare(b.invoiceId || '') || 0) * direction
            );
          case 'amount':
            return ((a.gross || 0) - (b.gross || 0)) * direction;
          case 'company':
            return (
              ((a.company?.name || '').localeCompare(b.company?.name || '') ||
                0) * direction
            );
          case 'sales':
            return (
              ((a.company?.sales?.name || '').localeCompare(
                b.company?.sales?.name || '',
              ) || 0) * direction
            );
          case 'paidOn':
            const paidOnDate1 = a.paidOn ? new Date(a.paidOn).getTime() : 0;
            const paidOnDate2 = b.paidOn ? new Date(b.paidOn).getTime() : 0;
            return (paidOnDate1 - paidOnDate2) * direction;
          case 'paymentDeadline':
            const aDeadline = a.paymentDeadline
              ? new Date(a.paymentDeadline).getTime()
              : 0;
            const bDeadline = b.paymentDeadline
              ? new Date(b.paymentDeadline).getTime()
              : 0;
            return (aDeadline - bDeadline) * direction;
          case 'invoiceDate':
            const aDate = a.invoiceDate ? new Date(a.invoiceDate).getTime() : 0;
            const bDate = b.invoiceDate ? new Date(b.invoiceDate).getTime() : 0;
            return (aDate - bDate) * direction;
          default:
            return 0;
        }
      })
    : [];
  $: totalAmount = sortedInvoices.reduce(
    (total, invoice) => total + invoice.gross || 0,
    0,
  );
  $: totalAmountNational = sortedInvoices.reduce(
    (total, invoice) => total + invoice.grossNational || 0,
    0,
  );
</script>

{#if isLoading}
  <p>Loading invoices...</p>
{:else if isError}
  <p>Error loading invoices: {error.message}</p>
{:else if invoices && invoices.length > 0}
  <div class="mb-4 text-lg font-semibold flex justify-between items-center">
    <div>
      Found {invoices?.length ?? 0} invoices
    </div>
    <div class="flex gap-2 items-center">
      <div class="text-sm mr-2">Sort by:</div>
      <Button
        variant={$sortConfig.key === 'invoiceDate' ? 'default' : 'outline'}
        size="sm"
        on:click={() => toggleSort('invoiceDate')}
      >
        Date {$sortConfig.key === 'invoiceDate'
          ? $sortConfig.direction === 'asc'
            ? '↑'
            : '↓'
          : ''}
      </Button>
      <Button
        variant={$sortConfig.key === 'amount' ? 'default' : 'outline'}
        size="sm"
        on:click={() => toggleSort('amount')}
      >
        Amount {$sortConfig.key === 'amount'
          ? $sortConfig.direction === 'asc'
            ? '↑'
            : '↓'
          : ''}
      </Button>
      <Button
        variant={$sortConfig.key === 'company' ? 'default' : 'outline'}
        size="sm"
        on:click={() => toggleSort('company')}
      >
        Company {$sortConfig.key === 'company'
          ? $sortConfig.direction === 'asc'
            ? '↑'
            : '↓'
          : ''}
      </Button>
      <Button
        variant={$sortConfig.key === 'paymentDeadline' ? 'default' : 'outline'}
        size="sm"
        on:click={() => toggleSort('paymentDeadline')}
      >
        Deadline {$sortConfig.key === 'paymentDeadline'
          ? $sortConfig.direction === 'asc'
            ? '↑'
            : '↓'
          : ''}
      </Button>

      {#if withPaidOnColumn}
        <Button
          variant={$sortConfig.key === 'paidOn' ? 'default' : 'outline'}
          size="sm"
          on:click={() => toggleSort('paidOn')}
        >
          Paid On {$sortConfig.key === 'paidOn'
            ? $sortConfig.direction === 'asc'
              ? '↑'
              : '↓'
            : ''}
        </Button>
      {/if}
      <Button
        variant={$sortConfig.key === 'sales' ? 'default' : 'outline'}
        size="sm"
        on:click={() => toggleSort('sales')}
      >
        Sales
        {#if $sortConfig.key === 'sales'}
          <span class="ml-1">{$sortConfig.direction === 'asc' ? '↑' : '↓'}</span
          >
        {/if}
      </Button>
    </div>
  </div>
  <Table>
    <TableHeader>
      <TableRow>
        <TableHead>Actions</TableHead>
        <TableHead
          class="cursor-pointer"
          on:click={() => toggleSort('invoiceId')}
        >
          Invoice Number
          {#if $sortConfig.key === 'invoiceId'}
            <span class="ml-1"
              >{$sortConfig.direction === 'asc' ? '↑' : '↓'}</span
            >
          {/if}
        </TableHead>
        <TableHead
          class="text-right cursor-pointer w-32"
          on:click={() => toggleSort('amount')}
        >
          Amount
          {#if $sortConfig.key === 'amount'}
            <span class="ml-1"
              >{$sortConfig.direction === 'asc' ? '↑' : '↓'}</span
            >
          {/if}
        </TableHead>
        <TableHead
          class="cursor-pointer"
          on:click={() => toggleSort('company')}
        >
          Company Name
          {#if $sortConfig.key === 'company'}
            <span class="ml-1"
              >{$sortConfig.direction === 'asc' ? '↑' : '↓'}</span
            >
          {/if}
        </TableHead>
        <TableHead
          class="cursor-pointer"
          on:click={() => toggleSort('paymentDeadline')}
        >
          Payment Deadline
          {#if $sortConfig.key === 'paymentDeadline'}
            <span class="ml-1"
              >{$sortConfig.direction === 'asc' ? '↑' : '↓'}</span
            >
          {/if}
        </TableHead>
        <TableHead
          class="cursor-pointer"
          on:click={() => toggleSort('invoiceDate')}
        >
          Invoice Date
          {#if $sortConfig.key === 'invoiceDate'}
            <span class="ml-1"
              >{$sortConfig.direction === 'asc' ? '↑' : '↓'}</span
            >
          {/if}
        </TableHead>
        {#if withPaidOnColumn}
          <TableHead
            class="cursor-pointer"
            on:click={() => toggleSort('paidOn')}
          >
            Paid On
            {#if $sortConfig.key === 'paidOn'}
              <span class="ml-1"
                >{$sortConfig.direction === 'asc' ? '↑' : '↓'}</span
              >
            {/if}
          </TableHead>
        {/if}
        <TableHead class="cursor-pointer" on:click={() => toggleSort('sales')}>
          Sales
          {#if $sortConfig.key === 'sales'}
            <span class="ml-1"
              >{$sortConfig.direction === 'asc' ? '↑' : '↓'}</span
            >
          {/if}
        </TableHead>
      </TableRow>
    </TableHeader>
    <TableBody>
      {#each sortedInvoices as invoice}
        {@const daysOverdue = Math.floor(
          (new Date(invoice.paymentDeadline).getTime() - new Date().getTime()) /
            1000 /
            60 /
            60 /
            24,
        )}
        <TableRow>
          <TableCell>
            <Button
              variant="outline"
              size="sm"
              on:click={() => {
                appStore.select({
                  id: invoice.id,
                  module: 'invoices',
                });
                appStore.openPopup(true);
              }}
            >
              View
            </Button>
          </TableCell>
          <TableCell
            >{invoice.invoiceId}
            <!--<InvoiceStatus status={invoice.status} />-->
          </TableCell>
          <TableCell class="text-right font-semibold text-md bg-yellow-100">
            <div>
              {invoice.gross > 0 ? formatCurrency(invoice.gross) : '-'}
            </div>
            <div class="w-full text-xs text-muted-foreground">
              {#if authStore.isLogistics()}
                {invoice.grossNational > 0
                  ? formatCurrency(invoice.grossNational, 'MDL')
                  : ''}
              {/if}
            </div>
          </TableCell>
          <TableCell class="text-muted-foreground"
            >{invoice.company?.name ?? ''}</TableCell
          >
          <TableCell>
            <div
              class:hidden={invoice.paymentDeadline === null ||
                invoice.status === 'Paid' ||
                invoice.status === 'Cancelled' ||
                invoice.status === 'Void' ||
                invoice.status === 'Draft'}
              class:text-red-500={daysOverdue < 0}
            >
              {daysOverdue < 0 ? 'since' : 'in'}
              {Math.abs(daysOverdue)} days
            </div>
          </TableCell>
          <TableCell
            >{(invoice.invoiceDate &&
              format(new Date(invoice.invoiceDate), 'dd MMM yyyy')) ||
              ''}</TableCell
          >
          {#if withPaidOnColumn}
            <TableCell
              >{(invoice.paidOn &&
                format(new Date(invoice.paidOn), 'dd MMM yyyy')) ||
                ''}</TableCell
            >
            <TableCell>{invoice.company?.sales?.name ?? ''}</TableCell>
          {/if}
        </TableRow>
      {/each}
    </TableBody>
  </Table>

  <div
    class="absolute bottom-0 right-0 text-base px-2 py-0 text-muted-foreground"
  >
    with a total of <span class="font-semibold"
      >{formatCurrency(totalAmount)} ({formatCurrency(
        totalAmountNational,
        'MDL',
      )})</span
    >
  </div>
{:else}
  <p>No invoices found for this account.</p>
{/if}
