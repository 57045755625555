<script lang="ts">
  import { slide } from 'svelte/transition';
  import PageWithTitle from '../../ui/page-with-title.svelte';
  import Activities from '$lib/pages/activities/activities.svelte';
  import AccountSelector from '$lib/widgets/accounts/account-selector.svelte';
  import Files from '$lib/components/hanta/files/files.svelte';
  import HantaInputTextareaRichtext from '$lib/widgets/input/hanta-input-textarea-richtext/hanta-input-textarea-richtext.svelte';
  import HantaInputText from '$lib/widgets/input/hanta-input-text.svelte';
  import HantaInputLovSelect from '$lib/widgets/input/hanta-input-lov-select.svelte';
  import HantaInputNumber from '$lib/widgets/input/hanta-input-number.svelte';
  import { Label } from '$lib/components/ui/label';
  import { superForm } from 'sveltekit-superforms';
  import { zodClient } from 'sveltekit-superforms/adapters';
  import * as Tabs from '$lib/components/ui/tabs';
  import { createMutation, useQueryClient } from '@tanstack/svelte-query';
  import { saveExpense } from '$lib/api/mutations';
  import { type ExpenseWithRefs, insertExpensesSchema } from '$db/schema';
  import HantaInputDate from '$lib/widgets/input/hanta-input-date.svelte';
  import FormActions from '$lib/app/forms/form-actions.svelte';
  import { alertStore } from '$lib/stores/alert-store';

  const moduleName = 'expenses';
  export let item: ExpenseWithRefs;

  const client = useQueryClient();

  const addMutation = createMutation({
    mutationFn: (variables): Promise<ExpenseWithRefs> =>
      Promise.resolve(saveExpense(variables)),
    onSuccess: () => client.invalidateQueries({ queryKey: [moduleName] }),
    onMutate: async newItem => {
      await client.cancelQueries({ queryKey: [moduleName, item.id] });
      const previousItems = client.getQueryData<ExpenseWithRefs[]>([
        moduleName,
        item.id,
      ]);
      client.setQueryData([moduleName, item.id], { ...newItem });
      return { previousItems };
    },
    onError: (err: any, variables: any, context: any) => {
      if (context?.previousItems) {
        client.setQueryData([moduleName], context.previousItems);
      }
      alertStore.show(
        'Error Saving Expense',
        err.message || 'Failed to save expense',
      );
    },
    onSettled: () => {
      client.invalidateQueries({ queryKey: [moduleName, item.id] });
    },
  });

  const form = superForm<ExpenseWithRefs>(
    {
      ...item,
      customFields: item.customFields || {},
    },
    {
      validators: zodClient(insertExpensesSchema),
      resetForm: false,
      SPA: true,
      dataType: 'json',
      onSubmit: async () => {
        if (Object.keys($errors).length > 0) {
          alertStore.show(
            'Validation Error',
            'Please check the form for errors',
          );
          console.error('Validation errors', $errors);
          $errors = {};
          return;
        }

        $addMutation.mutate($formData);
      },
    },
  );

  const { form: formData, errors, enhance } = form;
  let currentTab = 'files';
</script>

<PageWithTitle>
  <div slot="short-card" class="w-full p-4">
    <HantaInputText {form} name="name" label="Expense Name" hideLabel={true} />
  </div>

  <div slot="content" class="grid grid-cols-3 p-1">
    <div class="col-span-3">
      <div class="mt-4">
        <Tabs.Root bind:value={currentTab} class="w-full">
          <Tabs.List>
            <Tabs.Trigger value="details">Details</Tabs.Trigger>
            <Tabs.Trigger value="files">Files</Tabs.Trigger>
          </Tabs.List>
          <Tabs.Content value="files">
            <div class="my-4">
              <Label>Files</Label>
              <Files
                folder={`expenses/${item?.id}`}
                compact={true}
                title=""
                maxColumns={1}
                uploadOnEnd={true}
              />
            </div>
          </Tabs.Content>
          <Tabs.Content value="details">
            <div class="grid grid-cols-3 gap-4 mt-4"></div>
          </Tabs.Content>
        </Tabs.Root>
      </div>
    </div>
  </div>

  <div slot="sidebar">
    <form method="POST" use:enhance class="p-4 space-y-3">
      <HantaInputText {form} name="refId" label="ID" />
      <HantaInputText {form} name="name" label="Name" />

      <HantaInputNumber {form} name="amount" label="Amount in EUR" />
      <HantaInputLovSelect
        {form}
        bind:value={$formData.category}
        type="Expense > Category"
        label="Category"
        multiple={false}
        addable={true}
      />
      <HantaInputLovSelect
        {form}
        bind:value={$formData.subCategory}
        type="Expense > Sub Category"
        label="Sub Category"
        multiple={false}
        addable={true}
      />

      <HantaInputLovSelect
        {form}
        bind:value={$formData.status}
        type="Expense > Status"
        label="Status"
        multiple={false}
      />
      <AccountSelector
        bind:value={$formData.account}
        name="accountId"
        label="Account"
      />
      <HantaInputDate {form} name="expenseDate" label="Expense Date" />

      <HantaInputTextareaRichtext {form} name="description" rows={10} />
      <FormActions {form} />
    </form>
  </div>
</PageWithTitle>
