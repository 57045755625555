import { writable } from 'svelte/store';
import type { Tariff } from '$db/schema';

type TariffState = {
  currentTariff: Tariff | null;
  currentPricelist: string | null;
  isDirty: boolean;
  selectedTab: string;
};

const createTariffStore = () => {
  const { subscribe, set, update } = writable<TariffState>({
    currentTariff: null,
    currentPricelist: null,
    isDirty: false,
    selectedTab: 'pricelists'
  });

  return {
    subscribe,
    setTariff: (tariff: Tariff) => update(state => ({ ...state, currentTariff: tariff, isDirty: false })),
    setPricelist: (name: string | null) => update(state => ({ ...state, currentPricelist: name })),
    setTab: (tab: string) => update(state => ({ ...state, selectedTab: tab })),
    markDirty: () => update(state => ({ ...state, isDirty: true })),
    reset: () => set({
      currentTariff: null,
      currentPricelist: null,
      isDirty: false,
      selectedTab: 'pricelists'
    })
  };
};

export const tariffStore = createTariffStore();
