export const timeFilters = [
  'To-do',
  'Overdue',
  'Today',
  'Tomorrow',
  'This week',
  'Next week',
];

export const getIconForActivityType = (type: string): string => {
  const iconMap: Record<string, string> = {
    Call: 'mdi:phone',
    Phone: 'mdi:phone',

    Meeting: 'mdi:calendar-clock',

    Todo: 'mdi:checkbox-marked',

    Deadline: 'mdi:flag',

    Email: 'mdi:email',

    Assignment: 'mdi:briefcase-outline',

    LinkedIn: 'mdi:message-text',
    Message: 'mdi:message-text',

    Lunch: 'mdi:food-fork-drink',
  };

  return iconMap[type] || 'mdi:note-text';
};

export const tabs = [
  { value: 'all', label: 'All' },
  { value: 'Todo', label: 'Todo' },

  { value: 'Email', label: 'Email' },
  { value: 'LinkedIn', label: 'Message' },
  { value: 'Note', label: 'Note' },

  { value: 'Phone', label: 'Call' },

  { value: 'Meeting', label: 'Meeting' },
  { value: 'Deadline', label: 'Deadline' },

  { value: 'Assignment', label: 'Assignment' },
];

export const activityTypes = [
  'All',

  'Todo',
  'Email',
  'Message',
  'Note',
  'Call',
  'Meeting',
  'Deadline',
  'Lunch',
];
