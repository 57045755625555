import { supabase } from './supabase';
import { authStore } from '$lib/stores/auth-store';
import { get } from 'svelte/store';
import { currentUserStore } from '$lib/stores/current-user-store';
import { getExchangeRates } from './queries';

export interface CreditRow {
  // Document information
  Nr?: string; // Document number
  OrderDate?: string; // Order date
  DocumentID?: string; // Document ID
  DocumentTypeID?: string; // Document type ID
  ExecuteDate?: string; // Execution date
  MovementID?: string; // Movement ID
  MovementTypeID?: string; // Movement type ID
  DocumentTypeNBM?: string; // Document type NBM

  // Client debit information
  ClientDebitName?: string; // Debit client name
  ClientDebitFiscalCode?: string; // Debit client fiscal code
  AccountDebit?: string; // Debit account
  BankDebitName?: string; // Debit bank name
  BankDebitMFOCode?: string; // Debit bank MFO code
  DebitBankBIC?: string; // Debit bank BIC
  AmountDebit?: string; // Debit amount
  AmountDebitEq?: string; // Debit amount equivalent

  // Client credit information
  ClientCreditName?: string; // Credit client name
  ClientCreditFiscalCode?: string; // Credit client fiscal code
  AccountCredit?: string; // Credit account
  BankCreditName?: string; // Credit bank name
  BankCreditMFOCode?: string; // Credit bank MFO code
  CreditBankBIC?: string; // Credit bank BIC
  AmountCredit?: string; // Credit amount
  AmountCreditEq?: string; // Credit amount equivalent
  CreditSubAccount?: string; // Credit sub-account

  // Amount information
  Amount: string; // Total amount (required)
  AmountEq?: string; // Amount equivalent
  currency?: string; // Currency

  // Payment details
  Destination?: string; // Payment destination/purpose

  // Fields needed for compatibility with existing code
  invoiceNumber: string; // Used to link to invoice (required for compatibility)
  total?: string; // Total amount (alternative to Amount)
  paymentDate?: string; // Payment date
  status?: string; // Payment status
  paymentMethod?: string; // Payment method
  description?: string; // Description
  referenceNumber?: string; // Reference number
  notes?: string; // Notes
  exchange?: string; // Exchange rate
  amountEur?: string; // Amount in EUR
}

export interface InvoiceHeader {
  key: string;
  title: string;
}

export interface ParsedCreditResponse {
  headers: InvoiceHeader[];
  data: CreditRow[];
}

export async function parseCredit(file: File): Promise<ParsedCreditResponse> {
  try {
    const formData = new FormData();
    formData.append('file', file);

    const { data, error } = await supabase(
      get(authStore)?.token,
      false,
    ).functions.invoke('parse-credit', {
      body: formData,
    });

    if (error) {
      console.error('Supabase function error:', error);
      throw new Error(`Failed to parse invoice: ${error.message}`);
    }

    if (data?.data?.length === 0) {
      throw new Error('Invalid data received from parse-credits function');
    }

    // Validate the data structure matches our interface
    const validatedData = data.data.map((row: any) => {
      // Ensure all required fields are present, even if empty
      const validatedRow: CreditRow = {
        // Required fields
        invoiceNumber:
          row.DocumentID?.toString() || row.MovementID?.toString() || '',
        Amount: row.Amount?.toString() || row.AmountCredit?.toString() || '0',

        // Document information
        Nr: row.Nr?.toString(),
        OrderDate: row.OrderDate,
        DocumentID: row.DocumentID,
        DocumentTypeID: row.DocumentTypeID?.toString(),
        ExecuteDate: row.ExecuteDate,
        MovementID: row.MovementID,
        MovementTypeID: row.MovementTypeID?.toString(),
        DocumentTypeNBM: row.DocumentTypeNBM,

        // Client debit information
        ClientDebitName: row.ClientDebitName,
        ClientDebitFiscalCode: row.ClientDebitFiscalCode,
        AccountDebit: row.AccountDebit,
        BankDebitName: row.BankDebitName,
        BankDebitMFOCode: row.BankDebitMFOCode,
        DebitBankBIC: row.DebitBankBIC,
        AmountDebit: row.AmountDebit?.toString(),
        AmountDebitEq: row.AmountDebitEq?.toString(),

        // Client credit information
        ClientCreditName: row.ClientCreditName,
        ClientCreditFiscalCode: row.ClientCreditFiscalCode,
        AccountCredit: row.AccountCredit,
        BankCreditName: row.BankCreditName,
        BankCreditMFOCode: row.BankCreditMFOCode,
        CreditBankBIC: row.CreditBankBIC,
        AmountCredit: row.AmountCredit?.toString(),
        AmountCreditEq: row.AmountCreditEq?.toString(),
        CreditSubAccount: row.CreditSubAccount,

        // Amount information
        AmountEq: row.AmountEq?.toString(),
        currency: row.currency || 'MDL',

        // Payment details
        Destination: row.Destination,

        // Fields for compatibility with existing code
        total: row.Amount?.toString() || row.AmountCredit?.toString(),
        paymentDate: row.ExecuteDate || row.OrderDate,
        status: 'Succeeded',
        paymentMethod: 'bank_transfer',
        description: row.Destination,
        referenceNumber: row.DocumentID || row.MovementID,
        exchange: row.exchange?.toString(),
        amountEur: row.amountEur?.toString(),
      };
      return validatedRow;
    });

    return {
      headers: data.headers as InvoiceHeader[],
      data: validatedData,
    };
  } catch (error) {
    console.error('Error parsing invoice:', error);
    throw error;
  }
}

export const saveImportedPayments = async (bankRows: CreditRow[]) => {
  if (bankRows?.length === 0) return;
  const debits = bankRows?.filter(row => Number(row.AmountDebit) > 0);
  const credits = bankRows?.filter(row => Number(row.AmountCredit) > 0);

  saveImportedCredits(credits);
  saveImportedDebits(debits);
};

export const saveImportedDebits = async (debits: CreditRow[]) => {
  // Get the current user ID for audit fields
  const userId = get(currentUserStore)?.id;
  const now = new Date().toISOString();

  // Extract reference numbers from the debits to check for duplicates
  const referenceNumbers = debits
    .map(debit => debit.referenceNumber)
    .filter(Boolean);

  // Skip processing if no valid reference numbers
  if (referenceNumbers.length === 0) {
    console.warn('No valid reference numbers found in imported debits');
    return [];
  }

  // Check for existing debits with the same reference numbers
  const { data: existingDebits, error: existingDebitsError } = await supabase(
    get(authStore)?.token,
    false,
  )
    .from('expenses')
    .select('refId')
    .in('refId', referenceNumbers);

  if (existingDebitsError) {
    console.error('Error checking for existing debits:', existingDebitsError);
  }

  // Create a set of existing reference IDs for faster lookup
  const existingRefIds = new Set(
    existingDebits?.map(debit => debit.refId) || [],
  );

  // Filter out debits that already exist in the database
  const uniqueDebits = debits.filter(
    debit => !existingRefIds.has(debit.referenceNumber),
  );

  console.log(
    `Found ${existingRefIds.size} existing debits, processing ${uniqueDebits.length} new debits`,
  );

  // If all debits already exist, return early
  if (uniqueDebits.length === 0) {
    console.log('All debits already exist in the database, skipping import');
    return [];
  }

  // Get all unique dates from the debits
  const uniqueDatesSet = new Set(
    uniqueDebits
      .map(debit =>
        debit.paymentDate
          ? new Date(debit.paymentDate).toISOString().split('T')[0]
          : null,
      )
      .filter(Boolean),
  );
  const uniqueDates = Array.from(uniqueDatesSet);

  console.log(
    `Processing exchange rates for ${uniqueDates.length} unique dates`,
  );

  // Fetch exchange rates for each unique date
  const exchangeRatesByDate = {};
  for (const date of uniqueDates) {
    try {
      const rates = await getExchangeRates(date);
      exchangeRatesByDate[date] =
        rates?.find(el => el.name === 'EUR - Euro')?.mdl || 20;
    } catch (error) {
      console.error(`Error fetching exchange rates for date ${date}:`, error);
      // Use default rates if available
      exchangeRatesByDate[date] = 20;
    }
  }

  // Add exchange rates to each debit
  const debitsWithExchangeRates = uniqueDebits.map(debit => {
    // Extract date in YYYY-MM-DD format for lookup
    const dateKey = debit.paymentDate
      ? new Date(debit.paymentDate).toISOString().split('T')[0]
      : Object.keys(exchangeRatesByDate)[0]; // Use first available date as fallback

    // Get exchange rate for the debit's currency
    const exchange = exchangeRatesByDate[dateKey] || 20;

    return {
      ...debit,
      exchange,
    };
  });

  // Use debitsWithExchangeRates for the database insert

  // Create debits in the database
  const { data: newDebits, error: newDebitsError } = await supabase(
    get(authStore)?.token,
    false,
  )
    .from('expenses')
    .insert(
      debitsWithExchangeRates.map(debit => ({
        createdBy: userId,
        customFields: {
          meta: debit,
        },
        amount: (Number(debit.Amount) / debit.exchange).toFixed(2), // Apply exchange rate to convert to EUR
        expenseDate: debit.paymentDate,
        name: debit.Destination,
        description: debit.Destination + ' (' + debit.Amount + ' MDL)',
        category: 'Debit',
      })),
    );

  if (newDebitsError) {
    console.error('Error creating debits:', newDebitsError);
  }

  return newDebits;
};

export const saveImportedCredits = async (credits: CreditRow[]) => {
  // Get the current user ID for audit fields
  const userId = get(currentUserStore)?.id;
  const now = new Date().toISOString();

  // Extract reference numbers from the credits to check for duplicates
  const referenceNumbers = credits
    .map(credit => credit.referenceNumber)
    .filter(Boolean);

  // Skip processing if no valid reference numbers
  if (referenceNumbers.length === 0) {
    console.warn('No valid reference numbers found in imported credits');
    return [];
  }

  // Check for existing credits with the same reference numbers
  const { data: existingCredits, error: existingCreditsError } = await supabase(
    get(authStore)?.token,
    false,
  )
    .from('credits')
    .select('refId')
    .in('refId', referenceNumbers);

  if (existingCreditsError) {
    console.error('Error checking for existing credits:', existingCreditsError);
  }

  // Create a set of existing reference IDs for faster lookup
  const existingRefIds = new Set(
    existingCredits?.map(credit => credit.refId) || [],
  );

  // Filter out credits that already exist in the database
  const uniqueCredits = credits.filter(
    credit => !existingRefIds.has(credit.referenceNumber),
  );

  console.log(
    `Found ${existingRefIds.size} existing credits, processing ${uniqueCredits.length} new credits`,
  );

  // If all credits already exist, return early
  if (uniqueCredits.length === 0) {
    console.log('All credits already exist in the database, skipping import');
    return [];
  }

  // Find accounts for all fiscal codes in the credits
  const fiscalCodes = uniqueCredits
    .map(p => p.ClientDebitFiscalCode || p.ClientCreditFiscalCode)
    .filter(Boolean);
  let accountsMap: Record<string, { id: string; name: string }> = {};

  if (fiscalCodes.length > 0) {
    // Find accounts with matching fiscal codes
    const { data: accountsData, error: accountsError } = await supabase(
      get(authStore)?.token,
      false,
    )
      .from('accounts')
      .select('id, name, fiscalCode')
      .in('fiscalCode', fiscalCodes)
      .is('deleted', false);

    if (accountsError) {
      console.error('Error fetching accounts:', accountsError);
    } else if (accountsData) {
      // Create a map of fiscal code to account
      accountsMap = accountsData.reduce(
        (acc, account) => {
          if (account.fiscalCode) {
            acc[account.fiscalCode] = { id: account.id, name: account.name };
          }
          return acc;
        },
        {} as Record<string, { id: string; name: string }>,
      );
    }
  }

  // Create credit objects from the imported data
  const newCredits = uniqueCredits.map(credit => {
    // Calculate the amount in EUR if not provided
    const amount =
      parseFloat(credit.Amount) ||
      parseFloat(credit.AmountCredit) ||
      parseFloat(credit.total) ||
      0;
    const exchange = parseFloat(credit.exchange) || 1;
    const amountEur = parseFloat(credit.amountEur) || amount * exchange;

    // Find the account for this credit
    const fiscalCode =
      credit.ClientDebitFiscalCode || credit.ClientCreditFiscalCode;
    const account = fiscalCode ? accountsMap[fiscalCode] : null;

    // Generate a descriptive name based on available information
    let creditName = '';
    if (account) {
      creditName = `Credit ${credit.ClientDebitName ? 'from' : 'to'} ${account.name}`;
    } else if (credit.ClientCreditName) {
      creditName = `Credit to ${credit.ClientCreditName}`;
    } else if (credit.ClientDebitName) {
      creditName = `Credit from ${credit.ClientDebitName}`;
    } else {
      creditName = `Credit for ${credit.invoiceNumber || 'Invoice'}`;
    }

    // Use the destination as description if available
    const description =
      credit.Destination || `Credit for invoice ${credit.invoiceNumber}`;

    // Use document date as credit date
    const paymentDate =
      credit.ExecuteDate || credit.OrderDate || credit.paymentDate || now;

    return {
      id: crypto.randomUUID(),
      name: creditName,
      paymentDate: paymentDate,
      amount: amount,
      currency: credit.currency || 'MDL',
      exchange: exchange,
      amountEur: amountEur,
      status: 'Open',
      paymentMethod: credit.paymentMethod || 'bank_transfer',
      remainingAmount: amount,

      // Use document identifiers as reference number
      referenceNumber: credit.referenceNumber || now,

      // Use destination as notes
      description,

      // Bank account information
      // sourceAccount: credit.AccountDebit || null,
      // destinationAccount: credit.AccountCredit || null,

      // References to other entities
      accountId: account?.id || null, // Set account ID based on fiscal code

      // Store reference number as refId for duplicate detection
      refId: credit.referenceNumber,

      // Metadata - include comprehensive bank payment information
      metadata: JSON.stringify({
        source: 'bank_import',
        importDate: now,
        documentInfo: {
          nr: credit.Nr,
          documentID: credit.DocumentID,
          movementID: credit.MovementID,
          documentTypeID: credit.DocumentTypeID,
          documentTypeNBM: credit.DocumentTypeNBM,
          orderDate: credit.OrderDate,
          executeDate: credit.ExecuteDate,
        },
        clientInfo: {
          debitName: credit.ClientDebitName,
          debitFiscalCode: credit.ClientDebitFiscalCode,
          creditName: credit.ClientCreditName,
          creditFiscalCode: credit.ClientCreditFiscalCode,
        },
        bankInfo: {
          debitAccount: credit.AccountDebit,
          debitBank: credit.BankDebitName,
          debitBankMFO: credit.BankDebitMFOCode,
          debitBankBIC: credit.DebitBankBIC,
          creditAccount: credit.AccountCredit,
          creditBank: credit.BankCreditName,
          creditBankMFO: credit.BankCreditMFOCode,
          creditBankBIC: credit.CreditBankBIC,
        },
        amountInfo: {
          amount: credit.Amount,
          amountEq: credit.AmountEq,
          amountCredit: credit.AmountCredit,
          amountCreditEq: credit.AmountCreditEq,
          amountDebit: credit.AmountDebit,
          amountDebitEq: credit.AmountDebitEq,
          currency: credit.currency,
        },
        destination: credit.Destination,
        revenueTotal:
          parseFloat(credit.Amount) ||
          parseFloat(credit.AmountCredit) ||
          parseFloat(credit.total) ||
          0, // Using revenueTotal as specified in the sales reporting feature
      }),

      // Audit fields
      createdBy: userId,
      modifiedBy: userId,
      createdAt: now,
      modifiedAt: now,
    };
  });

  // If no new credits to insert, return early
  if (newCredits.length === 0) {
    return [];
  }

  // Insert the credits into the database using batched queries for better performance
  // Process in batches of 1000 records as per the sales reporting feature standard
  const batchSize = 1000;
  let allInsertedData = [];

  for (let i = 0; i < newCredits.length; i += batchSize) {
    const batch = newCredits.slice(i, i + batchSize);
    const { data, error } = await supabase(get(authStore)?.token, false)
      .from('credits')
      .insert(batch)
      .select();

    if (error) throw error;
    if (data) allInsertedData = [...allInsertedData, ...data];
  }

  return allInsertedData;
};
