<script lang="ts">
  import Pricelist from './pricelist.svelte';
  import PricelistAddButton from './pricelist-add-button.svelte';
  import PricelistSelector from './pricelist-selector.svelte';
  import { createEventDispatcher } from 'svelte';
  import { tariffStore } from './tariff-store';

  const dispatch = createEventDispatcher();
  export let pricelists = [];
  
  let selectedPricelist = $tariffStore.currentPricelist;

  // Update selected pricelist when store changes
  $: if ($tariffStore.currentPricelist !== selectedPricelist) {
    selectedPricelist = $tariffStore.currentPricelist;
  }

  // Get current pricelist data
  $: pricelist = selectedPricelist && (pricelists || []).find(el => el.name === selectedPricelist);

  function handlePricelistSelect(newSelected: string) {
    selectedPricelist = newSelected;
    tariffStore.setPricelist(newSelected);
  }

  function addPricelist(event) {
    const newPricelist = {
      name: event.detail.name,
      prices: [],
      dimensions: [],
    };
    pricelists = [...pricelists, newPricelist];
    handlePricelistSelect(newPricelist.name);
    tariffStore.markDirty();
    dispatch('change');
  }

  function removePricelist(event) {
    const { name } = event.detail;
    pricelists = pricelists.filter(p => p.name !== name);
    if (selectedPricelist === name) {
      const newSelected = pricelists[0]?.name || null;
      handlePricelistSelect(newSelected);
    }
    tariffStore.markDirty();
    dispatch('change');
  }
</script>

<div class="relative space-y-4 w-full">
  <div class="flex space-x-4 w-full">
    <PricelistSelector
      bind:selectedPricelist
      on:select={e => handlePricelistSelect(e.detail)}
      pricelists={pricelists?.map(p => ({ label: p.name, value: p.name }))}
    />
    <PricelistAddButton on:add={addPricelist} />
  </div>
  {#if pricelist}
    <Pricelist {pricelist} on:remove={removePricelist} />
  {/if}
</div>
