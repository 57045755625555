<script lang="ts">
  import { weightsAndZones } from '$lib/config';
  import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
  } from '$lib/components/ui/tabs';

  const {
    weights_TNT_ECO,
    weights_FEDEX_ENV,
    weights_FEDEX_PAK,
    weights_FEDEX_BOX,
    weights_FEDEX_DOX,
    zones_TNT,
    zones_FEDEX,
  } = weightsAndZones;

  import GenericPricingTable from './table-with-prices.svelte';
  import Calculator from './calculator.svelte';

  export let baseTariff: any | undefined = undefined;
  export let customerTariff: any | undefined = undefined;
  export let refDate: Date | undefined = undefined;
  export let withMdl: boolean = false;
  export let currency: string = 'EUR';
  export let withCalculator = false;
  export let withFuelFee = true;
  export let withSecurityFee = true;

  let tab = withCalculator ? 'calculator' : 'fedex-export';
</script>

<Tabs bind:value={tab} class="w-full">
  <TabsList class="grid grid-cols-3 md:grid-cols-8 w-full h-24 md:h-auto">
    {#if withCalculator}
      <TabsTrigger value="calculator">Calculator</TabsTrigger>
    {/if}
    <TabsTrigger value="fedex-export">FedEx Export</TabsTrigger>
    <TabsTrigger value="fedex-import">FedEx Import</TabsTrigger>
    <TabsTrigger value="tnt-eco">TNT Eco</TabsTrigger>
  </TabsList>
  <TabsContent value="calculator" class="mx-auto">
    {#if tab === 'calculator'}
      <Calculator />
    {/if}
  </TabsContent>
  <TabsContent value="tnt-eco">
    {#if tab === 'tnt-eco'}
      <div class="pricing-tables">
        <GenericPricingTable
          caption="TNT EXPORT-IMPORT ECONOMY EXPRESS "
          weights={weights_TNT_ECO}
          zones={zones_TNT}
          operator="TNT"
          product="ECO"
          {baseTariff}
          {customerTariff}
          {refDate}
          {withMdl}
          {withFuelFee}
          {withSecurityFee}
          {currency}
        />
      </div>
    {/if}
  </TabsContent>
  <TabsContent value="fedex-export">
    {#if tab === 'fedex-export'}
      <div class="pricing-tables">
        <GenericPricingTable
          caption="EXPORT DOCUMENTS - FedEx Envelope"
          weights={weights_FEDEX_ENV}
          zones={zones_FEDEX}
          operator="FedEx"
          product="DOX"
          type="EXPORT"
          packageType="PL - FedEx Envelope"
          {baseTariff}
          {customerTariff}
          {refDate}
          {withMdl}
          {withFuelFee}
          {withSecurityFee}
          {currency}
        />
        <GenericPricingTable
          caption="EXPORT DOCUMENTS AND NON-DOCUMENTS in FedEx PAK"
          weights={weights_FEDEX_PAK}
          zones={zones_FEDEX}
          operator="FedEx"
          product="XPS"
          type="EXPORT"
          packageType="PP - FedEx Pak"
          {baseTariff}
          {customerTariff}
          {refDate}
          {withMdl}
          {withFuelFee}
          {withSecurityFee}
          {currency}
        />
        <!-- EXPORT COLETE/ Expedieri NEDOCUMENTARE – BOX -->
        <GenericPricingTable
          caption="EXPORT NON-DOCUMENTS in BOX"
          weights={weights_FEDEX_BOX}
          zones={zones_FEDEX}
          operator="FedEx"
          product="XPS"
          type="EXPORT"
          packageType="PO - Other packaging material"
          {baseTariff}
          {customerTariff}
          {refDate}
          {withMdl}
          {withFuelFee}
          {withSecurityFee}
          {currency}
        />
      </div>
    {/if}
  </TabsContent>
  <TabsContent value="fedex-import">
    {#if tab === 'fedex-import'}
      <div class="pricing-tables">
        <GenericPricingTable
          caption="IMPORT DOCUMENTS - FedEx Envelope"
          weights={weights_FEDEX_ENV}
          zones={zones_FEDEX}
          operator="FedEx"
          product="DOX"
          type="IMPORT"
          packageType="PL - FedEx Envelope"
          paymentSide="RECEIVER"
          {baseTariff}
          {customerTariff}
          {refDate}
          {withMdl}
          {withFuelFee}
          {withSecurityFee}
          {currency}
        />
        <GenericPricingTable
          caption="IMPORT DOCUMENTS AND NON-DOCUMENTS in FedEx PAK"
          weights={weights_FEDEX_PAK}
          zones={zones_FEDEX}
          operator="FedEx"
          product="XPS"
          type="IMPORT"
          paymentSide="RECEIVER"
          packageType="PP - FedEx Pak"
          {baseTariff}
          {customerTariff}
          {refDate}
          {withMdl}
          {withFuelFee}
          {withSecurityFee}
          {currency}
        />

        <GenericPricingTable
          caption="IMPORT NON-DOCUMENTS in BOX"
          weights={weights_FEDEX_BOX}
          zones={zones_FEDEX}
          operator="FedEx"
          product="XPS"
          type="IMPORT"
          paymentSide="RECEIVER"
          packageType="PO - Other packaging material"
          {baseTariff}
          {customerTariff}
          {refDate}
          {withMdl}
          {withFuelFee}
          {withSecurityFee}
          {currency}
        />
      </div>
    {/if}
  </TabsContent>
</Tabs>
