<script lang="ts">
  import { onMount, onDestroy } from 'svelte';
  import * as d3 from 'd3';
  import { formatNumber } from '$lib/utils/hanta-utils';

  export let data = [];
  let container;
  let tooltip;
  let mounted = false;

  // Create or get existing tooltip
  function getTooltip() {
    let existing = d3.select('body').select('.chart-tooltip');
    if (!existing.empty()) {
      return existing;
    }
    return d3
      .select('body')
      .append('div')
      .attr('class', 'chart-tooltip')
      .style('opacity', 0)
      .style('position', 'absolute')
      .style('pointer-events', 'none')
      .style('z-index', 1000);
  }

  function createChart() {
    if (!container || !data.length || !mounted) return;

    // Clear previous chart
    d3.select(container).selectAll('*').remove();

    // Get or create tooltip
    tooltip = getTooltip();

    const margin = { top: 10, right: 10, bottom: 50, left: 40 };
    const width = container.clientWidth - margin.left - margin.right;
    const height = 400 - margin.top - margin.bottom;

    const svg = d3
      .select(container)
      .append('svg')
      .attr('width', '100%')
      .attr('height', height + margin.top + margin.bottom)
      .attr('viewBox', `0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    const x = d3
      .scaleBand()
      .range([0, width])
      .domain(data.map(d => d.period))
      .padding(0.1);

    // Modified x-axis with conditional label filtering
    svg
      .append('g')
      .attr('transform', `translate(0,${height})`)
      .call(
        d3.axisBottom(x).tickFormat((d, i) => {
          // Show every 2nd label if more than 12 items
          if (data.length > 12) {
            return i % 2 === 0 ? d : '';
          }
          return d; // Show all labels if 12 or fewer items
        }),
      )
      .selectAll('text')
      .style('text-anchor', 'end')
      .attr('dx', '-.8em')
      .attr('dy', '.15em')
      .attr('transform', 'rotate(-45)')
      .style('font-size', '10px')
      .style('font-family', 'system-ui, sans-serif')
      .each(function (d) {
        if (!d) return; // Skip empty labels
        const text = d3.select(this);
        const words = text.text();
        if (words.length > 15) {
          text.text(words.substring(0, 15) + '...');
        }
      });

    // Add x-axis gridlines
    svg
      .append('g')
      .attr('class', 'grid')
      .attr('transform', `translate(0,${height})`)
      .call(d3.axisBottom(x).tickSize(-height).tickFormat(''))
      .select('.domain')
      .remove();

    const y = d3
      .scaleLinear()
      .domain([0, d3.max(data, d => d.value)])
      .range([height, 0]);

    svg.append('g').call(d3.axisLeft(y));

    // Updated bar creation with safer tooltip handling
    svg
      .selectAll('mybar')
      .data(data)
      .join('rect')
      .attr('x', d => x(d.period))
      .attr('y', d => y(d.value))
      .attr('width', x.bandwidth())
      .attr('height', d => height - y(d.value))
      .attr('fill', '#69b3a2')
      .attr('class', 'bar')
      .on('mouseover', function (event, d) {
        const currentTooltip = getTooltip();

        d3.select(this).transition().duration(200).attr('fill', '#4a7b72');

        currentTooltip
          .html(
            `
            <div class="tooltip-content">
              <div class="tooltip-title">${d.period}</div>
              <div class="tooltip-value">${formatNumber(d.value)}</div>
            </div>
          `,
          )
          .style('left', `${Math.min(event.pageX + 10, window.innerWidth - 100)}px`)
          .style('top', `${event.pageY - 28}px`)
          .style('opacity', 1);
      })
      .on('mousemove', function (event) {
        const currentTooltip = getTooltip();
        currentTooltip
          .style('left', `${Math.min(event.pageX + 10, window.innerWidth - 100)}px`)
          .style('top', `${event.pageY - 28}px`);
      })
      .on('mouseout', function () {
        const currentTooltip = getTooltip();

        d3.select(this).transition().duration(200).attr('fill', '#69b3a2');

        currentTooltip.style('opacity', 0);
      });
  }

  onMount(() => {
    mounted = true;
    createChart();
  });

  onDestroy(() => {
    mounted = false;
    // Only remove tooltip if no other charts are using it
    const otherCharts = document.querySelectorAll('.chart');
    if (otherCharts.length <= 1) {
      d3.selectAll('.chart-tooltip').remove();
    }
  });

  // Watch for data changes
  $: if (data && mounted) {
    createChart();
  }
</script>

<div bind:this={container} class="chart" />

<style>
  .chart {
    width: 100%;
    min-width: 0;
    height: auto;
    position: relative;
    overflow-x: auto;
  }

  :global(.chart-tooltip) {
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 8px;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
    z-index: 1000;
  }

  :global(.tooltip-content) {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  :global(.tooltip-title) {
    font-weight: bold;
    color: #fff;
  }

  :global(.tooltip-value) {
    color: #a5f3fc;
  }

  :global(.grid line) {
    stroke: #e2e8f0;
    stroke-opacity: 0.3;
    shape-rendering: crispEdges;
  }
</style>
