<script lang="ts">
  import { Dialog as SheetPrimitive } from 'bits-ui';
  import { cn } from '$lib/utils/hanta-utils';
  import { fade } from 'svelte/transition';

  type $$Props = SheetPrimitive.OverlayProps;

  let className: $$Props['class'] = undefined;
  export let transition: $$Props['transition'] = fade;
  export let transitionConfig: $$Props['transitionConfig'] = {
    duration: 150,
  };
  export { className as class };
</script>

<SheetPrimitive.Overlay
  {transition}
  {transitionConfig}
  class={cn(
    'hidden fixed_TODO inset-0 z-20 bg-background/80 backdrop-blur-sm ',
    className,
  )}
  {...$$restProps}
/>
