import { toast } from 'svelte-sonner';
import { supabase } from '$lib/api/supabase';
import { appStore } from '$lib/stores/app-store';
import { authStore } from '$lib/stores/auth-store';
import { get } from 'svelte/store';
import { handlePipelineChanges } from './pipeline-realtime';
import { trashStore } from '$lib/stores/trash-store';
import { pipelineStore } from '$lib/stores/pipeline-store';

export enum ConnectionStatus {
  Connected = 'connected',
  Disconnected = 'disconnected',
  Error = 'error',
}

export interface ConnectionConfig {
  MAX_RECONNECT_ATTEMPTS: number;
  BASE_RECONNECT_DELAY: number;
  EXCEPTION_TABLES: string[];
}

export class ConnectionService {
  private config: ConnectionConfig;
  private subscription: any = null;
  private reconnectAttempts = 0;
  private reconnectTimeout: NodeJS.Timeout | null = null;

  constructor(config: ConnectionConfig) {
    this.config = config;
  }

  private getBackoffDelay(): number {
    return Math.min(
      this.config.BASE_RECONNECT_DELAY * Math.pow(2, this.reconnectAttempts),
      30000,
    );
  }

  private resetConnectionState() {
    this.reconnectAttempts = 0;
    appStore.setConnectionStatus(ConnectionStatus.Connected);
  }

  private handleDatabaseChanges(payload: any) {
    if (this.config.EXCEPTION_TABLES.includes(payload.table)) {
      return;
    }

    this.invalidateQueriesWithDebounce(payload.table);
    //this.invalidateQueries(payload.table);
    this.handleSpecificTableChanges(payload);
  }

  private debounceTimeouts: { [key: string]: NodeJS.Timeout } = {};
  private DEBOUNCE_TIME = {
    manifests: 1000, // 10 seconds for manifests
    default: 1000, // no debounce for other tables
  };

  private invalidateQueries = (table: string) => {
    const queryClient = get(appStore).queryClient;
    if (queryClient) {
      queryClient.invalidateQueries([table], {
        exact: false,
        refetchActive: true,
      });

      queryClient.invalidateQueries(['crm-object-selector', table], {
        exact: false,
        refetchActive: false,
      });
    }
  };

  private invalidateQueriesWithDebounce = (table: string) => {
    if (this.debounceTimeouts[table]) {
      clearTimeout(this.debounceTimeouts[table]);
    }

    const debounceTime =
      this.DEBOUNCE_TIME[table] || this.DEBOUNCE_TIME.default;

    this.debounceTimeouts[table] = setTimeout(() => {
      this.invalidateQueries(table);
      delete this.debounceTimeouts[table];
    }, debounceTime);
  };

  private handleSpecificTableChanges(payload: any) {
    const { eventType, old, new: newData, table } = payload;

    // Handle soft deletes (items with deleted=true)
    if (eventType === 'UPDATE' && newData?.deleted === true) {
      this.handleSoftDeletedItem(newData, table);
    }

    // Handle CRM object changes
    if (eventType === 'DELETE' && old?.id) {
      this.handleDeletedItem(old);
    }

    if (newData?.id) {
      this.handleUpdatedItem(newData);
    }

    // Handle pipeline changes
    if (
      ['pipelines', 'pipelinePhases', 'pipelineItems'].includes(payload.table)
    ) {
      handlePipelineChanges(payload);

      // Refresh pipeline store on changes
      if (payload.table === 'pipelines') {
        pipelineStore.loadPipelines();
      }
    }
  }

  private handleSoftDeletedItem(item: any, table: string) {
    // Handle the item that was soft-deleted (deleted=true)
    console.log(`Item soft-deleted: ${table}/${item.id}`);

    // Close popup if the deleted item is currently selected
    const currentSelected = get(appStore).selected;
    if (currentSelected?.id === item.id) {
      appStore.openPopup(false);
      appStore.select(undefined);
    }

    // Update CRM references if needed
    const currentCrmAccount = get(appStore).crmAccount;
    const currentCrmContact = get(appStore).crmContact;

    if (currentCrmAccount?.id === item.id) {
      appStore.setCrmAccount(undefined);
    }

    if (currentCrmContact?.id === item.id) {
      appStore.setCrmContact(undefined);
    }

    // Refresh the trash store to include the newly deleted item
    trashStore.loadTrashItems();

    // If the item is a pipeline, refresh the pipeline store
    if (table === 'pipelines') {
      pipelineStore.loadPipelines();
    }
  }

  private handleDeletedItem(item: any) {
    const currentSelected = get(appStore).selected;
    const currentCrmAccount = get(appStore).crmAccount;
    const currentCrmContact = get(appStore).crmContact;

    if (currentSelected?.id === item.id) {
      appStore.openPopup(false);
      appStore.select(undefined);
    }

    if (currentCrmAccount?.id === item.id) {
      appStore.setCrmAccount(undefined);
    }

    if (currentCrmContact?.id === item.id) {
      appStore.setCrmContact(undefined);
    }

    // Refresh the trash store when an item is permanently deleted
    trashStore.loadTrashItems();
  }

  private handleUpdatedItem(item: any) {
    const currentCrmAccount = get(appStore).crmAccount;
    const currentCrmContact = get(appStore).crmContact;

    if (currentCrmAccount?.id === item.id) {
      appStore.setCrmAccount(item);
    }

    if (currentCrmContact?.id === item.id) {
      appStore.setCrmContact(item);
    }
  }

  private attemptReconnect() {
    if (this.reconnectTimeout) {
      clearTimeout(this.reconnectTimeout);
    }

    if (this.reconnectAttempts >= this.config.MAX_RECONNECT_ATTEMPTS) {
      console.log('Max reconnection attempts reached');
      toast.error('Connection lost. Please refresh the page.');
      appStore.setConnectionStatus(ConnectionStatus.Error);
      return;
    }

    const token = get(authStore).token;
    if (!token) {
      console.error('No auth token available for reconnection');
      return;
    }

    this.reconnectAttempts++;
    const delay = this.getBackoffDelay();

    console.log(
      `Attempting to reconnect (${this.reconnectAttempts}/${this.config.MAX_RECONNECT_ATTEMPTS}) in ${delay / 1000}s...`,
    );

    this.reconnectTimeout = setTimeout(() => {
      this.unsubscribe();
      setTimeout(() => this.subscribeToChannels(), 100);
    }, delay);
  }

  unsubscribe() {
    try {
      if (this.subscription) {
        this.subscription.unsubscribe();
        this.subscription = null;
      }

      setTimeout(() => {
        supabase(get(authStore).token, false).removeAllChannels();
        appStore.setConnectionStatus(ConnectionStatus.Disconnected);
      }, 100);
    } catch (error) {
      console.error('Error during unsubscribe:', error);
    }
  }

  subscribeToChannels() {
    const token = get(authStore)?.token;

    console.log('Subscribing to channels...');

    try {
      this.subscription = supabase(token, false)
        .channel('*')
        .on('postgres_changes', { event: '*', schema: 'public' }, payload => {
          this.resetConnectionState();
          this.handleDatabaseChanges(payload);
        })
        .on('presence', { event: 'sync' }, () => this.resetConnectionState())
        .on('presence', { event: 'join' }, () => this.resetConnectionState())
        .on('presence', { event: 'leave' }, () => {
          appStore.setConnectionStatus(ConnectionStatus.Disconnected);
          this.attemptReconnect();
        })
        .on('system', { event: '*' }, payload => {
          if (payload.event === 'disconnected') {
            appStore.setConnectionStatus(ConnectionStatus.Disconnected);
            toast.error('Lost connection to server');
            this.attemptReconnect();
          }
        })
        .subscribe(status => {
          switch (status) {
            case 'SUBSCRIBED':
              this.resetConnectionState();
              break;
            case 'CLOSED':
              appStore.setConnectionStatus(ConnectionStatus.Disconnected);
              this.attemptReconnect();
              break;
            case 'CHANNEL_ERROR':
              appStore.setConnectionStatus(ConnectionStatus.Error);
              this.attemptReconnect();
              break;
          }
        });
    } catch (error) {
      console.error('Error during subscription:', error);
      this.attemptReconnect();
    }
  }

  cleanup() {
    if (this.reconnectTimeout) {
      clearTimeout(this.reconnectTimeout);
    }
    this.unsubscribe();
  }
}

// Configuration for the connection service
export const connectionConfig: ConnectionConfig = {
  MAX_RECONNECT_ATTEMPTS: 5,
  BASE_RECONNECT_DELAY: 5000,
  EXCEPTION_TABLES: ['search', 'rates', 'shipments', 'sequences', 'timelogs'],
};
