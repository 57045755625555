<script lang="ts">
  import { onMount } from 'svelte';
  import type { DateRange } from '$lib/components/hanta/date/hanta-date-range';
  import { createQuery } from '@tanstack/svelte-query';
  import * as Card from '$lib/components/ui/card/index.js';
  import * as Table from '$lib/components/ui/table/index.js';
  import * as Tabs from '$lib/components/ui/tabs/index.js';
  import { formatDate } from '$lib/utils/hanta-utils';
  import { Progress } from '$lib/components/ui/progress/index.js';
  import { Badge } from '$lib/components/ui/badge';
  import {
    format,
    differenceInDays,
    parseISO,
    getDay,
    addDays,
  } from 'date-fns';
  import { getFedexReport } from '$lib/api/reports-api';
  import { getHolidaysInPeriod } from '$lib/api/holiday-api';
  import Icon from '@iconify/svelte';
  import Button from '$lib/components/ui/button/button.svelte';
  import { popupStore } from '$lib/stores/app-store';
  import { writable, derived, get } from 'svelte/store';
  import * as Form from '$lib/components/ui/form/index.js';
  import Input from '$lib/components/ui/input/input.svelte';
  import { browser } from '$app/environment';

  // Define interface for position stats
  interface FedExPositionStat {
    position: string;
    totalVisitMeetings: number;
    totalPhoneCalls: number;
    salesReps: any[];
  }

  export let dateRange: DateRange;

  // Country code for holiday calculation
  const COUNTRY_CODE = 'MD';

  // Local variables for input fields - Indoor Sales
  let indoorVisitMeetingPerDayValue = 0;
  let indoorPhoneCallPerDayValue = 25;

  // Local variables for input fields - Field Sales
  let fieldVisitMeetingPerDayValue = 3;
  let fieldPhoneCallPerDayValue = 2;

  // Target values for the period
  let indoorVisitMeetingTarget = 0;
  let indoorPhoneCallTarget = 0;
  let fieldVisitMeetingTarget = 0;
  let fieldPhoneCallTarget = 0;
  let visitMeetingTarget = 0;
  let phoneCallTarget = 0;

  // Working days and holidays count
  let workingDaysCount = 0;
  let holidaysCount = 0;

  // Target values store
  const targetValues = writable({
    indoor: {
      visitMeetingPerDay: indoorVisitMeetingPerDayValue,
      phoneCallPerDay: indoorPhoneCallPerDayValue,
    },
    field: {
      visitMeetingPerDay: fieldVisitMeetingPerDayValue,
      phoneCallPerDay: fieldPhoneCallPerDayValue,
    },
  });

  // Load target values from localStorage on mount
  onMount(() => {
    if (browser) {
      const savedTargets = localStorage.getItem('fedexReportTargets');
      if (savedTargets) {
        try {
          const parsed = JSON.parse(savedTargets);
          targetValues.set(parsed);

          // Update local variables
          indoorVisitMeetingPerDayValue =
            parsed.indoor?.visitMeetingPerDay ?? 0;
          indoorPhoneCallPerDayValue = parsed.indoor?.phoneCallPerDay ?? 25;
          fieldVisitMeetingPerDayValue = parsed.field?.visitMeetingPerDay ?? 3;
          fieldPhoneCallPerDayValue = parsed.field?.phoneCallPerDay ?? 2;
        } catch (error) {
          console.error('Error parsing saved targets:', error);
        }
      }

      // Update targets if date range is available
      if (dateRange?.start && dateRange?.end) {
        console.log('Component mounted with date range, updating targets');
        updateTargets();
      }
    }
  });

  // Update target values in store and localStorage
  function updateTargetValues() {
    targetValues.update(values => {
      const updated = {
        indoor: {
          visitMeetingPerDay: indoorVisitMeetingPerDayValue,
          phoneCallPerDay: indoorPhoneCallPerDayValue,
        },
        field: {
          visitMeetingPerDay: fieldVisitMeetingPerDayValue,
          phoneCallPerDay: fieldPhoneCallPerDayValue,
        },
      };
      localStorage.setItem('fedexReportTargets', JSON.stringify(updated));
      return updated;
    });

    // Update targets if date range is available
    if (dateRange?.start && dateRange?.end) {
      updateTargets();
    }
  }

  // Function to convert DateValue to JavaScript Date
  function dateValueToDate(dateValue: any): Date | null {
    if (!dateValue) return null;

    // If it's already a Date object, return it
    if (dateValue instanceof Date) return dateValue;

    // If it has toDate method (DateValue object), use it
    if (typeof dateValue.toDate === 'function') {
      return dateValue.toDate();
    }

    // If it's a string, parse it
    if (typeof dateValue === 'string') {
      return new Date(dateValue);
    }

    // If we can't convert it, log an error and return null
    console.error('Could not convert to Date:', dateValue);
    return null;
  }

  // Function to format dates for PostgreSQL
  function formatDateForPostgres(dateValue: any): string {
    const date = dateValueToDate(dateValue);
    if (!date) return '';
    return format(date, 'yyyy-MM-dd');
  }

  // Calculate working days in the period
  async function calculateWorkingDays(
    startValue: any,
    endValue: any,
  ): Promise<number> {
    console.log('calculateWorkingDays called with:', startValue, endValue);

    // Convert DateValue objects to JavaScript Date objects
    const start = dateValueToDate(startValue);
    const end = dateValueToDate(endValue);

    if (!start || !end) {
      console.log('Invalid date range, returning 0');
      return 0;
    }

    // Ensure we're working with date objects with time set to midnight
    // to avoid any time-related comparison issues
    const startDate = new Date(
      start.getFullYear(),
      start.getMonth(),
      start.getDate(),
    );
    const endDate = new Date(end.getFullYear(), end.getMonth(), end.getDate());

    console.log('Normalized dates:', startDate, endDate);

    // Get holidays count
    try {
      holidaysCount = await getHolidaysInPeriod(
        startDate,
        endDate,
        COUNTRY_CODE,
      );
      console.log('Holidays count:', holidaysCount);
    } catch (error) {
      console.error('Error getting holidays:', error);
      holidaysCount = 0;
    }

    // Calculate total days in the period (inclusive of start and end)
    const totalDays = differenceInDays(endDate, startDate) + 1;
    console.log('Total days in period:', totalDays);

    // Count weekends (Saturdays and Sundays)
    let weekendCount = 0;

    // Create a new date object to avoid modifying the original
    let currentDate = new Date(startDate.getTime());

    console.log(
      'Checking for weekend days between',
      format(currentDate, 'yyyy-MM-dd'),
      'and',
      format(endDate, 'yyyy-MM-dd'),
    );

    while (currentDate <= endDate) {
      const dayOfWeek = getDay(currentDate);
      // 0 is Sunday, 6 is Saturday
      if (dayOfWeek === 0 || dayOfWeek === 6) {
        weekendCount++;
        console.log(
          'Weekend day found:',
          format(currentDate, 'yyyy-MM-dd'),
          'Day:',
          dayOfWeek,
        );
      }
      // Move to next day
      currentDate = addDays(currentDate, 1);
    }

    console.log('Weekend count:', weekendCount);

    // Subtract holidays and weekends from total days
    const workingDays = totalDays - holidaysCount - weekendCount;
    console.log(
      'Working days calculation:',
      totalDays,
      '-',
      holidaysCount,
      '-',
      weekendCount,
      '=',
      workingDays,
    );

    return Math.max(0, workingDays); // Ensure we don't return negative days
  }

  // Debug the date range
  $: {
    if (dateRange?.start && dateRange?.end) {
      console.log('Date range in component:', {
        start: dateRange.start,
        end: dateRange.end,
        startFormatted: formatDateForPostgres(dateRange.start),
        endFormatted: formatDateForPostgres(dateRange.end),
        startType: typeof dateRange.start,
        endType: typeof dateRange.end,
        startIsDate: dateRange.start instanceof Date,
        endIsDate: dateRange.end instanceof Date,
      });
    }
  }

  // Ensure date range contains proper Date objects
  $: {
    if (dateRange) {
      // Convert string dates to Date objects if needed
      if (dateRange.start && !(dateRange.start instanceof Date)) {
        console.log('Converting start date from string to Date object');
        dateRange.start =
          typeof dateRange.start === 'string'
            ? new Date(dateRange.start)
            : dateRange.start;
      }

      if (dateRange.end && !(dateRange.end instanceof Date)) {
        console.log('Converting end date from string to Date object');
        dateRange.end =
          typeof dateRange.end === 'string'
            ? new Date(dateRange.end)
            : dateRange.end;
      }
    }
  }

  // Update targets when date range changes or when the component mounts
  $: {
    console.log('Date range changed:', dateRange);
    if (dateRange?.start && dateRange?.end) {
      const startDate = dateValueToDate(dateRange.start);
      const endDate = dateValueToDate(dateRange.end);

      console.log(
        'Updating targets with date range:',
        startDate ? format(startDate, 'yyyy-MM-dd') : 'invalid',
        'to',
        endDate ? format(endDate, 'yyyy-MM-dd') : 'invalid',
      );

      if (startDate && endDate) {
        updateTargets();
      } else {
        console.error('Invalid date range objects:', dateRange);
      }
    } else {
      // Reset values when no date range is selected
      workingDaysCount = 0;
      holidaysCount = 0;
      indoorVisitMeetingTarget = 0;
      indoorPhoneCallTarget = 0;
      fieldVisitMeetingTarget = 0;
      fieldPhoneCallTarget = 0;
      visitMeetingTarget = 0;
      phoneCallTarget = 0;
    }
  }

  // Make sure to update targets when the component mounts if dateRange is available
  onMount(() => {
    if (dateRange?.start && dateRange?.end) {
      const startDate = dateValueToDate(dateRange.start);
      const endDate = dateValueToDate(dateRange.end);

      console.log(
        'Component mounted with date range:',
        startDate ? format(startDate, 'yyyy-MM-dd') : 'invalid',
        'to',
        endDate ? format(endDate, 'yyyy-MM-dd') : 'invalid',
      );

      if (startDate && endDate) {
        updateTargets();
      }
    }
  });

  // Calculate targets based on working days
  async function updateTargets() {
    if (!dateRange?.start || !dateRange?.end) {
      console.log('No date range available for updateTargets');
      return;
    }

    try {
      workingDaysCount = await calculateWorkingDays(
        dateRange.start,
        dateRange.end,
      );

      // Get current target values from store with fallbacks
      const targetVals = get(targetValues);
      const indoorVisitPerDay =
        targetVals?.indoor?.visitMeetingPerDay ?? indoorVisitMeetingPerDayValue;
      const indoorCallPerDay =
        targetVals?.indoor?.phoneCallPerDay ?? indoorPhoneCallPerDayValue;
      const fieldVisitPerDay =
        targetVals?.field?.visitMeetingPerDay ?? fieldVisitMeetingPerDayValue;
      const fieldCallPerDay =
        targetVals?.field?.phoneCallPerDay ?? fieldPhoneCallPerDayValue;

      // Indoor Sales targets
      indoorVisitMeetingTarget = Math.round(
        workingDaysCount * indoorVisitPerDay,
      );
      indoorPhoneCallTarget = Math.round(workingDaysCount * indoorCallPerDay);

      // Field Sales targets
      fieldVisitMeetingTarget = Math.round(workingDaysCount * fieldVisitPerDay);
      fieldPhoneCallTarget = Math.round(workingDaysCount * fieldCallPerDay);

      // Total targets (for backward compatibility)
      visitMeetingTarget = indoorVisitMeetingTarget + fieldVisitMeetingTarget;
      phoneCallTarget = indoorPhoneCallTarget + fieldPhoneCallTarget;

      console.log('Updated targets:', {
        workingDaysCount,
        holidaysCount,
        indoorVisitMeetingTarget,
        indoorPhoneCallTarget,
        fieldVisitMeetingTarget,
        fieldPhoneCallTarget,
      });
    } catch (error) {
      console.error('Error updating targets:', error);
    }
  }

  // Target values
  let targets = {
    calls: 100,
    emails: 200,
    meetings: 50,
    quotes: 30,
    orderCountGrowth: 5,
    weightGrowth: 10,
    supplierGrowth: 8,
    revenueGrowth: 15,
  };

  // Load targets from localStorage
  onMount(() => {
    const savedTargets = localStorage.getItem('fedexReportTargets');
    if (savedTargets) {
      targets = JSON.parse(savedTargets);
    }
  });

  // Update targets in localStorage
  function saveTargets() {
    localStorage.setItem('fedexReportTargets', JSON.stringify(targets));
  }

  // Function to view activity details
  const viewActivityDetails = activityId => {
    popupStore.open('activity', { id: activityId });
  };

  // Calculate achievement percentages
  $: getAchievementPercentage = (actual, target) => {
    if (!target) return 0;
    return Math.min(Math.round((actual / target) * 100), 100);
  };

  // Active tab
  let activeTab = 'overview';

  // Create stores for date range values
  const startDateStore = writable(null);
  const endDateStore = writable(null);

  // Update stores when date range changes
  $: startDateStore.set(dateRange?.start);
  $: endDateStore.set(dateRange?.end);

  // Create query with derived store
  const fedexReportQuery = createQuery(
    derived([startDateStore, endDateStore], ([$startDate, $endDate]) => ({
      enabled: !!$startDate && !!$endDate,
      queryKey: [
        'fedexReport',
        formatDateForPostgres($startDate),
        formatDateForPostgres($endDate),
      ],
      queryFn: () =>
        getFedexReport({
          startDate: formatDateForPostgres($startDate),
          endDate: formatDateForPostgres($endDate),
        }),
    })),
  );

  // Helper function to check if a position is Indoor Sales
  const isIndoorSales = position => {
    return (
      position?.toLowerCase().includes('indoor') ||
      position?.toLowerCase().includes('inside')
    );
  };

  // Helper function to check if a position is Field Sales
  const isFieldSales = position => {
    return (
      position?.toLowerCase().includes('field') ||
      position?.toLowerCase().includes('outside')
    );
  };

  // Get position-specific stats
  $: validatedPositionStats = $fedexReportQuery?.data?.positionStats?.length
    ? $fedexReportQuery.data.positionStats.map(pos => ({
        position: pos.position || 'Unassigned',
        totalVisitMeetings: pos.totalVisitMeetings || 0,
        totalPhoneCalls: pos.totalPhoneCalls || 0,
        salesReps: pos.salesReps || [],
      }))
    : [];

  $: indoorSalesStats = validatedPositionStats.filter(
    pos => pos.position && isIndoorSales(pos.position),
  ) as FedExPositionStat[];

  $: fieldSalesStats = validatedPositionStats.filter(
    pos => pos.position && isFieldSales(pos.position),
  ) as FedExPositionStat[];

  // Calculate totals for Indoor Sales
  $: indoorVisitMeetingsTotal = indoorSalesStats.reduce(
    (sum, pos) => sum + pos.totalVisitMeetings,
    0,
  );
  $: indoorPhoneCallsTotal = indoorSalesStats.reduce(
    (sum, pos) => sum + pos.totalPhoneCalls,
    0,
  );

  // Calculate totals for Field Sales
  $: fieldVisitMeetingsTotal = fieldSalesStats.reduce(
    (sum, pos) => sum + pos.totalVisitMeetings,
    0,
  );
  $: fieldPhoneCallsTotal = fieldSalesStats.reduce(
    (sum, pos) => sum + pos.totalPhoneCalls,
    0,
  );

  // Helper functions
  function calculatePercentageChange(current, previous) {
    if (!previous || previous === 0) return current > 0 ? 100 : 0;
    return ((current - previous) / previous) * 100;
  }

  function formatWeight(weight) {
    if (weight === undefined || weight === null) return '0';
    return weight.toFixed(2);
  }

  function formatCurrency(value, includeSymbol = true) {
    if (value === undefined || value === null)
      return includeSymbol ? '€0.00' : '0.00';

    // Handle formatting with or without the currency symbol
    return includeSymbol
      ? value.toLocaleString(undefined, {
          style: 'currency',
          currency: 'EUR',
          maximumFractionDigits: 2,
        })
      : value.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        });
  }

  // Regional order stats with consistent naming
  let regionalOrderStats = [];

  // Process regional data when it changes
  $: if ($fedexReportQuery?.data) {
    console.log('Fedex report data:', $fedexReportQuery.data);
    console.log(
      'Regional order stats raw:',
      $fedexReportQuery.data?.regional_order_stats,
    );

    regionalOrderStats = (
      $fedexReportQuery.data?.regional_order_stats || []
    ).map(region => {
      const prevYearRegion = (
        $fedexReportQuery.data?.prev_year_regional_order_stats || []
      ).find(r => r.region === region.region);

      return {
        region: region.region || 'Unknown',
        orderCount: region.order_count || 0,
        totalWeight: region.total_weight || 0,
        totalSupplier: region.total_supplier_eur || 0,
        currentRevenue: region.total_revenue_eur || 0,
        previousRevenue: prevYearRegion?.total_revenue_eur || 0,
      };
    });

    console.log('Processed regional order stats:', regionalOrderStats);
  }
</script>

<div class="space-y-6">
  <!-- Target Values Form -->

  {#if $fedexReportQuery.isLoading}
    <div class="flex justify-center items-center h-64">
      <div class="text-center">
        <div class="mb-4">
          <Icon
            icon="line-md:loading-twotone-loop"
            class="w-12 h-12 mx-auto text-primary"
          />
        </div>
        <p class="text-muted-foreground">Loading FedEx report data...</p>
      </div>
    </div>
  {:else if $fedexReportQuery.isError}
    <div class="bg-destructive/10 p-4 rounded-md">
      <p class="text-destructive font-medium">
        Error loading FedEx report data
      </p>
      <p class="text-sm text-muted-foreground mt-1">
        {$fedexReportQuery.error?.message || 'An unknown error occurred'}
      </p>
    </div>
  {:else if $fedexReportQuery?.data}
    <Tabs.Root
      value={activeTab}
      onValueChange={value => (activeTab = value)}
      class="w-full"
    >
      <Tabs.List class="grid w-full grid-cols-4">
        <Tabs.Trigger value="overview">Overview</Tabs.Trigger>
        <Tabs.Trigger value="by-type">By Activity Type</Tabs.Trigger>
        <Tabs.Trigger value="by-sales-rep">By Sales Rep</Tabs.Trigger>
        <Tabs.Trigger value="orders">Order Stats</Tabs.Trigger>
      </Tabs.List>

      <Tabs.Content value="overview" class="py-4">
        <!-- Overview Cards -->
        <div class="flex items-center justify-between mb-4">
          <h2 class="text-2xl font-bold">FedEx Report</h2>
          <div class="flex items-center gap-2">
            <span class="text-sm text-muted-foreground">
              {$fedexReportQuery.isLoading
                ? 'Loading...'
                : $fedexReportQuery.isError
                  ? 'Error loading data'
                  : `${$fedexReportQuery?.data?.totalActivities || 0} activities`}
            </span>
          </div>
        </div>

        <!-- Order Statistics Summary -->
        <Card.Root class="mt-6">
          <Card.Header class="pb-3">
            <Card.Title>Year-over-Year Comparison</Card.Title>
            <Card.Description>
              Performance metrics compared to the same period last year
            </Card.Description>
          </Card.Header>
          <Card.Content>
            <div class="space-y-6">
              {#if $fedexReportQuery?.data?.order_stats}
                {@const currentOrderCount =
                  $fedexReportQuery.data.order_stats.order_count || 0}
                {@const prevOrderCount =
                  $fedexReportQuery.data.prev_year_order_stats.order_count || 0}
                {@const orderCountChange =
                  prevOrderCount > 0
                    ? ((currentOrderCount - prevOrderCount) / prevOrderCount) *
                      100
                    : prevOrderCount
                      ? 100
                      : 0}

                {@const currentRevenue =
                  $fedexReportQuery.data.order_stats.total_revenue_eur || 0}
                {@const prevRevenue =
                  $fedexReportQuery.data.prev_year_order_stats
                    .total_revenue_eur || 0}
                {@const revenueChange =
                  prevRevenue > 0
                    ? ((currentRevenue - prevRevenue) / prevRevenue) * 100
                    : prevRevenue
                      ? 100
                      : 0}

                {@const currentWeight =
                  $fedexReportQuery.data.order_stats.total_weight || 0}
                {@const prevWeight =
                  $fedexReportQuery.data.prev_year_order_stats.total_weight ||
                  0}
                {@const weightChange =
                  prevWeight > 0
                    ? ((currentWeight - prevWeight) / prevWeight) * 100
                    : prevWeight
                      ? 100
                      : 0}

                {@const currentSupplier =
                  $fedexReportQuery.data.order_stats.total_supplier_eur || 0}
                {@const prevSupplier =
                  $fedexReportQuery.data.prev_year_order_stats
                    .total_supplier_eur || 0}
                {@const supplierChange =
                  prevSupplier > 0
                    ? ((currentSupplier - prevSupplier) / prevSupplier) * 100
                    : prevSupplier
                      ? 100
                      : 0}

                <!-- Order Count Progress -->
                <div>
                  <div class="flex justify-between mb-2">
                    <div class="flex items-center gap-2">
                      <span class="text-sm font-medium">Orders</span>
                      <span class="text-sm text-muted-foreground"
                        >({currentOrderCount})</span
                      >
                    </div>
                    <div class="flex items-center gap-2">
                      <span class="text-sm font-semibold"
                        >{orderCountChange >= 0
                          ? '+'
                          : ''}{orderCountChange.toFixed(1)}% ({currentOrderCount -
                          prevOrderCount >=
                        0
                          ? '+'
                          : ''}{currentOrderCount - prevOrderCount})</span
                      >
                    </div>
                  </div>
                  <div class="flex justify-between mb-1">
                    <div></div>
                    {#if targets.orderCountGrowth !== undefined}
                      {@const targetOrderCount =
                        prevOrderCount * (1 + targets.orderCountGrowth / 100)}
                      {@const progressToTarget =
                        prevOrderCount > 0
                          ? (currentOrderCount / targetOrderCount) * 100
                          : 0}
                      <Badge
                        variant={progressToTarget >= 100
                          ? 'success'
                          : 'destructive'}
                        class="mb-1"
                      >
                        <span class="flex items-center text-xs">
                          <span class="whitespace-nowrap"
                            >Target: {targets.orderCountGrowth}% ({Math.round(
                              targetOrderCount,
                            )})</span
                          > <span class="mx-1">|</span>
                          <span class="whitespace-nowrap"
                            >Progress: {progressToTarget.toFixed(1)}%</span
                          >
                        </span>
                      </Badge>
                    {/if}
                  </div>
                  <Progress
                    value={Math.min(Math.max(orderCountChange + 100, 0), 200) /
                      2}
                    class="h-2"
                  />
                </div>

                <!-- Revenue Progress -->
                <div>
                  <div class="flex justify-between mb-2">
                    <div class="flex items-center gap-2">
                      <span class="text-sm font-medium">Revenue</span>
                      <span class="text-sm text-muted-foreground"
                        >{currentRevenue.toLocaleString(undefined, {
                          style: 'currency',
                          currency: 'EUR',
                          maximumFractionDigits: 2,
                        })}</span
                      >
                    </div>
                    <div class="flex items-center gap-2">
                      <span class="text-sm font-semibold"
                        >{revenueChange >= 0 ? '+' : ''}{revenueChange.toFixed(
                          1,
                        )}% ({(currentRevenue - prevRevenue).toLocaleString(
                          undefined,
                          {
                            style: 'currency',
                            currency: 'EUR',
                            maximumFractionDigits: 2,
                          },
                        )})</span
                      >
                    </div>
                  </div>
                  <div class="flex justify-between mb-1">
                    <div></div>
                    {#if targets.revenueGrowth !== undefined}
                      {@const targetRevenue =
                        prevRevenue * (1 + targets.revenueGrowth / 100)}
                      {@const progressToTarget =
                        prevRevenue > 0
                          ? (currentRevenue / targetRevenue) * 100
                          : 0}
                      <Badge
                        variant={progressToTarget >= 100
                          ? 'success'
                          : 'destructive'}
                        class="mb-1"
                      >
                        <span class="flex items-center text-xs">
                          <span class="whitespace-nowrap"
                            >Target: {targets.revenueGrowth}% ({targetRevenue.toLocaleString(
                              undefined,
                              {
                                style: 'currency',
                                currency: 'EUR',
                                maximumFractionDigits: 0,
                              },
                            )})</span
                          > <span class="mx-1">|</span>
                          <span class="whitespace-nowrap"
                            >Progress: {progressToTarget.toFixed(1)}%</span
                          >
                        </span>
                      </Badge>
                    {/if}
                  </div>
                  <Progress
                    value={Math.min(Math.max(revenueChange + 100, 0), 200) / 2}
                    class="h-2"
                  />
                </div>

                <!-- Weight Progress -->
                <div>
                  <div class="flex justify-between mb-2">
                    <div class="flex items-center gap-2">
                      <span class="text-sm font-medium">Weight</span>
                      <span class="text-sm text-muted-foreground"
                        >{currentWeight.toFixed(2)} kg</span
                      >
                    </div>
                    <div class="flex items-center gap-2">
                      <span class="text-sm font-semibold"
                        >{weightChange >= 0 ? '+' : ''}{weightChange.toFixed(
                          1,
                        )}% ({(currentWeight - prevWeight).toFixed(2)} kg)</span
                      >
                    </div>
                  </div>
                  <div class="flex justify-between mb-1">
                    <div></div>
                    {#if targets.weightGrowth !== undefined}
                      {@const targetWeight =
                        prevWeight * (1 + targets.weightGrowth / 100)}
                      {@const progressToTarget =
                        prevWeight > 0
                          ? (currentWeight / targetWeight) * 100
                          : 0}
                      <Badge
                        variant={progressToTarget >= 100
                          ? 'success'
                          : 'destructive'}
                        class="mb-1"
                      >
                        <span class="flex items-center text-xs">
                          <span class="whitespace-nowrap"
                            >Target: {targets.weightGrowth}% ({targetWeight.toFixed(
                              2,
                            )} kg)</span
                          > <span class="mx-1">|</span>
                          <span class="whitespace-nowrap"
                            >Progress: {progressToTarget.toFixed(1)}%</span
                          >
                        </span>
                      </Badge>
                    {/if}
                  </div>
                  <Progress
                    value={Math.min(Math.max(weightChange + 100, 0), 200) / 2}
                    class="h-2"
                  />
                </div>

                <!-- Supplier Total Progress -->
                <div>
                  <div class="flex justify-between mb-2">
                    <div class="flex items-center gap-2">
                      <span class="text-sm font-medium">Supplier Total</span>
                      <span class="text-sm text-muted-foreground"
                        >{currentSupplier.toLocaleString(undefined, {
                          style: 'currency',
                          currency: 'EUR',
                          maximumFractionDigits: 2,
                        })}</span
                      >
                    </div>
                    <div class="flex items-center gap-2">
                      <span class="text-sm font-semibold"
                        >{supplierChange >= 0
                          ? '+'
                          : ''}{supplierChange.toFixed(1)}% ({(
                          currentSupplier - prevSupplier
                        ).toLocaleString(undefined, {
                          style: 'currency',
                          currency: 'EUR',
                          maximumFractionDigits: 2,
                        })})</span
                      >
                    </div>
                  </div>
                  <div class="flex justify-between mb-1">
                    <div></div>
                    {#if targets.supplierGrowth !== undefined}
                      {@const targetSupplier =
                        prevSupplier * (1 + targets.supplierGrowth / 100)}
                      {@const progressToTarget =
                        prevSupplier > 0
                          ? (currentSupplier / targetSupplier) * 100
                          : 0}
                      <Badge
                        variant={progressToTarget >= 100
                          ? 'success'
                          : 'destructive'}
                        class="mb-1"
                      >
                        <span class="flex items-center text-xs">
                          <span class="whitespace-nowrap"
                            >Target: {targets.supplierGrowth}% ({targetSupplier.toLocaleString(
                              undefined,
                              {
                                style: 'currency',
                                currency: 'EUR',
                                maximumFractionDigits: 0,
                              },
                            )})</span
                          > <span class="mx-1">|</span>
                          <span class="whitespace-nowrap"
                            >Progress: {progressToTarget.toFixed(1)}%</span
                          >
                        </span>
                      </Badge>
                    {/if}
                  </div>
                  <Progress
                    value={Math.min(Math.max(supplierChange + 100, 0), 200) / 2}
                    class="h-2"
                  />
                </div>
              {/if}
            </div>
          </Card.Content>
        </Card.Root>

        <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
          <Card.Root class="hidden">
            <Card.Content class="p-6">
              <div class="flex flex-col">
                <span class="text-sm text-muted-foreground"
                  >Total Activities</span
                >
                <span class="text-3xl font-bold"
                  >{$fedexReportQuery?.data?.totalActivities || 0}</span
                >
              </div>
            </Card.Content>
          </Card.Root>

          <!-- Indoor Sales Section -->
          <Card.Root class="col-span-1 md:col-span-3 mt-4 bg-muted">
            <Card.Header class="pb-2">
              <Card.Title>Indoor Sales KPIs</Card.Title>
              <Card.Description>
                {indoorSalesStats.reduce(
                  (sum, pos) => sum + pos.salesReps.length,
                  0,
                )} employees
              </Card.Description>
            </Card.Header>
            <Card.Content class="grid grid-cols-3 gap-2">
              <!-- Indoor Sales - Visits/Meetings Target Card -->
              <Card.Root>
                <Card.Content class="p-6">
                  <div class="flex flex-col">
                    <span class="text-sm text-muted-foreground">
                      Indoor Sales - Visits/Meetings
                    </span>
                    <div class="flex items-end gap-2">
                      <span class="text-3xl font-bold">
                        {indoorVisitMeetingsTotal}
                      </span>
                      <span class="text-sm text-muted-foreground mb-1">
                        of {indoorVisitMeetingTarget} target
                      </span>
                    </div>
                    <Progress
                      value={getAchievementPercentage(
                        indoorVisitMeetingsTotal,
                        indoorVisitMeetingTarget,
                      )}
                      class="h-2 mt-2"
                    />
                    <span class="text-xs text-muted-foreground mt-1">
                      {getAchievementPercentage(
                        indoorVisitMeetingsTotal,
                        indoorVisitMeetingTarget,
                      )}% of target
                    </span>
                  </div>
                </Card.Content>
              </Card.Root>

              <!-- Indoor Sales - Phone Calls Target Card -->
              <Card.Root>
                <Card.Content class="p-6">
                  <div class="flex flex-col">
                    <span class="text-sm text-muted-foreground">
                      Indoor Sales - Phone Calls
                    </span>
                    <div class="flex items-end gap-2">
                      <span class="text-3xl font-bold">
                        {indoorPhoneCallsTotal}
                      </span>
                      <span class="text-sm text-muted-foreground mb-1">
                        of {indoorPhoneCallTarget} target
                      </span>
                    </div>
                    <Progress
                      value={getAchievementPercentage(
                        indoorPhoneCallsTotal,
                        indoorPhoneCallTarget,
                      )}
                      class="h-2 mt-2"
                    />
                    <span class="text-xs text-muted-foreground mt-1">
                      {getAchievementPercentage(
                        indoorPhoneCallsTotal,
                        indoorPhoneCallTarget,
                      )}% of target
                    </span>
                  </div>
                </Card.Content>
              </Card.Root>

              <!-- Indoor Sales - Overall Achievement Card -->
              <Card.Root>
                <Card.Content class="p-6">
                  <div class="flex flex-col">
                    <span class="text-sm text-muted-foreground">
                      Indoor Sales - Overall Achievement
                    </span>
                    <div class="flex items-end gap-2">
                      <span class="text-3xl font-bold">
                        {Math.round(
                          (getAchievementPercentage(
                            indoorVisitMeetingsTotal,
                            indoorVisitMeetingTarget,
                          ) +
                            getAchievementPercentage(
                              indoorPhoneCallsTotal,
                              indoorPhoneCallTarget,
                            )) /
                            2,
                        )}%
                      </span>
                    </div>
                    <Progress
                      value={Math.round(
                        (getAchievementPercentage(
                          indoorVisitMeetingsTotal,
                          indoorVisitMeetingTarget,
                        ) +
                          getAchievementPercentage(
                            indoorPhoneCallsTotal,
                            indoorPhoneCallTarget,
                          )) /
                          2,
                      )}
                      class="h-2 mt-2"
                    />
                  </div>
                </Card.Content>
              </Card.Root>
            </Card.Content>
          </Card.Root>
          <!-- Field Sales Section -->
          <Card.Root class="col-span-1 md:col-span-3 mt-4 bg-muted">
            <Card.Header class="pb-2">
              <Card.Title>Field Sales KPIs</Card.Title>
              <Card.Description>
                {fieldSalesStats.reduce(
                  (sum, pos) => sum + pos.salesReps.length,
                  0,
                )} employees
              </Card.Description>
            </Card.Header>
            <Card.Content class="grid grid-cols-3 gap-2">
              <!-- Field Sales - Visits/Meetings Target Card -->
              <Card.Root>
                <Card.Content class="p-6">
                  <div class="flex flex-col">
                    <span class="text-sm text-muted-foreground">
                      Field Sales - Visits/Meetings
                    </span>
                    <div class="flex items-end gap-2">
                      <span class="text-3xl font-bold">
                        {fieldVisitMeetingsTotal}
                      </span>
                      <span class="text-sm text-muted-foreground mb-1">
                        of {fieldVisitMeetingTarget} target
                      </span>
                    </div>
                    <Progress
                      value={getAchievementPercentage(
                        fieldVisitMeetingsTotal,
                        fieldVisitMeetingTarget,
                      )}
                      class="h-2 mt-2"
                    />
                    <span class="text-xs text-muted-foreground mt-1">
                      {getAchievementPercentage(
                        fieldVisitMeetingsTotal,
                        fieldVisitMeetingTarget,
                      )}% of target
                    </span>
                  </div>
                </Card.Content>
              </Card.Root>

              <!-- Field Sales - Phone Calls Target Card -->
              <Card.Root>
                <Card.Content class="p-6">
                  <div class="flex flex-col">
                    <span class="text-sm text-muted-foreground">
                      Field Sales - Phone Calls
                    </span>
                    <div class="flex items-end gap-2">
                      <span class="text-3xl font-bold">
                        {fieldPhoneCallsTotal}
                      </span>
                      <span class="text-sm text-muted-foreground mb-1">
                        of {fieldPhoneCallTarget} target
                      </span>
                    </div>
                    <Progress
                      value={getAchievementPercentage(
                        fieldPhoneCallsTotal,
                        fieldPhoneCallTarget,
                      )}
                      class="h-2 mt-2"
                    />
                    <span class="text-xs text-muted-foreground mt-1">
                      {getAchievementPercentage(
                        fieldPhoneCallsTotal,
                        fieldPhoneCallTarget,
                      )}% of target
                    </span>
                  </div>
                </Card.Content>
              </Card.Root>

              <!-- Field Sales - Overall Achievement Card -->
              <Card.Root>
                <Card.Content class="p-6">
                  <div class="flex flex-col">
                    <span class="text-sm text-muted-foreground">
                      Field Sales - Overall Achievement
                    </span>
                    <div class="flex items-end gap-2">
                      <span class="text-3xl font-bold">
                        {Math.round(
                          (getAchievementPercentage(
                            fieldVisitMeetingsTotal,
                            fieldVisitMeetingTarget,
                          ) +
                            getAchievementPercentage(
                              fieldPhoneCallsTotal,
                              fieldPhoneCallTarget,
                            )) /
                            2,
                        )}%
                      </span>
                    </div>
                    <Progress
                      value={Math.round(
                        (getAchievementPercentage(
                          fieldVisitMeetingsTotal,
                          fieldVisitMeetingTarget,
                        ) +
                          getAchievementPercentage(
                            fieldPhoneCallsTotal,
                            fieldPhoneCallTarget,
                          )) /
                          2,
                      )}
                      class="h-2 mt-2"
                    />
                  </div>
                </Card.Content>
              </Card.Root>
            </Card.Content>
          </Card.Root>
          <!-- Total Section -->
          <Card.Root class="col-span-1 md:col-span-3 mt-4 bg-muted">
            <Card.Header class="pb-2">
              <Card.Title>Total KPIs (All Sales)</Card.Title>
            </Card.Header>
            <Card.Content class="grid grid-cols-3 gap-2">
              <!-- Visits/Meetings Target Card -->
              <Card.Root>
                <Card.Content class="p-6">
                  <div class="flex flex-col">
                    <span class="text-sm text-muted-foreground">
                      Total Visits/Meetings
                    </span>
                    <div class="flex items-end gap-2">
                      <span class="text-3xl font-bold">
                        {$fedexReportQuery?.data?.visitMeetingCount || 0}
                      </span>
                      <span class="text-sm text-muted-foreground mb-1">
                        of {visitMeetingTarget} target
                      </span>
                    </div>
                    <Progress
                      value={getAchievementPercentage(
                        $fedexReportQuery?.data?.visitMeetingCount || 0,
                        visitMeetingTarget,
                      )}
                      class="h-2 mt-2"
                    />
                    <span class="text-xs text-muted-foreground mt-1">
                      {getAchievementPercentage(
                        $fedexReportQuery?.data?.visitMeetingCount || 0,
                        visitMeetingTarget,
                      )}% of target
                    </span>
                  </div>
                </Card.Content>
              </Card.Root>

              <!-- Phone Calls Target Card -->
              <Card.Root>
                <Card.Content class="p-6">
                  <div class="flex flex-col">
                    <span class="text-sm text-muted-foreground">
                      Total Phone Calls
                    </span>
                    <div class="flex items-end gap-2">
                      <span class="text-3xl font-bold">
                        {$fedexReportQuery?.data?.phoneCallCount || 0}
                      </span>
                      <span class="text-sm text-muted-foreground mb-1">
                        of {phoneCallTarget} target
                      </span>
                    </div>
                    <Progress
                      value={getAchievementPercentage(
                        $fedexReportQuery?.data?.phoneCallCount || 0,
                        phoneCallTarget,
                      )}
                      class="h-2 mt-2"
                    />
                    <span class="text-xs text-muted-foreground mt-1">
                      {getAchievementPercentage(
                        $fedexReportQuery?.data?.phoneCallCount || 0,
                        phoneCallTarget,
                      )}% of target
                    </span>
                  </div>
                </Card.Content>
              </Card.Root>

              <!-- Overall Achievement Card -->
              <Card.Root>
                <Card.Content class="p-6">
                  <div class="flex flex-col">
                    <span class="text-sm text-muted-foreground">
                      Overall Achievement
                    </span>
                    <div class="flex items-end gap-2">
                      <span class="text-3xl font-bold">
                        {Math.round(
                          (getAchievementPercentage(
                            $fedexReportQuery?.data?.visitMeetingCount || 0,
                            visitMeetingTarget,
                          ) +
                            getAchievementPercentage(
                              $fedexReportQuery?.data?.phoneCallCount || 0,
                              phoneCallTarget,
                            )) /
                            2,
                        )}%
                      </span>
                    </div>
                    <Progress
                      value={Math.round(
                        (getAchievementPercentage(
                          $fedexReportQuery?.data?.visitMeetingCount || 0,
                          visitMeetingTarget,
                        ) +
                          getAchievementPercentage(
                            $fedexReportQuery?.data?.phoneCallCount || 0,
                            phoneCallTarget,
                          )) /
                          2,
                      )}
                      class="h-2 mt-2"
                    />
                  </div>
                </Card.Content>
              </Card.Root>
            </Card.Content>
          </Card.Root>
        </div>

        <Card.Root class="my-4 bg-blue-50">
          <Card.Header>
            <Card.Title>Target Settings</Card.Title>
            <Card.Description>
              Set daily target values for activities ({#if workingDaysCount > 0}{workingDaysCount}{:else}calculating...{/if}
              working days in selected period, {holidaysCount} holidays excluded)
            </Card.Description>
          </Card.Header>
          <Card.Content>
            <div class="grid grid-cols-2 gap-6">
              <!-- Indoor Sales Targets -->
              <div>
                <h3 class="text-base font-medium mb-3">Indoor Sales Targets</h3>
                <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div class="space-y-2">
                    <div class="space-y-2">
                      <label
                        for="indoorVisitMeetingPerDay"
                        class="text-sm font-medium"
                        >Visits/Meetings per day</label
                      >
                      <Input
                        id="indoorVisitMeetingPerDay"
                        type="number"
                        min="0"
                        step="0.5"
                        bind:value={indoorVisitMeetingPerDayValue}
                        on:input={updateTargetValues}
                      />
                      <p class="text-sm text-muted-foreground">
                        Target: {indoorVisitMeetingTarget} visits/meetings for the
                        period
                      </p>
                    </div>
                  </div>
                  <div class="space-y-2">
                    <div class="space-y-2">
                      <label
                        for="indoorPhoneCallPerDay"
                        class="text-sm font-medium">Phone calls per day</label
                      >
                      <Input
                        id="indoorPhoneCallPerDay"
                        type="number"
                        min="0"
                        step="0.5"
                        bind:value={indoorPhoneCallPerDayValue}
                        on:input={updateTargetValues}
                      />
                      <p class="text-sm text-muted-foreground">
                        Target: {indoorPhoneCallTarget} phone calls for the period
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Field Sales Targets -->
              <div>
                <h3 class="text-base font-medium mb-3">Field Sales Targets</h3>
                <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div class="space-y-2">
                    <div class="space-y-2">
                      <label
                        for="fieldVisitMeetingPerDay"
                        class="text-sm font-medium"
                        >Visits/Meetings per day</label
                      >
                      <Input
                        id="fieldVisitMeetingPerDay"
                        type="number"
                        min="0"
                        step="0.5"
                        bind:value={fieldVisitMeetingPerDayValue}
                        on:input={updateTargetValues}
                      />
                      <p class="text-sm text-muted-foreground">
                        Target: {fieldVisitMeetingTarget} visits/meetings for the
                        period
                      </p>
                    </div>
                  </div>
                  <div class="space-y-2">
                    <div class="space-y-2">
                      <label
                        for="fieldPhoneCallPerDay"
                        class="text-sm font-medium">Phone calls per day</label
                      >
                      <Input
                        id="fieldPhoneCallPerDay"
                        type="number"
                        min="0"
                        step="0.5"
                        bind:value={fieldPhoneCallPerDayValue}
                        on:input={updateTargetValues}
                      />
                      <p class="text-sm text-muted-foreground">
                        Target: {fieldPhoneCallTarget} phone calls for the period
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Growth Targets -->
              <div class="col-span-2">
                <h3 class="text-base font-medium mb-3">Growth Targets</h3>
                <div class="grid grid-cols-1 md:grid-cols-4 gap-4">
                  <div class="space-y-2">
                    <div class="space-y-2">
                      <label for="orderCountGrowth" class="text-sm font-medium"
                        >Order Count Growth (%)</label
                      >
                      <Input
                        id="orderCountGrowth"
                        type="number"
                        min="0"
                        step="0.5"
                        bind:value={targets.orderCountGrowth}
                        on:input={saveTargets}
                      />
                      <p class="text-sm text-muted-foreground">
                        Target: {targets.orderCountGrowth}% growth
                      </p>
                    </div>
                  </div>
                  <div class="space-y-2">
                    <div class="space-y-2">
                      <label for="weightGrowth" class="text-sm font-medium"
                        >Weight Growth (%)</label
                      >
                      <Input
                        id="weightGrowth"
                        type="number"
                        min="0"
                        step="0.5"
                        bind:value={targets.weightGrowth}
                        on:input={saveTargets}
                      />
                      <p class="text-sm text-muted-foreground">
                        Target: {targets.weightGrowth}% growth
                      </p>
                    </div>
                  </div>
                  <div class="space-y-2">
                    <div class="space-y-2">
                      <label for="supplierGrowth" class="text-sm font-medium"
                        >Supplier Growth (%)</label
                      >
                      <Input
                        id="supplierGrowth"
                        type="number"
                        min="0"
                        step="0.5"
                        bind:value={targets.supplierGrowth}
                        on:input={saveTargets}
                      />
                      <p class="text-sm text-muted-foreground">
                        Target: {targets.supplierGrowth}% growth
                      </p>
                    </div>
                  </div>
                  <div class="space-y-2">
                    <div class="space-y-2">
                      <label for="revenueGrowth" class="text-sm font-medium"
                        >Revenue Growth (%)</label
                      >
                      <Input
                        id="revenueGrowth"
                        type="number"
                        min="0"
                        step="0.5"
                        bind:value={targets.revenueGrowth}
                        on:input={saveTargets}
                      />
                      <p class="text-sm text-muted-foreground">
                        Target: {targets.revenueGrowth}% growth
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card.Content>
        </Card.Root>
      </Tabs.Content>

      <Tabs.Content value="by-type" class="py-4">
        <!-- Activities by Position Summary -->
        <Card.Root class="mt-6">
          <Card.Header>
            <Card.Title>Activities by Sales Position</Card.Title>
            <Card.Description>
              Completed activities grouped by sales position
            </Card.Description>
          </Card.Header>
          <Card.Content>
            {#if $fedexReportQuery?.data?.positionStats && $fedexReportQuery.data.positionStats.length > 0}
              <div class="rounded-md border">
                <Table.Root>
                  <Table.Header>
                    <Table.Row>
                      <Table.Head>Sales Position</Table.Head>
                      <Table.Head>#employees</Table.Head>

                      <Table.Head class="text-center"
                        >Visits / Meetings</Table.Head
                      >
                      <Table.Head class="text-center">Phone Calls</Table.Head>
                      <Table.Head class="text-center"
                        >Target (per day)</Table.Head
                      >
                      <Table.Head class="text-center">Achievement</Table.Head>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {#each $fedexReportQuery.data.positionStats as positionStat}
                      {@const isIndoor = isIndoorSales(positionStat.position)}
                      {@const isField = isFieldSales(positionStat.position)}
                      {@const employeeCount =
                        positionStat.salesReps?.length || 0}

                      <!-- Calculate position targets -->
                      {@const targetVisitMeeting = isIndoor
                        ? indoorVisitMeetingTarget
                        : isField
                          ? fieldVisitMeetingTarget
                          : visitMeetingTarget}
                      {@const targetPhoneCall = isIndoor
                        ? indoorPhoneCallTarget
                        : isField
                          ? fieldPhoneCallTarget
                          : phoneCallTarget}

                      <!-- Calculate achievement percentages -->
                      {@const visitsAchievement =
                        targetVisitMeeting > 0
                          ? Math.min(
                              Math.round(
                                (positionStat.totalVisitMeetings /
                                  targetVisitMeeting) *
                                  100,
                              ),
                              100,
                            )
                          : 0}
                      {@const callsAchievement =
                        targetPhoneCall > 0
                          ? Math.min(
                              Math.round(
                                (positionStat.totalPhoneCalls /
                                  targetPhoneCall) *
                                  100,
                              ),
                              100,
                            )
                          : 0}
                      {@const overallAchievement =
                        targetVisitMeeting + targetPhoneCall > 0
                          ? Math.min(
                              Math.round(
                                ((positionStat.totalVisitMeetings +
                                  positionStat.totalPhoneCalls) /
                                  (targetVisitMeeting + targetPhoneCall)) *
                                  100,
                              ),
                              100,
                            )
                          : 0}

                      <!-- Get per day targets -->
                      {@const visitsPerDay = isIndoor
                        ? $targetValues.indoor?.visitMeetingPerDay
                        : isField
                          ? $targetValues.field?.visitMeetingPerDay
                          : Math.round(
                              ($targetValues.indoor?.visitMeetingPerDay +
                                $targetValues.field?.visitMeetingPerDay) /
                                2,
                            )}
                      {@const callsPerDay = isIndoor
                        ? $targetValues.indoor?.phoneCallPerDay
                        : isField
                          ? $targetValues.field?.phoneCallPerDay
                          : Math.round(
                              ($targetValues.indoor?.phoneCallPerDay +
                                $targetValues.field?.phoneCallPerDay) /
                                2,
                            )}

                      <Table.Row>
                        <Table.Cell>
                          <div class="font-medium">{positionStat.position}</div>
                        </Table.Cell>
                        <Table.Cell class="text-center"
                          >{employeeCount}</Table.Cell
                        >
                        <Table.Cell class="text-center">
                          <div class="flex flex-col items-center">
                            <span>{positionStat.totalVisitMeetings}</span>
                            {#if targetVisitMeeting > 0}
                              <span class="text-xs text-muted-foreground mt-1">
                                {visitsAchievement}% of target ({Math.round(
                                  targetVisitMeeting,
                                )})
                              </span>
                            {/if}
                          </div>
                        </Table.Cell>
                        <Table.Cell class="text-center">
                          <div class="flex flex-col items-center">
                            <span>{positionStat.totalPhoneCalls}</span>
                            {#if targetPhoneCall > 0}
                              <span class="text-xs text-muted-foreground mt-1">
                                {callsAchievement}% of target ({Math.round(
                                  targetPhoneCall,
                                )})
                              </span>
                            {/if}
                          </div>
                        </Table.Cell>
                        <Table.Cell class="text-center">
                          {visitsPerDay}v / {callsPerDay}c
                        </Table.Cell>
                        <Table.Cell>
                          <div class="flex items-center gap-2">
                            <div class="flex-1">
                              <div
                                class="h-2 w-full bg-muted rounded-full overflow-hidden"
                              >
                                <div
                                  class="h-full bg-primary rounded-full"
                                  style="width: {overallAchievement}%"
                                />
                              </div>
                            </div>
                            <span class="text-sm">{overallAchievement}%</span>
                          </div>
                        </Table.Cell>
                      </Table.Row>
                    {/each}
                  </Table.Body>
                </Table.Root>
              </div>
            {:else}
              <div class="p-8 text-center rounded-md border">
                <Icon
                  icon="mdi:chart-bar-off"
                  width={48}
                  height={48}
                  class="mx-auto mb-3 text-muted-foreground"
                />
                <p class="text-muted-foreground">
                  No position stats available for the selected period.
                </p>
                <p class="text-xs text-muted-foreground mt-2">
                  Try selecting a different date range or check that position
                  stats exist.
                </p>
              </div>
            {/if}
          </Card.Content>
        </Card.Root>

        <!-- Activities by Type Summary -->
        <Card.Root>
          <Card.Header>
            <Card.Title>Activities by Type</Card.Title>
            <Card.Description>
              Completed activities grouped by type for the period {formatDate(
                dateRange?.start.toString(),
              )} to {formatDate(dateRange?.end.toString())}
            </Card.Description>
          </Card.Header>
          <Card.Content>
            {#if $fedexReportQuery?.data?.typeStats && $fedexReportQuery.data.typeStats.length > 0}
              <div class="space-y-6">
                {#each $fedexReportQuery.data.typeStats as typeStat, i}
                  <div class="space-y-2">
                    <div class="flex items-center justify-between mb-1">
                      <div class="flex items-center gap-2">
                        <Badge
                          variant={i === 0
                            ? 'default'
                            : i === 1
                              ? 'secondary'
                              : 'outline'}
                        >
                          {typeStat.type || 'Unknown'}
                        </Badge>
                        <span class="text-sm"
                          >{typeStat.count || 0} activities</span
                        >
                      </div>
                      <span class="text-sm text-muted-foreground"
                        >{(
                          (typeStat.count /
                            $fedexReportQuery.data.typeStats.reduce(
                              (acc, t) => acc + (t.count || 0),
                              0,
                            )) *
                          100
                        ).toFixed(1)}%</span
                      >
                    </div>
                    <Progress
                      value={(typeStat.count /
                        $fedexReportQuery.data.typeStats.reduce(
                          (acc, t) => acc + (t.count || 0),
                          0,
                        )) *
                        100}
                      class="h-2"
                    />
                  </div>
                {/each}
              </div>
            {:else}
              <div class="p-8 text-center rounded-md border">
                <Icon
                  icon="mdi:chart-bar-off"
                  width={48}
                  height={48}
                  class="mx-auto mb-3 text-muted-foreground"
                />
                <p class="text-muted-foreground">
                  No activity type data available for the selected period.
                </p>
                <p class="text-xs text-muted-foreground mt-2">
                  Try selecting a different date range or check that activity
                  type data exists.
                </p>
              </div>
            {/if}
          </Card.Content>
        </Card.Root>
      </Tabs.Content>

      <Tabs.Content value="by-sales-rep" class="py-4">
        <div class="space-y-4">
          <h3 class="text-lg font-semibold">Activities by Sales Rep</h3>
          {#if $fedexReportQuery?.data?.salesRepStats && $fedexReportQuery.data.salesRepStats.length > 0}
            <div class="rounded-md border">
              <Table.Root>
                <Table.Header>
                  <Table.Row>
                    <Table.Head>Sales Rep</Table.Head>
                    <Table.Head>Position</Table.Head>
                    <Table.Head class="text-center">Total Activities</Table.Head
                    >
                    <Table.Head class="text-center"
                      >Visits / Meetings</Table.Head
                    >
                    <Table.Head class="text-center">Phone Calls</Table.Head>
                    <Table.Head class="text-center">Target (per day)</Table.Head
                    >
                    <Table.Head class="text-center">Achievement</Table.Head>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {#each $fedexReportQuery.data.salesRepStats as salesRepStat}
                    {@const isIndoor = isIndoorSales(
                      salesRepStat.salesRep?.position || '',
                    )}
                    {@const isField = isFieldSales(
                      salesRepStat.salesRep?.position || '',
                    )}

                    <!-- Calculate targets based on position -->
                    {@const targetVisitMeeting = isIndoor
                      ? indoorVisitMeetingTarget
                      : isField
                        ? fieldVisitMeetingTarget
                        : visitMeetingTarget}
                    {@const targetPhoneCall = isIndoor
                      ? indoorPhoneCallTarget
                      : isField
                        ? fieldPhoneCallTarget
                        : phoneCallTarget}

                    <!-- Calculate achievement percentages -->
                    {@const totalActivities = salesRepStat.count}
                    {@const visitsAchievement =
                      targetVisitMeeting > 0
                        ? Math.min(
                            Math.round(
                              (salesRepStat.visitMeetingCount /
                                targetVisitMeeting) *
                                100,
                            ),
                            100,
                          )
                        : 0}
                    {@const callsAchievement =
                      targetPhoneCall > 0
                        ? Math.min(
                            Math.round(
                              (salesRepStat.phoneCallCount / targetPhoneCall) *
                                100,
                            ),
                            100,
                          )
                        : 0}
                    {@const overallAchievement =
                      targetVisitMeeting + targetPhoneCall > 0
                        ? Math.min(
                            Math.round(
                              ((salesRepStat.visitMeetingCount +
                                salesRepStat.phoneCallCount) /
                                (targetVisitMeeting + targetPhoneCall)) *
                                100,
                            ),
                            100,
                          )
                        : 0}

                    <!-- Get per day targets -->
                    {@const visitsPerDay = isIndoor
                      ? $targetValues.indoor?.visitMeetingPerDay
                      : isField
                        ? $targetValues.field?.visitMeetingPerDay
                        : Math.round(
                            ($targetValues.indoor?.visitMeetingPerDay +
                              $targetValues.field?.visitMeetingPerDay) /
                              2,
                          )}
                    {@const callsPerDay = isIndoor
                      ? $targetValues.indoor?.phoneCallPerDay
                      : isField
                        ? $targetValues.field?.phoneCallPerDay
                        : Math.round(
                            ($targetValues.indoor?.phoneCallPerDay +
                              $targetValues.field?.phoneCallPerDay) /
                              2,
                          )}

                    <Table.Row>
                      <Table.Cell>
                        <div class="font-medium">
                          <Button
                            variant="link"
                            class="h-auto p-0"
                            on:click={() =>
                              viewActivityDetails(
                                salesRepStat.salesRep?.id || '',
                              )}
                          >
                            {salesRepStat.salesRep?.name || 'Unknown'}
                          </Button>
                        </div>
                      </Table.Cell>
                      <Table.Cell>
                        <Badge variant="outline">
                          {salesRepStat.salesRep?.position || 'Unknown'}
                        </Badge>
                      </Table.Cell>
                      <Table.Cell class="text-center"
                        >{totalActivities || 0}</Table.Cell
                      >
                      <Table.Cell class="text-center">
                        <div class="flex flex-col items-center">
                          <span>{salesRepStat.visitMeetingCount || 0}</span>
                          {#if targetVisitMeeting > 0}
                            <span class="text-xs text-muted-foreground mt-1">
                              {visitsAchievement}% of target ({Math.round(
                                targetVisitMeeting,
                              )})
                            </span>
                          {/if}
                        </div>
                      </Table.Cell>
                      <Table.Cell class="text-center">
                        <div class="flex flex-col items-center">
                          <span>{salesRepStat.phoneCallCount || 0}</span>
                          {#if targetPhoneCall > 0}
                            <span class="text-xs text-muted-foreground mt-1">
                              {callsAchievement}% of target ({Math.round(
                                targetPhoneCall,
                              )})
                            </span>
                          {/if}
                        </div>
                      </Table.Cell>
                      <Table.Cell class="text-center">
                        {visitsPerDay}v / {callsPerDay}c
                      </Table.Cell>
                      <Table.Cell>
                        <div class="flex items-center gap-2">
                          <div class="flex-1">
                            <div
                              class="h-2 w-full bg-muted rounded-full overflow-hidden"
                            >
                              <div
                                class="h-full bg-primary rounded-full"
                                style="width: {overallAchievement}%"
                              />
                            </div>
                          </div>
                          <span class="text-sm">{overallAchievement}%</span>
                        </div>
                      </Table.Cell>
                    </Table.Row>
                  {/each}
                </Table.Body>
              </Table.Root>
            </div>
          {:else}
            <div class="p-8 text-center rounded-md border">
              <Icon
                icon="mdi:chart-bar-off"
                width={48}
                height={48}
                class="mx-auto mb-3 text-muted-foreground"
              />
              <p class="text-muted-foreground">
                No sales rep data available for the selected period.
              </p>
              <p class="text-xs text-muted-foreground mt-2">
                Try selecting a different date range or check that sales rep
                data exists.
              </p>
            </div>
          {/if}
        </div>
      </Tabs.Content>

      <Tabs.Content value="orders" class="py-4">
        <div class="flex items-center justify-between mb-4">
          <h2 class="text-2xl font-bold">Order Statistics</h2>
        </div>

        <!-- Combined Period Comparison -->
        <Card.Root class="my-6">
          <Card.Header>
            <Card.Title>Period Comparison</Card.Title>
            <Card.Description>
              Comparing current period ({$fedexReportQuery?.data?.order_stats
                ? formatDate($fedexReportQuery.data.order_stats.start_date) +
                  ' - ' +
                  formatDate($fedexReportQuery.data.order_stats.end_date)
                : 'N/A'}) with the same period last year
            </Card.Description>
          </Card.Header>
          <Card.Content>
            <div class="grid grid-cols-1 md:grid-cols-4 gap-4">
              <!-- Orders -->
              <div
                class="flex flex-col p-4 border rounded-md col-span-1 hover:bg-muted/30 transition-colors"
              >
                <span class="text-sm text-muted-foreground">Orders</span>
                <div class="flex flex-col">
                  <span class="text-2xl font-bold">
                    {$fedexReportQuery?.data?.order_stats?.order_count?.toLocaleString() ||
                      '0'}
                  </span>
                  {#if $fedexReportQuery?.data?.prev_year_order_stats?.order_count !== undefined}
                    {@const current =
                      $fedexReportQuery.data.order_stats?.order_count || 0}
                    {@const previous =
                      $fedexReportQuery.data.prev_year_order_stats
                        ?.order_count || 0}
                    {@const percentChange = calculatePercentageChange(
                      current,
                      previous,
                    )}
                    {@const absoluteDiff = current - previous}
                    <div class="flex items-center mt-1">
                      <span class="text-xs text-muted-foreground mr-2"
                        >vs last year:</span
                      >
                      <Badge
                        variant={percentChange >= 0 ? 'success' : 'destructive'}
                        class="flex items-center space-x-1"
                      >
                        <span class="flex items-center">
                          {percentChange >= 0 ? '+' : ''}{percentChange.toFixed(
                            1,
                          )}%
                          <span class="ml-1"
                            >({absoluteDiff >= 0
                              ? '+'
                              : ''}{absoluteDiff.toLocaleString()})</span
                          >
                          <Icon
                            icon={percentChange >= 0
                              ? 'mdi:arrow-up'
                              : 'mdi:arrow-down'}
                            width={16}
                            height={16}
                            class="ml-1"
                          />
                        </span>
                      </Badge>
                    </div>
                  {/if}
                  {#if targets.orderCountGrowth > 0}
                    {@const currentOrderCount =
                      $fedexReportQuery?.data?.order_stats?.order_count || 0}
                    {@const prevOrderCount =
                      $fedexReportQuery?.data?.prev_year_order_stats
                        ?.order_count || 0}
                    {@const actualGrowth = calculatePercentageChange(
                      currentOrderCount,
                      prevOrderCount,
                    )}
                    {@const progressToTarget =
                      (actualGrowth / targets.orderCountGrowth) * 100}
                    <div class="mt-2">
                      <div
                        class="flex justify-between items-center text-xs text-muted-foreground mb-1"
                      >
                        <span>Target: {targets.orderCountGrowth}% growth</span>
                        <span
                          >{Math.min(Math.round(progressToTarget), 100)}%</span
                        >
                      </div>
                      <Progress
                        value={Math.min(progressToTarget, 100)}
                        class="h-1.5"
                      />
                    </div>
                  {/if}
                </div>
              </div>

              <!-- Weight -->
              <div
                class="flex flex-col p-4 border rounded-md col-span-1 hover:bg-muted/30 transition-colors"
              >
                <span class="text-sm text-muted-foreground"
                  >Total Weight (kg)</span
                >
                <div class="flex flex-col">
                  <span class="text-2xl font-bold">
                    {formatWeight(
                      $fedexReportQuery?.data?.order_stats?.total_weight,
                    )}
                  </span>
                  {#if $fedexReportQuery?.data?.prev_year_order_stats?.total_weight !== undefined}
                    {@const current =
                      $fedexReportQuery.data.order_stats?.total_weight || 0}
                    {@const previous =
                      $fedexReportQuery.data.prev_year_order_stats
                        ?.total_weight || 0}
                    {@const percentChange = calculatePercentageChange(
                      current,
                      previous,
                    )}
                    {@const absoluteDiff = current - previous}
                    <div class="flex items-center mt-1">
                      <span class="text-xs text-muted-foreground mr-2"
                        >vs last year:</span
                      >
                      <Badge
                        variant={percentChange >= 0 ? 'success' : 'destructive'}
                        class="flex items-center space-x-1"
                      >
                        <span class="flex items-center">
                          {percentChange >= 0 ? '+' : ''}{percentChange.toFixed(
                            1,
                          )}%
                          <span class="ml-1"
                            >({absoluteDiff >= 0 ? '+' : ''}{formatWeight(
                              absoluteDiff,
                            )})</span
                          >
                          <Icon
                            icon={percentChange >= 0
                              ? 'mdi:arrow-up'
                              : 'mdi:arrow-down'}
                            width={16}
                            height={16}
                            class="ml-1"
                          />
                        </span>
                      </Badge>
                    </div>
                  {/if}
                  {#if targets.weightGrowth > 0}
                    {@const currentWeight =
                      $fedexReportQuery?.data?.order_stats?.total_weight || 0}
                    {@const prevWeight =
                      $fedexReportQuery?.data?.prev_year_order_stats
                        ?.total_weight || 0}
                    {@const actualGrowth = calculatePercentageChange(
                      currentWeight,
                      prevWeight,
                    )}
                    {@const progressToTarget =
                      (actualGrowth / targets.weightGrowth) * 100}
                    <div class="mt-2">
                      <div
                        class="flex justify-between items-center text-xs text-muted-foreground mb-1"
                      >
                        <span>Target: {targets.weightGrowth}% growth</span>
                        <span
                          >{Math.min(Math.round(progressToTarget), 100)}%</span
                        >
                      </div>
                      <Progress
                        value={Math.min(progressToTarget, 100)}
                        class="h-1.5"
                      />
                    </div>
                  {/if}
                </div>
              </div>

              <!-- Supplier Total -->
              <div
                class="flex flex-col p-4 border rounded-md col-span-1 hover:bg-muted/30 transition-colors"
              >
                <span class="text-sm text-muted-foreground"
                  >Supplier Total (€)</span
                >
                <div class="flex flex-col">
                  <span class="text-2xl font-bold">
                    {formatCurrency(
                      $fedexReportQuery?.data?.order_stats?.total_supplier_eur,
                    )}
                  </span>
                  {#if $fedexReportQuery?.data?.prev_year_order_stats?.total_supplier_eur !== undefined}
                    {@const current =
                      $fedexReportQuery.data.order_stats?.total_supplier_eur ||
                      0}
                    {@const previous =
                      $fedexReportQuery.data.prev_year_order_stats
                        ?.total_supplier_eur || 0}
                    {@const percentChange = calculatePercentageChange(
                      current,
                      previous,
                    )}
                    {@const absoluteDiff = current - previous}
                    <div class="flex items-center mt-1">
                      <span class="text-xs text-muted-foreground mr-2"
                        >vs last year:</span
                      >
                      <Badge
                        variant={percentChange >= 0 ? 'success' : 'destructive'}
                        class="flex items-center space-x-1"
                      >
                        <span class="flex items-center">
                          {percentChange >= 0 ? '+' : ''}{percentChange.toFixed(
                            1,
                          )}%
                          <span class="ml-1"
                            >({absoluteDiff >= 0 ? '+' : ''}{formatCurrency(
                              absoluteDiff,
                              false,
                            )})</span
                          >
                          <Icon
                            icon={percentChange >= 0
                              ? 'mdi:arrow-up'
                              : 'mdi:arrow-down'}
                            width={16}
                            height={16}
                            class="ml-1"
                          />
                        </span>
                      </Badge>
                    </div>
                  {/if}
                  {#if targets.supplierGrowth > 0}
                    {@const currentSupplier =
                      $fedexReportQuery?.data?.order_stats
                        ?.total_supplier_eur || 0}
                    {@const prevSupplier =
                      $fedexReportQuery?.data?.prev_year_order_stats
                        ?.total_supplier_eur || 0}
                    {@const actualGrowth = calculatePercentageChange(
                      currentSupplier,
                      prevSupplier,
                    )}
                    {@const progressToTarget =
                      (actualGrowth / targets.supplierGrowth) * 100}
                    <div class="mt-2">
                      <div
                        class="flex justify-between items-center text-xs text-muted-foreground mb-1"
                      >
                        <span>Target: {targets.supplierGrowth}% growth</span>
                        <span
                          >{Math.min(Math.round(progressToTarget), 100)}%</span
                        >
                      </div>
                      <Progress
                        value={Math.min(progressToTarget, 100)}
                        class="h-1.5"
                      />
                    </div>
                  {/if}
                </div>
              </div>

              <!-- Revenue Total -->
              <div
                class="flex flex-col p-4 border rounded-md col-span-1 hover:bg-muted/30 transition-colors"
              >
                <span class="text-sm text-muted-foreground"
                  >Revenue Total (€)</span
                >
                <div class="flex flex-col">
                  <span class="text-2xl font-bold">
                    {formatCurrency(
                      $fedexReportQuery?.data?.order_stats?.total_revenue_eur,
                    )}
                  </span>
                  {#if $fedexReportQuery?.data?.prev_year_order_stats?.total_revenue_eur !== undefined}
                    {@const current =
                      $fedexReportQuery.data.order_stats?.total_revenue_eur ||
                      0}
                    {@const previous =
                      $fedexReportQuery.data.prev_year_order_stats
                        ?.total_revenue_eur || 0}
                    {@const percentChange = calculatePercentageChange(
                      current,
                      previous,
                    )}
                    {@const absoluteDiff = current - previous}
                    <div class="flex items-center mt-1">
                      <span class="text-xs text-muted-foreground mr-2"
                        >vs last year:</span
                      >
                      <Badge
                        variant={percentChange >= 0 ? 'success' : 'destructive'}
                        class="flex items-center space-x-1"
                      >
                        <span class="flex items-center">
                          {percentChange >= 0 ? '+' : ''}{percentChange.toFixed(
                            1,
                          )}%
                          <span class="ml-1"
                            >({absoluteDiff >= 0 ? '+' : ''}{formatCurrency(
                              absoluteDiff,
                              false,
                            )})</span
                          >
                          <Icon
                            icon={percentChange >= 0
                              ? 'mdi:arrow-up'
                              : 'mdi:arrow-down'}
                            width={16}
                            height={16}
                            class="ml-1"
                          />
                        </span>
                      </Badge>
                    </div>
                  {/if}
                  {#if targets.revenueGrowth > 0}
                    {@const currentRevenue =
                      $fedexReportQuery?.data?.order_stats?.total_revenue_eur ||
                      0}
                    {@const prevRevenue =
                      $fedexReportQuery?.data?.prev_year_order_stats
                        ?.total_revenue_eur || 0}
                    {@const actualGrowth = calculatePercentageChange(
                      currentRevenue,
                      prevRevenue,
                    )}
                    {@const progressToTarget =
                      (actualGrowth / targets.revenueGrowth) * 100}
                    <div class="mt-2">
                      <div
                        class="flex justify-between items-center text-xs text-muted-foreground mb-1"
                      >
                        <span>Target: {targets.revenueGrowth}% growth</span>
                        <span
                          >{Math.min(Math.round(progressToTarget), 100)}%</span
                        >
                      </div>
                      <Progress
                        value={Math.min(progressToTarget, 100)}
                        class="h-1.5"
                      />
                    </div>
                  {/if}
                </div>
              </div>
            </div>
          </Card.Content>
        </Card.Root>

        <!-- Regional Order Statistics -->
        <div class="space-y-4">
          <div class="flex items-center justify-between">
            <div>
              <h3 class="text-xl font-semibold">Regional Order Statistics</h3>
              <p class="text-sm text-muted-foreground">
                Order statistics broken down by region for the current period
              </p>
            </div>
            <div class="text-sm text-muted-foreground">
              {#if regionalOrderStats?.length > 0}
                {regionalOrderStats.length} regions
              {:else}
                No regional data available
              {/if}
            </div>
          </div>

          {#if regionalOrderStats && regionalOrderStats.length > 0}
            <div
              class="rounded-lg border shadow-sm overflow-hidden bg-primary/50"
            >
              <Table.Root>
                <Table.Header>
                  <Table.Row class="bg-muted/50">
                    <Table.Head class="font-semibold">Region</Table.Head>
                    <Table.Head class="font-semibold text-right"
                      >Orders</Table.Head
                    >
                    <Table.Head class="font-semibold text-right"
                      >Weight (kg)</Table.Head
                    >
                    <Table.Head class="font-semibold text-right"
                      >Supplier (€)</Table.Head
                    >
                    <Table.Head class="font-semibold text-right"
                      >Revenue (€)</Table.Head
                    >
                    <Table.Head class="font-semibold text-right"
                      >YoY Change</Table.Head
                    >
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {#each regionalOrderStats || [] as region}
                    {@const percentChange = calculatePercentageChange(
                      region.currentRevenue,
                      region.previousRevenue,
                    )}
                    {@const absoluteDiff =
                      (region.currentRevenue || 0) -
                      (region.previousRevenue || 0)}
                    <Table.Row class="hover:bg-muted/30 transition-colors">
                      <Table.Cell class="font-medium"
                        >{region.region || 'Unknown'}</Table.Cell
                      >
                      <Table.Cell class="text-right">
                        {(region.orderCount || 0).toLocaleString()}
                      </Table.Cell>
                      <Table.Cell class="text-right">
                        {formatWeight(region.totalWeight)}
                      </Table.Cell>
                      <Table.Cell class="text-right">
                        {formatCurrency(region.totalSupplier)}
                      </Table.Cell>
                      <Table.Cell class="text-right">
                        {formatCurrency(region.currentRevenue)}
                      </Table.Cell>
                      <Table.Cell>
                        <div
                          class="flex items-center justify-end gap-1.5 font-medium"
                        >
                          <Badge
                            variant={percentChange >= 0
                              ? 'success'
                              : 'destructive'}
                            class="flex items-center space-x-1"
                          >
                            <span class="flex items-center">
                              {percentChange >= 0
                                ? '+'
                                : ''}{percentChange.toFixed(1)}% ({absoluteDiff >=
                              0
                                ? '+'
                                : ''}{formatCurrency(absoluteDiff, false)})
                              <Icon
                                icon={percentChange >= 0
                                  ? 'mdi:arrow-up'
                                  : 'mdi:arrow-down'}
                                width={16}
                                height={16}
                                class="ml-1"
                              />
                            </span>
                          </Badge>
                        </div>
                      </Table.Cell>
                    </Table.Row>
                  {/each}
                </Table.Body>
                <Table.Footer class="bg-muted/20">
                  <Table.Row>
                    <Table.Cell class="font-semibold">All Regions</Table.Cell>
                    <Table.Cell class="text-right font-medium">
                      {regionalOrderStats
                        .reduce((sum, r) => sum + (r.orderCount || 0), 0)
                        .toLocaleString()}
                    </Table.Cell>
                    <Table.Cell class="text-right font-medium">
                      {formatWeight(
                        regionalOrderStats.reduce(
                          (sum, r) => sum + (r.totalWeight || 0),
                          0,
                        ),
                      )}
                    </Table.Cell>
                    <Table.Cell class="text-right font-medium">
                      {formatCurrency(
                        regionalOrderStats.reduce(
                          (sum, r) => sum + (r.totalSupplier || 0),
                          0,
                        ),
                      )}
                    </Table.Cell>
                    <Table.Cell class="text-right font-medium">
                      {formatCurrency(
                        regionalOrderStats.reduce(
                          (sum, r) => sum + (r.currentRevenue || 0),
                          0,
                        ),
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {@const currentTotal = regionalOrderStats.reduce(
                        (sum, r) => sum + (r.currentRevenue || 0),
                        0,
                      )}
                      {@const previousTotal = regionalOrderStats.reduce(
                        (sum, r) => sum + (r.previousRevenue || 0),
                        0,
                      )}
                      {@const totalChange = calculatePercentageChange(
                        currentTotal,
                        previousTotal,
                      )}

                      <div class="flex items-center justify-end gap-1.5">
                        <Badge
                          variant={totalChange >= 0 ? 'success' : 'destructive'}
                          class="flex items-center space-x-1"
                        >
                          <span class="flex items-center">
                            {totalChange >= 0 ? '+' : ''}{totalChange.toFixed(
                              1,
                            )}%
                            <Icon
                              icon={totalChange >= 0
                                ? 'mdi:arrow-up'
                                : 'mdi:arrow-down'}
                              width={16}
                              height={16}
                              class="ml-1"
                            />
                          </span>
                        </Badge>
                      </div>
                    </Table.Cell>
                  </Table.Row>
                </Table.Footer>
              </Table.Root>
            </div>
          {:else}
            <div class="p-8 text-center rounded-md border">
              <Icon
                icon="mdi:chart-bar-off"
                width={48}
                height={48}
                class="mx-auto mb-3 text-muted-foreground"
              />
              <p class="text-muted-foreground">
                No regional data available for the selected period.
              </p>
              <p class="text-xs text-muted-foreground mt-2">
                Try selecting a different date range or check that regional data
                exists.
              </p>
            </div>
          {/if}
        </div>

        <!-- Regional Comparison Charts -->
        <Card.Root class="my-6">
          <Card.Header>
            <Card.Title>Regional Performance</Card.Title>
            <Card.Description>
              Comparing key metrics across regions
            </Card.Description>
          </Card.Header>
          <Card.Content>
            {#if $fedexReportQuery?.data?.regional_order_stats && Array.isArray($fedexReportQuery?.data?.regional_order_stats) && $fedexReportQuery?.data?.regional_order_stats.length > 0}
              <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
                <!-- Orders by Region -->
                <div class="border rounded-md p-4">
                  <h3 class="text-base font-medium mb-4">Orders by Region</h3>
                  <div class="space-y-2">
                    {#each $fedexReportQuery?.data?.regional_order_stats || [] as region}
                      {@const totalOrders =
                        $fedexReportQuery?.data?.order_stats?.order_count || 1}
                      {@const percentage =
                        totalOrders > 0
                          ? (region.order_count / totalOrders) * 100
                          : 0}
                      <div>
                        <div class="flex justify-between mb-1">
                          <span class="text-sm">{region.region}</span>
                          <span class="text-sm font-medium"
                            >{region.order_count} ({percentage.toFixed(
                              1,
                            )}%)</span
                          >
                        </div>
                        <Progress value={percentage} class="h-2" />
                      </div>
                    {/each}
                  </div>
                </div>

                <!-- Revenue by Region -->
                <div class="border rounded-md p-4">
                  <h3 class="text-base font-medium mb-4">
                    Revenue by Region (€)
                  </h3>
                  <div class="space-y-2">
                    {#each $fedexReportQuery?.data?.regional_order_stats || [] as region}
                      {@const totalRevenue =
                        $fedexReportQuery?.data?.order_stats
                          ?.total_revenue_eur || 1}
                      {@const percentage =
                        totalRevenue > 0
                          ? (region.total_revenue_eur / totalRevenue) * 100
                          : 0}
                      <div>
                        <div class="flex justify-between mb-1">
                          <span class="text-sm">{region.region}</span>
                          <span class="text-sm font-medium"
                            >{region.total_revenue_eur?.toLocaleString(
                              undefined,
                              {
                                style: 'currency',
                                currency: 'EUR',
                                maximumFractionDigits: 2,
                              },
                            )} ({percentage.toFixed(1)}%)</span
                          >
                        </div>
                        <Progress value={percentage} class="h-2" />
                      </div>
                    {/each}
                  </div>
                </div>
              </div>
            {:else}
              <div class="text-center py-8 text-muted-foreground">
                No regional data available for the selected period.
              </div>
            {/if}
          </Card.Content>
        </Card.Root>
      </Tabs.Content>
    </Tabs.Root>
  {:else}
    <div class="text-center py-12">
      <p class="text-muted-foreground">
        No data available for the selected date range.
      </p>
    </div>
  {/if}
</div>
