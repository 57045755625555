<script lang="ts">
  import PageWithTitle from '../../ui/page-with-title.svelte';

  import HantaInputTextareaRichtext from '$lib/widgets/input/hanta-input-textarea-richtext/hanta-input-textarea-richtext.svelte';
  import HantaInputText from '$lib/widgets/input/hanta-input-text.svelte';
  import HantaInputLovSelect from '$lib/widgets/input/hanta-input-lov-select.svelte';
  import HantaInputNumber from '$lib/widgets/input/hanta-input-number.svelte';
  import { Label } from '$lib/components/ui/label';
  import { superForm } from 'sveltekit-superforms';
  import { zodClient } from 'sveltekit-superforms/adapters';
  import * as Tabs from '$lib/components/ui/tabs';
  import { createMutation, useQueryClient } from '@tanstack/svelte-query';
  import Files from '$lib/components/hanta/files/files.svelte';
  import HantaInputDate from '$lib/widgets/input/hanta-input-date.svelte';

  import FormActions from '$lib/app/forms/form-actions.svelte';
  import { alertStore } from '$lib/stores/alert-store';
  import { insertBalancesSchema } from '$db/schema';
  import { saveBalance } from '$lib/api/mutations';
  import Button from '$lib/components/ui/button/button.svelte';
  import CrmObjectSelector from '$lib/widgets/crm-object-selector.svelte';
  import { popupStore } from '$lib/stores/app-store';
  import { Input } from '$lib/components/ui/input';

  const moduleName = 'balances';
  export let item: any; // TODO: Add proper type

  const client = useQueryClient();

  const addMutation = createMutation({
    mutationFn: (variables): Promise<any> =>
      Promise.resolve(saveBalance(variables)),
    onSuccess: () => client.invalidateQueries({ queryKey: [moduleName] }),
    onMutate: async newItem => {
      await client.cancelQueries({ queryKey: [moduleName, item.id] });
      const previousItems = client.getQueryData<any[]>([moduleName, item.id]);

      client.setQueryData([moduleName, item.id], { ...newItem });

      return { previousItems };
    },
    onError: (err: any, variables: any, context: any) => {
      if (context?.previousItems) {
        client.setQueryData([moduleName], context.previousItems);
      }
      alertStore.show(
        'Error Saving Balance',
        err.message || 'Failed to save balance',
        undefined,
        () => {
          $addMutation.mutate(variables);
        },
      );
    },
    onSettled: () => {
      client.invalidateQueries({ queryKey: [moduleName, item.id] });
    },
  });

  const form = superForm<any>(
    {
      ...item,
      customFields: item.customFields || {},
    },
    {
      validators: zodClient(insertBalancesSchema),
      resetForm: false,
      SPA: true,
      dataType: 'json',
      onSubmit: async () => {
        if (Object.keys($errors).length > 0) {
          alertStore.show(
            'Validation Error',
            'Please check the form for errors',
            undefined,
            () => {
              // Optional: Add form resubmission logic
            },
          );
          console.error('Validation errors', $errors, { form: $formData });
          $errors = {};
          return;
        }

        console.debug('Form data', $formData);

        $addMutation.mutate($formData, {
          onError(error) {
            console.error('Error', error);
            alertStore.show(
              'Error Saving Balance',
              error.message || 'Failed to save balance',
              undefined,
              () => {
                $addMutation.mutate($formData);
              },
            );
          },
          onSuccess: balance => {},
        });
      },
    },
  );

  const { form: formData, errors, enhance } = form;

  let currentTab = 'details';
  let currentFilter = 'all';
  let searchTerm = '';

  let currentPage = 0;
  const itemsPerPage = 5;

  $: positionsWithoutOrder = ($formData.positions || []).filter(
    p => !p.order,
  ).length;
  $: positionsWithDifferences = ($formData.positions || []).filter(
    p => p.order && Math.abs(p.diffTotal) > 0,
  ).length;
  $: positionsAllFine = ($formData.positions || []).filter(
    p => p.order && Math.abs(p.diffTotal) <= 5,
  ).length;

  $: filteredPositions = ($formData.positions || [])
    .filter(position => {
      // First apply search filter if search term exists
      if (searchTerm) {
        const searchLower = searchTerm.toLowerCase();
        const orderName = position.order?.name?.toLowerCase() || '';
        const comments = position.comments?.toLowerCase() || '';
        const positionNumber = position.position?.toString() || '';

        if (
          !orderName.includes(searchLower) &&
          !comments.includes(searchLower) &&
          !positionNumber.includes(searchLower)
        ) {
          return false;
        }
      }

      // Then apply the category filters
      if (currentFilter === 'no-order') {
        return !position.order;
      }
      if (currentFilter === 'differences') {
        return position.order && Math.abs(position.diffTotal) > 0;
      }
      if (currentFilter === 'fine') {
        return position.order && Math.abs(position.diffTotal) <= 5;
      }
      return true;
    })
    .sort((a, b) => {
      if (currentFilter === 'differences') {
        return Math.abs(b.diffTotal) - Math.abs(a.diffTotal);
      }
      return a.position - b.position;
    });

  $: totalPages = Math.ceil(filteredPositions.length / itemsPerPage);
  $: if (currentPage >= totalPages) {
    currentPage = Math.max(0, totalPages - 1);
  }

  $: startIndex = currentPage * itemsPerPage;
  $: endIndex = Math.min(startIndex + itemsPerPage, filteredPositions.length);
  $: paginatedPositions = filteredPositions.slice(startIndex, endIndex);
  $: currentRange =
    filteredPositions.length > 0
      ? `${startIndex + 1}-${endIndex} of ${filteredPositions.length}`
      : '0 of 0';

  function nextPage() {
    if (currentPage < totalPages - 1) {
      currentPage++;
    }
  }

  function previousPage() {
    if (currentPage > 0) {
      currentPage--;
    }
  }

  function addPosition() {
    const newPosition = {
      id: crypto.randomUUID(),
      comments: null,
      crmTotal: null,
      position: ($formData.positions || []).length + 1,
      crmWeight: null,
      diffTotal: 0,
      diffWeight: 0,
      balanceTotal: null,
      balanceWeight: null,
    };
    $formData.positions = [...($formData.positions || []), newPosition];
    // Go to the last page
    currentPage = Math.ceil($formData.positions.length / itemsPerPage) - 1;
  }

  function removePosition(id: string) {
    $formData.positions = ($formData.positions || []).filter(p => p.id !== id);
  }

  // Calculate differences for each position
  $: if ($formData.positions) {
    $formData.positions = $formData.positions.map(position => {
      const insurancePrice =
        position.order?.shipment?.rate?.services?.find(s =>
          s.product.includes('Страховка'),
        )?.supplierEur ?? 0;

      // Get CRM values from order if available
      const crmTotal =
        (position.order?.shipment?.rate?.supplierTotalEur ??
          position.crmTotal ??
          0) - insurancePrice;

      const crmWeight =
        position.order?.shipment?.weight ?? position.crmWeight ?? 0;

      // Get balance values, defaulting to CRM values if not set
      const balanceTotal = position.balanceTotal ?? crmTotal;
      const balanceWeight = position.balanceWeight ?? crmWeight;

      return {
        ...position,
        crmTotal,
        crmWeight,
        balanceTotal,
        balanceWeight,
        diffTotal: crmTotal - balanceTotal,
        diffWeight: crmWeight - balanceWeight,
      };
    });
  }

  // Calculate total differences
  $: {
    if ($formData.positions) {
      const crmTotal = $formData.positions.reduce(
        (sum, p) => sum + (p.crmTotal ?? 0),
        0,
      );
      const balanceTotal = $formData.positions.reduce(
        (sum, p) => sum + (p.balanceTotal ?? 0),
        0,
      );
      $formData.balance = crmTotal;
      $formData.difference = crmTotal - balanceTotal;

      const crmWeight = $formData.positions.reduce(
        (sum, p) => sum + (p.crmWeight ?? 0),
        0,
      );
      const balanceWeight = $formData.positions.reduce(
        (sum, p) => sum + (p.balanceWeight ?? 0),
        0,
      );
      $formData.differenceWeight = crmWeight - balanceWeight;
    }
  }

  $: readOnly = $formData.status === 'Closed' || $formData.status === 'закрыт';
</script>

<PageWithTitle>
  <div slot="short-card" class="p-4 w-full">
    <HantaInputText {form} name="name" label="Balance Name" hideLabel={true} />
  </div>

  <div slot="content" class="grid grid-cols-3 p-1">
    <div class="col-span-3">
      <div class="mt-4">
        <Tabs.Root bind:value={currentTab} class="w-full">
          <Tabs.List>
            <Tabs.Trigger value="details">Details</Tabs.Trigger>
            <Tabs.Trigger value="files">Files</Tabs.Trigger>
          </Tabs.List>
          <Tabs.Content value="files">
            <div class="my-4">
              <Label>Files</Label>
              <Files
                folder={`balances/${item?.id}`}
                compact={true}
                title=""
                maxColumns={4}
                uploadOnEnd={true}
              />
            </div>
          </Tabs.Content>
          <Tabs.Content value="details">
            <div class="mt-4">
              <div class="flex justify-between items-center mb-2">
                <div class="flex items-center gap-4">
                  <h3 class="text-lg font-semibold">Positions</h3>
                  <div class="flex items-center gap-2">
                    <Button
                      variant={currentFilter === 'all' ? 'default' : 'outline'}
                      size="sm"
                      on:click={() => {
                        currentFilter = 'all';
                        currentPage = 0;
                      }}
                    >
                      All ({$formData.positions?.length ?? 0})
                    </Button>
                    <Button
                      variant={currentFilter === 'no-order'
                        ? 'default'
                        : 'outline'}
                      size="sm"
                      on:click={() => {
                        currentFilter = 'no-order';
                        currentPage = 0;
                      }}
                    >
                      Consignment Not Found ({positionsWithoutOrder})
                    </Button>
                    <Button
                      variant={currentFilter === 'differences'
                        ? 'default'
                        : 'outline'}
                      size="sm"
                      on:click={() => {
                        currentFilter = 'differences';
                        currentPage = 0;
                      }}
                    >
                      With Differences ({positionsWithDifferences})
                    </Button>
                    <Button
                      variant={currentFilter === 'fine' ? 'default' : 'outline'}
                      size="sm"
                      on:click={() => {
                        currentFilter = 'fine';
                        currentPage = 0;
                      }}
                    >
                      All Fine ({positionsAllFine})
                    </Button>
                  </div>
                </div>
                <div class="flex items-center gap-2">
                  <Button
                    variant="default"
                    on:click={addPosition}
                    disabled={readOnly}
                  >
                    Add Position
                  </Button>
                </div>
              </div>
              <div class="relative w-64 my-4">
                <Input
                  type="text"
                  placeholder="Search by name or comments..."
                  bind:value={searchTerm}
                  on:input={() => (currentPage = 0)}
                  class="w-full"
                />
                {#if searchTerm}
                  <Button
                    variant="ghost"
                    size="sm"
                    class="absolute right-1 top-1/2 transform -translate-y-1/2"
                    on:click={() => {
                      searchTerm = '';
                      currentPage = 0;
                    }}
                  >
                    Clear
                  </Button>
                {/if}
              </div>
              {#if paginatedPositions.length > 0}
                <div
                  class="flex items-center justify-between mb-4 border-b pb-4"
                >
                  <Button
                    variant="outline"
                    size="sm"
                    disabled={currentPage === 0}
                    on:click={previousPage}
                  >
                    Previous
                  </Button>
                  <div class="flex items-center gap-2">
                    <span class="text-sm text-muted-foreground">
                      {currentRange}
                    </span>
                  </div>
                  <Button
                    variant="outline"
                    size="sm"
                    disabled={currentPage >= totalPages - 1}
                    on:click={nextPage}
                  >
                    Next
                  </Button>
                </div>

                <div class="space-y-2">
                  {#each paginatedPositions as position (position.id)}
                    <div
                      class="border rounded p-3"
                      class:bg-red-50={position.order == null && !readOnly}
                      class:bg-green-50={Math.abs(position.diffTotal) < 5}
                    >
                      <div class="flex justify-between items-center mb-2">
                        <div class="flex items-center gap-2">
                          <h4 class="text-sm font-medium">
                            Position {position.position ?? ''}
                          </h4>
                          <div class="min-w-[250px] flex items-center gap-2">
                            {#if position.order || readOnly}
                              <div>Order {position.order?.name ?? ''}</div>
                              <Button
                                variant="ghost"
                                on:click={() => {
                                  popupStore.openPopup('crmObject', {
                                    module: 'orders',
                                    id: position.order?.id,
                                  });
                                }}
                              >
                                View
                              </Button>
                              <Button
                                variant="ghost"
                                disabled={readOnly}
                                on:click={() => (position.order = null)}
                              >
                                Clear
                              </Button>
                            {:else}
                              <CrmObjectSelector
                                module="orders"
                                bind:value={position.order}
                              />
                            {/if}
                          </div>
                        </div>
                        <div class="flex gap-2">
                          <Button
                            variant="ghost"
                            disabled={readOnly}
                            on:click={() => removePosition(position.id)}
                          >
                            Remove
                          </Button>
                        </div>
                      </div>

                      <div class="grid grid-cols-6 gap-4 items-center">
                        <div class="text-md space-y-2 text-right">
                          <h5 class="text-xs font-medium mb-1">&nbsp;</h5>
                          <div
                            class="grid grid-cols-1 gap-2 text-base font-medium text-muted-foreground"
                          >
                            <div>Price</div>
                            <div>Weight</div>
                          </div>
                        </div>

                        <div class="col-span-1 space-y-2 items-end">
                          <h5 class="text-xs font-medium mb-1">Balance Data</h5>
                          <div class="grid grid-cols-1 gap-2">
                            {#if !readOnly}
                              <input
                                type="number"
                                bind:value={position.balanceTotal}
                                class="text-base"
                              />
                              <input
                                type="number"
                                bind:value={position.balanceWeight}
                                class="text-base"
                              />
                            {:else}
                              <div class="text-lg">
                                {position.balanceTotal?.toFixed(2)} €
                              </div>
                              <div class="text-lg">
                                {position.balanceWeight?.toFixed(2)} kg
                              </div>
                            {/if}
                          </div>
                        </div>

                        <div class="col-span-1 space-y-2">
                          <h5 class="text-xs font-medium mb-1 text-right">
                            CRM Data
                          </h5>
                          <div
                            class="grid grid-cols-1 gap-2 text-lg text-right"
                          >
                            <div>
                              {position?.crmTotal?.toFixed(2)} €
                            </div>
                            <div>
                              {position?.crmWeight?.toFixed(2)} kg
                            </div>
                          </div>
                        </div>

                        <div class="col-span-1 space-y-2">
                          <h5 class="text-xs font-medium mb-1">Differences</h5>
                          <div
                            class="grid grid-cols-1 gap-1 text-lg text-primary"
                          >
                            <div>{position.diffTotal?.toFixed(2)} €</div>
                            <div>{position.diffWeight?.toFixed(2)} kg</div>
                          </div>
                        </div>
                        <div class="mt-2 col-span-2">
                          <label
                            class="text-xs font-medium mb-2"
                            for={'comments-' + position.id}>Comments</label
                          >
                          <textarea
                            id={'comments-' + position.id}
                            bind:value={position.comments}
                            class="w-full mt-2 text-lg"
                            rows="2"
                            disabled={readOnly}
                          />
                        </div>
                      </div>
                    </div>
                  {/each}

                  <div
                    class="flex items-center justify-between mt-4 border-t pt-4"
                  >
                    <Button
                      variant="outline"
                      size="sm"
                      disabled={currentPage === 0}
                      on:click={previousPage}
                    >
                      Previous
                    </Button>
                    <div class="flex items-center gap-2">
                      <span class="text-sm text-muted-foreground">
                        {currentRange}
                      </span>
                    </div>
                    <Button
                      variant="outline"
                      size="sm"
                      disabled={currentPage >= totalPages - 1}
                      on:click={nextPage}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              {:else}
                <div class="text-center py-4 text-muted-foreground text-sm">
                  No positions added yet. Click "Add Position" to start.
                </div>
              {/if}
            </div>
          </Tabs.Content>
        </Tabs.Root>
      </div>
    </div>
  </div>
  <div slot="sidebar">
    <form method="POST" use:enhance class="p-3 space-y-2">
      <div class="grid grid-cols-2 gap-2">
        <HantaInputText {form} name="name" label="Name" />
        <HantaInputLovSelect
          {form}
          bind:value={$formData.status}
          type="Balance > Status"
          label="Status"
          multiple={false}
        />
      </div>

      <div class="grid grid-cols-2 gap-2">
        <HantaInputText {form} name="invoiceNumber" label="Invoice Number" />
        <HantaInputText {form} name="customerNumber" label="Customer Number" />
      </div>

      <div class="grid grid-cols-2 gap-2">
        <HantaInputNumber {form} name="amount" label="Amount" />
        <HantaInputText {form} name="currency" label="Currency" />
      </div>

      <div class="grid grid-cols-2 gap-6 py-4">
        <div class="text-xl">
          <div class="text-primary font-bold">
            {$formData.balance?.toFixed(2) ?? 0} €
          </div>
          <div class="text-xs">CRM Total</div>
        </div>
        <div class="text-xl">
          <div class="text-primary font-bold">
            {$formData.difference?.toFixed(2) ?? 0} €
          </div>
          <div class="text-xs">Difference</div>
        </div>

        <div class="text-xl hidden">
          <div class="text-primary">
            {$formData.differenceWeight ?? 0} kg
          </div>
          <div>Difference</div>
        </div>
      </div>

      <div class="grid grid-cols-2 gap-2">
        <HantaInputDate {form} name="invoiceDate" label="Invoice Date" />
        <HantaInputDate {form} name="dueDate" label="Due Date" />
        <HantaInputDate {form} name="paidOn" label="Paid On" />
      </div>

      <HantaInputTextareaRichtext {form} name="description" rows={6} />

      <FormActions {form} />
    </form>
  </div>
</PageWithTitle>
