<script lang="ts">
  import { onMount } from 'svelte';
  import { savePipelineItems } from '$lib/api/pipeline-api';
  import { pipelineStore } from '$lib/stores/pipeline-store';
  import { isLoading } from '$lib/stores/loading-store';
  import PipelineBuilder from '$lib/components/pipelines/pipeline-builder.svelte';
  import { Button } from '$lib/components/ui/button';
  import { deletePipeline } from '$lib/api/pipeline-api';
  import { toast } from 'svelte-sonner';

  import Icon from '@iconify/svelte';
  import type { Pipeline, Phase } from '$lib/types/pipeline';

  export let selectedPipelineId: string | null = null;

  let isPreviewMode = true;
  let userToggledMode = false; // Track if user has manually toggled mode
  let phases: Phase[] = [];

  // Subscribe to pipelines store
  let pipelines: Pipeline[] = [];
  pipelineStore.subscribe(value => {
    pipelines = value;
    if (!selectedPipelineId) {
      selectedPipelineId = pipelines[0]?.id;
    }
  });

  $: selectedPipeline = pipelines.find(p => p.id === selectedPipelineId);
  $: if (selectedPipeline) {
    phases = selectedPipeline.phases;
    if (phases.length === 0) {
      isPreviewMode = false;
    }
  }

  onMount(() => {
    pipelineStore.loadPipelines();
  });

  function handlePhaseReorder(updatedPhases: Phase[]) {
    if (!selectedPipeline || !updatedPhases || updatedPhases.length === 0) {
      return;
    }
    
    // First check if actual reordering has happened by comparing IDs in order
    const orderChanged = updatedPhases.some(
      (phase, index) => phases[index]?.id !== phase.id
    );
    
    if (orderChanged) {
      console.log('Phase order changed locally');
      
      // Create a proper copy with updated order values
      const reorderedPhases = updatedPhases.map((phase, index) => ({
        ...phase,
        sort: index,
        phaseIndex: index, // Update both for compatibility
      }));
      
      // Update local phases array
      phases = reorderedPhases;
      
      // Update the selected pipeline reference
      selectedPipeline.phases = reorderedPhases;
      
      // Inform user to save changes
      toast.info('Phase order changed. Click "Save Pipeline" to apply changes.');
      
      // NOTE: NO AUTO-SAVE HERE - User must click Save Pipeline button
    } else {
      console.log('No change in phase order, skipping update');
    }
  }

  function handleTogglePreview() {
    // Just update the local variable - this will automatically update the prop
    // that's passed to the component via reactive binding
    isPreviewMode = !isPreviewMode;
    
    // Set user preference flag
    userToggledMode = true;
    
    // No need to directly modify component instance properties
  }

  function onPhaseAdd() {
    const newPhase = {
      id: crypto.randomUUID(),
      name: '',
      description: '',
      order: phases.length,
      items: [],
    };
    
    // Update local state only
    phases = [...phases, newPhase];
    selectedPipeline.phases = phases;
    
    // Inform user to save changes
    toast.info('Phase added locally. Click "Save Pipeline" to apply changes.');
  }

  function onPhaseRemove(id: string) {
    if (!selectedPipeline) return;
    
    // First remove the phase locally - this immediately updates the UI
    const updatedPhases = phases.filter(p => p.id !== id);
    
    // Update local references only, no database update
    phases = updatedPhases;
    selectedPipeline.phases = updatedPhases;
    
    // Display toast notification that changes need to be saved
    toast.info('Phase removed locally. Click "Save Pipeline" to apply changes.');
  }

  function onDuplicatePipeline() {
    if (selectedPipeline) {
      // pipelineStore.duplicatePipeline(selectedPipeline.id);
    }
  }

  async function onChange(phase: Phase, items: any[]) {
    console.log('onChange', { phase, items });
    await savePipelineItems(phase.id, items);
  }

  async function onDeletePipeline() {
    if (selectedPipeline) {
      pipelineStore.deletePipeline(selectedPipeline.id);
      await deletePipeline(selectedPipeline.id);
    }
  }

  async function onSavePipeline() {
    if (selectedPipeline) {
      try {
        // Set global loading state to true - this will freeze the UI
        isLoading.set(true);
        
        console.log('Saving all pipeline changes at once:', selectedPipeline.phases);
        
        // Create a deep clone of the current pipeline to avoid any reference issues
        const pipelineBackup = JSON.parse(JSON.stringify(selectedPipeline));
        
        // Create a copy of the current local state to prevent UI flicker
        const localPhases = [...selectedPipeline.phases];
        
        // Ensure all phases have the correct sort values before saving
        const phasesWithUpdatedOrder = localPhases.map((phase, index) => ({
          ...phase,
          sort: index,
          // Also keep phaseIndex for backward compatibility
          phaseIndex: index
        }));
        
        // Create a copy of the pipeline with ordered phases for saving
        const pipelineToSave = {
          ...selectedPipeline,
          phases: phasesWithUpdatedOrder
        };
        
        // Save to the database - this will handle all pending changes at once
        const updatedPipeline = await pipelineStore.updatePipeline(pipelineToSave);
        
        // Preserve our existing phases array structure to avoid UI flickering
        // But update with any new database-generated IDs or fields
        if (updatedPipeline && updatedPipeline.phases) {
          // Rather than immediately updating the UI, apply changes after the loading overlay
          // disappears to ensure no visual flicker is visible
          setTimeout(() => {
            // Map the returned phase IDs to our local phases if needed
            selectedPipeline.phases = phasesWithUpdatedOrder.map(localPhase => {
              const serverPhase = updatedPipeline.phases.find(p => p.id === localPhase.id);
              return serverPhase || localPhase;
            });
            
            // Update our working phases reference
            phases = selectedPipeline.phases;
          }, 1000); // Update state 1 second after loading overlay disappears
        }
        
        toast.success('All pipeline changes saved successfully');
        
        // Keep loading state active for a reasonable delay period (1.5 seconds)
        // This ensures any DOM updates happen while the overlay is visible
        await new Promise(resolve => setTimeout(resolve, 1500));
      } catch (error) {
        console.error('Failed to save pipeline changes:', error);
        toast.error('Failed to save pipeline changes');
      } finally {
        // Reset loading state when save operation is complete (after delay)
        isLoading.set(false);
      }
    } else {
      toast.error('No pipeline selected');
    }
  }

  let pipelineBuilderComponent: PipelineBuilder; // Reference to the component instance
</script>

<div class="w-full">
  {#if $isLoading}
    <div class="fixed inset-0 bg-background/70 backdrop-blur-sm z-50 flex items-center justify-center">
      <div class="flex flex-col items-center gap-5 p-8 rounded-xl bg-background/80 shadow-lg border border-muted/30 backdrop-blur">
        <div class="relative">
          <div class="w-16 h-16 rounded-full border-4 border-primary/30 border-t-primary animate-spin"></div>
          <div class="absolute inset-0 flex items-center justify-center">
            <div class="w-3 h-3 rounded-full bg-primary animate-pulse"></div>
          </div>
        </div>
        <div class="text-center">
          <p class="text-xl font-medium text-foreground">Saving changes</p>
          <p class="text-sm text-muted-foreground mt-1">Please wait while we update your pipeline</p>
        </div>
      </div>
    </div>
  {/if}

  <div class="absolute top-4 left-48 flex items-center space-x-2">
    {#if selectedPipeline}
      <h2 class="ml-12 text-lg font-bold">{selectedPipeline.name}</h2>
      <Button
        variant={isPreviewMode ? 'ghost' : 'outline'}
        on:click={handleTogglePreview}
      >
        <Icon
          icon={isPreviewMode ? 'mdi:cog' : 'mdi:eye'}
          class="mr-2 w-4 h-4"
        />
        {isPreviewMode ? 'Configure' : 'Preview'}
      </Button>
    {/if}
  </div>

  {#if selectedPipeline}
    <PipelineBuilder
      bind:this={pipelineBuilderComponent}
      pipeline={selectedPipeline}
      {isPreviewMode}
      {userToggledMode}
      {onPhaseAdd}
      {onPhaseRemove}
      onPhaseReorder={handlePhaseReorder}
      {onSavePipeline}
      {onChange}
    />
  {:else}
    <div class="py-12 text-center text-muted-foreground">
      <p>Select a pipeline to begin</p>
    </div>
  {/if}
</div>
