<script lang="ts">
  import Button from '$lib/components/ui/button/button.svelte';
  import { cn } from '$lib/utils/hanta-utils';
  import Badge from '$lib/components/ui/badge/badge.svelte';
  import Icon from '@iconify/svelte';

  export let value: boolean | null = null;
  export let counts = { true: 0, false: 0, null: 0 };

  const options = [
    { label: 'True', value: true, icon: 'mdi:check-circle' },
    { label: 'False', value: false, icon: 'mdi:close-circle' },
    { label: 'Empty', value: 'null', icon: 'mdi:minus-circle' },
  ];

  function handleSelect(newValue: boolean | null) {
    value = newValue;
  }
</script>

<div class="flex gap-2">
  {#each options as option}
    <Button
      size="sm"
      variant={value === option.value ? 'default' : 'outline'}
      class={cn(
        'flex gap-2',
        value === option.value && 'bg-primary text-secondary',
      )}
      on:click={() => {
        handleSelect(option.value === value ? undefined : option.value);
      }}
    >
      <Icon icon={option.icon} width="20" />
      <span>{option.label}</span>
      {#if counts && counts[option.value + ''] > 0}
        <Badge variant="secondary">{counts[option.value + '']}</Badge>
      {/if}
    </Button>
  {/each}
</div>
