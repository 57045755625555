<script lang="ts">
  import { saveOrder } from '$lib/api/mutations';
  import AddOrderModal from './invoice-positions-add-order-modal.svelte';
  import { Button } from '$lib/components/ui/button/index';
  import { dndzone, type DndEvent, SOURCES, TRIGGERS } from 'svelte-dnd-action';
  import { flip } from 'svelte/animate';

  import { generateUUID } from '$lib/utils/browser-utils';

  import * as DropdownMenu from '$lib/components/ui/dropdown-menu/index.js';

  import HantaInputTextArea from '$lib/widgets/input/hanta-input-textarea.svelte';
  import HantaSelect from '$lib/components/hanta/select.svelte';
  import HantaInputNumber from '$lib/widgets/input/hanta-input-number.svelte';
  import HantaInputCurrency from '$lib/widgets/input/hanta-input-currency.svelte';
  import HantaInputSelect from '$lib/widgets/input/hanta-input-select.svelte';
  import {
    calculatePosition,
    calculateSum,
  } from '$lib/pages/invoices/invoice-utils';
  import { tweened } from 'svelte/motion';
  import { cubicOut } from 'svelte/easing';
  import { currencyFormatter } from '$lib/app/utils';
  import { popupStore } from '$lib/stores/app-store';
  import * as Card from '$lib/components/ui/card';
  import { Badge } from '$lib/components/ui/badge';
  import { formatDate } from './../../pages/invoices/invoice-utils';
  import { formatCurrency } from '$lib/utils/hanta-utils';

  import Icon from '@iconify/svelte';

  export let positions = [];
  export let orders = [];
  export let isLocked = false;
  export let form;
  export let errors;
  export let flipDurationMs = 300;
  export let isDragging = false;

  const { form: formData } = form;

  let selectedPositionIdx;

  let showOrderModal = false;
  function openOrderModal() {
    showOrderModal = true;
  }

  function closeOrderModal() {
    showOrderModal = false;
  }

  async function removeOrder(order) {
    if ($formData.status !== 'Draft') {
      alert('Cannot remove order from invoice');
      return;
    }
    const orderToSave = JSON.parse(JSON.stringify(order));
    orderToSave.status = 'PROCESSING';
    orderToSave.invoiceId = null;
    await saveOrder(orderToSave);
    orders = orders.filter(o => o.id !== order.id);
    // form.submit();
  }

  const format: (value: number) => string = currencyFormatter().format;
  let total = tweened(0, { duration: 500, easing: cubicOut });

  function transformDraggedElement(el: HTMLElement, index) {
    const idx = el?.querySelector('#hanta-index');
    if (idx) {
      idx.innerHTML = index + 1 + '.';
    }
  }

  function handleConsider(e) {
    const {
      items: newItems,
      info: { trigger, source },
    } = e.detail;

    if (source === SOURCES.KEYBOARD && trigger === TRIGGERS.DRAG_STOPPED) {
      isDragging = false;
    }

    positions = newItems;
    $formData.positions = positions;
  }

  async function handleFinalize(e: CustomEvent<DndEvent<RowType>>) {
    let { items: newItems } = e.detail;
    isDragging = false;

    positions = newItems;
    $formData.positions = newItems;
  }

  function handleMaybeSelect(id, e) {
    isDragging = true;
  }

  const addPosition = async () => {
    formData.update((data: any) => {
      if (!data.positions) data.positions = [];

      data.positions.push({
        id: generateUUID(),
        text: '',
        unit: 'Stunden',
        quantity: 1,
        price: 0,
        tax: 19,
        sum: 0,
      });

      return data;
    });

    isDragging = false;
    positions = $formData.positions;
    selectedPositionIdx = positions.length - 1;
    showOrderModal = true;
  };

  const removePositionAtIdx = idx => {
    positions = positions.filter((_, i) => i !== idx);
    $formData.positions = positions;
  };

  const editPositionAtIdx = idx => {
    selectedPositionIdx = idx;
    showOrderModal = true;
  };

  const clonePositionFromIdx = async idx => {
    const clone = JSON.parse(JSON.stringify(positions[idx]));
    clone.id = generateUUID();

    positions = [
      ...positions.slice(0, idx + 1),
      clone,
      ...positions.slice(idx + 1),
    ];

    $formData.positions = positions;
    selectedPositionIdx = idx;
    showOrderModal = true;
  };

  $: sum = calculateSum($formData);
  $: total.set(sum.sum);

  function getStatusStyle(status) {
    switch (status) {
      case 'PROCESSING':
        return 'bg-yellow-100 dark:bg-yellow-900 text-yellow-800 dark:text-yellow-200';
      case 'CANCELLED':
        return 'bg-red-100 dark:bg-red-900 text-red-800 dark:text-red-200';
      case 'COMPLETED':
        return 'bg-green-100 dark:bg-green-900 text-green-800 dark:text-green-200';
      default:
        return 'bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-200';
    }
  }

  function getStatusIcon(status) {
    switch (status) {
      case 'PROCESSING':
        return 'mdi:clock';
      case 'CANCELLED':
        return 'mdi:close-circle';
      case 'COMPLETED':
        return 'mdi:check-circle';
      default:
        return 'mdi:package';
    }
  }
</script>

<div class="flex flex-col gap-6 text-left">
  <!-- Orders Card -->
  <Card.Root>
    <Card.Header>
      <Card.Title>Orders</Card.Title>
    </Card.Header>
    <Card.Content class="pr-8">
      {#if orders?.length > 0}
        <table class="w-full">
          <thead>
            <tr class="border-b border-gray-200 dark:border-gray-700">
              <th class="w-12"></th>
              <th
                class="pb-2 font-semibold text-left text-primary dark:text-primary-dark"
                >Order</th
              >

              <th
                class="pb-2 font-semibold text-right text-primary dark:text-primary-dark"
                >Amount</th
              >
            </tr>
          </thead>
          <tbody>
            {#each orders || [] as order}
              <tr class="border-b border-gray-100 dark:border-gray-700">
                <td class="py-4">
                  <DropdownMenu.Root>
                    <DropdownMenu.Trigger asChild let:builder>
                      <Button builders={[builder]} variant="ghost" size="icon">
                        <Icon icon="mdi:dots-vertical" class="size-4" />
                      </Button>
                    </DropdownMenu.Trigger>
                    <DropdownMenu.Content class="w-56">
                      <DropdownMenu.Item
                        on:click={() => {
                          popupStore.openPopup('crmObject', {
                            module: 'orders',
                            id: order.id,
                          });
                        }}
                      >
                        Open
                      </DropdownMenu.Item>
                      <DropdownMenu.Item
                        disabled={$formData.status !== 'Draft'}
                        on:click={() => {
                          removeOrder(order);
                        }}
                      >
                        Remove
                      </DropdownMenu.Item>
                    </DropdownMenu.Content>
                  </DropdownMenu.Root>
                </td>
                <td class="py-4">
                  <div class="flex flex-col">
                    <div class="flex items-center space-x-2">
                      <span class="font-semibold text-primary"
                        >{order.name}</span
                      >
                      <Badge
                        variant={order.status === 'COMPLETED'
                          ? 'outline'
                          : 'default'}
                        class="mt-1 mb-2 px-2 py-1 text-xs rounded-full inline-flex items-center {getStatusStyle(
                          order.status,
                        )}"
                      >
                        <Icon icon={getStatusIcon(order.status)} class="mr-1" />
                        {order.status}
                      </Badge>
                    </div>
                    <div class="my-2"></div>
                    {#if order?.shipment}
                      <div class="text-sm text-muted-foreground">
                        <div class="grid grid-cols-2 gap-y-1 gap-x-4">
                          {#if order.orderDate}<div>Date:</div>
                            <div class="text-primary">
                              {formatDate(order.orderDate)}
                            </div>{/if}
                          {#if order.shipment.country}<div>Country:</div>
                            <div>{order.shipment.country}</div>{/if}
                          {#if order.shipment.weight}<div>Weight:</div>
                            <div>{order.shipment.weight}</div>{/if}
                          {#if order.shipment.operator}<div>Operator:</div>
                            <div>{order.shipment.operator}</div>{/if}
                          {#if order.shipment.direction}<div>Direction:</div>
                            <div>{order.shipment.direction}</div>{/if}
                          {#if order.shipment.product}<div>Product:</div>
                            <div>{order.shipment.product}</div>{/if}

                          {#if order.shipment.packageType}<div>
                              Package Type:
                            </div>
                            <div>{order.shipment.packageType}</div>{/if}

                          {#if order.shipment.rate}<div>Rate:</div>
                            <div class="text-primary">
                              {formatCurrency(
                                order.shipment.rate?.price,
                                'EUR',
                              )}
                            </div>{/if}
                          {#each order.shipment.rate?.services || [] as service}
                            <div>{service?.product}</div>
                            <div class="text-primary">
                              {formatCurrency(service?.price, 'EUR')}
                            </div>
                          {/each}
                        </div>
                      </div>
                    {/if}
                  </div>
                </td>

                <td
                  class="py-4 text-base text-right text-primary dark:text-primary-dark"
                >
                  {formatCurrency(order.shipment?.totalEUR || 0, 'EUR')}
                </td>
              </tr>
            {/each}
          </tbody>
        </table>
        <p class="mt-4 text-sm text-primary/60 dark:text-primary-dark/60">
          {orders?.length} order(s)
        </p>
      {:else}
        <p class="text-muted-foreground">No orders found for this invoice.</p>
      {/if}
    </Card.Content>
  </Card.Root>

  <!-- Positions Card -->
  <Card.Root>
    <Card.Header>
      <Card.Title>Positions</Card.Title>
    </Card.Header>
    <Card.Content>
      {#if positions?.length > 0}
        <section
          class="flex flex-col gap-1.5"
          on:consider={handleConsider}
          on:finalize={handleFinalize}
          use:dndzone={{
            dragDisabled: positions?.length === 0 || isLocked,
            items: positions,
            flipDurationMs,
            transformDraggedElement,
          }}
        >
          {#each positions || [] as position, idx (position.id || position.text)}
            <div
              role="button"
              tabindex="0"
              on:mousedown={e => handleMaybeSelect(position.id, e)}
              on:keydown={e => handleMaybeSelect(position.id, e)}
              animate:flip={{ duration: flipDurationMs }}
              class="hover:bg-muted group w-full p-0 bg-primary-800 rounded border border-solid border-primary/20 shadow !leading-normal"
            >
              <div
                class="flex relative flex-row gap-2 px-3 py-2 w-full content-stretch"
              >
                {#key idx}
                  <div id="hanta-index" class="font-bold min-w-4">
                    {idx + 1}.

                    <div
                      class="absolute top-7 left-0 flex flex-col items-center w-4 gap-4 p-4 font-bold transition-opacity bg-primary-800 shadow opacity-0 shrink-0 grow-0 {!isLocked
                        ? 'group-hover:opacity-100'
                        : ''} bg-opacity-90"
                    >
                      <button
                        on:click={e => {
                          e.preventDefault();
                          clonePositionFromIdx(idx);
                        }}
                        class="text-muted-foreground hover:text-muted-foreground"
                      >
                        <Icon icon="mdi:content-copy" class="size-4" />
                      </button>
                      <button
                        on:click={e => {
                          e.preventDefault();
                          editPositionAtIdx(idx);
                        }}
                        class="text-blue-400 hover:text-muted-foreground"
                      >
                        <Icon icon="mdi:pencil" class="size-4" />
                      </button>
                      <button
                        on:click={e => {
                          e.preventDefault();
                          removePositionAtIdx(idx);
                        }}
                        class="text-red-400 hover:text-muted-foreground"
                      >
                        <Icon icon="mdi:trash" class="size-4" />
                      </button>
                    </div>
                  </div>
                  <div class="w-full !leading-1">
                    <HantaInputTextArea
                      readOnly={true}
                      {form}
                      rows={6}
                      name="positions[{idx}].text"
                      placeholder="Enter position description..."
                    />
                    {#if $errors?.positions && $errors?.positions[idx]?.text}
                      <span class="text-red-500"
                        >{$errors?.positions &&
                          $errors?.positions[idx]?.text}</span
                      >
                    {/if}
                  </div>
                  <div class="flex flex-row gap-2 items-baseline self-start">
                    <div class="w-28 shrink-0 grow-0">
                      <HantaSelect
                        readOnly={true}
                        searchable={false}
                        bind:value={$formData.positions[idx].unit}
                        options={[
                          { label: 'Stunden', value: 'Stunden' },
                          { label: 'pauschal', value: 'pauschal' },
                          { label: 'Stück', value: 'Stück' },
                        ]}
                      />
                    </div>
                    <div class="w-12 shrink-0 grow-0">
                      {#if $formData.positions[idx].unit !== 'pauschal'}
                        <HantaInputNumber
                          readOnly={true}
                          asFloat={true}
                          {form}
                          name="positions[{idx}].quantity"
                        />
                      {/if}
                    </div>
                    <div class="w-16 shrink-0 grow-0">
                      <HantaInputCurrency
                        {form}
                        name="positions[{idx}].price"
                        readOnly={true}
                      />
                    </div>
                    <div class="w-20 shrink-0 grow-0">
                      <HantaInputSelect
                        readOnly={true}
                        asInt={true}
                        {form}
                        name="positions[{idx}].tax"
                        options={[
                          { label: '0%', value: '0' },
                          { label: '7%', value: '7' },
                          { label: '19%', value: '19' },
                        ]}
                      />
                    </div>
                    <div
                      class="w-20 text-sm font-medium text-right shrink-0 grow-0"
                    >
                      {formatCurrency(
                        calculatePosition($formData.positions[idx]).net,
                        'EUR',
                      )}
                    </div>
                  </div>
                {/key}
              </div>
            </div>
          {/each}
        </section>
      {:else}
        <div class="text-muted-foreground">No positions added yet</div>
      {/if}
    </Card.Content>
  </Card.Root>
</div>

{#if !isLocked}
  <div class="flex flex-row mt-4">
    <Button on:click={() => addPosition()}>
      <Icon icon="mdi:plus" class="size-4" />
      Add
    </Button>
    <div class="flex flex-col justify-center items-end w-full">
      <div>Net {format(sum.sum - sum.tax)}</div>
      <span class="font-normal text-nowrap">VAT {format(sum.tax)}</span>
      <div class="text-lg font-bold underline">{format(sum.sum)}</div>
    </div>
  </div>
{/if}

{#if showOrderModal}
  <AddOrderModal
    {form}
    formData={$formData}
    positionIdx={selectedPositionIdx}
    orderType={'one time'}
    on:close={closeOrderModal}
    on:save={closeOrderModal}
  />
{/if}

<style>
  :global(body) {
    --color-primary: theme('colors.gray.800');
    --color-primary-dark: theme('colors.gray.200');
  }
  :global(body.dark) {
    --color-primary: theme('colors.gray.200');
    --color-primary-dark: theme('colors.gray.800');
  }
</style>
