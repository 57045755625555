import { supabase } from './supabase';
import { authStore } from '$lib/stores/auth-store';
import { get } from 'svelte/store';

export interface ManifestRow {
  name: string;
  manifestNr: string;
  originCountry: string;
  shipperNameAndCompany: string;
  recipientNameAndCompany: string;
  recipientAddress: string;
  recipientCompany: string;
  recipientName: string;
  packageCount: string;
  weight: string;
  weightUnit: string;
  customsValue: string;
  currencyCode: string;
  commodityDescription: string;
  commodityHarmonizedCode: string;
  recipientTaxId: string;
  recipientPhone: string;
  masterTrackingNumber: string;
  destinationCity: string;
  destinationCountry: string;
  recipientPostalCode: string;
  paymentPayorCode: string;
  category: string;
  declarationNumber: string;
  status: string;
}

export interface ManifestHeader {
  key: string;
  title: string;
}

export interface ParsedManifestResponse {
  headers: ManifestHeader[];
  data: ManifestRow[];
}

export async function parseManifest(
  file: File,
): Promise<ParsedManifestResponse> {
  try {
    const formData = new FormData();
    formData.append('file', file);

    const { data, error } = await supabase(
      get(authStore)?.token,
      false,
    ).functions.invoke('parse-manifest', {
      body: formData,
    });

    if (error) {
      console.error('Supabase function error:', error);
      throw new Error(`Failed to parse manifest: ${error.message}`);
    }

    if (!data || typeof data !== 'object' || !data.headers || !data.data) {
      throw new Error('Invalid data received from parse-manifest function');
    }

    return {
      headers: data.headers as ManifestHeader[],
      data: data.data as ManifestRow[],
    };
  } catch (error) {
    console.error('Error parsing manifest:', error);
    throw error;
  }
}
