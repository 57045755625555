<script lang="ts">
  import { superForm } from 'sveltekit-superforms';
  import { zodClient } from 'sveltekit-superforms/adapters';
  import { insertManifestSchema, type Manifest } from '$db/schema';
  import { appStore } from '$lib/stores/app-store';
  import { createMutation, useQueryClient } from '@tanstack/svelte-query';
  import { alertStore } from '$lib/stores/alert-store';
  import FormActions from '$lib/app/forms/form-actions.svelte';
  import ManifestDetailsCard from './manifest-details-card.svelte';
  import { saveManifest } from '$lib/api/mutations';

  export let item;

  const client = useQueryClient();

  const formSchema = insertManifestSchema.partial();

  const mutation = createMutation({
    mutationFn: (variables): Promise<Manifest> =>
      Promise.resolve(saveManifest(variables)),
    onSuccess: () => client.invalidateQueries({ queryKey: ['manifests'] }),
    onMutate: async newItem => {
      await client.cancelQueries({ queryKey: ['manifests'] });
      const previousItems = client.getQueryData<Manifest[]>(['manifests']);

      client.setQueryData(['manifests'], { ...newItem });

      return { previousItems };
    },
    onError: (err: any, variables: any, context: any) => {
      if (context?.previousItems) {
        client.setQueryData(['manifests'], context.previousItems);
      }
      alertStore.show(
        'Error Saving Time Entry',
        err.message || 'Failed to save time entry',
        undefined,
        () => {
          // Optional: Add retry logic here
          $mutation.mutate(variables);
        },
      );
    },
    onSettled: () => {
      client.invalidateQueries({ queryKey: ['manifests'] });
    },
  });
  const form = superForm(item, {
    resetForm: false,
    SPA: true,
    validators: zodClient(formSchema),
    validationMethod: 'oninput',
    dataType: 'json',
    onSubmit: async () => {
      console.debug('Form data', $formData);
      if (Object.keys($errors).length > 0) {
        alertStore.show(
          'Validation Error',
          'Please check the form for errors',
          undefined,
          () => {
            // Optional: Add form resubmission logic
          },
        );
        console.error('Validation errors', $errors, { form: $formData });
        $errors = {};
        return;
      }

      $mutation.mutate($formData, {
        onError(error) {
          console.error('Error', error);
          alertStore.show(
            'Error Saving Manifest',
            error.message || 'An error occurred while saving the manifest',
            undefined,
            () => {
              // Optional: Add retry logic
              $mutation.mutate($formData);
            },
          );
        },
        onSuccess: () => {
          // alertStore.show('Success', 'Manifest saved successfully');
        },
      });
    },
  });

  let { form: formData, enhance, errors } = form;

  $: readOnly = $formData.status === 'COMPLETED';
</script>

<main class="overflow-scroll p-6 py-24 min-h-screen bg-cool">
  <form class="space-y-6 w-full" method="POST" use:enhance>
    <div class="mx-auto max-w-5xl">
      <div class="grid grid-cols-1 gap-8">
        <ManifestDetailsCard {form} {readOnly} />
      </div>

      <FormActions {form} />
    </div>
  </form>
</main>
