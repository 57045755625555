<script lang="ts">
  import { getLocalTimeZone, today } from '@internationalized/date';

  import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
  } from '$lib/components/ui/card';
  import * as Tabs from '$lib/components/ui/tabs';
  import OrderMetrics from '$lib/components/statistics/order-metrics.svelte';
  import OrderMap from '$lib/components/statistics/order-map.svelte';

  import HantaDateRange from '$lib/components/hanta/date/hanta-date-range.svelte';
  import type { DateRange } from 'bits-ui';

  let dateRange: DateRange = {
    start: today(getLocalTimeZone()).subtract({ years: 2 }),
    end: today(getLocalTimeZone()),
  };

  export let accountId: string;
  let groupBy = 'quarter' as 'month' | 'year' | 'quarter';
  let field = 'order_count' as
    | 'order_count'
    | 'total_amount_eur'
    | 'total_weight'
    | 'total_discount_eur'
    | 'total_revenue_eur';
</script>

<Card class="w-full">
  <CardHeader>
    <CardTitle>Order Statistics</CardTitle>
  </CardHeader>
  <CardContent class="pb-48">
    <div class="flex flex-col gap-4 my-4">
      <!-- Controls Container -->
      <div class="flex flex-col gap-4">
        <!-- Time Group Tabs -->
        <div class="flex justify-between items-center flex-wrap gap-4">
          <Tabs.Root bind:value={groupBy} class="w-full">
            <Tabs.List
              class="flex justify-center p-2 rounded-lg bg-secondary/10"
            >
              <Tabs.Trigger
                value="month"
                class="px-6 py-2 rounded-md transition-all duration-200 hover:bg-primary/10 data-[state=active]:bg-primary data-[state=active]:text-primary-foreground"
              >
                Monthly
              </Tabs.Trigger>
              <Tabs.Trigger
                value="quarter"
                class="px-6 py-2 rounded-md transition-all duration-200 hover:bg-primary/10 data-[state=active]:bg-primary data-[state=active]:text-primary-foreground"
              >
                Quarterly
              </Tabs.Trigger>
              <Tabs.Trigger
                value="year"
                class="px-6 py-2 rounded-md transition-all duration-200 hover:bg-primary/10 data-[state=active]:bg-primary data-[state=active]:text-primary-foreground"
              >
                Yearly
              </Tabs.Trigger>
            </Tabs.List>
          </Tabs.Root>
          <HantaDateRange bind:value={dateRange} />
        </div>

        <!-- Field Selection Tabs -->
        <Tabs.Root bind:value={field} class="w-full">
          <Tabs.List
            class="flex justify-center p-2 rounded-lg bg-secondary/10 flex-wrap h-32 md:h-auto"
          >
            <Tabs.Trigger
              value="order_count"
              class="px-6 py-2 rounded-md transition-all duration-200 hover:bg-primary/10 data-[state=active]:bg-primary data-[state=active]:text-primary-foreground"
            >
              Order Count
            </Tabs.Trigger>
            <Tabs.Trigger
              value="total_weight"
              class="px-6 py-2 rounded-md transition-all duration-200 hover:bg-primary/10 data-[state=active]:bg-primary data-[state=active]:text-primary-foreground"
            >
              Total Weight
            </Tabs.Trigger>
            <Tabs.Trigger
              value="total_amount_eur"
              class="px-6 py-2 rounded-md transition-all duration-200 hover:bg-primary/10 data-[state=active]:bg-primary data-[state=active]:text-primary-foreground"
            >
              Total Sales
            </Tabs.Trigger>

            <Tabs.Trigger
              value="total_revenue_eur"
              class="px-6 py-2 rounded-md transition-all duration-200 hover:bg-primary/10 data-[state=active]:bg-primary data-[state=active]:text-primary-foreground"
            >
              Total Revenue
            </Tabs.Trigger>

            <Tabs.Trigger
              value="total_discount_eur"
              class="px-6 py-2 rounded-md transition-all duration-200 hover:bg-primary/10 data-[state=active]:bg-primary data-[state=active]:text-primary-foreground"
            >
              Total Discount
            </Tabs.Trigger>
          </Tabs.List>
        </Tabs.Root>
      </div>

      <!-- Charts -->
      <OrderMetrics
        {accountId}
        timeGroup={groupBy}
        {field}
        date_from={dateRange?.start?.toString()}
        date_to={dateRange?.end?.toString()}
      />

      <OrderMetrics
        {accountId}
        timeGroup={groupBy}
        {field}
        criteria="type"
        date_from={dateRange?.start?.toString()}
        date_to={dateRange?.end?.toString()}
      />

      <OrderMetrics
        {accountId}
        timeGroup={groupBy}
        {field}
        criteria="country"
        date_from={dateRange?.start?.toString()}
        date_to={dateRange?.end?.toString()}
      />

      <OrderMetrics
        {accountId}
        timeGroup={groupBy}
        {field}
        criteria="product"
        date_from={dateRange?.start?.toString()}
        date_to={dateRange?.end?.toString()}
      />

      <OrderMetrics
        {accountId}
        timeGroup={groupBy}
        {field}
        criteria="operator"
        date_from={dateRange?.start?.toString()}
        date_to={dateRange?.end?.toString()}
      />

      <OrderMap
        {accountId}
        {field}
        date_from={dateRange?.start?.toString()}
        date_to={dateRange?.end?.toString()}
      />
    </div>
  </CardContent>
</Card>

<style>
  :global(.tabs-list) {
    @apply flex space-x-2 border-b border-border;
  }

  :global(.tab-trigger) {
    @apply px-4 py-2 text-sm font-medium text-muted-foreground hover:text-foreground;
  }

  :global(.tab-trigger[data-state='active']) {
    @apply text-foreground border-b-2 border-primary;
  }
</style>
