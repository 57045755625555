<script lang="ts">
  import { getCustomerOrderMetrics } from '$lib/api/aggregations';
  import StackedBarChart from '$lib/components/hanta/statistics/stacked-bar-chart.svelte';
  import BarChart from '$lib/components/hanta/statistics/bar-chart.svelte';
  import { onMount } from 'svelte';

  export let title: string;
  export let accountId: string;
  export let timeGroup: 'month' | 'year' | 'quarter';
  export let criteria: string | null = null;
  export let field: string;

  export let date_from: string;
  export let date_to: string;

  // Format field name for display
  $: formattedField = field
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  // Combine title with field name if title not provided
  $: chartTitle = title || `${formattedField} by ${criteria || 'Time Period'}`;

  let statistics = [];
  let stackedData: any[] = [];
  let isLoading = false;

  function transformToStackedData(data: any[], field: string) {
    const periods = [...new Set(data.map(d => d.period))].sort();
    const criteriaValues = [...new Set(data.map(d => d.criteria_value))];

    // Create the basic stacked data
    const stackedData = periods.map(period => {
      const periodData = data.filter(d => d.period === period);
      const result: any = { period };

      criteriaValues.forEach(criteriaValue => {
        const match = periodData.find(d => d.criteria_value === criteriaValue);
        result[criteriaValue] = match ? match[field] : 0;
      });

      return result;
    });

    // Calculate totals for each criteria value
    const totals = criteriaValues.reduce((acc, criteria) => {
      acc[criteria] = stackedData.reduce(
        (sum, item) => sum + (item[criteria] || 0),
        0,
      );
      return acc;
    }, {});

    // Sort criteria values by their totals
    const sortedCriteriaValues = criteriaValues.sort(
      (a, b) => totals[b] - totals[a],
    );

    // Reorder the properties in each data point according to the sorted criteria
    return stackedData.map(item => {
      const orderedItem: any = { period: item.period };
      sortedCriteriaValues.forEach(criteria => {
        orderedItem[criteria] = item[criteria];
      });
      return orderedItem;
    });
  }

  async function fetchData() {
    if (!accountId || !timeGroup || isLoading) return;

    isLoading = true;
    try {
      const data = await getCustomerOrderMetrics({
        customer_uuid: accountId,
        time_group: timeGroup,
        criteria,
        date_from,
        date_to,
      });

      if (criteria) {
        stackedData = transformToStackedData(data, field);
      } else {
        statistics = data
          .map(d => ({
            period: d.period,
            value: d[field],
          }))
          .reverse();
      }
    } catch (error) {
      console.error('Error fetching metrics:', error);
    } finally {
      isLoading = false;
    }
  }

  // Watch for prop changes
  $: {
    if (accountId && timeGroup && date_from && date_to && field) {
      fetchData();
    }
  }

  // Initial fetch
  onMount(() => {
    if (accountId && timeGroup) {
      fetchData();
    }
  });
</script>

<div class="relative">
  <!-- Title Section -->
  <div class="mb-4">
    <h3 class="text-lg font-semibold text-foreground">{chartTitle}</h3>
    {#if isLoading}
      <span class="text-sm text-muted-foreground">Loading...</span>
    {/if}
  </div>

  <div class="transition-opacity duration-300" class:opacity-50={isLoading}>
    {#if criteria && stackedData.length > 0}
      <StackedBarChart
        data={stackedData}
        title={chartTitle}
        margin={{ top: 10, right: 10, bottom: 50, left: 40 }}
        width={'100%'}
        height={'100%'}
        adjustTextSize={true}
        adjustTooltipPosition={true}
      />
    {:else if statistics.length > 0}
      <BarChart
        data={statistics}
        title={chartTitle}
        margin={{ top: 10, right: 10, bottom: 50, left: 40 }}
        width={'100%'}
        height={'100%'}
        adjustTextSize={true}
        adjustTooltipPosition={true}
      />
    {/if}
  </div>

  {#if !statistics.length && !stackedData.length}
    <div
      class="flex absolute inset-0 justify-center items-center bg-background/50"
    >
      <div class="text-sm text-muted-foreground">
        {isLoading ? 'Loading...' : 'No data available for this period'}
      </div>
    </div>
  {/if}
</div>

<style>
  /* Add any additional styling here */
  .chart-container {
    width: 100%;
    height: 100%;
  }
  @media (max-width: 768px) {
    .chart-container {
      width: 100%;
      height: 300px;
    }
  }
</style>
