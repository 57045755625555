<script lang="ts">
  import { onMount } from 'svelte';
  import { getItems } from '$lib/api/queries';
  import { formatCurrency } from '$lib/utils/hanta-utils';
  import { formatDate } from '$lib/app/utils';
  import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
  } from '$lib/components/ui/card';
  import { popupStore } from '$lib/stores/app-store';
  import Button from '$lib/components/ui/button/button.svelte';
  import Icon from '@iconify/svelte';
  import { Separator } from '$lib/components/ui/separator';
  import { Badge } from '$lib/components/ui/badge';

  export let accountId: string;

  let credits = [];
  let loading = true;
  let error = null;
  let totalRemaining = 0;

  async function loadCredits() {
    try {
      loading = true;
      error = null;

      // Use batched queries for better performance with large datasets
      const batchSize = 1000;
      let allCredits = [];
      let hasMore = true;
      let offset = 0;

      while (hasMore) {
        const result = await getItems({
          collection: 'credits',
          filters: [
            {
              field: 'accountId',
              operator: 'eq',
              value: accountId,
              active: true,
            },
          ],
          from: offset,
          to: offset + batchSize,
        });

        if (result.error) {
          throw result.error;
        }

        if (result.data && result.data.length > 0) {
          allCredits = [...allCredits, ...result.data];
          offset += result.data.length;
        }

        // If we got fewer results than the batch size, we've reached the end
        hasMore = result.data && result.data.length === batchSize;
      }

      // Filter credits with positive remaining amounts
      credits = allCredits
        .filter(credit => credit.remainingAmount > 0)
        .sort((a, b) => new Date(b.paymentDate) - new Date(a.paymentDate));

      // Calculate total remaining amount
      totalRemaining = credits.reduce(
        (sum, credit) => sum + (credit.remainingAmount || 0),
        0,
      );
    } catch (err) {
      console.error('Error loading credits:', err);
      error = err.message || 'Failed to load credits';
    } finally {
      loading = false;
    }
  }

  function openCredit(id) {
    popupStore.openPopup('crmObject', {
      module: 'credits',
      id,
    });
  }

  function createNewCredit() {
    popupStore.openPopup('newItem', {
      module: 'credits',
      defaultValues: {
        accountId: accountId,
      },
    });
  }

  onMount(() => {
    loadCredits();
  });
</script>

<Card class="my-4 bg-secondary">
  <CardHeader class="flex flex-row items-center justify-between space-y-0 pb-2">
    <CardTitle class="text-xl font-medium mb-12">Available Credits</CardTitle>
    <div class="flex items-center gap-2">
      <div class="text-sm font-bold bg-green-600 text-green-50 p-2">
        {formatCurrency(totalRemaining, 'MDL')}
      </div>
      <Button
        variant="ghost"
        size="sm"
        class="hidden h-8 w-8 p-0"
        on:click={createNewCredit}
        title="Add new credit"
      >
        <Icon icon="mdi:plus" class="h-4 w-4" />
      </Button>
    </div>
  </CardHeader>
  <CardContent>
    {#if loading}
      <div class="flex justify-center py-4">
        <Icon icon="mdi:loading" class="animate-spin h-6 w-6" />
      </div>
    {:else if error}
      <div class="text-red-500 text-sm">{error}</div>
    {:else if credits.length === 0}
      <div class="text-center py-2 text-sm text-muted-foreground">
        No available credits
      </div>
    {:else}
      <div class="space-y-3">
        {#each credits as credit}
          <div class="flex justify-between items-center border-b pb-2">
            <div>
              <div
                class="font-medium text-sm truncate max-w-[400px]"
                title={credit.name}
              >
                {credit.name}
              </div>
              <div
                class="text-xs text-muted-foreground flex items-center gap-1"
              >
                <span>{formatDate(credit.paymentDate)}</span>
                <Badge variant="outline" class="text-xs">{credit.status}</Badge>
              </div>
            </div>
            <div class="flex items-center gap-2">
              <div class="text-sm font-semibold">
                {formatCurrency(
                  credit.remainingAmount,
                  credit.currency || 'MDL',
                )}
              </div>
              <Button
                variant="ghost"
                size="sm"
                class="h-8 w-8 p-0"
                on:click={() => openCredit(credit.id)}
              >
                <Icon icon="mdi:open-in-new" class="h-4 w-4" />
              </Button>
            </div>
          </div>
        {/each}

        {#if credits.length > 0}
          <div class="pt-2 flex justify-between items-center">
            <span class="text-sm font-medium">Total Available</span>
            <span class="text-sm font-bold"
              >{formatCurrency(totalRemaining, 'MDL')}</span
            >
          </div>

          <div class="hidden pt-2">
            <Button
              variant="outline"
              size="sm"
              class="w-full"
              on:click={() =>
                popupStore.openPopup('specialPage', { page: 'credits' })}
            >
              <Icon icon="mdi:credit-card-outline" class="h-4 w-4 mr-2" />
              View All Credits
            </Button>
          </div>
        {/if}
      </div>
    {/if}
  </CardContent>
</Card>
