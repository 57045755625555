import { authStore } from '$lib/stores/auth-store';
import { supabase } from './supabase';
import { get } from 'svelte/store';

export async function docx2pdf(source, destination) {
  const { data, error } = await supabase(
    get(authStore)?.token,
  ).functions.invoke('docx-to-pdf', {
    body: { source, destination },
  });

  if (error) console.error(error);
  return data;
}

export async function generateDocx(payload) {
  const { data, error } = await supabase(
    get(authStore)?.token,
    false,
  ).functions.invoke('docx-template', {
    body: payload,
  });

  if (error) console.error(error);
  return data;
}
