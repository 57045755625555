<script lang="ts">
  import * as Tabs from '$lib/components/ui/tabs';
  import { fly } from 'svelte/transition';
  import { appStore } from '$lib/stores/app-store';
  import ItemsList from './list/items-list.svelte';
  import Badge from '$lib/components/ui/badge/badge.svelte';
  import { cn } from '$lib/utils/hanta-utils';

  import NewItemNavButton from './navigation/new-item-nav-button.svelte';

  let countInPrimaryTab = 0;
  const modulePages = [
    'accounts',
    'contacts',
    'deals',
    'invoices',
    'orders',
    'leads',
    'issues',
    'contracts',
    'tariffs',
    'payments',
    'credits',
    'expenses',
    'balances',
    'activities',
    'manifests',
  ];
  $: isModulePage = modulePages.includes($appStore.currentModule);
</script>

{#if isModulePage}
  <div
    in:fly={{ x: -200, duration: 250 }}
    class={cn(
      'fixed overflow-auto  ',
      $appStore.isMobile
        ? 'h-full w-full'
        : $appStore.isCollapsed
          ? 'top-[0px] left-[70px] w-[240px] h-[100svh]'
          : 'top-[0px] left-[210px] w-[240px] h-[100svh]',
    )}
  >
    <Tabs.Root value="all" class="flex flex-col">
      <div class="flex items-center p-2 space-x-2">
        <h1
          class="ml-1 text-xl font-bold capitalize md:flex-1 flex justify-between"
        >
          <div class="flex items-center flex-1 space-x-2">
            <div>
              {$appStore.currentModule}
            </div>

            <Badge variant={'default'}>{countInPrimaryTab}</Badge>
          </div>
          <NewItemNavButton />
        </h1>
      </div>

      <Tabs.Content value="all" class="mt-2">
        <ItemsList
          bind:count={countInPrimaryTab}
          module={$appStore.currentModule}
          current={$appStore.selected}
          selected={[]}
          sort={$appStore.sort}
          filters={$appStore.filters}
          on:goto={e => appStore.goToPage(e.detail)}
          on:next={appStore.nextPage}
          on:previous={appStore.prevPage}
          on:search={e => appStore.setSearch(e.detail)}
          on:sort={e => appStore.setSort(e.detail)}
          on:filters={e => appStore.setFilters(e.detail)}
          on:current={e => {
            appStore.select(e.detail);
            appStore.openPopup(true);
          }}
        />
      </Tabs.Content>
      <Tabs.Content value="unread" class="-mt-2">TODO</Tabs.Content>
    </Tabs.Root>
  </div>
{/if}
