import { NumberUtils } from './utils/NumberUtils';
import type { PriceRequest, Service } from './prices';
import { getWeek, getYear } from 'date-fns';

export class ServicePreparator {
  private readonly ELIGIBLE_OPERATORS = ['TNT', 'FedEx'] as const;

  public prepareServices(item: PriceRequest): void {
    if (!this.isEligibleOperator(item.operator)) return;

    if (item.insurance) this.prepareInsurance(item);
    if (item.securityFee) this.prepareSecurityFee(item);
    if (item.odaFee) this.prepareOdaFee(item);
    if (item.fuelFee) this.prepareFuelFee(item);

    if (item.additionalServices) this.prepareAdditionalServices(item);
  }

  private prepareAdditionalServices(item: PriceRequest): void {
    if (item.additionalServices) {
      item.additionalServices.forEach((service: string) => {
        const additionalService = this.findOrCreateService(item, service);
        additionalService.operator = item.operator;
      });
    }
  }

  private isEligibleOperator(
    operator?: string,
  ): operator is (typeof this.ELIGIBLE_OPERATORS)[number] {
    return this.ELIGIBLE_OPERATORS.includes(operator as any);
  }

  private findOrCreateService(
    item: PriceRequest,
    productName: string,
  ): Service {
    let service = item.services?.find(
      (service: Service) => service.product === productName,
    );

    if (!service) {
      service = {
        product: productName,
        weight: item.weight,
      };
      item.services = item.services || [];
      item.services.push(service);
    }

    service.mdl2eur = item.mdl2eur;
    return service;
  }

  private prepareInsurance(item: PriceRequest): void {
    if (item.insurance) {
      const insuranceProductName = 'Страховка ' + item.insurance;
      const insurance = this.findOrCreateService(item, insuranceProductName);
      insurance.operator = item.operator;
      insurance.weight = NumberUtils.roundValue(item.priceInInvoiceEur || 0);
    }
  }

  private prepareSecurityFee(item: PriceRequest): void {
    if (item.securityFee) {
      const securityFee = this.findOrCreateService(
        item,
        'Надбавка за безопасность',
      );
      securityFee.operator = item.operator;
    }
  }

  private prepareOdaFee(item: PriceRequest): void {
    if (item.odaFee) {
      const odaFeeProductName =
        'ODA Surcharge' +
        (item.operator === 'TNT'
          ? ' ' + item.direction + ' ' + item.product
          : '');
      const odaFee = this.findOrCreateService(item, odaFeeProductName);
      odaFee.weight = 1;
      odaFee.operator = item.operator;
    }
  }

  private prepareFuelFee(item: PriceRequest): void {
    if (item.fuelFee) {
      const fuelFee = this.findOrCreateService(item, 'Топливная надбавка');
      fuelFee.weight = NumberUtils.roundValue(item.price);
      fuelFee.weightOrig = NumberUtils.roundValue(item.supplierEur);
      fuelFee.operator = item.operator;

      const refDate = item.date
        ? item.date.substr(0, 10)
        : new Date().toISOString().split('T')[0];
      const datum = new Date(refDate);
      fuelFee['week'] = getWeek(datum) + ' ' + getYear(datum);
    }
  }
}
