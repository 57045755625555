<script lang="ts">
  import AccountKpi from './account-kpi.svelte';
  import * as Tabs from '$lib/components/ui/tabs';
  import Activities from '$lib/pages/activities/activities.svelte';
  import AccountInvoices from './account-invoices.svelte';
  import ContactsWidget from '$lib/widgets/contacts/contacts-widget.svelte';
  import ContractsWidget from '$lib/widgets/contracts/contracts-widget.svelte';
  import PageWithTitle from '../../ui/page-with-title.svelte';
  import { type ActivityWithRefs, type AccountWithRefs } from '$db/schema';
  import DealsWidget from '$lib/widgets/deals-widget.svelte';
  import Files from '$lib/components/hanta/files/files.svelte';
  import AccountCredits from '$lib/components/hanta/accounts/account-credits.svelte';
  import AccountDebits from '$lib/components/hanta/accounts/account-debits.svelte';
  import AccountForm from '$lib/widgets/accounts/account-form.svelte';

  import AccountStatistics from './account-statistics.svelte';

  import AccountCardShort from './account-card-short.svelte';
  import AccountCard from './account-card.svelte';
  import { appStore } from '$lib/stores/app-store';

  import AccountLeads from './account-leads.svelte';
  import AccountOrders from './account-orders.svelte';
  import { authStore } from '$lib/stores/auth-store';
  import { guardFinanceAccess, guardSalesAccess } from '$lib/auth/guards';
  import { currentTabStore } from './accounts-store';
  type $$Props = { crmAccount: AccountWithRefs };

  export let crmAccount: AccountWithRefs;

  let activity: ActivityWithRefs;
  $: activity = {
    name: '',
    description: '',
    accounts: [crmAccount],
    deals: [],
    candidates: [],
    contacts: [],
  };

  let currentTab: string;
  $: currentTab = $currentTabStore;
</script>

<PageWithTitle>
  <div slot="short-card" class="flex space-x-2">
    <AccountCardShort {crmAccount} {activity} />
  </div>

  <div slot="card" class="flex items-center space-x-8">
    <AccountCard {crmAccount} {activity} />
  </div>

  <div slot="content">
    <Tabs.Root
      value={currentTab}
      onValueChange={value => currentTabStore.set(value)}
      class="flex flex-col mt-4"
    >
      <Tabs.List class="flex justify-start w-fit h-32 md:h-auto flex-wrap">
        {#if $appStore.isMobile}
          <Tabs.Trigger value="about">About</Tabs.Trigger>
        {/if}
        <Tabs.Trigger value="overview">Overview</Tabs.Trigger>
        <Tabs.Trigger value="timeline">Timeline</Tabs.Trigger>

        {#if authStore.isLogistics()}
          <Tabs.Trigger value="financial">Financial</Tabs.Trigger>
          {#if guardFinanceAccess() || guardSalesAccess()}
            <Tabs.Trigger value="analysis">Analysis</Tabs.Trigger>
            <Tabs.Trigger value="statistics">Statistics</Tabs.Trigger>
          {/if}

          <Tabs.Trigger value="orders">Orders</Tabs.Trigger>
          <Tabs.Trigger value="leads">Leads</Tabs.Trigger>
          {#if guardFinanceAccess() || guardSalesAccess()}
            <Tabs.Trigger value="invoices">Invoices</Tabs.Trigger>
          {/if}
        {/if}
      </Tabs.List>
      {#if $appStore.isMobile}
        <Tabs.Content value="about" class="p-2 text-left">
          <AccountForm {crmAccount} type="about" />
        </Tabs.Content>
      {/if}

      {#if authStore.isLogistics()}
        <Tabs.Content
          value="invoices"
          class="overflow-scroll p-2 w-full h-full"
        >
          {#if currentTab === 'invoices'}
            <AccountInvoices accountId={crmAccount?.id} />
          {/if}
        </Tabs.Content>
        <Tabs.Content
          value="statistics"
          class="overflow-scroll p-2 w-full h-full"
        >
          {#if currentTab === 'statistics'}
            {#if authStore.isLogistics()}
              <AccountKpi account={crmAccount} />
            {/if}
            <AccountStatistics accountId={crmAccount?.id} />
          {/if}
        </Tabs.Content>
        <Tabs.Content value="leads" class="overflow-scroll p-2 w-full h-full">
          {#if currentTab === 'leads'}
            <AccountLeads accountId={crmAccount?.id} />
          {/if}
        </Tabs.Content>
        <Tabs.Content value="orders" class="overflow-scroll p-2 w-full h-full">
          {#if currentTab === 'orders'}
            <AccountOrders accountId={crmAccount?.id} />
          {/if}
        </Tabs.Content>

        <Tabs.Content
          value="financial"
          class="overflow-scroll p-2 w-full h-full"
        >
          {#if guardFinanceAccess() || guardSalesAccess()}
            <AccountCredits accountId={crmAccount?.id} />
            <AccountDebits accountId={crmAccount?.id} />
          {/if}
          <AccountForm {crmAccount} type="financial" />
        </Tabs.Content>
        <Tabs.Content
          value="analysis"
          class="overflow-scroll p-2 w-full h-full"
        >
          <AccountForm {crmAccount} type="analysis" />
        </Tabs.Content>
        <Tabs.Content
          value="contracts"
          class="overflow-scroll p-2 w-full h-full"
        ></Tabs.Content>
      {/if}

      <Tabs.Content value="overview" class="overflow-scroll p-2 w-full h-full">
        {#key `${crmAccount?.id}-${crmAccount?.modifiedAt}`}
          {#if authStore.isLogistics()}
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
              <ContractsWidget
                contracts={crmAccount?.contracts || []}
                customerId={crmAccount?.id}
              />
            </div>
          {/if}
          {#if authStore.isHeadhunter()}
            <DealsWidget
              deals={crmAccount?.deals || []}
              customerId={crmAccount?.id}
            />
          {/if}
          <ContactsWidget
            contacts={crmAccount?.contacts || []}
            customerId={crmAccount?.id}
          />

          <div
            class="overflow-hidden p-4 px-4 py-5 my-8 rounded-lg shadow bg-primary-800 sm:p-6"
          >
            <Files folder={`accounts/${crmAccount?.id}`} compact={true} />
          </div>
        {/key}
      </Tabs.Content>

      <Tabs.Content value="timeline" class="overflow-scroll p-2 w-full h-full">
        <Activities id={crmAccount?.id} module={'accounts'} type="all" />
      </Tabs.Content>
    </Tabs.Root>
  </div>

  <div slot="sidebar">
    {#if !$appStore.isMobile}
      <AccountForm bind:crmAccount />
    {/if}
  </div>
</PageWithTitle>
