<!-- status-badge.svelte -->
<script lang="ts">
  import { Badge } from '$lib/components/ui/badge';
  import { cn } from '$lib/utils/hanta-utils.js';

  export let status: string;

  $: statusClass = cn(
    'rounded-none uppercase',
    status === 'Paid' && 'bg-green-600 text-white',
    status === 'DELIVERY' && 'bg-green-600 text-white',
    status === 'DRAFT' && 'bg-blue-600 text-white',
    status === 'ARCHIVE' && 'bg-gray-600 text-white',
    status === 'PENDING' && 'bg-red-600 text-white',
    status === 'PROCESSING' && 'bg-yellow-600 text-white',
    status === 'открыт' && 'bg-yellow-600 text-white',
    status === 'закрыт' && 'bg-green-600 text-white',
    status === 'Succeeded' && 'bg-green-600 text-white',
    status === 'Failed' && 'bg-red-600 text-white',
    status === 'Open' && 'bg-blue-600 text-white',
  );
</script>

<Badge variant="outline" class={statusClass}>
  {status ?? 'n/a'}
</Badge>
