import { subDays, format, addDays } from 'date-fns';
import { supabase } from './supabase';
import { get } from 'svelte/store';
import { authStore } from '$lib/stores/auth-store';
import { insertInvoiceSchema, InvoiceWithRefs, type Invoice } from '$db/schema';
import { toast } from 'svelte-sonner';
import { calculateSum } from '$lib/pages/invoices/invoice-utils';
import { guardFinanceAccess, guardSalesAccess } from '$lib/auth/guards';
import { getItemById } from './queries';

function checkInvoiceAccess() {
  if (!guardFinanceAccess() && !guardSalesAccess()) {
    throw new Error(
      'Access denied: Insufficient permissions for invoice operations',
    );
  }
}

export interface InvoiceStatus {
  status: string;
  count: number;
  amount: number;
}

interface InvoiceQuery {
  label: string;
  startDate?: Date;
  endDate?: Date;
}

export async function invoiceStatistics(
  start: Date,
  end: Date,
): Promise<InvoiceStatus[]> {
  checkInvoiceAccess();

  let query = supabase(get(authStore)?.token, false)
    .from('invoices')
    .select('status, count:id.count(), amount:net.sum()');

  try {
    if (start) {
      query = query.gte('modifiedAt', format(start.toDate(), 'yyyy-MM-dd'));
    }

    if (end) {
      query = query.lt(
        'modifiedAt',
        format(addDays(end.toDate(), 1), 'yyyy-MM-dd'),
      );
    }
  } catch (error) {
    console.error('Error fetching invoice statistics:', error, { start, end });
    throw error;
  }

  const { data, error } = await query;

  if (error) {
    console.error('Error fetching invoice statistics:', error);
    throw error;
  }

  return data || [];
}

export async function cancelInvoice(id: string) {
  checkInvoiceAccess();
  const { data, error } = await supabase(
    get(authStore)?.token,
    false,
  ).functions.invoke('cancel-invoice', {
    body: { id: id },
  });

  if (error) console.error(error);
  return data;
}

const createInvoiceQuery = (query: InvoiceQuery) => {
  checkInvoiceAccess();
  const baseQuery = supabase(get(authStore)?.token, false)
    .from('invoices')
    .select('status, count:id.count(), amount:debit.sum()')
    .filter('status', 'in', '(Open,Overdue)')
    .filter('deleted', 'eq', false);

  if (query.startDate) {
    baseQuery.filter(
      'paymentDeadline',
      'gte',
      format(query.startDate, 'yyyy-MM-dd'),
    );
  }
  if (query.endDate) {
    baseQuery.filter(
      'paymentDeadline',
      'lt',
      format(query.endDate, 'yyyy-MM-dd'),
    );
  }

  return baseQuery;
};

export async function outstandingInvoicesStatistics(): Promise<
  InvoiceStatus[]
> {
  checkInvoiceAccess();
  const currentDate = new Date();
  currentDate.setHours(2, 0, 0, 0);

  const currentDateEnd = new Date();
  currentDateEnd.setHours(21, 59, 59, 999);

  const queries: InvoiceQuery[] = [
    { label: 'More than 60 days past due', endDate: subDays(currentDate, 60) },
    {
      label: '30–60 days past due',
      startDate: subDays(currentDate, 60),
      endDate: subDays(currentDateEnd, 30),
    },
    {
      label: '0-30 days past due',
      startDate: subDays(currentDate, 30),
      endDate: currentDateEnd,
    },
    { label: 'Not yet past due', startDate: currentDate },
  ];

  try {
    const results = await Promise.all(queries.map(q => createInvoiceQuery(q)));
    return queries.map((q, index) => ({
      status: q.label,
      count: results[index].data[0]?.count ?? 0,
      amount: results[index].data[0]?.amount ?? 0,
      paymentDeadlineStart: q.startDate,
      paymentDeadlineEnd: q.endDate,
    }));
  } catch (error) {
    console.error('Error fetching outstanding invoices statistics:', error);
    throw error;
  }
}

export const finalizeInvoice = async (id: string) => {
  checkInvoiceAccess();
  const { data, error } = await supabase(get(authStore)?.token, false).rpc(
    'finalize_invoice',
    {
      p_id: id,
    },
  );

  if (error || data.error) {
    const e = error || data.error;
    toast.error('Error: ' + e);
    throw Error(e);
  }

  return await getItemById('invoices', id, true);
};

export const updateInvoiceStatus = async (id: string, status: string) => {
  checkInvoiceAccess();
  const { data, error } = await supabase(get(authStore)?.token, false).rpc(
    'update_invoice_status',
    {
      p_id: id,
      p_status: status,
    },
  );

  if (error) {
    toast.error('Error: ' + error.message);
    throw Error(error.message);
  }
};

export const saveInvoice = async (
  invoice: InvoiceWithRefs,
): Promise<InvoiceWithRefs> => {
  checkInvoiceAccess();
  const invoiceToSave = insertInvoiceSchema.parse(invoice);

  const sum = calculateSum(invoice);

  invoiceToSave.gross = sum.sum;
  invoiceToSave.debit = sum.sum;
  invoiceToSave.vat = sum.tax;
  invoiceToSave.net = sum.net;
  invoiceToSave.grossNational = sum.sumNational;

  let response = await supabase(get(authStore)?.token, false)
    .from('invoices')
    .upsert(invoiceToSave)
    .select('id');
  const { data, error } = response;

  if (error) {
    throw Error(error.message);
  } else {
    return data[0] as InvoiceWithRefs;
  }
};

export async function getAccountInvoices(accountId: string) {
  checkInvoiceAccess();

  // Only fetch the last 1000 invoices (most recent ones)
  const { data, error } = await supabase(get(authStore)?.token, false)
    .from('invoices')
    .select('*')
    .eq('customer', accountId)
    .order('invoiceDate', { ascending: false }) // Order by most recent first
    .limit(1000); // Limit to 1000 records

  if (error) throw error;

  // Sort in ascending order for processing
  const sortedData = data
    ? [...data].sort(
        (a, b) =>
          new Date(a.invoiceDate).getTime() - new Date(b.invoiceDate).getTime(),
      )
    : [];

  // Process the data to identify gaps greater than 12 months
  if (sortedData && sortedData.length > 1) {
    const processedData = [];

    for (let i = 0; i < sortedData.length; i++) {
      // Add the current invoice
      processedData.push(sortedData[i]);

      // If there's a next invoice, check for a gap
      if (i < sortedData.length - 1) {
        const currentDate = new Date(sortedData[i].invoiceDate);
        const nextDate = new Date(sortedData[i + 1].invoiceDate);

        // Calculate the gap in months
        const yearDiff = nextDate.getFullYear() - currentDate.getFullYear();
        const monthDiff = nextDate.getMonth() - currentDate.getMonth();
        const gapInMonths = yearDiff * 12 + monthDiff;

        // If gap is greater than 12 months, add a marker
        if (gapInMonths > 12) {
          processedData.push({
            id: `gap-${i}`,
            isGapMarker: true,
            gapInMonths,
            beforeInvoiceDate: sortedData[i].invoiceDate,
            afterInvoiceDate: sortedData[i + 1].invoiceDate,
          });
        }
      }
    }

    // Sort in descending order for display (newest first)
    return processedData.reverse();
  }

  // Return the data in descending order (newest first)
  return sortedData.reverse();
}

export async function getAccountInvoicesWithFilter(filter: {
  status?: string;
  startDate?: string;
  endDate?: string;
  paymentDeadlineStart?: string;
  paymentDeadlineEnd?: string;
}) {
  checkInvoiceAccess();
  let query = supabase(get(authStore)?.token, false)
    .from('invoices')
    .select('*, company:accounts(id, name, sales:users(id, name))')
    .filter('status', 'eq', filter.status)
    .filter('deleted', 'eq', false);

  if (filter.startDate) {
    query = query.filter('modifiedAt', 'gte', filter.startDate);
  }
  if (filter.endDate) {
    query = query.filter(
      'modifiedAt',
      'lt',
      format(addDays(new Date(filter.endDate), 1), 'yyyy-MM-dd'),
    );
  }
  if (filter.paymentDeadlineStart) {
    query = query.filter(
      'paymentDeadline',
      'gte',
      new Date(filter.paymentDeadlineStart).toISOString(),
    );
  }
  if (filter.paymentDeadlineEnd) {
    query = query.filter(
      'paymentDeadline',
      'lt',
      addDays(new Date(filter.paymentDeadlineEnd), 1).toISOString(),
    );
  }

  query = query.order('createdAt', { ascending: false });

  const { data, error } = await query;
  if (error) throw error;
  return data;
}
